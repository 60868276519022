import { createSlice } from '@reduxjs/toolkit';

const chatSelectedUser = createSlice({
  name: 'chatSelectedUser',
  initialState: [],
  reducers: {
    toggleUser: (state, action) => {
      const user = action.payload;
      const userIds = state.map((selectedUser) => selectedUser.id);

      return userIds.includes(user.id)
        ? state.filter((selectedUser) => selectedUser.id !== user.id)
        : [...state, user];
    },
    resetSelectedUser: (state) => {
      return [];
    },
  },
});

export const { toggleUser, resetSelectedUser } = chatSelectedUser.actions;
export default chatSelectedUser.reducer;
