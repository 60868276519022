import React, {useEffect, useState} from "react"
import {getDomain, getItems} from "../../../functions/functions";
import {routes} from "../../../pages";

function SendSMSSearchContaragent(props) {
    const [value, setValue] = useState(''),
        [displayName, setDisplayName] = useState(''),
        [addButton, setAddButton] = useState(false),
        [options, setOptions] = useState([]),
        [showOptions, setShowOptions] = useState(false),
        [canAdd, setCanAdd] = useState(props.canAdd ? props.canAdd : true)


    const contragent_url = routes.filter(r => r.name === "counterparty")

    useEffect(() => {
        if (props?.start_display_name) {
            setDisplayName(props.start_display_name)
        }
    }, []);

    const emptySearch = () => {
        setAddButton(true)
        return 'Нет результатов'
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler(e)
        }
    }

    const setData = (data) => {
        const arr = []
        if (data.count > 0) {
            for (let contragent of data.results) {
                const option = {
                    value: contragent.id,
                    display_name: contragent.org_name ? contragent.org_name : contragent.fio,
                    phone: contragent.phone
                }
                arr.push(option)
                setAddButton(false)
            }
        } else {
            emptySearch()

        }

        setOptions(arr)

    }

    const setError = (message) => {
        console.log('====================================');
        console.log('Error', message);
        console.log('====================================');
    }

    const searchHandler = (e) => {
        // console.log('e.target.value', e.target.value)
        if (e.target.value.length >= 2) {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
        e.preventDefault()
        setDisplayName(e.target.value)
        const url = `${getDomain()}${contragent_url[0]?.url}?search=${e.target.value}`
        //setStatus(() => <ContentLoader />)

        getItems(url, 'GET', setData, false, setError)
    }

    const selectContragent = (value, name) => {
        setShowOptions(false)
        setValue(value)
        setDisplayName(name)
    }

    return (
        <>
            <div className={"form__item " + props.classes}>
                <span className="error-message">{props.error ? props.error : ''}</span>
                <div className={`contragent__fake-select ${!showOptions ? 'd-none' : ''}`}>
                    {options.length > 0 ?
                        options.map(op => (
                            <span className={displayName === op.display_name ? 'selected' : ''} onClick={() => {
                                selectContragent(op.value, op.display_name)
                                if (props?.setValue) {
                                    props.setValue(op)
                                }
                            }}>{op.display_name}</span>
                        )) :
                        <span className="no-resalts">Нет результатов</span>
                    }
                </div>

                {/*<input type="hidden" value={value} name={props.field.name}/>*/}
                <div className="search-contragent-wrap">
                    <input
                        onChange={e => searchHandler(e)}
                        onKeyDown={onEnter}
                        value={displayName}
                        type="text"
                        className='contragent_search'
                        placeholder='Введите слово для поиска…'/>
                </div>
            </div>
        </>
    )
}


export default SendSMSSearchContaragent