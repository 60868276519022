import React from "react"

function TextItem(props) {
    return (
        <div className={"form__item " + props.classes}>
            <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span>
            <input type={props.type ? props.type : 'text'}
                id={props.field.id}
                ref={props.inputRef}
                name={props.field.name}
                value={props.value}
                onChange={props.handler ? e => props.handler(e, props.field, props.setValue, props.setError) : props.mask ? e => props.mask(e, props.setValue, props.setError) : e => props.fieldHandler(e)}
                onBlur={props.blurHandle}
                className={props.fieldDirty && props.error !== '' ? "error" : ""}
                placeholder={props.field.placeholder}
                required={props.required}
                min={props.type === 'number' ? 0 : ''}
                readOnly={props.readOnly} />
            <label htmlFor={props.field.name}>{props.field.label}{props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default TextItem