import React, { useState, useEffect } from "react"
import MySelect from "./MySelect";

function SelectItem(props) {
    const [value, setValue] = useState('')

    useEffect(() => {
        props.initiator(value.label)
    }, [value])

    useEffect(() => {
        if (props.field.value || props.value) {
            const val = props.value ? props.value : props.field.value;
            const options = props.field.children.filter(option => {
                if (typeof val !== 'object' && !Array.isArray(val)) {
                    return option.value === val || option.value === +val;
                } else if (Array.isArray(val)) {
                    return val.some(v => option.value === v.id);
                } else {
                    try {
                        const new_val = val.id
                        return option.value === new_val
                    } catch {
                        return option.value === val.toString();
                    }
                }
            });
            setValue(options);
        }
    }, []);


    const handleChange = selectedOption => {setValue(selectedOption), console.log(selectedOption);}
    

    return (
        <div className={"form__item " + props.classes}>
            <span className="error-message">{props.error ? props.error : ''}</span>
            <MySelect classNamePrefix="select-item"
                className={props.readOnly ? '_read-only' : ''}
                onChange={handleChange}
                noOptionsMessage={() => 'Нет результатов'}
                value={value}
                options={props.field.children}
                isMulti={props.field.multiple && true}
                name={props.field.name}
                placeholder={props.field.placeholder}
                isSearchable={true}
                required={props.required}
                isReadOnly={props.readOnly}
            />
            <label htmlFor={props.field.name}>{props.field.label}{props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default SelectItem