import React, { useEffect, useState } from "react"

import FormErrorsContext from "../functions/formErrorsContext";
import { getItems } from "../functions/functions";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteFilterData, setFilterData } from "../store/filtersSlice";

function Filter(props) {
    const [formErrors, setFormErrors] = useState({})

    const [filterOptions, setFilterOptions] = useState(false)
        , pathname = useLocation().pathname
        , dispatch = useDispatch()
        , addFilterData = (name, value) => dispatch(setFilterData([name, value]))
        , cleanFilterData = (name) => dispatch(deleteFilterData(name))


    useEffect(() => {
        console.log('Filter props --------------', props)
        
        setFilterOptions(false)
        const runDataTimeout = setTimeout(() => {
            getItems(props.url, 'OPTIONS', (data) => setFilterOptions(data))
        }, 3000)
        return () => {
            setFilterOptions(false)
            clearTimeout(runDataTimeout);
        }
    }, [pathname])

    // page filter
    const filterData = (e) => {
        e.preventDefault()
        const form = e.currentTarget
            , formData = new FormData(form)
            , fields = []
        for (let [name, value] of formData) {
            if (value) {
                const obj = {}
                obj[name] = value
                fields.push(obj)
            }
        }
        addFilterData(props.name, fields)
        console.log('filter fields', fields);
    }

    // clear page filter
    const clearFilter = (e) => {
        e.preventDefault()
        //const url = props.is_my ? props.url + `&is_my=${userData["user_id"]}` : props.url
        //getItems(url, 'GET', setData)
        cleanFilterData(props.name)
    }

    return (
        <>
            <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                <form className="filter-form" action="" onSubmit={filterData}>
                    <props.filter url={props.url} values={props.selectedFields} options={filterOptions} />
                    <input type="submit" className="d-none" id="submit-filter" />
                    <label className="filter-submit" htmlFor="submit-filter">Применить</label>
                    <a className="filter-clear" href="" onClick={clearFilter}>Сбросить</a>
                </form>
            </FormErrorsContext.Provider>
        </>
    )
}

export default Filter