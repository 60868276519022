import React from "react";

import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import getFormattedDateTime from "../../functions/getFormattedDateTime";
import TextEditor from "../../components/form/TextEditor/TextEditor"


function LentaFields({ fields }) {
  console.log("fields", fields);

  return (
    <>
      <FormItem field={fields.organisation} required={true} classes="_full" />
      <FormItem field={fields.author} required={true} read_only={true} classes="_full" value={fields.author.value ? fields.author.value : localStorage.getItem('user_id')} />
      <FormItem field={fields.upload_at} classes="_full" type='date' read_only={true} value={fields.upload_at.value ? getFormattedDateTime(fields.upload_at.value) : getFormattedDateTime(Date.now())} />
      <FormItem field={fields.title} required={true} classes="_full" />
      {/* <FormItem field={fields.content} classes="_full" /> */}
      <TextEditor field={fields.content} classes="_full" />

      <DocumentAddFormItem field={fields.files} classes="_full" />
    </>
  );
}
export default LentaFields;
