import React from 'react'
import ViewPage from '../../components/ViewPage'
import ViewContainer from "../../components/ViewContainer/ViewContainer";

const SendSmsView = (props) => {
    return (
            <ViewPage {...props} />
    )
}

export default SendSmsView