import React, {useEffect, useState} from "react"

import FormItem from "../../components/form/FormItem";
import {createFieldsObject} from "../../functions/functions";

function SendSMSFilter(props) {
    const fields = ['is_sent', 'sender'] // 'register_date_in', 'register_date_out'
    const [items, setItems] = useState([])

    const isSentOptions = [
        {value: 'true', label: 'Отправлены', display_name: 'Отправлены'},
        {value: 'false', label: 'Не отправлены', display_name: 'Не отправлены'},
    ]

    // console.log('[[[[[[[[[[[', props.values)
    useEffect(() => {
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        const isSent = {
            children: isSentOptions,
            label: "Состояние",
            name: "is_sent",
            placeholder: "",
            read_only: false,
            required: true,
            type: "select",
            value: ""
        }
        new_fields.is_sent = isSent
        setItems(new_fields)
    }

    const getValue = (key) => {
        const value = props.values.find(item => item[key] !== undefined)
        if (value !== undefined) {
            return value[key]
        }
        return ''
    }

    return (
        <>
            {Object.keys(items).length > 0 ? fields.map((key) => {
                const value = getValue(key)
                const new_field = items[key]
                console.log('value', value)
                return <FormItem key={key} field={new_field} required={false} value={value}/>
            }) : <>загрузка</>}
        </>
    )
}

export default SendSMSFilter