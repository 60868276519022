import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { useSelector } from "react-redux"

import { createFieldsObject, getItems, getDomain } from "../functions/functions";
import { useParams } from "react-router-dom"
import HeaderInside from "./HeaderInside";
import ContentLoader from "./ContentLoader";
import Alert from "./Allert";

function DetailPage(props) {

    const [fields, setFields] = useState({})
        , [modelType, setModelType] = useState(false)
        , userData = useSelector(state => state.user_data.userData)
        , fetchNavNotifications = useOutletContext()
        , [status, setStatus] = useState(() => <ContentLoader />)
        , params = useParams()
        // , [notifications, getNewNotifications] = useOutletContext()
        , location = useLocation()
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , [deleted, setDeleted] = useState(false)
        , navigate = useNavigate()
        , userHasPermission = (permission) => {
            const groups = userData.groups ? userData.groups : []
            const permissions = userData.permissions ? userData.permissions : []
            let status = false

            if (groups && permissions)
                status = groups.indexOf('Admin') !== -1 || permissions.indexOf(permission.toLowerCase()) !== -1
            else if (groups)
                status = groups.indexOf('Admin') !== -1
            else if (permissions)
                status = permissions.indexOf(permission.toLowerCase()) !== -1
            if (fields.registrar) {
                if (fields.registrar.value == userData.user_id)
                    status = true
            }
            if (fields.author) {
                if (fields.author.value == userData.user_id)
                    status = true
            }
            if (fields.creator) {
                if (fields.creator.id == userData.user_id)
                    status = true
            }
            return status
        }
        , isAdmin = () => {
            const groups = userData.groups ? userData.groups : []
            if (groups.indexOf('Admin') !== -1) {
                return true
            } else {
                return false
            }
        }
    // Нужно для контролирования возможности изменения в компоненте props.component
    const [canEdit, setCanEdit] = useState(true)

    useEffect(() => {
        setAlert({ title: '', text: '', type: '', })

        getItems(`${props.url}${params.id}/?format=json`, 'OPTIONS', setData, false, (error) => {
            setStatus('')
            setAlert({
                title: 'Ошибка загрузки',
                text: error, //'При загрузке произошла ошибка, попробуйте обновить страницу',
                type: 'error', // error, alert, info, success
            })
        })
        if (location.state ? location.state.message && true : false) {
            const m = location.state.message
            setAlert({ title: m.title ? m.title : '', text: m.text ? m.text : '', type: m.type ? m.type : 'success' })
        }
    }, [])

    useEffect(() => {
        getItems(`${getDomain()}/api/notifications/read`, "POST", data => {
            fetchNavNotifications()
        }, JSON.stringify({
            owner_id: userData.user_id,
            notification_ids: null,
            instance_ids: [params.id],
            model_name: props.model_name
        }))
    }, [])

    const setData = (data) => {
        setAlert({ title: '', text: '', type: '', })
        try {
            setModelType(data.actions.PUT.type)
        } catch { }
        const newFields = createFieldsObject(data.actions.PUT)
        const setValues = (data) => {
            setAlert({ title: '', text: '', type: '', })
            for (const key in data) {
                if (newFields[key])
                    newFields[key].value = data[key] ?? ''
            }
            if (data.creator) {
                newFields['creator'] = {
                    id: data.creator.id,
                    label: "Создатель",
                    value: data.creator.first_name + ' ' + data.creator.last_name
                }
            }
            console.log('newFields', newFields)
            setFields(newFields)
        }
        getItems(`${props.url}${params.id}/`, 'GET', setValues)
    }

    const deleteItem = (event) => {
        event.preventDefault()
        const url = `${props.url}${params.id}/`
            , toDelete = confirm('Вы уверены, что хотите удалить объект?')
        if (toDelete) {
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    //'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status >= 500) {
                            throw Error('Ошибка сервера. Попробуйте позже.')
                        } else {
                            throw Error(response.statusText)
                        }
                    }
                    if (response.status === 204) {
                        return response
                    }
                    return response.json()
                })
                .then(data => {
                    setDeleted(true)
                })
                .catch(error => {
                    if (error.message.toLowerCase() === 'failed to fetch') {
                        setAlert({ title: "Ошибка", text: "Доступ запрещен", type: "error" })
                    } else {
                        setAlert({ title: "Ошибка", text: "При удалении возникла ошибка. Обновите страницу и попроуйте еще раз или обратетесь в поддержку", type: "error" })
                    }

                })
        }
    }
    const goToBack = (name) => {
        navigate(name === 'lenta' ? '/' : `/${name}/`)
    }


    const userIsCreator = () => {
        return (+fields.author?.value === +userData.user_id || +fields.registrar?.value === +userData.user_id || +fields.creator?.id === +userData.user_id)

    }

    return (
        <div className={deleted ? "" : "page"}>
            {deleted
                ?
                <div className="page-error">
                    <div className="page-error__card">
                        <h1 className="page-error__title">Объект удален</h1>
                        <p className="page-error__message"><a href="" onClick={(e) => {
                            e.preventDefault()
                            goToBack(props.name)
                        }}>Вернуться к списку</a></p>
                    </div>
                </div>
                :
                <>
                    <HeaderInside title={props.title}
                                  edit={canEdit && userHasPermission('change_' + props.model_name) ? {url: `/${props.name}/edit/${params.id}`} : false}
                                  create={userHasPermission('add_' + props.model_name) ? {url: `/${props.name}/create/`} : false}
                        // delete_btn={userHasPermission('delete_' + props.model_name) ? { deleteItem: deleteItem } : false}
                                  delete_btn={props.can_delete && (isAdmin() || (userHasPermission('delete_' + props.model_name) && userIsCreator())) ? {deleteItem: deleteItem} : false
                                  }
                                  clickEvent={() => goToBack(props.name)}
                    />
                    <hr/>
                    <div className="page__content">
                        <Alert setAlert={setAlert} alert={alert}/>
                        {Object.keys(fields).length > 0 ?
                            <props.component fields={fields} name={props.name} url={props.url} type={modelType}
                                             id={params.id} setCanEdit={setCanEdit}/> :
                            status
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default DetailPage