import React from "react";
import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import AddedFormItem from "../../components/form/AddedFormItem";
import SelectTaskGroup from "./SelectTaskGroup";
import TextEditor from "../../components/form/TextEditor/TextEditor";
import getFormattedDate from "../../functions/getFormattedDate";


function TasksFields({ fields, basedOn, itemNumber, itemUrl}) {

    const formatted_date = getFormattedDate()

    // console.log('fields', fields);
    return (
        <>
            <FormItem field={fields.organisation} classes="_full" />
            <FormItem field={fields.receiver} required={true} classes="_full" />
            <FormItem field={fields.author} required={true} classes="_full" read_only={true} value={fields.author.value ? fields.author.value : localStorage.getItem('user_id')} />

            <SelectTaskGroup field={fields.task_project} classes="_full" />

            <FormItem field={fields.date_of_creation} classes="_full" read_only={true} value={fields.date_of_creation.value ? fields.date_of_creation.value : formatted_date} />
            <FormItem field={fields.dead_line} classes="_full" />
            <FormItem field={fields.name} required={true} classes="_full"  value={basedOn && `${basedOn} ${itemNumber}`}/>

            <TextEditor field={fields.description} classes="_full"  value={basedOn ? `<a href="${itemUrl}">${basedOn} ${itemNumber}</a>` : ''} />

            <input type="hidden" name='tasks_status' value={fields.tasks_status.value ? fields.tasks_status.value : 4} />
            {/* <FormItem field={fields.file} classes="_full"/> */}
            <DocumentAddFormItem field={fields.other_doc} classes="_full" />

            <AddedFormItem field={fields.soresponsibles} classes="_full" button={'Добавить соисполнителя'} />
            <AddedFormItem field={fields.observers} classes="_full" button={'Добавить наблюдателя'} />
        </>
    )
}
export default TasksFields;