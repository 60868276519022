import React, {useEffect, useState} from "react";
import CustomCardsLenta from "../../components/CardsLenta/customCardsLenta";
import UpcomingDatesTableItem from "./UpcomingDatesTableItem";
import apiConfig from "../../api/apiConfig";
import {getItems} from "../../functions/functions";

export default function UpcomingDatesTable() {
    const [users, setUsers] = useState([])
    const NUM_OF_DAYS = 7

    const fetchUsers = (important_dates_after, important_dates_before) => {
        const url = apiConfig.baseUrl + `user/?important_dates_after=${important_dates_after}&important_dates_before=${important_dates_before}`
        getItems(url, "GET", (data) => (setUsers(data.results)))

    }

    useEffect(() => {
        const startDate = new Date()
        const endDate = new Date()
        endDate.setDate(startDate.getDate() + NUM_OF_DAYS - 1)

        fetchUsers(startDate.toLocaleDateString(), endDate.toLocaleDateString())
    }, []);

    const getContent = () => {
        const startDate = new Date()
        const endDate = new Date()
        endDate.setDate(startDate.getDate() + NUM_OF_DAYS - 1)

        const res = []
        for (let i = 0; i < NUM_OF_DAYS; i++) {
            const curDate = new Date(startDate)
            curDate.setDate(startDate.getDate() + i)
            res.push(<UpcomingDatesTableItem date={curDate} users={users} key={i}/>)
        }

        return res
    }

    return (
        <div className={'upcomingDatesTable'}>
            {getContent()}
        </div>
    )
}