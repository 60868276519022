import React, { useState } from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import Lightbox from "../../components/Lightbox";

function UsersPage(props) {
    const [modaldStyle, setModaldStyle] = useState({ display: 'none' })
    const [imgUrl, setImgUrl] = useState(false)
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())

    const closeModal = (e) => {
        e.preventDefault()
        setImgUrl(false)
        setTimeout(() => setModaldStyle({ display: 'none' }), 300)
    }
    const openModal = (e, file) => {
        e.preventDefault()
        setModaldStyle({ display: 'grid' })
        setTimeout(() => setImgUrl(file), 100)
    }

    return (
        <>
            <table className="table _users">
                <thead>
                    <tr>
                        <th></th>
                        <th>ФИО</th>
                        <th>Должность БИК</th>
                        <th>Должность Фонд</th>
                        <th>Структура БИК</th>
                        <th>Структура Фонд</th>
                        <th className="_center">Внутренний</th>
                        <th className="_center">Личный телефон</th>
                        <th className="_center">СКУД Фонд</th>
                        <th className="_center">Электронная почта</th>
                        <th className="_center">Статус</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td>{item.file ?
                                    <img src={item.file} style={{ cursor: 'pointer' }} className="table__img" onClick={(e) => openModal(e, item.file)} /> :
                                    <span className='comment__no-photo'></span>}</td>
                                <td><Link to={`detail/${item.id}`} className="table__link">{item.last_name} {item.first_name} {item.patronymic}</Link></td>
                                <td>{item.post_bik}</td>
                                <td>{item.post_fond}</td>
                                <td>{item.structure_bik}</td>
                                <td>{item.structure_fond}</td>
                                <td className="_center">{item.internal_phone}</td>
                                <td className="_center">{item.personal_phone}</td>
                                {item.skud_fond ?
                                    <td className="_center">{item.skud_fond}</td> :
                                    <td></td>
                                }
                                <td className="_center">{item.email}</td>
                                <td className="_center">{item.status ? item.status.id ? item.status.id : item.status : ''}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
            <Lightbox active={imgUrl} style={modaldStyle} onClick={closeModal} >
                <img src={imgUrl} onClick={closeModal} />
            </Lightbox>
        </>
    )
}

export default UsersPage;