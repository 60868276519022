import React, { useEffect, useState } from 'react'
import { createFieldsObject, createOrUpdate, getItems } from '../../functions/functions'
import FormItem from '../../components/form/FormItem'
import FormErrorsContext from '../../functions/formErrorsContext'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Magic from '../../../public/interface_img/magic.svg'
import Copy from '../../../public/interface_img/copy.svg'
import Alert from '../../components/Allert'


const GigachatComponent = (props) => {
  const userData = useSelector(state => state.user_data.userData)
  const [options, setOptions] = useState({})
    , [fields, setFields] = useState({})
    , [newText, setNewText] = useState('')
    , [formErrors, setFormErrors] = useState({})
    , [newId, setNewId] = useState(false)
    , [load, setLoad] = useState(false)
    , [loading, setLoading] = useState(false)
    , [alert, setAlert] = useState({
      title: '',
      text: '',
      type: '', // error, alert, info, success
    })
    , navigate = useNavigate()

  useEffect(() => {
    getItems(`${props.url}`, 'OPTIONS', setData)

  }, [newText])

  const setData = (data) => {
    console.log(data)
    const newFields = createFieldsObject(data.actions.create)
    console.log('newFields', newFields);
    setFields(newFields)
  }

  const generateSend = (e) => {
    e.preventDefault()
    setLoad(true)
    const button = e.currentTarget
      , inputs = button.closest('.form-data').querySelectorAll('input:not(.select-item__input)')
      , textareas = button.closest('.form-data').querySelectorAll('textarea')
      , formJson = {}

    for (const input of inputs) {
      formJson[input.name] = input.value
    }

    for (const textarea of textareas) {
      formJson[textarea.name] = textarea.value
    }

    fetch(`${props.url}`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${userData.token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(formJson)

    })
      .then(response => {
        if (!response.ok) {
          // throw Error('Ошибка загрузки файла')
          setAlert({
            title: 'Ошибка',
            text: 'Неверно заполнены поля',
            type: 'error'
          })
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setLoad(false)
        }
        setLoad(false)
        return response.json()
      })

      .then(data => {
        chatAnswer(data)
      })
  }

  const chatAnswer = (data) => {
    setNewText(data.full_answer)

  }

  const copyText = (e) => {
    e.preventDefault()

    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    setAlert({
      title: 'Готово',
      text: 'Текст скопирован',
      type: 'success'
    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }




  return (
    <div className="mt-16">
      <hr />
      <div className={`page__content ${loading ? '_disabled' : ''}`}>

        <Alert setAlert={setAlert} alert={alert} />

        {Object.keys(fields).length > 0 &&
          <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
            <form action={props.url + newId + '/'} method="POST" onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, setErrors, navigateToView, fields)}>
              <div className="form-container form-data">
                <FormItem field={fields.organization} classes="_full" required />
                <FormItem field={fields.answer_format} classes="_full" required />
                <FormItem field={fields.fio} classes="_full" required />
                <FormItem field={fields.text_of_appeal} classes="_full" type="textarea" required />
                <FormItem field={fields.short_answer} classes="_full" type="textarea" required />

                {load ?
                  <div className={`form__item _full align-items-center`}>
                    <div className="simple-spinner">
                      <span></span>
                    </div>
                  </div>
                  :
                  <a className='btn _full generate-btn' onClick={(e) => generateSend(e)}>
                    <span>Генерировать</span>
                    <img src={Magic} alt="" />
                  </a>

                }

                <div className={`form__item _full`}>
                  <textarea name="full_answer" rows="3" readOnly={true} value={newText} id="myInput"></textarea>
                  <label htmlFor='full_answer'>Результат</label>

                </div>

                <a className='btn _outline' onClick={(e) => copyText(e)}>Копировать <img src={Copy} alt="" /></a>

              </div >
            </form>
          </FormErrorsContext.Provider>
        }
      </div>
    </div>
  )
}

export default GigachatComponent