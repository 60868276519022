import React from 'react'
import { FileItem, InfoItem } from "../../components/InfoItem";
import HistoryTable from './HistoryTable';
import { getDomain } from '../../functions/functions';

const InventoryDetail = (props) => {
    console.log(props.fields);
    return (
        <>
            <div className={"page__info-container"}>
                <InfoItem item={props.fields.title} classes="_full" />
                <InfoItem item={props.fields.inventory_number} classes="_grid-start-1" />
                <InfoItem item={props.fields.nomenclature_code} classes="" />
                <InfoItem item={props.fields.serial_number} classes="_full" />
                <InfoItem item={props.fields.organisation} classes="_full" />
                <InfoItem item={props.fields.status} classes="_full" />
                <InfoItem item={props.fields.responsible} classes="_full" />
                <InfoItem item={props.fields.real_user} classes="_full" />
                <InfoItem item={props.fields.description} classes="_full" />
                <img src={props.fields.image.value} className="w-100" />

            </div>
            <HistoryTable url={props.url} id={props.id} />


        </>

    )
}

export default InventoryDetail