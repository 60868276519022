import { API_AGREEMENT_MODEL } from "../const";
import apiConfig from "../../apiConfig";
import axiosClient from '../../axiosClient';

export const agreementGetReport = async (id) => {
    let methodUrl = [API_AGREEMENT_MODEL.url + '/' + API_AGREEMENT_MODEL.methods.agreementGetReport.url + '/'].join('/');
    try {
        const resp = apiConfig.baseUrl + methodUrl + id + '/';

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
