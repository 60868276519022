import React from "react"
import { getDomain } from "../../functions/functions";

const CheckComments = (props) => {
    return (
        <>
            {props.item.value.map((comment, idx) => {
                return (
                    <div key={idx} className={`comment ${props.classes ? props.classes : ''}`}>
                        {comment.user.file ?
                            <img className='comment__photo' src={`${getDomain()}${comment.user.file}`} alt="" /> :
                            <span className="comment__no-photo"></span>
                        }
                        <div>
                            <span className="comment__author">{comment.user.last_name} {comment.user.first_name} {comment.user.patronymic}</span>
                            <p className="comment__text">
                                <span key={idx}>{comment.description}</span>
                            </p>
                        </div>

                    </div>
                )
            })}
        </>
    )
}




export default CheckComments
