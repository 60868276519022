import React from "react";

function NotificationPagination({is_next, paginate, loader}) {
    const handler = (e) => {
        e.preventDefault()
        paginate()
    }

    return (
        <div className='pagination'>
            {is_next ? <a className={loader ? "pagination-arrow _loader" : "pagination-arrow"}
                                  onClick={e => handler(e)}>{loader ? "" : "Загрузить ещё"}</a> : <></>}
        </div>
    )
}

export default NotificationPagination
