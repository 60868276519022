import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import SortingCell from "../../components/SortingCell";
import getFormattedDateTime from "../../functions/getFormattedDateTime";

function TimelogTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    function parseDate(dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('.').map(Number);
        const [hours, minutes] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes);
    }

    function getDifference(start_date, end_date) {
        const startDate = parseDate(start_date);
        const endDate = parseDate(end_date);

        const diffMs = endDate - startDate;

        const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

        return (
            <>
                {(diffDays < 0 || diffHours < 0 || diffMinutes < 0) ?
                    'Некорректный интервал отсутствия'
                    : `${diffDays > 0 ? diffDays + ' дней' : ""} ${diffHours < 10 ? '0' + diffHours : diffHours}ч ${diffMinutes < 10 ? '0' + diffMinutes : diffMinutes}м`
                }
            </>
        )
    }

    return (
        <>
            <table className="table _timelog">
                <thead>
                    <tr>
                        <th className="">Дата создания</th>
                        <th className="" >Сотрудник</th>
                        <th className="">Интервал отсутствия</th>
                        <th className="" >Время отсутствия</th>
                        <th className="">Причина отсутствия</th>

                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)

                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td>{getFormattedDateTime(item.creation_date)}</td>
                                <td>{item.user?.last_name ? item.user.last_name : ''} {item.user?.first_name ? item.user?.first_name[0]?.toUpperCase() : ''}. {item.user?.patronymic ? item.user?.patronymic[0]?.toUpperCase() : ''}</td>
                                <td className="">с {item.start_date} до {item.end_date}</td>
                                <td className="">{getDifference(item.start_date, item.end_date)}</td>
                                <td className="">{item.description}</td>

                            </tr>
                        )
                    })}
                </tbody>

            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}
export default TimelogTable;