import React from "react"
import FormItem from "../../components/form/FormItem";
import AddedFormItem from "../../components/form/AddedFormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import getFormattedDate from "../../functions/getFormattedDate";

function ServiceRecordsFields({fields}) {
    const formatted_date = getFormattedDate()

    return(
        <>
            <FormItem field={fields.organisation} classes="_full"/>
            {fields.record_number.value ?
                <FormItem field={fields.record_number} classes="_grid-start-1" read_only={true} />
            : <input type="hidden" name="record_number" value="0"/>
            }

            <FormItem field={fields.date_of_creation} classes="" read_only={true} value={fields.date_of_creation.value ? fields.date_of_creation.value : formatted_date}/>
            <FormItem field={fields.name} required={true}  classes="_full"/>
            <FormItem field={fields.description} classes="_full"/>
            <FormItem field={fields.receiver} required={true}  classes="_full"/>
            <FormItem field={fields.file} classes="_full"/>

            <input type="hidden" name='status' value={fields.status.value.id || 3}/>

            <DocumentAddFormItem field={fields.other_doc} classes="_full"/>
            <AddedFormItem field={fields.observers} classes="_full" button={'Добавить наблюдателя'} />

            <FormItem field={fields.author} required={true} classes="d-none _full" read_only={true} value={localStorage.getItem('user_id')} />

        </>
    )
}

export default ServiceRecordsFields