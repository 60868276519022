import React, { useState } from "react";
import { InfoItem, FileItem } from "../../components/InfoItem";
import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import FastTaskCreate from "../../components/form/FastTaskCreate";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";

function IncomingDetail(props) {
  //console.log(props.fields);
  const [createTask, setCreateTask] = useState(false)

  return (
    <>
      <div className={"page__info-container"}>
        <InfoItem item={props.fields.organisation} classes="_full" />
        <InfoItem item={props.fields.incoming_number} />
        <InfoItem item={props.fields.register_date} />
        <InfoItem item={props.fields.date_out} />
        <InfoItem item={props.fields.out_org_number} />
        <InfoItem item={props.fields.doc_name} classes="_full" />
        <InfoItem item={props.fields.contragent} classes="_full" />
        <InfoItem item={props.fields.who_unsubscribed} classes="_full" />
        <FileItem item={props.fields.file} classes="_full" />
        <FileItem item={props.fields.other_doc} classes="_full" />
        <InfoItem item={props.fields.document_type} classes="_full" />
        <InfoItem item={props.fields.source} classes="_full" />
        <InfoItem item={props.fields.description} classes="_full" />
        <InfoItem item={props.fields.service_comment} classes="_full" />
        <InfoItem item={props.fields.resolution} classes="_full" />
        <InfoItem item={props.fields.execution_period} classes="_full" />
        <InfoItem item={props.fields.responsible} classes="_full" />
        <InfoItem item={props.fields.registrar} classes="_full" />
        {props.fields?.output?.value.length > 0 &&
          <div className="info__item _full" >
            <label className="info__label">Исходящий документ</label>
            <div className="info__text" >
              {props.fields?.output?.value?.map((field, idx) => (
                <a href={"/outgoing/detail/" + field.id} key={idx}>
                  {field.output_number}
                </a>
              ))}
            </div>
          </div>
        }

        {/* {props.fields.output.value[0] ? (
          <div className="info__item _full">
            <label className="info__label">Исходящий документ</label>
            <div className="info__text">
              <a href={"/outgoing/detail/" + props.fields.output.value[0].id}>
                {props.fields.output.value[0].output_number}
              </a>
            </div>
          </div>
        ) : (
          ""
        )} */}
        {props.fields.do_not_executed.value ? (
          <div className="info__item _full">
            <label className="info__label">Исходящий документ</label>
            <div className="info__text">Не требуется</div>
          </div>
        ) : (
          ""
        )}
      </div>

      <PersonalAccesses url={props.url} fields={props.fields} absolut_save_btn={false} page_name={props.name}>
        <div className="d-none">
          <FormItem field={props.fields.contragent} />
          <FormItem field={props.fields.file} />
          <FormItem field={props.fields.date_out} />
          <FormItem field={props.fields.doc_name} />
          <FormItem field={props.fields.organisation} />
          <FormItem field={props.fields.register_date} />
          <FormItem field={props.fields.registrar} />
          <FormItem field={props.fields.responsible} />
          <FormItem field={props.fields.who_unsubscribed} />
          <FormItem field={props.fields.do_not_executed} />
          <FormItem field={props.fields.execution_period} />
          <DocumentAddFormItem field={props.fields.other_doc} />
        </div>
      </PersonalAccesses>

      <FastTaskCreate index={props.fields.incoming_number.value} id={props.fields.id.value} text='входящего' url={'my-incoming'} />



      {props.fields.comments && (
        <div className="lenta">
          <CommentComponent
            comments={props.fields.comments}
            object_id={props.fields.id.value}
            type={props.type}
          />
        </div>
      )}
      <HistoryComponent id={props.id} name={'incoming'} />
    </>
  );
}

export default IncomingDetail;
