import React from 'react'
import { useSelector } from 'react-redux';
import getFormattedDateTime from '../../functions/getFormattedDateTime';
import { getDomain, getItems } from '../../functions/functions';
import download from "/public/interface_img/download.svg";

const TelephonyTable = (props) => {


    const userData = useSelector((state) => state.user_data.userData)

    const cutPhone = (phone) => {
        const new_phone = phone?.slice(phone.indexOf(':') + 1, phone.lastIndexOf('@'))
        return (new_phone)
    }

    const getCallRecord = (id) => {
        const body = { 'session_id': id }
        getItems(`https://collback.bik31.ru/api/ip_telephony/call-list/get_record/`, 'POST', setData, JSON.stringify(body))
    }


    const setData = (data) => {

        const url = data.url
        // Создаем ссылку на документ
        var link = document.createElement('a');
        link.href = url;

        // Устанавливаем атрибуты для загрузки
        link.target = '_blank';
        link.download = url.split('/').pop(); // Устанавливаем имя файла на основе URL

        // Эмулируем клик по ссылке для начала загрузки

        var clickEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
        });
        link.dispatchEvent(clickEvent);


    }

    return (
        <>
            <table className="table _telephony">
                <thead>
                    <tr>
                        <th className="">Звонок</th>
                        <th className="_right">Дата и время</th>
                        <th className="_center">Исходящий номер</th>
                        <th className="_center">Входящий номер</th>
                        <th className="_right">Сотрудник</th>
                        <th className="_center">Файл</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className=''>{item.type === 'incoming' ? 'Входящий' : item.type === 'outbound' ? 'Исходящий' : "Внутренний"}</td>
                                <td className='_right'>{getFormattedDateTime(item.timestamp)}</td>
                                <td className='_center'>{cutPhone(item.from_number)}</td>
                                <td className='_center'>{cutPhone(item.request_number)}</td>
                                {item.user ?
                                    <td className='_right'>{item.user.last_name ? item.user.last_name : ""} {item.user?.first_name ? item.user.first_name[0].toUpperCase() : ''}.{item.user?.patronymic ? item.user.patronymic[0].toUpperCase() : ""}.</td>
                                    : <td></td>
                                }
                                {/* <td className='_center table_download' onClick={() => getCallRecord(item.session_id)}>Скачать</td> */}
                                {item.is_record === true ?
                                    <td className="_center" onClick={() => getCallRecord(item.session_id)}>
                                        <span className="table__link" download target="_blank">
                                            <svg width="16" height="16" viewBox="0 0 16 16" className={"download-icon"} stroke="#0088CC" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <use href={download + "#item"}></use>
                                            </svg>
                                            <span className="download_icon"></span>
                                            Скачать
                                        </span>
                                    </td>

                                    :
                                    <td className="_center">Запись отсутствует</td>
                                }

                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}

export default TelephonyTable