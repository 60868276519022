import { configureStore } from '@reduxjs/toolkit'

import userDataReducer from './userDataSlice'
import filtersReducer from './filtersSlice'
import searchReducer from './searchSlice'
import navStateReduser from './navSlice'
import qrReduser from './QRSlice'
import tablePageReduser from './tablePageSlice'
import chatReducer from './chatReducer'
import chatSelectedUser from './chatSelectedUser'
import accordionMenuReduser from './accordionMenu'
import tableNotificationReducer from './tableNotification'
import navNotificationReducer from "./navNotification";
import overduePopupSlice from "./overduePopupSlice";
import chatForwardedMessage from "./chatForwardedMessage";

export const store = configureStore({
  reducer: {
    user_data: userDataReducer,
    filters_data: filtersReducer,
    search_data: searchReducer,
    nav_state: navStateReduser,
    qr_data: qrReduser,
    table_page: tablePageReduser,
    activeChat: chatReducer,
    selectedUserChat: chatSelectedUser,
    accordionMenu: accordionMenuReduser,
    tableNotification: tableNotificationReducer,
    navNotification: navNotificationReducer,
    overduePopup: overduePopupSlice,
    chatForwardedMessage: chatForwardedMessage,
  },
})