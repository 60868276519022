import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import { getDomain } from '../../functions/functions';
import { useDispatch, useSelector } from "react-redux"
import { setQR } from '../../store/QRSlice'

const InventoryTable = (props) => {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
        , userData = useSelector(state => state.user_data.userData)
        , [openQR, setOpenQR] = useState('')
        , navigate = useNavigate()
    const dispatch = useDispatch()
    const setQrData = item => dispatch(setQR(item))

    const isInvent = () => {
        const groups = JSON.parse(userData.groups)
        return (groups.indexOf('Inventory') !== -1)
    }




    return (
        <>
            <table className="table _inventory">
                <thead>
                    <tr>
                        <th className="_center">QR-код</th>
                        <th>Номер</th>
                        <th>Наименование</th>
                        <th>Номенклатурный</th>
                        <th>Серийный</th>
                        <th>Организация</th>
                        <th>Ответственный</th>
                        <th>Пользователь</th>
                        <th className="_center">Фото</th>
                        <th className="_center">Статус</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                {item.qr_code ?
                                    isInvent() ?
                                        <td className="pt-0 pb-0">
                                            <img src={getDomain() + item.qr_code} onClick={(e) => {
                                                setQrData(item)
                                                window.open('/print-qr', '_blank').focus(); // сафари и хром работают
                                                //navigate('/print-qr')
                                            }} className="table__qr" />

                                        </td>
                                        :
                                        <img src={getDomain() + item.qr_code} className="table__qr" />
                                    : <td></td>

                                }
                                {item.inventory_number ?
                                    isInvent() ?
                                        <td><Link to={`detail/${item.id}`} className="table__link">{item.inventory_number}</Link></td>
                                        :
                                        <td>{item.inventory_number}</td>
                                    : <td></td>
                                }
                                {item.title ?
                                    <td>{item.title}</td> : <td></td>
                                }
                                {item?.nomenclature_code ?
                                    <td>{item.nomenclature_code}</td> : <td></td>
                                }
                                {item.serial_number ?
                                    <td>{item.serial_number}</td>
                                    : <td></td>
                                }
                                {item.organisation ?
                                    <td>{item.organisation.name}</td>
                                    : <td></td>
                                }
                                <td>{item.responsible?.last_name ? item.responsible?.last_name : ''} {item.responsible?.first_name ? item.responsible?.first_name?.[0] + '.' : ""} {item.responsible?.patronymic ? item.responsible?.patronymic?.[0] + '.' : ''}</td>
                                <td>{item.real_user?.last_name ? item.real_user.last_name : ''} {item.real_user?.first_name ? item.real_user.first_name[0] + '.' : ""} {item.real_user?.patronymic ? item.real_user.patronymic[0] + '.' : ''}</td>

                                {item.image_thumbnail ?
                                    <td className="pt-0 pb-0">
                                        <img src={getDomain() + item.image_thumbnail} className="table__qr" />
                                    </td> : <td></td>
                                }
                                {item.status ?
                                    <td className="_center _service-records-status"
                                        style={item.status.color
                                            ? { backgroundColor: item.status.color, color: 'white' }
                                            : { color: '#919399' }}>
                                        {item.status.title}
                                    </td>
                                    :
                                    <td></td>
                                }

                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}

export default InventoryTable