import React, {useState, useEffect} from 'react'
import {Emoji} from 'emoji-picker-react';

import CommentFile from './CommentFile'
import getHumanizedDateTime from '../../functions/getHumanizedDateTime'
import {getDomain} from '../../functions/functions'
import CommentInputGroup from './CommentInputGroup'
import {useSelector} from 'react-redux';
import Alert from '../Allert';
import CommentCardDelete from "./CommentCardDelete";

const CommentCard = ({comment, type, parent_id = null, parent = false, object_id}) => {
    const [answer, setAnswer] = useState(false)
        , userData = useSelector(state => state.user_data.userData)
        , [alert, setAlert] = useState({
        title: '',
        text: '',
        type: '', // error, alert, info, success
    })


    const isAuthor = () => {
        return (+comment.author?.id === +userData.user_id)
    }
    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1
    };

    const deleteComment = (id) => {
        const url = getDomain() + '/api/comment/' + `${id}/`
            , toDelete = confirm('Вы уверены, что хотите удалить комментарий?')
        if (toDelete) {
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    //'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status >= 500) {
                            throw Error('Ошибка сервера. Попробуйте позже.')
                        } else {
                            throw Error(response.statusText)
                        }
                    }
                    if (response.status === 204) {
                        return response
                    }
                    return response.json()
                })
                .then(data => {
                    // setDeleted(true)
                })
            // .catch(error => {
            //     if (error.message.toLowerCase() === 'failed to fetch') {
            //         setAlert({ title: "Ошибка", text: "Доступ запрещен", type: "error" })
            //     } else {
            //         setAlert({ title: "Ошибка", text: "При удалении возникла ошибка. Обновите страницу и попроуйте еще раз или обратетесь в поддержку", type: "error" })
            //     }

            // })
        }
    }

    return (
        <div className='comment'>
            <Alert setAlert={setAlert} alert={alert}/>

            {comment.author?.file ?
                <img src={`${comment.author.file.startsWith('http') ? '' : getDomain()}${comment.author.file}`}
                     className='comment__photo'/> :
                <span className='comment__no-photo'></span>
            }

            <div className='comment__content'>
                <span
                    className='comment__author'>{comment.author ? comment.author.last_name + ' ' + comment.author.first_name + ' ' + comment.author.patronymic : 'Неизвестный пользователь'}</span>
                <p className='comment__text'>{comment.text}</p>
                {comment.files?.length > 0 && comment.files.map((file, idx) => (
                    <CommentFile key={idx} file={`${getDomain()}${file.file}`} date={file.upload_at}/>
                ))}
                <div className='comment__footer'>
                    <span className='comment__date'>{getHumanizedDateTime(comment.upload_at)}</span>
                    <span className='comment__answer-btn' onClick={() => setAnswer(!answer)}>Ответить</span>
                    {(isAuthor() || isAdmin()) &&
                        <span className='comment__delete-btn' onClick={() => deleteComment(comment.id)}>Удалить</span>
                    }
                </div>

                {comment.answers?.length > 0 && comment.answers.map((answer, idx) => {
                        if (!answer.is_available) {
                            // console.log('answer ==>', answer)
                            return <CommentCardDelete comment={answer}
                                                      parent_id={parent_id === null ? comment.id : parent_id}
                                                      parent={comment.author} type={type} object_id={object_id} key={idx}/>
                        }

                        return <CommentCard comment={answer} parent_id={parent_id === null ? comment.id : parent_id}
                                            parent={comment.author} type={type} object_id={object_id} key={idx}/>
                    }
                )}
                {answer && <CommentInputGroup object_id={object_id} type={type}
                                              parent_id={parent_id === null ? comment.id : parent_id}
                                              parent={comment.author} close={() => setAnswer(false)}/>}
            </div>
        </div>
    )
}


export default CommentCard