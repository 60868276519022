import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getItems } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";


function ClientsTasksFilter(props) {
    // const fields = ['category', 'responsible', 'executor', 'contragent', 'status', 'create_at_in', 'create_at_out', 'condition', 'type']




    const fields = ['category', 'responsible', 'executor', 'contragent', 'status', 'condition', 'land_type', 'create_at_in', 'create_at_out']
        , [items, setItems] = useState([])


    useEffect(() => {
        console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        const create_at_in = {
            label: "Дата регистрации с",
            name: "create_at_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }
        const create_at_out = {
            label: "Дата регистрации по",
            name: "create_at_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }
        new_fields.create_at_in = create_at_in
        new_fields.create_at_out = create_at_out
        setItems(new_fields)

    }

    return (
        <>
            {Object.keys(items).length > 0 ?
                <>
                    {fields.map((key) => {
                        const value = props.values.find(item => item[key] !== undefined)
                        const new_field = items[key]
                        if (items[key].name === 'contragent') {
                            return <SearchContragent key={key} field={new_field} required={false} value={value} canAdd={false} />
                        } else {
                            return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />
                        }
                    })}
                    {/* <FormItem field={for_date} required={false} value={for_date_val ? for_date_val['for_date'] : ''} /> */}
                </> : <>загрузка</>}
        </>
    )
}

export default ClientsTasksFilter