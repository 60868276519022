import React, {useState, useEffect} from "react";

function ContentLoader() {
    return (
        <>
            <div className="box">
                <div className="cat-box">
                    <div className="cat">
                        <div className="helf-box helf-box--ass">
                            <div className="helf-box clip">
                                <div className="cat__body"></div>
                            </div>
                            <div className="cat__ass">
                                <div className="cat__body--fake"></div>
                                <div className="cat__foots"></div>
                                <div className="cat__tail"></div>
                            </div>
                        </div>
                        <div className="helf-box helf-box--head">
                            <div className="cat__head">
                                <div className="cat__face">
                                    <div className="cat__ears"></div>
                                    <div className="cat__eyes"></div>
                                    <div className="cat__mouth"></div>
                                    <div className="cat__mustache"></div>
                                </div>
                                <div className="cat__foots"></div>
                            </div>
                            <div className="helf-box clip">
                                <div className="cat__body"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
        // <div className="loading-cat">
        //     <div className="cat-body"></div>
        //     <div className="cat-animation-mask"></div>
        //     <div className="cat-head">
        //         <div className="cat-face"></div>
        //         <div className="cat-ear"></div>
        //         <div className="cat-hand"></div>
        //         <div className="cat-eye"></div>
        //         <div className="cat-eye-light"></div>
        //         <div className="cat-mouth"></div>
        //         <div className="cat-beard left"></div>
        //         <div className="cat-beard right"></div>
        //     </div>
        //     <div className="cat-foot">
        //         <div className="cat-belly"></div>
        //         <div className="cat-leg"></div>
        //         <div className="cat-tail"></div>
        //     </div>
        // </div>

    )
}
export default ContentLoader