import React, { useState, useEffect } from "react"
import Select from 'react-select';
import Modal from "../Modal";

import { createFieldsObject, getItems, getDomain } from "../../functions/functions";




//=================================================================================================================================================


function SearchLandolot(props) {
    const [value, setValue] = useState(''),
        [displayName, setDisplayName] = useState(''),
        [addButton, setAddButton] = useState(false),
        [options, setOptions] = useState([]),
        [showOptions, setShowOptions] = useState(false),
        [canAdd, setCanAdd] = useState(props.canAdd ? props.canAdd : true)

    useEffect(() => {

        if (props.value) {
            const url = `${getDomain()}/api/geo/landplots/${props.value}`
            getItems(url, 'GET', (data) => {
                setDisplayName(data.cadastral ? data.cadastral : data.id)
            }, false, setError)
            setValue(props.value)
        } else if (props.field.value) {
            const url = `${getDomain()}/api/geo/landplots/${props.field.value}`
            getItems(url, 'GET', (data) => {
                setDisplayName(data.cadastral ? data.cadastral : data.id)
            }, false, setError)
            setValue(props.field.value)
        }

    }, [])


    const handleChange = selectedOption => {
        setAddButton(false)
        setValue(selectedOption)
    }

    const addOption = (data) => {
        const new_option = {}
        console.log('!!!', data);
        new_option.value = data.id
        new_option.label = data.fio ? data.fio : data.org_name
        options.push(new_option)
        setOptions(options)
        setValue(new_option.value)
        setDisplayName(new_option.label)
        setShowOptions(false)
    }

    const emptySearch = () => {
        setAddButton(true)
        return 'Нет результатов'
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler(e)
        }
    }

    const setData = (data) => {
        const arr = []
        if (data.count > 0) {
            for (let land of data.results) {
                const option = { value: land.id, display_name: land.cadastral ? land.cadastral : land.id }
                arr.push(option)
                setAddButton(false)
            }
        } else {
            emptySearch()

        }

        setOptions(arr)

    }

    const setError = (message) => {
        console.log('====================================');
        console.log('Error', message);
        console.log('====================================');
    }

    const searchHandler = (e) => {
        if (e.target.value.length >= 2) {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
        e.preventDefault()
        setDisplayName(e.target.value)
        const url = `${getDomain()}/api/geo/landplots/?search=${e.target.value}`
        //setStatus(() => <ContentLoader />)
        getItems(url, 'GET', setData, false, setError)
    }

    const selectContragent = (value, name) => {
        setShowOptions(false)
        setValue(value)
        setDisplayName(name)
    }

    return (
        <>
            <div className={"form__item " + props.classes}>
                <span className="error-message">{props.error ? props.error : ''}</span>
                <div className={`contragent__fake-select ${!showOptions ? 'd-none' : ''}`}>
                    {options.length > 0 ?
                        options.map(op => (
                            <span className={displayName === op.display_name ? 'selected' : ''} onClick={() => selectContragent(op.value, op.display_name)}>{op.display_name}</span>
                        )) :
                        <span className="no-resalts">Нет результатов</span>
                    }
                </div>

                <input type="hidden" value={value} name={props.field.name} />
                <div className="search-contragent-wrap">
                    <input
                        onChange={e => searchHandler(e)}
                        onKeyDown={onEnter}
                        value={displayName}
                        type="text"
                        className='contragent_search'
                        placeholder='Введите кадастровый номер…' />
                </div>
                <label htmlFor={props.field.name}>{props.field.label}{props.field.required ? <span>*</span> : ''}</label>

            </div>
        </>
    )
}


export default SearchLandolot