import React, {useEffect, useId, useRef, useState} from "react"

import userPlus from '../../../../public/interface_img/user-plus-01.svg'
import SendSMSSearchContaragent from "./sendSMSSearchContaragent";

function SendSMSAddedFormItem({field, button, can_add = true, value = null, setFormData = null}) {
    const [countValues, setCountValues] = useState(0)
    const [contragents, setContragents] = useState([])
    const [respValues, setRespValues] = useState([])

    useEffect(() => {
        if (value) {
            setContragents(value)
            setCountValues(value.length)
        }
    }, []);

    useEffect(() => {
        const resp = contragents.map((item, idx) => {
            return item.id
        })

        setRespValues([...resp])
        if (setFormData)
            setFormData(resp)

        // console.log('contragents', contragents)
    }, [contragents])

    const pushItemOfState = (setFun, item) => {
        setFun(prev => [...prev, item])
    }

    const addField = (e) => {
        e.preventDefault()
        pushItemOfState(setContragents, {
            id: null,
            phone: '',
            fio: null,
            index: countValues,
            // item: SearchFormItem(countValues)
        })

        setCountValues(countValues + 1)
    }

    const removeValue = (idx) => {
        setContragents(prevState => {
            return prevState.filter(item => item.index !== idx)
        })
    }

    const SearchFormItem = ({idx}) => {
        const formItemData = contragents[idx]
        return (formItemData &&
            <div key={formItemData.index} className="send-sms-search-form-item">
                <SendSMSSearchContaragent
                    field={field}
                    setValue={(data) => {
                        setContragents((prevState) => {
                            let newContragents = prevState
                            let editValueIndex = newContragents.findIndex(item => item.index === formItemData.index)
                            if (editValueIndex >= 0) {
                                newContragents[editValueIndex].id = data.value
                                newContragents[editValueIndex].phone = data.phone
                                newContragents[editValueIndex].fio = data.display_name
                            }
                            return [...newContragents]
                        })
                    }}
                    start_display_name={formItemData.fio}
                    classes="_full"/>
                <span className={"delete-select"} onClick={(e) => {
                    e.preventDefault()
                    removeValue(formItemData.index)
                }}></span>
            </div>
        )
    }

    return (
        <>
            <div className="form-added-item _full">
                <span className="form-added-item__title">{field.label}</span>
                {contragents?.length > 0 && contragents.map((item, idx) => {
                    return <SearchFormItem idx={idx}/>
                })}


                <a href="" onClick={addField} className={"added-field-btn _grid-start-1"}>
                    <img src={userPlus}/>
                    {button}
                </a>

                <div className="_full d-none">
                    <select name={field.name} value={respValues} onChange={() => {
                    }} multiple={true}>
                        {respValues ? respValues.map((item, idx) => {
                            return <option key={idx} value={item}>{item}</option>
                        }) : <></>}
                    </select>
                </div>
            </div>

            {contragents?.length > 0 &&
                <div className={'send-sms-phones-list'}>
                    <div className="form-added-item _full">
                        <label className={'send-sms-phones-list-label'}>Список номеров</label>
                        {contragents.map(item => <span className={'send-sms-phone-item'}>{item.phone}</span>)}
                    </div>
                </div>}
        </>
    )
}

export default SendSMSAddedFormItem