import React, { useState, useEffect } from "react"
import FormItem from "../../components/form/FormItem";
import SearchContragent from "../../components/form/SearchContragent";
import ApprovalSheet from "../../components/form/ApprovalSheet";
import AddedFormItem from "../../components/form/AddedFormItem";

function AgreementFields({ fields }) {

    const [initiator, setInitiator] = useState()
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Добавляем ведущий ноль, если месяц меньше 10
    const year = currentDate.getFullYear();

    // Формируем строку в формате "день месяц год"
    const formattedDate = `${day}.${month}.${year}`;

    const handleInitiator = (value) => {
        setInitiator(value);
    };

    const updatedFields = {
        ...fields.status,
        children: [...fields.status.children],
    };

    // Находим индекс элемента с display_name равным 'Согласован'
    const indexToRemove = updatedFields.children.findIndex((item) => item.display_name === 'Согласован');

    // Удаляем только третий элемент из массива `children`, если элемент существует
    if (indexToRemove !== -1) {
        updatedFields.children.splice(indexToRemove, 1);
    }

    return (
        <>
            <FormItem field={fields.organisation} classes="_full" required={true} />
            <FormItem field={fields.agreement_type} classes="_full" required={true} />
            <FormItem field={fields.initiator} initiator={handleInitiator} classes="_full" required={true} />
            <ApprovalSheet value={fields.level_agreements.value} field={fields.level_agreements} initiator={initiator} classes="_full" />
            <FormItem field={fields.doc_num} classes="_grid-start-1" required={true} />
            <FormItem value={formattedDate} field={fields.date_of_approval} required={true} />
            <FormItem value={fields.status.value.id} field={updatedFields} classes="_full" required={true} />
            <FormItem field={fields.footing} classes="_full" required={true} />
            <FormItem field={fields.price} classes="_full" required={true} />
            <FormItem field={fields.doc_name} classes="_full" required={true} />
            <SearchContragent field={fields.contragent} classes="_full" />
            <FormItem field={fields.file} classes="_full" />
            <AddedFormItem field={fields.observers} classes="_full" button={'Добавить наблюдателя'} />
        </>
    )
}

export default AgreementFields