import React from "react";
import { Link } from "react-router-dom"
import LinkDownload from "../../components/LinkDownload";
import { checkNotifications, getDomain } from "../../functions/functions";
import SortingCell from "../../components/SortingCell";

function OutgoingPage(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _outgoing">
                <thead>
                    <tr>
                        {/* ordering_fields = ['doc_name', 'register_date', 'output_number', 'organisation', 
                'contragent', 'responsible'] */}
                        <SortingCell classes="" title={'Наименование документа'} name={'doc_name'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата регистрации'} name={'register_date'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Исходящий номер'} name={'output_number'} page_name={props.name} />
                        <th className="_center">Скан документа</th>
                        <SortingCell classes="_center" title={'Фонд/БИК'} name={'organisation'} page_name={props.name} />
                        <SortingCell classes="" title={'Кому'} name={'contragent'} page_name={props.name} />
                        <SortingCell classes="" title={'Ответственный'} name={'responsible'} page_name={props.name} />
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td className=""><Link to={'detail/' + item.id} className="table__link">{item.doc_name}</Link></td>
                                <td className="_center">{item.register_date}</td>
                                <td className="_center">{item.output_number}</td>
                                <td className="_center">{item.file ?
                                    <LinkDownload url={getDomain() + item.file} /> :
                                    <></>}
                                </td>
                                <td className="_center">{item.organisation?.name ? item.organisation.name : ''}</td>
                                <td>{item.contragent ? item.contragent.org_name ? item.contragent.org_name : item.contragent.fio : ''}</td>
                                <td>{item.responsible ? item.responsible?.last_name
                                    ? item.responsible?.last_name + ' ' + item.responsible?.first_name?.[0] + '.' + item.responsible?.patronymic?.[0] + '.'
                                    : '' : ''}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    );
}

export default OutgoingPage;