import React, { useState, useEffect } from "react"
import FileItem from "./FileItem";
import { getDomain, getFileName } from "../../functions/functions";
import { useSelector } from "react-redux";

function AddFoldInput(props) {
    const [loading, setLoading] = useState(false)
        , [error, setError] = useState('')
        , userData = useSelector(state => state.user_data.userData)

    const addField = (item) => {
        if (typeof item === 'object') {
            const new_arr = [...props.files]
            for (const url of item) {
                new_arr.push({ "file": decodeURI(url) })
            }
            props.setFiles(props.folderIdx, new_arr)
        } else {
            const new_arr = [...props.files]
            new_arr.push({ "file": decodeURI(item) })
            props.setFiles(props.folderIdx, new_arr)
        }
    }

    const isAdmin = () => {
        const groups = JSON.parse(userData.groups)
        return (groups.indexOf('Admin') !== -1)
    }

    const removeField = (e, index) => {
        e.preventDefault()
        const arr = [...props.files]
        arr.splice(index, 1)
        props.setFiles(props.folderIdx, arr)
    }
    const sendFile = function (e) {
        const input = e.target,
            files = [...input.files]
            , new_value = []
        setLoading(true)
        setError('')

        for (let i = 0; i < files.length; i++) {
            const form = new FormData()
            form.append('file', files[i])

            fetch(getDomain() + '/api/incoming/upload/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: form,
            })
                .then(response => {
                    if (!response.ok) {
                        throw Error('Ошибка загрузки файла')
                    } else {
                        // console.log('response', response)
                    }
                    return response.json()
                })
                .then(data => {
                    const url = decodeURIComponent(data)
                    new_value.push(url)
                    if (new_value.length === files.length) {
                        addField(new_value)
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                    if (error.message === 'URI malformed') {
                        setError('Имя файла содержит запрещенные символы')
                    } else if (error.message.toLowerCase() === 'failed to fetch') {
                        setError('Доступ запрещен')
                    } else {
                        setError(error.message)
                    }
                })
        }
    }


    return (
        <>

            <label htmlFor={"folder_" + (props.id ? props.id : props.index)} className="add-file__btn mt-8">{loading ? 'Загрузка...' : 'Добавить документ'}</label>


            {/*<input type="hidden" name={props.name} onChange={() => {}} value={JSON.stringify(value)}/>*/}
            <input id={"folder_" + (props.id ? props.id : props.index)} onChange={e => sendFile(e)} className="d-none" type="file" multiple />
            {props.files?.length > 0 ? props.files.map((val, idx) => {
                return (

                    <div className="file__item_wrap" key={idx}>
                        <a className="file-label" title={getFileName(val.file)} idx={idx} href={getDomain() + val.file} download={true}>{getFileName(val.file)}</a>
                        {isAdmin() &&
                            <a href="" onClick={e => removeField(e, idx)} className="delete-file"></a>
                        }
                    </div>

                )
            }) : ''}
            {error ? <span className="error-message ml-4 mb-0">{error}</span> : ''}
        </>
    )
}

export default AddFoldInput