import { API_AGREEMENT_MODEL } from "../const";
import apiConfig from "../../apiConfig";
import axiosClient from '../../axiosClient';

export const agreementUpload = async (file) => {
    let methodUrl = [API_AGREEMENT_MODEL.url + '/' + API_AGREEMENT_MODEL.methods.agreementUpload.url + '/'].join('/');

    console.log('METHOD', file);

    try {
        const formData = new FormData();
        formData.append('file', file);

        const resp = await axiosClient.post(apiConfig.baseUrl + methodUrl, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
