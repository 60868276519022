import React, {useRef, useState} from "react"
import HeaderInside from "./HeaderInside";

function Modal({title = "Модальное окно", button = { classes: 'btn', title: 'Открыть', addition: ''}, children, classes}) {
    const modal = useRef()
    const [active, setActive] = useState(false)
    
    const closeModal = (e) => {
        e.preventDefault()
        setActive(false)
        setTimeout(() => modal.current.style.display = 'none', 300) 
    }
    const openModal = (e) => {
        e.preventDefault()
        modal.current.style.display = 'grid'
        setTimeout(() => setActive(true), 100)   
    }
    // classes: _small
    return (
        <>
            <a onClick={openModal}
               className={button.classes}>{button.addition ? <span className="filter-btn__count">{button.addition}</span> : <></>}{button.title}</a>
            <div className={active ? "modal active" : "modal"} ref={modal} onClick={closeModal}>
                <div className={`modal__content ${classes ? classes : ''}`} onClick={(e) => e.stopPropagation()}>
                    <HeaderInside title={title} clickEvent={closeModal}/>
                    <hr/>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Modal
