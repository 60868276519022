import React, {useEffect, useCallback} from "react";
import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"

import Nav from "./Nav";
import {getDomain, getItems} from "../functions/functions";
import WebSocketComponent from "../functions/openSocket";
import {addTableNotification} from "../store/tableNotification";
import {setNavNotification} from "../store/navNotification";
import Favicon from "../../public/favicon.ico";
import {routes} from "../pages";

function Page(props) {
    const modelOptions = [
        {value: '', label: 'Все уведомления'},
        {value: 'AnnouncementModel', label: 'Лента'},
        {value: 'ChatModel', label: 'Чаты'},
        {value: 'MeetingModel', label: 'Календарь'},
        {value: 'IncomingModel', label: 'Входящие'},
        {value: 'OutputModel', label: 'Исходящие'},
        {value: 'AgreementModel', label: 'Согласования'},
        {value: 'ServiceRecordModel', label: 'Служебки'},
        {value: 'DocReestrModel', label: 'Договоры'},
        {value: 'GeneratedDocument', label: 'Документы'},
        {value: 'TasksModel', label: 'Задачи'},
        {value: 'ClientTaskModel', label: 'Задачи клиентов', use_permissions: true},
        {value: 'SupportModel', label: 'Техподдержка'},
        {value: 'AhoSupportModel', label: 'Заявки АХО'},
        {value: 'ExploitationSupportModel', label: 'Заявки отдела эксплуатации'},
        {value: 'CapSupportModel', label: 'Заявки кап. строительства'},
    ];

    const userData = useSelector(state => state.user_data.userData)
        , navState = useSelector(state => state.nav_state.navState)
        , dispatch = useDispatch()
        , setNotificationClasses = (notificationClasses) => dispatch(setNavNotification(notificationClasses))
        //для добавление нотификации, полученной через websocket
        , webSocketAddNotification = (model_name, notificationCurrent) => dispatch(addTableNotification({
            model_name: model_name,
            notification: notificationCurrent
        }))

    const fetchNavNotifications = useCallback(async () => {
        console.log('------------fetchNavNotifications--------------')
        try {
            const response = await fetch(`${getDomain()}/api/notifications/classes?owner_id=${userData.user_id}`);
            const result = await response.json();
            setNotificationClasses(result);
        } catch (error) {
            setNotificationClasses({notificationClasses: [], notificationPrivateClasses: []})
            console.error('Ошибка при получении классов уведомлений:', error);
        }
    }, [userData.user_id]);


    const navigateToView = (name, id) => {
        let url = `/${name}/detail/${id}`
        if (name === "chat") {
            url = `/${name}`
        }
        let win = window.open();
        win.location = url;
    }
    const visitNotification = (model_name, obj_id, ntf_id) => {
        const obj_url = routes.filter(r => r.model_name === model_name)
        console.log(obj_url);
        console.log('obj_url', obj_url[obj_url.length - 1]);
        navigateToView(obj_url[obj_url.length - 1].name, obj_id)
    }
    const parseHtmlToText = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };
    const sendWebNotification = (title, options) => {
        try {
            // Проверяем, выданы ли права на уведомления
            if (Notification.permission === 'granted') {
                const message = parseHtmlToText(options.body);
                const now = new Date().toLocaleTimeString('en-GB', {hour12: false}).replace(/:/g, '-');
                const tag = `${now}`;

                const notification = new Notification(title, {
                    body: message,
                    icon: Favicon,
                    tag: tag,
                    renotify: false,
                });

                notification.onclick = (event) => {
                    event.preventDefault();
                    visitNotification(options.model_name, options.instance_id, options.id);
                };
            } else {
                throw new Error('Notification permission not granted');
            }
        } catch (error) {
            console.error('Error while sending notification:', error.message);
        }
    };

    const requestNotificationPermission = useCallback(() => {
        try {
            if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
                Notification.requestPermission().then(permission => {
                    if (permission !== 'granted') {
                        console.log('Permission for notifications was denied');
                    }
                });
            }
        } catch (error) {
            console.error('Error while sending notification:', error.message);
        }

    }, []);

    const addNotification = (data) => {
        if (!data.upload_time) {
            const today = new Date()
            data.upload_time = today.toISOString()
        }
        if (!data.message)
            data.message = 'Новое сообщение в чате'

        if (data.model_name) {
            webSocketAddNotification(data.model_name, data);
            fetchNavNotifications()
            const modelOption = modelOptions.find(option => option.value === data.model_name);
            const label = modelOption ? modelOption.label : "";
            sendWebNotification(label, {
                body: data.message,
                icon: Favicon,
                model_name: data.model_name,
                instance_id: data.instance_id,
                id: data.id
            });
            //setNotifications([...notifications, data])
        }
    }

    useEffect(() => {
        fetchNavNotifications();
        requestNotificationPermission();
    }, []);

    return (
        <div className={`container${navState.nav_active ? ' nav-active' : ''}`}>
            <WebSocketComponent handleMessageEvent={addNotification}/>
            <Nav/>
            <Outlet context={fetchNavNotifications}/>
        </div>
    );
}

export default Page
