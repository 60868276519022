import React, { useState, useEffect, useContext } from "react"

import FormErrorsContext from "../../functions/formErrorsContext";

function PasswordItem(props) {
    const [value, setValue] = useState(props.value ? props.value : props.field.value)
        , [confirmValue, setConfirmValue] = useState('')
        , [fieldDirty, setFieldDirty] = useState(props.field.error ? true : false)
        , [confirmDirty, setConfirmDirty] = useState(false)
        , [error, setError] = useState(props.field.error ? props.field.error : '')
        , [confirmError, setConfirmError] = useState('')
        , required = props.required !== "undefind" ? props.required : props.field.required
        , readOnly = props.read_only !== "undefind" ? props.read_only : props.field.read_only
        , {formErrors, setFormErrors} = useContext(FormErrorsContext)

    useEffect(() => {
        if (props.field.error) {
            setError(props.field.error)
        }
    }, [{...props.field}])

    const passwordHandler = e => {
        const val = e.target.value
        setValue(val)
        if (required === true) {
            if (val.length < 5 || val.length > 12) {
                setError('Пароль должен содержать не меньше 5 и не больше 12 символов')
            } else  {
                if (val.length === 0) {
                    setError('Заполните поле')
                } else {
                    setError('')
                }
            }
        }
    }

    const confirmPasswordHandler = e => {
        const val = e.target.value
        setConfirmValue(val)

        if (val !== value) {
            setConfirmError('Пароли не совпадают')
            formErrors['password'] = 'Пароли не совпадают'
            setFormErrors({...formErrors})
        } else {
            setConfirmError('')
            delete formErrors['password']
            setFormErrors({})
        }
    }

    return (
        <>
            <div className={`form__item ${props.classes}`}>
                <span className="error-message">{(fieldDirty && error) && error}</span>
                <input type="password"
                       id={props.field.id}
                       name={props.field.name}
                       value={value}
                       onChange={props.handler ? e => props.handler(e, props.field, setValue, setError) : props.mask ? e => props.mask(e, setValue, setError) : e => passwordHandler(e)}
                       onBlur={() => setFieldDirty(true)}
                       className={fieldDirty && error !== '' ? "error" : ""}
                       placeholder={props.field.placeholder}
                       required={required}
                       min={props.field.type === 'number' ? 0 : ''}
                       readOnly={readOnly}/>
                <label htmlFor={props.field.name}>Пароль{required ? <span>*</span> : ''}</label>
            </div>
            <div className={`form__item ${props.classes}`}>
                <span className="error-message">{confirmError && confirmError}</span>
                <input type="password"
                       id={'confirm_' + props.field.id}
                       name={'confirm_' + props.field.name}
                       value={confirmValue}
                       onChange={props.handler ? e => props.handler(e, props.field, setValue, setError) : props.mask ? e => props.mask(e, setValue, setError) : e => confirmPasswordHandler(e)}
                       onBlur={() => setConfirmDirty(true)}
                       className={confirmError !== '' ? "error" : ""}
                       placeholder={props.field.placeholder}
                       required={required}
                       min={props.field.type === 'number' ? 0 : ''}/>
                <label htmlFor={props.field.name}>Подтверждение пароля{required ? <span>*</span> : ''}</label>
            </div>
        </>
    )
}

export default PasswordItem