import React, { useState, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"


import { FileItem, InfoItem } from "../../components/InfoItem";
import { createOrUpdate, onEmailInput } from "../../functions/functions";
import FormItem from "../../components/form/FormItem";
import TextareaItem from "../../components/form/TextareaItem";
import FormErrorsContext from "../../functions/formErrorsContext";
import { getDomain } from "../../functions/functions";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";
import getFormattedDate from "../../functions/getFormattedDate";
import Alert from "../../components/Allert";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";


function EngineeringNetworkDetail(props) {
    const [loading, setLoading] = useState(false)
        , [agreementStatus, setAgreementStatus] = useState(props.fields.support_status.value)
        , [canSave, setCanSave] = useState(false)
        , [succeed, setSucceed] = useState(false)
        , form = useRef()
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , params = useParams()
        , [formErrors, setFormErrors] = useState([])
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)
        , formatted_date = getFormattedDate()



    const navigateToView = (id) => {
        const url = props.name === 'incoming' ? '/' : `/${props.name}/`
        //navigate(`/${props.name}/detail/${id}`, {
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект изменен',
                    status: 'success'
                }
            }
        })
    }

    const succeedSend = (id, status_id, user_id) => {
        fetch(`${getDomain()}/api/capsupport/${id}/succeed/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${userData.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ "id": id, "status_id": status_id, 'user_id': user_id })

        })
            .then(response => {
                console.log('response', response)
                navigateToView()
            })
    }

    const setErrors = (data) => {
        data.then(res => {
            console.log('errors', res)
        })
    }
    const isСapitalСonstruction = () => {
        const groups = JSON.parse(userData.groups)
        return (groups.indexOf('СapitalСonstruction') !== -1)
    }


    function sendAgreement(value) {
        setAgreementStatus(value)
    }

    function symbolsNumber(e) {
        if (e.target.value.length > 1) {
            setCanSave(true)
        }
        else {
            setCanSave(false)
        }
    }

    return (
        <>
            <Alert alert={alert} setAlert={setAlert} />
            <div className={"page__info-container"}>
                <InfoItem item={props.fields.organisation} />
                <InfoItem item={props.fields.date_of_creation} />
                <InfoItem item={props.fields.id} classes="_full" />
                <InfoItem item={props.fields.author} classes="_full" />

                <InfoItem item={props.fields.receiver} classes="_full" />

                <InfoItem item={props.fields.name} classes="_full" />
                <InfoItem item={props.fields.description} classes="_full" />
                <FileItem item={props.fields.file} classes="_full" />

                <InfoItem item={props.fields.comment_reason} classes="_full" />
                <InfoItem item={props.fields.succeed_text} classes="_full" />
                {/*<FileItem item={props.fields.succeed_file} classes="_full"/>*/}

                {/*<InfoItem field={props.fields.succeed_text}  classes="_full"/>*/}
                {/*<FileItem item={props.fields.succeed_file} classes="_full"/>*/}

                {props.fields.date_of_succeed &&
                    <InfoItem item={props.fields.date_of_succeed} classes="_full" />
                }

            </div>

            <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>

                <form action={`${props.url}${params.id}/`} ref={form} onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, setErrors, navigateToView, props.fields)}>

                    <div className="d-none">
                        <FormItem field={props.fields.organisation} classes="_full" />
                        <FormItem field={props.fields.date_of_creation} classes="_grid-start-1" />
                        <FormItem field={props.fields.id} classes="" />
                        <FormItem field={props.fields.author} classes="" />
                        <FormItem field={props.fields.comment_reason} classes="_full" />

                        <FormItem field={props.fields.succeed_text} classes="_full" />
                        {/*<FormItem field={props.fields.succeed_file} classes="_full"/>*/}

                        <FormItem field={props.fields.receiver} classes="_full" />
                        <FormItem field={props.fields.name} classes="_full" />
                        <FormItem field={props.fields.description} classes="_full" />
                        <FormItem field={props.fields.file} classes="_full" />

                    </div>
                    {props.fields.support_status.value === 4 ?
                        <>

                            {isСapitalСonstruction() ?
                                <>
                                    {agreementStatus === 4 ?
                                        <div className="page__info-container mt-16 records-buttons">
                                            <div className="agreed_button">
                                                <span htmlFor="agreed_button" onClick={() => sendAgreement(3)} className="service-records_button _agreed">Принять в работу</span>
                                            </div>
                                            <div className="disagreed_button">
                                                <span htmlFor="disagreed_button" onClick={() => sendAgreement(2)} className="service-records_button _disagreed">Отклонить</span>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                    {agreementStatus === 2 ?
                                        <div className="page__info-container mt-16">
                                            <div className='_full d-flex justify-content-center'>
                                                <div className='service-records _status-disagreed'>Отклонено</div>
                                                <span onClick={() => sendAgreement(4)} className="service-records _cancel">Отменить</span>
                                            </div>
                                            <TextareaItem field={props.fields.comment_reason} required={true} fieldHandler={symbolsNumber} classes="_full" />
                                        </div>
                                        : ""
                                    }
                                    {agreementStatus === 3 ?
                                        <div className="page__info-container mt-16">
                                            <div className='_full d-flex justify-content-center'>
                                                <div className='service-records _status-agreed'>Принята в работу</div>
                                                <span onClick={() => sendAgreement(4)} className="service-records _cancel">Отменить</span>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                </>
                                : ''
                            }
                        </>
                        : ''
                    }
                    {props.fields.support_status.value === 3 ?
                        <>
                            {isСapitalСonstruction() ?

                                <div className="page__info-container mt-16 records-buttons">
                                    <div className="agreed_button _full">
                                        {agreementStatus === 1 ?
                                            <div className={"page__info-container"}>
                                                <div className='_full d-flex justify-content-center'>
                                                    <div className='service-records _status-agreed _full justify-content-center'>Выполнено</div>
                                                    <span onClick={() => sendAgreement(3)} className="service-records _cancel">Отменить</span>
                                                </div>

                                                <TextareaItem field={props.fields.succeed_text} required={true} fieldHandler={symbolsNumber} classes="_full" />
                                                <input type="hidden" name={'date_of_succeed'} value={formatted_date} />
                                            </div>
                                            :
                                            <span htmlFor="agreed_button" onClick={() => {
                                                sendAgreement(1);
                                            }} className="service-records_button _agreed m-0">Выполнено</span>
                                        }

                                    </div>
                                </div>
                                : ''
                            }
                        </>
                        : ""
                    }
                    {props.fields.support_status.value === 1 ?
                        <div className={"page__info-container"}>
                            <div className='service-records _status-agreed _full justify-content-center mt-24'>Выполнено</div>
                        </div>
                        : ""
                    }
                    {props.fields.support_status.value === 2 ?
                        <div className={"page__info-container"}>
                            <div className='service-records _status-disagreed _full justify-content-center mt-24'>Отклонено</div>
                        </div>
                        : ""
                    }

                    <input type="hidden" name="support_status" value={agreementStatus} />
                    <input type="hidden" name="receiver" value={+userData.user_id} />
                    {isСapitalСonstruction() ?
                        <div className="form__buttons-panel">
                            <input type="submit" id="save_button" className="d-none" value="Send" />
                            <label htmlFor="save_button" onClick={() => {
                                agreementStatus === 1 && canSave ? succeedSend(props.fields.id.value, 1, +userData.user_id) : ''
                            }} style={{ width: "fit-content", marginLeft: "auto" }}
                                className='save_button_fixed mt-0 ml-0'>{loading ? 'Сохранение...' : 'Сохранить'}</label>
                        </div>
                        : ''
                    }
                </form>
            </FormErrorsContext.Provider>
            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} />
                </div>
            }
            <HistoryComponent id={props.id} name={props.name} />

        </>

    )
}
export default EngineeringNetworkDetail;