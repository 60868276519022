import { createSlice } from '@reduxjs/toolkit';

const chatForwardedMessage = createSlice({
    name: 'forwardedMessage',
    initialState: {
        forwardedMessage: null,
    },
    reducers: {
        setForwardedMessage(state, action) {
            state.forwardedMessage = action.payload
            return state
        }
    },
});

export const { setForwardedMessage } = chatForwardedMessage.actions;
export default chatForwardedMessage.reducer;
