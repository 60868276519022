import React from "react";
import '../../styles/BirthdayCards.scss'

function CorpDateCard(props) {
    // props = {last_name, first_name, patronymic, birth_date, file}
    const getDayMonth = (date) => {
        return date?.slice(0, 5)
    }

    return (
        <div className={"birthday-card"} onClick={e => props.onClick(e, props)}>
            <div className={"congratulations-text"}>Поздравляем!</div>
            <div className={"main"}>
                <div className={"img-view"}>
                    <div className={"birth-date"}>{getDayMonth(props?.corp_date)}</div>
                    <img src={props?.file} className={"img-file"} alt={''}/>
                </div>
                <div className={"meta"}>
                    <div className={"last-name"}>{props?.last_name}</div>
                    <div className={"first_name-patronymic"}>{props?.first_name} {props?.patronymic}</div>
                    <div className={"hintText"}>{props?.hintText}</div>
                </div>
            </div>
        </div>
    );
}

export default CorpDateCard