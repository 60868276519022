import React, { useEffect, useState } from 'react'
import Lightbox from '../../components/Lightbox'
import { createFieldsObject, getDomain, getItems } from '../../functions/functions'
import HeaderInside from '../../components/HeaderInside'
import FormErrorsContext from '../../functions/formErrorsContext'
import FormItem from '../../components/form/FormItem'
import Alert from '../../components/Allert'

const ResponsibilityTransfer = (props) => {
    const [openModal, setOpenModal] = useState(false)
        , [styleModal, setStyleModal] = useState({ display: 'none' })
        , [fields, setFields] = useState([])
        , [error, setError] = useState('')
        , [formErrors, setFormErrors] = useState({})


    let user = {
        "type": "select",
        "required": true,
        "read_only": false,
        "label": "На кого",
        "name": "new_responsible",
        "children": fields,
        "multiple": false,
        "placeholder": "",
        "value": ''
    }


    const setModal = (e) => {
        setStyleModal({ display: 'grid' })
        setTimeout(() => {
            setOpenModal(true)
        }, 100)
    }

    const closeModal = (e) => {
        setOpenModal(false)
        setTimeout(() => {
            setStyleModal({ display: 'none' })
        }, 300)
    }

    useEffect(() => {
        getItems(`${getDomain()}/api/user/?limit=1000&status=Активен`, 'GET', setData)
    }, [])

    useEffect(() => {
        user.children = fields
    }, [fields])


    const setData = (data) => {
        const array = []
        data?.results?.map((user, idx) => {
            const newItem = {
                "value": user.id,
                "display_name": `${user.last_name ? user.last_name : ''} ${user.first_name ? user.first_name : ''} ${user.patronymic ? user.patronymic : ''}`,
                "label": `${user.last_name ? user.last_name : ''} ${user.first_name ? user.first_name : ''} ${user.patronymic ? user.patronymic : ''}`
            }
            array.push(newItem)
        })

        setFields([...array])
    }

    const Transfer = (e) => {

        e.preventDefault()
        const button = e.currentTarget
            , inputs = button.closest('.form-data').querySelectorAll('.form-container input')
            , formJson = {}

        for (const input of inputs) {
            if (input.name) {
                formJson[input.name] = input.value
            }
        }

        fetch(`${getDomain()}/api/user/${props.id}/transfer_responsibility/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify(formJson)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка. Неверно заполнены поля')
                }
                return response.json()
            })
            .then(data => {
                setTimeout(() => {
                    setOpenModal(false)
                }, 200)
                props.setAlert({
                    title: 'Ответственность передана',
                    text: '',
                    type: 'success'
                })

            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                    props.setAlert({
                        title: 'Доступ запрещен',
                        type: 'error'
                    })

                } else {
                    props.setAlert({
                        title: error.message,
                        type: 'error'
                    })

                }
            })
            
    }

    return (
        <>
            <a onClick={setModal} className='add-btn btn mt-16'>Передать ответстенность</a>
            {openModal &&
                <Lightbox style={styleModal} active={openModal} classes={'modal__content'} onClick={closeModal}  >
                    <HeaderInside title={"Передать ответственность"} clickEvent={e => { setOpenModal(false) }} />
                    <hr />
                    <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                        {error ? <p className="error-message mb-8">{error}</p> : <></>}
                        {/* {Object.keys(fields).length > 0 ? */}
                        <div className="form-data">
                            <div className="form-container form-2-columns">
                                <FormItem field={user} required={true} classes="_full" />
                            </div>
                            <div className="modal__buttons-panel">

                                <a href="" className="save_button_fixed" onClick={Transfer}>Передать</a>
                            </div>
                        </div>
                        {/* :
                            <>Загрузка...</> */}
                        {/* } */}
                    </FormErrorsContext.Provider>
                </Lightbox>
            }
        </>
    )
}

export default ResponsibilityTransfer