import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getItems } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";

function ServiceRecordsFilter(props) {
    const fields = ['name', 'organisation', 'author', 'status', 'receiver', 'date_of_creation'],
        [items, setItems] = useState([])

    useEffect(() => {
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        // const out_doc = {
        //     children:[
        //         {value: "1", label: 'Исполнено'},
        //         {value: "2", label: 'Просрочено'},
        //         {value: "3", label: 'Текущие'},
        //     ],
        //     label: "По выполнению",
        //     name: "out_doc",
        //     placeholder: "",
        //     read_only: false,
        //     required: true,
        //     type: "select",
        //     value: ""
        // }
        const statusSelect = {
            children: [
                { value: "1", label: 'Согласовано' },
                { value: "2", label: 'Не согласовано' },
                { value: "3", label: 'На согласовании' },
                { value: "", label: 'Все' },
            ],
            label: "Статус",
            name: "status",
            placeholder: "",
            read_only: false,
            required: true,
            type: "select",
            value: ""
        }

        // new_fields.out_doc = out_doc
        new_fields.status = statusSelect
        setItems(new_fields)
    }

    return (
        <>
            {Object.keys(items).length > 0 ? fields.map((key) => {
                const value = props.values.find(item => item[key] !== undefined)
                const new_field = items[key]
                // return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''}  />
                if (items[key].name === 'contragent') {
                    return <SearchContragent key={key} field={new_field} required={false} value={value ? value[key] : ''} canAdd={false} />
                } else {
                    return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />

                }
            }) : <>загрузка</>}

        </>
    )
}

export default ServiceRecordsFilter