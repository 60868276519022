import React, { useState, useEffect } from "react";

import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom"
import { getDomain, setUserData } from "../functions/functions"
import { useDispatch, useSelector } from "react-redux"
import { setUserDataItem } from "../store/userDataSlice"


function RefreshPage() {
    const navigate = useNavigate()
        , dispatch = useDispatch()
        , addUserData = (name, value) => dispatch(setUserDataItem(name, value))

    useEffect(() => {
        submitLogin()
    }, [])

    const submitLogin = () => {
        const object = {
            "refresh" : localStorage.getItem("refresh")
        }
        
        fetch(getDomain() + '/api/refresh/',  {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify(object)
        })
            .then(response => {
                if (!response.ok) {
                    navigate('/logout/', )
                }
                return response.json()
            })
            .then(data => {
                localStorage.setItem("token", data.access)
                localStorage.setItem("refresh", data.refresh)
                sessionStorage.removeItem('websocketState')
                addUserData(['token', data.access])
                addUserData(['refresh', data.refresh])
                const id = jwt_decode(data.access).user_id
                getUserData(id)
            })
            .catch(err => console.error(err.message));
    }

    const getUserData = (id) => {
        fetch(getDomain() + '/api/user/' + id + '/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    navigate('/logout/')
                }
                return response.json()
            })
            .then(data => {
                setUserData(data, addUserData)
                navigate('/')
            })
            .catch(err => console.error(err))
    }



    return <></>
}

export default RefreshPage;