import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import SortingCell from "../../components/SortingCell";

function TaskTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _tasks">
                <thead>
                    <tr>
                        {/* ordering_fields = ['name', 'id', 'author', 'receiver', 'organisation', 'date_of_creation', 'dead_line', 'date_of_succeed', 'task_project', 'tasks_status'] */}
                        <SortingCell classes="" title={'Тема'} name={'name'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Номер'} name={'id'} page_name={props.name} />
                        <SortingCell classes="" title={'От кого'} name={'author'} page_name={props.name} />
                        <SortingCell classes="" title={'Кому'} name={'receiver'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Организация'} name={'organisation'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата создания'} name={'date_of_creation'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Срок выполнения'} name={'dead_line'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата выполнения'} name={'date_of_succeed'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Проект'} name={'task_project'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Статус'} name={'tasks_status'} page_name={props.name} />
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        const status = () => {
                            if (!item.date_of_succeed && item.dead_line) {
                                const str = item.dead_line.split('.')
                                const dead_line = new Date(str[2], str[1] - 1, str[0])
                                const date_now = new Date()
                                const date = new Date();
                                date_now.setHours(0)
                                date_now.setMinutes(0)
                                date_now.setSeconds(0)
                                date_now.setMilliseconds(0)
                                date.setDate(date.getDate() + 2)
                                if (date_now > dead_line) {
                                    return 'просрочено'
                                } else if (date > dead_line) {
                                    return 'внимание'
                                }
                            }
                            return false
                        }

                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                
                                <td><Link to={`detail/${item.id}`} className="table__link">{item.name}</Link></td>
                                <td className="_center">{item.id}</td>
                                <td>{item.author?.last_name ? item.author.last_name : ''} {item.author?.first_name ? item.author?.first_name[0]?.toUpperCase() : ''}. {item.author?.patronymic ? item.author?.patronymic[0]?.toUpperCase() : ''}</td>
                                
                                {item.receiver ?
                                    <td>{item.receiver.last_name ? item.receiver.last_name : ""} {item.receiver?.first_name ? item.receiver?.first_name[0].toUpperCase() : ''}.{item.receiver?.patronymic ? item.receiver.patronymic[0].toUpperCase() : ""}.</td>
                                    : <td></td>
                                }
                                {item.organisation ?
                                    <td className="_center">{item.organisation.name}</td>
                                    : <td></td>
                                }
                                <td className="_center">{item.date_of_creation}</td>
                                <td className="_center" style={status() === "просрочено" ? { color: 'red' } : status() === "внимание" ? { color: 'rgb(255, 158, 0)' } : {}}>{item.dead_line}</td>
                                {item.date_of_succeed ?
                                    <td className="_center">{item.date_of_succeed}</td>
                                    : <td></td>
                                }
                                {item.task_project?.title ?
                                    <td className="_center">{item.task_project.title}</td>
                                    : <td></td>
                                }
                                {item.tasks_status ?
                                    <td className="_center _service-records-status"
                                        style={item.tasks_status.color
                                            ? { backgroundColor: item.tasks_status.color, color: 'white' }
                                            : { color: '#919399' }}>
                                        {item.tasks_status.title}
                                    </td>
                                    :
                                    <td></td>
                                }
                            </tr>
                        )
                    })}
                </tbody>

            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}
export default TaskTable;