import React, { useState } from "react";
import LogoUrl from "/public/Logo.svg"
import FormItem from "../components/form/FormItem";
import { getDomain } from "../functions/functions";

function LoginPage() {
    const [fields, setFields] = useState([
        {
            id: 'id_username',
            name: 'username',
            type: 'text',
            label: 'Логин',
            placeholder: '',
            value: '',
            required: true,
            disabled: false
        }
    ])

    const submitLogin = (event) => {
        event.preventDefault()
        const form = event.currentTarget,
            url =  getDomain() + '/api/login/',
            formData = new FormData(form)
        //console.dir(form)
        //console.log(formData)
        fetch(url,  {
            method: form.method,
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: formData
        })
            .then(response => {
                //console.log(response)
                if (!response.ok) {
                    throw new Error("Network response was not OK");
                }
                return response.json()
            })
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    return (
        <div className="container">
            <div className="login bg">
                <div>
                    <img src={LogoUrl} alt="" className="login__logo"/>

                    <p className="login__title">Коллектив 1.0</p>
                </div>
                <div className="login__card-wrap">
                    <div className="login__card">
                        <h1 className="card__title">Восстановление пароля</h1>
                        <form action="" onSubmit={submitLogin} method="post">
                            {fields.map((field, idx) => {
                                return <FormItem field={field} key={idx}/>
                            })}
                            <a href="/login" className="form__link">Войти</a>
                            <input type="submit" id="submit" className="d-none" value="Войти"/>
                            <label htmlFor="submit" className="gr-button _block">Восстановить</label>
                        </form>
                    </div>
                </div>
                <div>
                    <p className="login__contact">Белгородская ипотечная корпорация 2023</p>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;