import React from "react";
import {useSelector} from "react-redux"

import {FileItem, InfoItem} from "../../components/InfoItem";
import {onEmailInput} from "../../functions/functions";

function UserDetail(props) {
    // console.log(props.fields)

    const isAdmin = () => {
        const userData = useSelector(state => state.user_data.userData)
        const groups = userData.groups ? userData.groups : []
        if (groups.indexOf('Admin') !== -1) {
            return true
        } else {
            return false
        }
    }


    return (
        <div className={"page__info-container"}>
            <InfoItem item={props.fields.status} classes="_full"/>
            <InfoItem item={props.fields.groups} classes="_full"/>
            <InfoItem item={props.fields.last_name}/>
            <InfoItem item={props.fields.first_name}/>
            <InfoItem item={props.fields.patronymic}/>

            {/*{isAdmin() && <InfoItem item={props.fields.birth_date}/>}*/}
            <InfoItem item={props.fields.birth_date}/>
            <InfoItem item={props.fields.email} mask={onEmailInput} classes="_full"/>
            <InfoItem item={props.fields.internal_phone}/>
            <InfoItem item={props.fields.corp_date}/>
            <InfoItem item={props.fields.personal_phone}/>
            <InfoItem item={props.fields.organisation}/>
            <InfoItem item={props.fields.department} classes="_full"/>

            <InfoItem item={props.fields.skud_fond}/>
            <InfoItem item={props.fields.structure_bik} classes="_full"/>
            <InfoItem item={props.fields.post_bik} classes="_full"/>
            <InfoItem item={props.fields.structure_fond} classes="_full"/>
            <InfoItem item={props.fields.post_fond} classes="_full"/>
            <FileItem item={props.fields.file} classes="_full"/>

        </div>
    )
}

export default UserDetail