import React from 'react'

const Switch = ({ label, value, onChange, className = '' }) => {
    return (
        <div onClick={onChange} className={`${className ? className : ''} switch${value ? ' _active' : ''}`}>
            <div className='switch__toggle'></div>
            <label htmlFor="" className='switch__label'>{label}</label>
        </div>
    )
}

export default Switch