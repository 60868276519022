import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";


function Pagination({pagination = {}, paginate, loader, name}){
    const tablePageData = useSelector(state => state.table_page.tablePageData)
    const handler = (e) => {
        console.log('tablePageData.nextUrl[name]', tablePageData.nextUrl[name])
        if (tablePageData.nextUrl[name])
            paginate(e, tablePageData.nextUrl[name])
        else 
            paginate(e, pagination.next)
    }
    return (
        <div className='pagination'>
            {/*{pagination.previous ? <a className='pagination-arrow _previous' onClick={e => paginate(e, pagination.previous)}>Предыдущая</a> : <></>}
            {pagination.next ? <a className='pagination-arrow _next' onClick={e => paginate(e, pagination.next)}>Следующая</a> : <></>}*/}
            {pagination.next ? <a className={loader ? "pagination-arrow _loader" : "pagination-arrow"} onClick={e => handler(e)}>{loader ? "" : "Загрузить ещё"}</a> : <></>}
        </div>
    )
}

export default Pagination
