import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getItems } from "../../functions/functions";

function TasksFilter(props) {
    const fields = ['organisation', 'tasks_status', 'author', 'receiver', 'date_of_creation', 'task_project', 'register_date_in', 'register_date_out']
        , [items, setItems] = useState([])
        , for_date = {
            "name": "for_date",
            "label": "По сроку выполнения",
            "type": "select",
            "value": "",
            "required": false,
            "read_only": false,
            "placeholder": "",
            "error": "",
            "children": [
                {
                    "value": 0,
                    "display_name": "Выполненные",
                    "label": "Выполненные"
                },
                {
                    "value": 1,
                    "display_name": "Просроченные",
                    "label": "Просроченные"
                },
                {
                    "value": 2,
                    "display_name": "Внимание",
                    "label": "Внимание"
                },

            ]
        }
        , for_date_val = props.values.find(item => item['for_date'] !== undefined)

    useEffect(() => {
        // console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        const register_date_in = {
            label: "Дата регистрации с",
            name: "register_date_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }
        const register_date_out = {
            label: "Дата регистрации по",
            name: "register_date_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }
        new_fields.register_date_in = register_date_in
        new_fields.register_date_out = register_date_out
        setItems(new_fields)

    }

    return (
        <>
            {Object.keys(items).length > 0 ?
                <>
                    {fields.map((key) => {
                        const value = props.values.find(item => item[key] !== undefined)
                        const new_field = items[key]
                        return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />

                    })}
                    <FormItem field={for_date} required={false} value={for_date_val ? for_date_val['for_date'] : ''} />
                </> : <>загрузка</>}
        </>
    )
}

export default TasksFilter