import React, { useState } from 'react'
import Alert from '../../Allert'
import { useParams, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { createOrUpdate } from '../../../functions/functions'
import FormErrorsContext from '../../../functions/formErrorsContext'
import AddedFormItem from '../AddedFormItem'

const PersonalAccesses = ({ children, url, fields, absolut_save_btn = true, page_name, client_tasks = false }) => {
    const params = useParams()
        , [loading, setLoading] = useState(false)
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '',
        }) // error, alert, info, success
        , [formErrors, setFormErrors] = useState([])
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)

    const setErrors = (data) => {
        data.then(res => {
            console.log('errors', res)
        })
    }



    const navigateToView = (id) => {

        const url = `/${page_name}/`
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект изменен',
                    status: 'success'
                }
            }
        })

    }

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        if (groups.indexOf('Admin') !== -1) {
            return true
        } else {
            return false
        }
    }

    const isAuthor = () => {
        return (+fields.author?.value === +userData.user_id || +fields.registrar?.value === +userData.user_id || +fields.creator?.id === +userData.user_id)
    }


    const isReceiver = () => {
        return ((+fields.receiver?.value === +userData.user_id) || (+fields.responsible?.value === +userData.user_id))
    }

    const isSoresponsible = () => {
        if (fields.soresponsibles) {
            const array = fields.soresponsibles.value.filter(soresp => +userData.user_id === +soresp.user)
            if (array.length > 0) {
                return true
            }
            return false
        }
        return false
    }

    return (
        <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
            <Alert setAlert={setAlert} alert={alert} />
            <form action={`${url}${params.id}/`} onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, setErrors, navigateToView, fields)}>

                {children}

                {fields.soresponsibles &&
                    <div className="page__info-container mt-16">
                        <AddedFormItem field={fields.soresponsibles} classes="_full" button={'Добавить соисполнителя'} can_delete={isAuthor() || isAdmin() || isReceiver()} can_add={isAuthor() || isAdmin() || isReceiver()} />
                    </div>
                }
                {fields.observers &&
                    <div className="page__info-container mt-16">
                        <AddedFormItem field={fields.observers} classes="_full" button={'Добавить наблюдателя'} can_delete={isAuthor() || isAdmin() || isReceiver()} can_add={isAuthor() || isAdmin() || isReceiver() || isSoresponsible()} />
                    </div>
                }
                {fields.members &&
                    <div className="page__info-container mt-16">
                        <AddedFormItem field={fields.members} classes="_full" button={'Добавить участника'} can_delete={isAuthor() || isAdmin()} can_add={isAuthor() || isAdmin()} />
                    </div>
                }
                {/* 'client_tasks' условие нужно что бы не выводить эту кнопку в задачах клиентов */}
                {(!client_tasks & (isAuthor() || isAdmin() || isReceiver() || isSoresponsible() || page_name.includes('register'))) ?
                    <div className={absolut_save_btn ? 'form__buttons-panel' : 'page__info-container mt-16'}>
                        <input type="submit" id="save_button" className="d-none" value="Send" />
                        <label htmlFor="save_button" style={{ width: "fit-content", marginLeft: "auto" }} className="save_button_fixed mt-0 ml-0">{loading ? 'Сохранение...' : 'Сохранить'}</label>
                    </div>
                    : ''
                }

            </form>

        </FormErrorsContext.Provider>
    )
}

export default PersonalAccesses