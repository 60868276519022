
import React from "react"
import download from "/public/interface_img/download.svg";

function LinkDownload(props) {
    return (
        <a href={props.url} className="table__link" download target="_blank">
            <svg width="16" height="16" viewBox="0 0 16 16" className={"download-icon"} stroke="#0088CC" fill="none" xmlns="http://www.w3.org/2000/svg">
                <use href={download + "#item"}></use>
            </svg>
            <span className="download_icon"></span>
            {props.title ? props.title : 'Скачать'}
        </a>
    )
}

export default LinkDownload