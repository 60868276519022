import { createSlice } from '@reduxjs/toolkit'

const tablePageData = {
    count: null,
    limit: {},
    init_limit: {},
    initialUrl: '',
    nextUrl: {},
    //search: '',
}

export const tablePageSlice = createSlice({
    name: 'user_data',
    initialState: {
        tablePageData
    },
    reducers: {
        setCountNum(state, action) {
            state.tablePageData.count = action.payload
        },
        setUrls(state, action) {
            let url
            
            if (action.payload[1]) {
                url = new URL(action.payload[1])
                
                //url = action.payload[1].match(/http[a-z, :, \/, \., \-, \_, A-Z, 0-9]*/)[0]
                //url_p = action.payload[1].match(/\?[a-z, =, &, -, \_, \. %, A-Z, 0-9]*/)[0]
            }

            if (url) {
                console.log('url', url)
                const urlParams = new URLSearchParams(url.search)
                const nextUrlParams = new URLSearchParams(url.search)

                state.tablePageData.init_limit[action.payload[0]] = urlParams.get('offset')

                if (state.tablePageData.limit[action.payload[0]]) {
                    nextUrlParams.set('limit', state.tablePageData.limit[action.payload[0]]) // ставим адекватный лимит из сохраненного
                } else { // запрос не первый
                    state.tablePageData.limit[action.payload[0]] = urlParams.get('limit') // при первом запросе записываем лимит / некст без изменений
                }
                state.tablePageData.nextUrl[action.payload[0]] = url.origin + url.pathname + '?' + nextUrlParams.toString() // записываем новый 
            }
        },
    }
})

export const { setCountNum, setUrls } = tablePageSlice.actions
export default tablePageSlice.reducer