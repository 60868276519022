import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import SortingCell from "../../components/SortingCell";

function EngineeringNetworkTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (<>
        <table className="table _support">
            <thead>
                <tr>
                    {/* ordering_fields = ['name', 'id', 'author', 'receiver', 
                        'organisation', 'date_of_creation', 'date_of_succeed', 'support_status', ] */}
                    <SortingCell classes="" title={'Тема'} name={'name'} page_name={props.name} />
                    <SortingCell classes="_center" title={'Номер'} name={'id'} page_name={props.name} />
                    <SortingCell classes="" title={'От кого'} name={'author'} page_name={props.name} />
                    <SortingCell classes="" title={'Кому'} name={'receiver'} page_name={props.name} />
                    <SortingCell classes="_center" title={'Организация'} name={'organisation'} page_name={props.name} />
                    <SortingCell classes="_center" title={'Дата обращения'} name={'date_of_creation'} page_name={props.name} />
                    <SortingCell classes="_center" title={'Дата выполнения'} name={'date_of_succeed'} page_name={props.name} />
                    <SortingCell classes="_center" title={'Статус'} name={'support_status'} page_name={props.name} />
                </tr>
            </thead>
            <tbody>
                {props.items.map((item, idx) => {
                    const notify = checkNotifications(item.id, page_notifications)
                    return (
                        <tr key={idx} className={notify ? '_notify-row' : ''}>
                            {item.name ?
                                <td><Link to={`detail/${item.id}`} className="table__link">{item.name}</Link></td> : <td></td>
                            }
                            <td className="_center">{item.id}</td>
                            <td>{item?.author?.last_name ? item.author.last_name : ''} {item?.author?.first_name ? item.author.first_name : ''} {item?.author?.patronymic ? item.author.patronymic : ''}</td>
                            {item.receiver ?
                                <td>{item.receiver.last_name ? item.receiver.last_name : ""} {item.receiver?.first_name ? item.receiver.first_name[0].toUpperCase() : ''}.{item.receiver?.patronymic ? item.receiver.patronymic[0].toUpperCase() : ""}.</td>
                                : <td></td>
                            }
                            {item.organisation ?
                                <td className="_center">{item.organisation.name}</td>
                                : <td></td>
                            }
                            <td className="_center">{item.date_of_creation}</td>
                            {item.date_of_succeed ?
                                <td className="_center">{item.date_of_succeed}</td>
                                : <td></td>
                            }

                            {item.support_status ?
                                <td className="_center _service-records-status"
                                    style={item.support_status.color
                                        ? { backgroundColor: item.support_status.color, color: 'white' }
                                        : { color: '#919399' }}>
                                    {item.support_status.title}
                                </td>
                                :
                                <td></td>
                            }
                        </tr>
                    )
                })}
            </tbody>
        </table>
        {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
    </>
    )
}
export default EngineeringNetworkTable;