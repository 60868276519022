import React, {useEffect} from "react";
import {InfoItem} from "../../components/InfoItem";
import getFormattedDateTime from "../../functions/getFormattedDateTime"

function SendSMSDetail(props) {
    console.log('detail props', props)

    useEffect(() => {
        checkDate()
    }, [])

    let expenses = [
        {label: 'Контрагенты', value: 3},
        {label: 'Сообщения', value: 3},
        {label: 'Стоимость отправки (₽)', value: 12},
        {label: 'Баланс СМС организации (₽)', value: 20000}
    ]

    const SenderItem = (props) => {
        return (
            <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.item.label}</span>
                <p className="info__text">{props.item.value.last_name} {props.item.value.first_name} {props.item.value.patronymic}</p>
            </div>
        )
    }

    const getContragentsStr = (items) => {
        let res = items.map((item) => item.fio).join(', ')

        return res
    }

    const getContragentsPhonesStr = (items) => {
        let res = items.map((item) => item.phone).join(', ')
        return res
    }

    const ContragentItem = (props) => {
        return (
            <>
                <div className={`info__item ${props.classes ? props.classes : ''}`}>
                    <span className="info__label">{props.item.label}</span>
                    <p className="info__text">{getContragentsStr(props.item.value)}</p>
                </div>
                <div className={`info__item ${props.classes ? props.classes : ''}`}>
                    <span className="info__label">Телефоны</span>
                    <p className="info__text">{getContragentsPhonesStr(props.item.value)}</p>
                </div>
            </>
        )
    }

    const getSendAt = () => {
        let res = {...props.fields.send_at}
        res.value = getFormattedDateTime(res.value)
        return res
    }

    const checkDate = () => {
        const cur_date = new Date()
        const parsed_date = new Date(props.fields.send_at.value)
        if (props?.setCanEdit)
            props.setCanEdit(parsed_date.getTime() > cur_date.getTime())
    }

    console.log('props.fields', props.fields)
    return (
        <>
            <div className={"page__info-container"}>
                <InfoItem item={getSendAt()} classes="_full"/>
                <InfoItem item={props.fields.sms_text} classes="_full"/>
                <SenderItem item={props.fields.sender} classes="_full"/>
                <ContragentItem item={props.fields.contragent} classes="_full"/>
            </div>
        </>
    );
}

export default SendSMSDetail;
