import React, { useState, useEffect } from "react"
import FormItem from "../../components/form/FormItem"
import SaleAddFormItem from "../../components/form/SaleAddFormItem";
import {onEmailInput, onInnInput, onPhoneInput} from "../../functions/functions"

function CounterpartyFields({fields}) {
    const [isHuman, setIsHuman] = useState(fields.status.value === "Физическое лицо" ? true : false)
        // field.status is object
    return (
        <>
            <div className="tabs _full">
                <span className={isHuman ? "tab" : "tab _active"} onClick={() => {setIsHuman(false)}}>Юридическое лицо</span>
                <span className={isHuman ? "tab _active" : "tab"} onClick={() => {setIsHuman(true)}}>Физическое лицо</span>
            </div>
            {!isHuman ?
                <>
                    <FormItem field={fields.org_form} classes="_grid-start-1"/>
                    <FormItem field={fields.inn} mask={onInnInput}/>
                    <FormItem field={fields.ogrn} classes="_grid-start-1"/>
                    <FormItem field={fields.kpp} />
                    <FormItem field={fields.org_name} required={true} classes="_full"/>
                    <FormItem field={fields.fakt_address} classes="_full"/>
                    <FormItem field={fields.ur_address} classes="_full"/>
                    <FormItem field={fields.fio} classes="_full"/>
                    <FormItem field={fields.phone} mask={onPhoneInput} classes="_full"/>
                    <FormItem field={fields.email} mask={onEmailInput} classes="_full"/>
                    <FormItem field={fields.dir_fio} classes="_full"/>
                    <FormItem field={fields.dir_phone} mask={onPhoneInput} classes="_full"/>
                    <FormItem field={fields.payment_account} classes="_full"/>
                    <FormItem field={fields.bik} classes="_full"/>
                    <FormItem field={fields.bank} classes="_full"/>
                    <FormItem field={fields.cor_account} classes="_full"/>
                    <p className="_full form__sub-title">Бонусная программа</p>
                    <FormItem field={fields.partner} classes="_full"/>
                    <FormItem field={fields.partner_login} classes="_grid-start-1"/>
                    <FormItem field={fields.partner_password}/>
                    <SaleAddFormItem field={fields.saleaction} classes="_full"/>
                    <input type="hidden" name="status" value="Юридическое лицо" />
                </> :
                <>
                    <FormItem field={fields.bik_card_number} classes="_full"/>
                    <FormItem field={fields.fio} required={true} classes="_full"/>
                    <FormItem field={fields.birth_date} classes="_grid-start-1"/>
                    <FormItem field={fields.place_of_birth} classes="_full"/>
                    <FormItem field={fields.inn} mask={onInnInput}/>
                    <FormItem field={fields.fakt_address} classes="_full"/>
                    <FormItem field={fields.phone} mask={onPhoneInput} classes="_full"/>
                    <FormItem field={fields.email} mask={onEmailInput} classes="_full"/>
                    <FormItem field={fields.passport} classes="_full"/>
                    <FormItem field={fields.www_password} classes="_full"/>
                    <FormItem field={fields.work_place} classes="_full"/>
                    <FormItem field={fields.family} classes="_full"/>
                    <FormItem field={fields.spouse_fio} classes="_full"/>
                    {/*<p className="_full form__sub-title">Бонусная программа</p>*/}
                    <FormItem field={fields.client} classes="_full"/>
                    <FormItem field={fields.bonus_doc_number} classes="_full"/>
                    <input type="hidden" name="status" value="Физическое лицо"/>
                </>
            }
        </>
    )
}

export default CounterpartyFields