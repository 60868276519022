import React, { useState, useEffect, useRef } from "react"
import { InfoItem, FileItem } from "../../components/InfoItem";
import { useParams, useNavigate } from "react-router-dom"
import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";

function RegisterDetail(props) {
    const [isSettings, setIsSettings] = useState(true)
        , navigate = useNavigate()

        console.log(props.fields);

    const navigateToView = (id) => {
        const url = props.name === 'incoming' ? '/' : `/${props.name}/`
        //navigate(`/${props.name}/detail/${id}`, {
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект изменен',
                    status: 'success'
                }
            }
        })
    }

    const setErrors = (data) => {
        data.then(res => {
            console.log('errors', res)
        })
    }

    return (
        <>
            <div className='page__info-container'>
                <div className="tabs _full">
                    <span className={isSettings ? "tab" : "tab _active"} onClick={() => { setIsSettings(false) }}>Материалы договора</span>
                    <span className={isSettings ? "tab _active" : "tab"} onClick={() => { setIsSettings(true) }}>Настройки договора</span>
                </div>
                {!isSettings ?
                    <>
                        <div className="_full">
                            <PersonalAccesses url={props.url} fields={props.fields} page_name={props.name}>

                                <div className='detail_combined-block _full mb-8 mx-0'>
                                    <FileItem item={props.fields.file} classes="_full" />
                                    <div>
                                        <p className="info-title">Сумма</p>
                                        <p className="info-value">{props.fields.price.value}</p>
                                    </div>
                                    <div>
                                        <p className="info-title">Уплачено</p>
                                        <p className="info-value">{props.fields.payment_sum.value}</p>
                                    </div>
                                </div>

                                <div className='form-container _full _combined-block mb-8 mx-0'>
                                    <DocumentAddFormItem field={props.fields.doc_payment} classes="_full flex-column-reverse" />
                                    <FormItem field={props.fields.payment_sum} />
                                </div>
                                <DocumentAddFormItem field={props.fields.act_files} classes="_full flex-column-reverse" />
                                <DocumentAddFormItem field={props.fields.form_files} classes="_full flex-column-reverse" />
                                <DocumentAddFormItem field={props.fields.other_files} classes="_full flex-column-reverse" />

                                <div className="d-none">
                                    <FormItem field={props.fields.organisation} />
                                    <FormItem field={props.fields.status} />
                                    <FormItem field={props.fields.doc_num} />
                                    <FormItem field={props.fields.doc_law} />
                                    <FormItem field={props.fields.date_of_approval} />
                                    <FormItem field={props.fields.date_of_conclusion} />
                                    <FormItem field={props.fields.date_of_execution} />
                                    <FormItem field={props.fields.footing} />
                                    <FormItem field={props.fields.price} />
                                    <FormItem field={props.fields.doc_name} />
                                    <FormItem field={props.fields.contragent} />
                                    <FormItem field={props.fields.initiator} />
                                </div>
                            </PersonalAccesses>
                        </div>


                    </> :
                    <>
                        <>
                            <InfoItem item={props.fields.organisation} classes="_full" />
                            <InfoItem item={props.fields.status} classes="_full" />
                            <InfoItem item={props.fields.doc_num} classes="_grid-start-1" />
                            <InfoItem item={props.fields.doc_law} classes="_full" />
                            <InfoItem item={props.fields.date_of_approval} classes="_grid-start-1" />
                            <InfoItem item={props.fields.date_of_conclusion} />
                            <InfoItem item={props.fields.date_of_execution} classes="_grid-start-1" />
                            <InfoItem item={props.fields.footing} classes="_full" />
                            <InfoItem item={props.fields.price} classes="_full" />
                            <InfoItem item={props.fields.doc_name} classes="_full" />
                            <InfoItem item={props.fields.contragent} classes="_full" />
                            <FileItem item={props.fields.file} classes="_full" />
                            <InfoItem item={props.fields.initiator} classes="_full" />
                        </>

                    </>
                }
            </div>
            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} />
                </div>
            }
            <HistoryComponent id={props.id} name={'reestr'} />

        </>
    )
}

export default RegisterDetail