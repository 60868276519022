import React, { useEffect, useState } from "react"
import FormItem from "../../components/form/FormItem";
import SearchContragent from "../../components/form/SearchContragent";
import { createFieldsObject, getDomain, getItems } from "../../functions/functions";

const TelephonyFilter = (props) => {

    useEffect(() => {
        getItems(`${getDomain()}/api/skud/users/?limit=1000`, 'GET', setUsers)
    }, [])

    const [items, setItems] = useState({
        dateBegin: {
            label: "Начало периода",
            name: "register_date_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        },
        dateEnd: {
            label: "Конец периода",
            name: "register_date_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        },
    })

    const setUsers = (data) => {
  
        let users = []
        data.results.map((item, key) => {
            users.push({ value: item.id, label: `${item?.last_name} ${item.first_name} ${item.patronymic}` })
        })
        items.users = {
            children: users,
            label: "Работник",
            name: "user",
            placeholder: "",
            read_only: false,
            required: true,
            type: "select",
            value: ""
        }
        setItems({ ...items })
    }
    const getValue = (key) => {
        const value = props.values.find(item => item[key] !== undefined)
        if (value !== undefined)
            return value[key]
        return ''
    }
    return (
        <>
            {Object.keys(items).length > 0 ? Object.keys(items).map((key) => {
                const value = getValue(key)
                const new_field = items[key]

                if (items[key].name === 'contragent') {
                    return <SearchContragent key={key} field={new_field} required={false} value={value} canAdd={false} />
                } else {
                    return <FormItem key={key} field={new_field} required={false} value={value} />

                }
            }) : <>загрузка</>}
            {items.register_date_in ? <FormItem field={items.register_date_in} required={false} value={() => getValue('register_date_in')} /> : ''}
            {items.register_date_out ? <FormItem field={items.register_date_out} required={false} value={() => getValue('register_date_out')} /> : ''}
        </>
    )
}

export default TelephonyFilter