import { API_CONTRAGENT_MODEL } from "./const";
import apiConfig from "../apiConfig";
import axiosClient from '../axiosClient';

export const contragetnGet = async (id) => {
    let methodUrl = [API_CONTRAGENT_MODEL.url, API_CONTRAGENT_MODEL.methods.getContragent.url].join('/');


    try {
        const resp = await axiosClient.get(apiConfig.baseUrl + methodUrl + '/' + id);
        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
