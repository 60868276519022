import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"

import SearchIcon from '/public/interface_img/search.svg'
import CloseIcon from '/public/interface_img/x-close.svg'
import { useLocation } from 'react-router-dom'
import { deleteSearchData, setSearchData } from '../store/searchSlice'


const Search = () => {
    const searchData = useSelector(state => state.search_data.searchData)
        , [search, setSearch] = useState('')
        , pathname = useLocation().pathname
        , dispatch = useDispatch()
        , deleteSearch = () => dispatch(deleteSearchData())
        , setSearchStore = (value) => dispatch(setSearchData(value))

    useEffect(() => {
        deleteSearch()
    }, [pathname])

    useEffect(() => {
        console.log('searchData.search', search)
        if (searchData.search)
            setSearch(searchData.search)
        else
            setSearch('')
    }, [searchData])

    // search
    const searchHandler = (e, value) => {
        e.preventDefault()
        setSearchStore(value)
    }

    // clear search
    const searchClear = (e) => {
        e.preventDefault()
        deleteSearch()
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler(e, search)
        }
    }

    return (
        <div className='page-search'>
            <input
                onChange={e => setSearch(e.target.value)}
                onKeyDown={onEnter}
                value={search}
                type="text"
                name='search'
                className='page_search'
                autoComplete="off"
                placeholder='Введите слово для поиска…' />
            {searchData.search?.length > 0 && <span onClick={searchClear} className='page-search__delete'><img src={CloseIcon} alt="" /></span>}
            <button onClick={e => searchHandler(e, search)} disabled={search.length < 3} className='page-search__button'><img src={SearchIcon} alt="Поиск" /></button>
        </div>
    )
}

export default Search