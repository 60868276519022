import React, { useState, useEffect, useContext, useRef } from "react"


import RadioItem from "./RadioItem";
import CheckboxItem from "./CheckboxItem";
import Boolean from "./Boolean";
import SelectItem from "./SelectItem";
import FileItem from "./FileItem";
import TextareaItem from "./TextareaItem";
import FormErrorsContext from "../../functions/formErrorsContext";
import DateItem from "./DateItem";
import TextItem from "./TextItem";

const FormItem = (props) => {
    const [value, setValue] = useState(props.value ? props.value : props.field.value)
        , [fieldDirty, setFieldDirty] = useState(props.field.error ? true : false)
        , required = props.required ?? props.field.required
        , readOnly = props.read_only ?? props.field.read_only
        , type = props.type ? props.type : props.field.type
        , [error, setError] = useState(props.field.error ? props.field.error : '')
        , { formErrors, setFormErrors } = useContext(FormErrorsContext)
        , inputRef = useRef()

    useEffect(() => {
        // console.log(props.field.name, props.field.value, '======' + type)

        try {
            inputRef.current.value = props.value ? props.value : props.field.value
        } catch { }

    }, [props.field.name]); // обновляет значения полей при переключении по табам

    useEffect(() => {
        if (props.read_only === true) {
            props.field.read_only = true
        }
    }, [])

    useEffect(() => {
        if (props.field.error) {
            setError(props.field.error)
            setFieldDirty(true)
        }
    }, [{ ...props.field }])



    const fieldHandler = e => {
        const val = e.target.value
        setValue(val)

        // Добавлено для перехвата значения компонентом выше
        if (props?.setFormData)
            props.setFormData(val)

        if (required === true) {
            if (type === 'password') {
                if (val.length < 5 || val.length > 12) {
                    setError('Пароль должен содержать не меньше 5 и не больше 12 символов')
                } else {
                    if (val.length === 0) {
                        setError('Заполните поле')
                        if (fieldDirty) {
                            formErrors[props.field.name] = 'Заполните поле'
                            setFormErrors({ ...formErrors })
                        }
                    } else {
                        setError('')
                        if (fieldDirty) {
                            delete formErrors[props.field.name]
                            setFormErrors({})
                        }
                    }
                }
            } else if (type === 'date') {

            } else {
                if (val.length === 0) {
                    setError('Заполните поле')
                    if (fieldDirty) {
                        formErrors[props.field.name] = 'Заполните поле'
                        setFormErrors({ ...formErrors })
                    }
                } else {
                    setError('')
                    if (fieldDirty) {
                        delete formErrors[props.field.name]
                        setFormErrors({})
                    }
                }
            }
        }
    }

    const blurHandle = e => {
        if (required || value?.length > 0)
            setFieldDirty(true)
        if (!required && value?.length === 0)
            setFieldDirty(false)
        if (required === true && value?.length === 0)
            setError('Заполните поле')
    }

    const [label, setLabel] = useState('');

    const handleInitiator = (value) => {
        setLabel(value);
    };

    useEffect(() => {
        if (typeof props.initiator === 'function') {
            props.initiator(label);
        }
    }, [label]);

    if (type === "boolean") {
        return <Boolean {...props} inputRef={inputRef} required={required} readOnly={readOnly} value={value} />

    } else if (type === "checkbox") {
        return <CheckboxItem {...props} inputRef={inputRef} required={required} readOnly={readOnly} value={value} />

    } else if (type === "radio") {
        return <RadioItem {...props} inputRef={inputRef} required={required} readOnly={readOnly} value={value} />

    } else if (type === "select") {
        return <SelectItem {...props} inputRef={inputRef} required={required} readOnly={readOnly} initiator={handleInitiator} />;

    } else if (type === "file") {
        return <FileItem {...props} inputRef={inputRef} error={error} setError={setError} value={value} required={required} readOnly={readOnly} />

    } else if (type === "textarea") {
        return <TextareaItem {...props} inputRef={inputRef} error={error} value={value} fieldHandler={fieldHandler} required={required} blurHandle={blurHandle} readOnly={readOnly} />

    } else if (type === 'date' || type === 'datetime') {
        return (
            <div className={`form__item ${props.classes}`}>
                <span className="error-message">{(fieldDirty && error) && error}</span>
                <DateItem field={props.field}
                    fieldDirty={fieldDirty}
                    inputRef={inputRef}
                    error={error}
                    value={value}
                    type={type}
                    blurHandle={blurHandle}
                    readOnly={readOnly}
                    setValue={setValue}
                    setError={setError}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
                <label htmlFor={props.field.name}>{props.field.label}{required ? <span>*</span> : ''}</label>
            </div>
        )
    }
    else {
        return (
            <TextItem {...props} error={fieldDirty && error !== '' ? "error" : ""} inputRef={inputRef} className={fieldDirty && error !== '' ? "error" : ""} type={type} value={value} fieldHandler={fieldHandler} setValue={setValue} setError={setError} required={required} blurHandle={blurHandle} readOnly={readOnly} />
        )
    }
}

export default FormItem