import React from 'react'
import { checkNotifications, getDomain } from '../../functions/functions';
import { Link } from "react-router-dom";
import SortingCell from "../../components/SortingCell";
import { getFormatedDate } from '../../functions/functionsNew';
import LinkDownload from '../../components/LinkDownload';
import getFormattedDateTime from '../../functions/getFormattedDateTime';
import getFormattedDateTimeSec from '../../functions/getFormattedDateTimeSec';

const DocumentsTable = (props) => {
  const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())


  return (
    <>
      {props.tableLoading ? props.tableLoading :
        <table className="table _generate_documents">
          <thead>
            <tr>
              <th className="">Тип документа</th>
              <th className="_right">Дата создания</th>
              <th className="_center">Ответственный</th>
              <th className="">Файл</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, idx) => {
            
              const notify = checkNotifications(item.id, page_notifications)


              return (
                <tr key={idx} className={notify ? '_notify-row' : ''}>
                  {item.doc_title ? <td className="">{item.doc_title.template_name}</td>
                    : <td></td>
                  }
                  {item.createion_date ? <td className="_right">{getFormattedDateTimeSec(item.createion_date)}</td>
                    : <td></td>
                  }
                  {item.responsible ?
                    <td className='_center'>{item.responsible?.last_name ? item.responsible?.last_name : ""} {item.responsible?.first_name ? item.responsible?.first_name?.[0].toUpperCase() : ''}.{item.responsible?.patronymic ? item.responsible?.patronymic?.[0].toUpperCase() : ""}.</td>
                    : <td></td>
                  }
                  {item.generated_doc ?
                    <td className="">{item.generated_doc ? <LinkDownload url={getDomain() + item.generated_doc} /> : <></>}</td>
                    : <td></td>
                  }

                </tr>
              )
            })}
          </tbody>

        </table>
      }
    </>
  )
}

export default DocumentsTable