import { API_CHAT_MODEL } from "./const";
import apiConfig from "../apiConfig";
import axiosClient from '../axiosClient';

export const getChats = async (body, filter) => {
    let methodUrl = [API_CHAT_MODEL.url + '/' + API_CHAT_MODEL.methods.chat.url].join('/');

    try {

        const resp = await axiosClient.post(apiConfig.baseUrl + methodUrl , body);

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
export const updateChats = async (body) => {
    let methodUrl = [API_CHAT_MODEL.url + '/' + API_CHAT_MODEL.methods.update.url].join('/');

    try {

        const resp = await axiosClient.post(apiConfig.baseUrl + methodUrl, body);

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
