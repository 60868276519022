import React from "react";
import FormItem from "../../components/form/FormItem";
import { useSelector } from "react-redux";


const TimelogFields = ({ fields }) => {
  const userData = useSelector(state => state.user_data.userData)

  // console.log("fields", fields);
  return (
    <>
      <p className="_full bold-title">Период отсутствия</p>
      <FormItem field={fields.start_date} classes="_full" />
      <FormItem field={fields.end_date} classes="_full" />
      <FormItem field={fields.description} classes="_full" />
    </>
  )
}

export default TimelogFields