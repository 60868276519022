import React from "react";
import { Link } from "react-router-dom";
import { getDomain, checkNotifications } from "../../functions/functions";
import LinkDownload from "../../components/LinkDownload";
import SortingCell from "../../components/SortingCell";


function ServiceRecordsTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _service_records">
                <thead>
                    <tr>
                        {/* ordering_fields = ['name', 'date_of_creation', 'record_number', 
                        'organisation', 'author', 'receiver',   'status'] */}
                        <SortingCell classes="" title={'Наименование служебной записки'} name={'name'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата создания'} name={'date_of_creation'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Номер'} name={'record_number'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Фонд/БИК'} name={'organisation'} page_name={props.name} />
                        <SortingCell classes="" title={'От кого'} name={'author'} page_name={props.name} />
                        <SortingCell classes="" title={'Кому'} name={'receiver'} page_name={props.name} />
                        <th className="_center">Скан документа</th>
                        <SortingCell classes="_center" title={'Статус'} name={'status'} page_name={props.name} />

                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        let execution_date = ''
                        if (item.register_date) {
                            const register_date = new Date(item.register_date.split('.')[2], item.register_date.split('.')[1], item.register_date.split('.')[0])
                            register_date.setDate(register_date.getDate() + item.execution_period)
                            execution_date = `${register_date.getDate() < 10 ? "0" : ""}${register_date.getDate()}.${(register_date.getMonth()) < 10 ? "0" : ""}${register_date.getMonth()}.${register_date.getFullYear()}`
                        }
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td className=""><Link to={`/${props.name}/detail/${item.id}`} className="table__link">{item.name}</Link></td>
                                <td className="_center">{item.date_of_creation}</td>
                                <td className="_center">{item.record_number}</td>
                                <td className="_center">{item.organisation ? item.organisation.name : ''}</td>
                                {item.author ?
                                    <td>{item.author.last_name ? item.author.last_name : ""} {item.author?.first_name ? item.author.first_name[0].toUpperCase() : ""}.{item.author?.patronymic ? item.author.patronymic[0].toUpperCase() : ""}.</td>

                                    : <td></td>
                                }
                                {item.receiver ?
                                    <td>{item.receiver.last_name ? item.receiver.last_name : ""} {item.receiver?.first_name ? item.receiver?.first_name[0].toUpperCase() : ''}.{item.receiver.patronymic ? item.receiver.patronymic[0].toUpperCase() : ""}.</td>
                                    : <td></td>
                                }
                                {/* {item.responsible ?
                                <td>{item.responsible?.last_name ? item.responsible?.last_name : ""} {item.responsible?.first_name ? item.responsible?.first_name?.[0].toUpperCase(): ''}.{item.responsible?.patronymic ? item.responsible?.patronymic?.[0].toUpperCase() : ""}.</td>
                                : <td></td>
                            } */}
                                <td className="_center">{item.file ? <LinkDownload url={getDomain() + item.file} /> : <></>}</td>
                                {item.status ?
                                    <td className="_center _service-records-status"
                                        style={item.status.color_text
                                            ? { backgroundColor: item.status.color_text, color: 'white' }
                                            : { color: '#919399' }}>
                                        {item.status.status}
                                    </td>
                                    :
                                    <td></td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    );
}

export default ServiceRecordsTable;