import { createSlice } from '@reduxjs/toolkit'

const filtersData = localStorage.getItem('filter') ? JSON.parse(localStorage.getItem('filter')) : {}
const sortData = {}
const onlyNew = {}

export const filtersDataSlice = createSlice({
    name: 'user_data',
    initialState: {
        filtersData,
        sortData,
        onlyNew
    },
    reducers: {
        setFilterData(state, action) {
            state.filtersData[action.payload[0]] = action.payload[1]
            localStorage.setItem('filter', JSON.stringify(state.filtersData))
        },
        deleteFilterData(state, action) {
            delete state.filtersData[action.payload]
            localStorage.setItem('filter', JSON.stringify(state.filtersData))
        },
        sortBy(state, action) {
            if (state.sortData[action.payload[0]] === action.payload[1])
                state.sortData[action.payload[0]] = '-' + action.payload[1]
            else if (state.sortData[action.payload[0]] === `-${action.payload[1]}`)
                delete state.sortData[action.payload[0]]
            else
                state.sortData[action.payload[0]] = action.payload[1]
        },
        clearSort(state, action) {
            delete state.sortData[action.payload]
        },
        setOnlyNew(state, action) {
            state.onlyNew[action.payload[0]] = action.payload[1]
        }
    }
})

export const { setFilterData, deleteFilterData, sortBy, clearSort, setOnlyNew } = filtersDataSlice.actions
export default filtersDataSlice.reducer