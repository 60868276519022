import React, { useState, useEffect } from 'react';
import ReactQuill from "react-quill"

export default function TextEditor(props) {
    const [value, setValue] = useState(props.value ? props.value : props.field.value ? props.field.value : '' )
    
    useEffect(() => {
        console.log('value', value)
    }, [value])

    const modules = {
        toolbar: [
            [{ 'header': [2, 3, false] }],
            ['bold', 'italic', 'underline', 'blockquote', 'link'],
            [
                { 'list': 'ordered' },
                { 'list': 'bullet' },
                { 'align': [] },
                { 'color': [] },
                'clean'
            ]
        ],
    }

    return (
        <div className={`form__item ${props.classes ? ' ' + props.classes : ''}`}>
            <input type='hidden' name={props.field.name} value={value} />
            <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span>
            <ReactQuill
                theme="snow"
                modules={modules}
                value={value}
                onChange={setValue} />
            <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}