import React from 'react'
import {getDomain} from "../../functions/functions";
import getHumanizedDateTime from "../../functions/getHumanizedDateTime";
import CommentCard from "./CommentCard";

export default function CommentCardDelete({comment, type, parent_id = null, parent = false, object_id}) {
    return (
        <div className='comment'>
            {comment.author?.file ?
                <img src={`${comment.author.file.startsWith('http') ? '' : getDomain()}${comment.author.file}`}
                     className='comment__photo'/> :
                <span className='comment__no-photo'></span>
            }

            <div className='comment__content'>
                <span
                    className='comment__author'>{comment.author ? comment.author.last_name + ' ' + comment.author.first_name + ' ' + comment.author.patronymic : 'Неизвестный пользователь'}</span>
                <p className='comment__deleteMessageText'>Комментарий удален</p>
                <div className='comment__footer'>
                    <span className='comment__date'>{getHumanizedDateTime(comment.upload_at)}</span>
                </div>
                {comment.answers?.length > 0 && comment.answers.map((answer, idx) => {
                        if (!answer.is_available) {
                            console.log('answer ==>', answer)
                            return <CommentCardDelete comment={answer}
                                                      parent_id={parent_id === null ? comment.id : parent_id}
                                                      parent={comment.author} type={type} object_id={object_id} key={idx}/>
                        }

                        return <CommentCard comment={answer} parent_id={parent_id === null ? comment.id : parent_id}
                                            parent={comment.author} type={type} object_id={object_id} key={idx}/>
                    }
                )}
            </div>
        </div>
    )
}