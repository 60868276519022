import React from "react";
import {useSelector} from "react-redux";

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import Filter from "../../components/Filter"
import {userHasPermission} from "../../functions/functions";
import CardsLentaWrapper from "../../components/CardsLenta/wrapperCardsLenta";

function LentaView(props) {
    const filtersData = useSelector(state => state.filters_data.filtersData)

    return (
        <div className="page">
            <Header
                {...props}
                Filter={props.filter ? () => <Modal
                    title='Фильтры'
                    button={{classes: 'filter-btn', title: 'Фильтры', addition: filtersData[props.name]?.length}}>
                    <Filter filter={props.filter} url={props.url} name={props.name}
                            setData={props.setData}
                            filterData={props.filterData}
                            clearFilter={props.clearFilter}
                            selectedFields={filtersData[props.name] ? filtersData[props.name] : []}/>
                </Modal> : false}
                create={props.use_permissions ? userHasPermission('add_' + props.model_name) ? {
                    url: `/${props.name}/create`,
                    title: props.create_title
                } : false : {url: `/${props.name}/create`, title: props.create_title}}/>
            <CardsLentaWrapper/>
            <hr/>
            <Table {...props} />
        </div>
    );
}


export default LentaView