import React, { useEffect, useState } from 'react';
import arrowDoc from "/public/page_icons/documents-file-arrow.svg";
import checkDoc from "/public/page_icons/documents-file-check.svg";
import delDoc from "/public/page_icons/documents-file-delete.svg";
import getDateObject from '../functions/getDateObject'

const AgreementPeople = ({ nameInit, dateInit, agreementUser, status, comments, redo, info }) => {
    const [nameInitiator, setNameInitiator] = useState('');


    useEffect(() => {
        if (nameInit != undefined) {
            const findNameInitiator = () => {

                const foundName = nameInit.children.find((name) => name.value === nameInit.value);
                if (foundName) {
                    setNameInitiator(foundName);
                }
            };

            findNameInitiator();
        }
    }, [nameInit]);

    const date = getDateObject(dateInit);
    const formattedDate = date.toLocaleDateString(); // Формат даты: "04.07.2023"
    const formattedTime = date.toLocaleTimeString(); // Формат времени: "12:17:36"
    const formatedDataTime = `${formattedDate} ${formattedTime || ''}`;

    return (
        <div className="initiator__container _full">
            <p className="agreementDateTime">{formatedDataTime}</p>
            <h1 className="agreementName">
                {nameInitiator.label ?
                    nameInitiator.label
                    :
                    agreementUser
                }
            </h1>
            <div className='agreementFooter'>
                <img
                    className={`agreementIconDocs ${comments != undefined && status == 1}`}
                    src={nameInitiator.label ?
                        arrowDoc
                        :
                        status == 1 ?
                            checkDoc
                            :
                            delDoc
                    }
                    alt=""
                />
                <p className="agreementText">
                    {redo ?
                        'Повторное согласование'
                        :
                        nameInitiator.label ?
                            'Инициировано согласование' :
                            (status === 1 ?
                                (comments !== undefined ? comments.map((item) => {
                                    return item.comment;
                                }).pop() : 'Согласовано') :
                                (comments !== undefined ? (
                                    <p className={comments !== undefined && status !== 1 ? 'disable' : ''}>
                                        {comments.map((item) => {
                                            return item.comment;
                                        }).pop()}
                                    </p>
                                ) : 'Не согласовано')
                            )
                    }
                </p>
            </div>
        </div>
    );
};

export default AgreementPeople;
