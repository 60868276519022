import React from "react"
import AddFileInput from "./AddFileInput";

function DocumentAddFormItem(props) {
    return (
        <div className={`file__item  ${props.classes && props.classes}`}>
            <AddFileInput field={props.field} folder={props.folder}/>
            <span className="bold-title ml-4">{props.field.label}</span>
        </div>
    )
}

export default DocumentAddFormItem