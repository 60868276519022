import React from 'react';
import "../../../styles/CardCalendar.scss"
import { Link } from 'react-router-dom';


const CardCalendar = ({ event }) => {
    const date = new Date(event.start);
    const now = new Date()

     return (
        <Link to={`detail/${event._def.extendedProps.id}`} className={`calendar-item ${event.backgroundColor ? event.backgroundColor : ''} ${now > date ? 'fc-past-time' : ''}`}>
            <p className='event-time'>{`${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`}</p>
            <p className='event-title'>{event.title}</p>
        </Link>
    );
}

export default CardCalendar