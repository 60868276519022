import React from 'react'

import { getFileName } from '../functions/functions'

const FileComponent = ({ file, onClick = false, date = false, size = false, onDelete = false }) => {
    return (
        <div className='file__wrap'>
            <a href={file} className='file' download>
                <div className='file__content'>
                    <span className='file__name'>{getFileName(file)}</span>
                    {date ?
                        <span className='file__info'>{date}</span> :
                        size ?
                            <span className='file__info'>{size}</span>
                            : ''}
                </div>

            </a>
            {/*{onDelete && <span className='file__delete' onClick={onDelete}></span>}*/}
        </div>
    )
}

export default FileComponent