import React, { useState, useEffect } from "react"

import {getFileName, getDomain} from "../../functions/functions";

function FileItem(props) {
    const [files, setFiles] = useState('')
        , [value, setValue] = useState(props.value)
        , [loading, setLoading] = useState(false)

    const fileDelete = function (e) {
        e.preventDefault()
        setFiles('')
        setValue('')
    }
    const sentFile = function (e) {
        const input = e.target,
            files = [...input.files],
            form = new FormData()
        console.dir(files)
        form.append('file', files[0])
        for (const [name, value] of form) {
            //console.log(name, value)
        }
        setLoading(true)
        fetch(getDomain() + '/api/incoming/upload/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: form,
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Ошибка загрузки файла')
                }
                return response.json()
            })
            .then(data => {
                setLoading(false)
                const url = decodeURIComponent(data)
                setValue(url)
            })
            .then(error => {
                setLoading(false)
                props.setError(error)
            })
    }
    return (
        <div className={"form__item file__item " + props.classes}>
            <input id="file-btn" onChange={e => sentFile(e)} type="file" name={files || !value ? props.field.name : ''}/>
            <label className={`add-file__btn${loading ? ' _disabled' : ''} mt-8`} htmlFor="file-btn">{ loading ? 'Загрузка...' : 'Добавить файл' }</label>
            {props.error ? <span className="error-message">{props.error}</span> : ''}
            {value || files ?
                <div className="file__item_wrap">
                    <a className="file-label" href={getDomain() + value} download={true}>{files ? files : value ? getFileName(value) : ''}</a>
                    <a onClick={e => fileDelete(e, setFiles)} className="delete-file" href=""></a>
                </div> : <></>
            }
            {/*<input id="file-btn" onChange={e => fileUpload(e, setFiles)} type="file" name={files || !value ? props.field.name : ''}/>*/}
            {/* --- Если нет загруженного файла и есть путь к существующему, отправляем путь в hidden ---*/}
            {!files && value ? <input type="hidden" name={props.field.name} onChange={() => {}} value={decodeURIComponent(value)}/> : ''}

            <label className="bold-title" htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default FileItem