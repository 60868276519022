import React from "react"
import FormItem from "../../components/form/FormItem";
import SearchContragent from "../../components/form/SearchContragent";
import DocumentSourceItem from "../../components/form/DocumentSourceItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import AddedFormItem from "../../components/form/AddedFormItem";
import getFormattedDate from "../../functions/getFormattedDate";
import IncomingNumbersSearch from "../../components/form/IncomingNumbersSearch";

function OutgoingFields(props) {
    const formatted_date = getFormattedDate()
    const responsible_value = props.is_my ? localStorage.getItem('user_id') : ''


    return (
        <>
            <FormItem field={props.fields.organisation} required={true} classes="" read_only={true}/>
            <FormItem field={props.fields.date_out} required={true} classes=""/>
            <FormItem field={props.fields.output_number} classes="_grid-start-1" read_only={true} />
            <FormItem field={props.fields.register_date} classes="" read_only={true} value={props.fields.register_date.value ? props.fields.register_date.value : formatted_date}/>
            <FormItem field={props.fields.doc_name} type={'textarea'} required={true} classes="_full"/>
            <SearchContragent field={props.fields.contragent} classes="_full" />
            <FormItem field={props.fields.whom_fio} classes="_full"/>
            <FormItem field={props.fields.output_doc_type} classes="_full"/>
            <DocumentSourceItem field={props.fields.source} second_field={props.fields.mail_type} classes="_full" required={true}/>
            <FormItem field={props.fields.motiv_number} classes="_grid-start-1"/>
            <FormItem field={props.fields.close_doc_number} classes=""/>
            <FormItem field={props.fields.description} classes="_full"/>
            <FormItem field={props.fields.service_comment} classes="_full"/>
            <FormItem field={props.fields.file} classes="_full"/>
            <DocumentAddFormItem field={props.fields.other_doc} classes="_full"/>
            <FormItem field={props.fields.responsible} required={true} classes="_full" read_only={!!props.is_my} value={responsible_value ? responsible_value : false} />
            <FormItem field={props.fields.registrar} classes="d-none" read_only={true} value={localStorage.getItem('user_id')} />
            <p className="_full form__sub-title">Прикрепить к входящему</p>

            <IncomingNumbersSearch field={props.fields.incoming_numbers} classes="_full" />

            {/* <FormItem field={props.fields.incoming_numbers} classes="_grid-start-1 _full" /> */}

            {/* <AddedFormItem field={props.fields.incoming_number} classes="_full" button={'Добавить входящее'} /> */}
            <AddedFormItem field={props.fields.observers} classes="_full" button={'Добавить наблюдателя'} />
        </>
    );
}

export default OutgoingFields