import React, { useEffect } from "react";
import { logOut } from "../functions/functions";

function LogoutPage() {
    useEffect(() => {
        logOut()
    })
    return (
        <div>
        </div>
    );
}

export default LogoutPage;