import { API_CHAT_MODEL } from "./const";
import apiConfig from "../apiConfig";
import axiosClient from '../axiosClient';

export const getChatRoom = async (id, filter) => {
    let methodUrl = [API_CHAT_MODEL.url + '/' + id + '/' + filter].join('/');

    try {

        const resp = await axiosClient.get(apiConfig.baseUrl + methodUrl);

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
