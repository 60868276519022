import React from 'react'
import FormItem from '../../components/form/FormItem'
import AddedFormItem from '../../components/form/AddedFormItem'
import { useLocation } from 'react-router-dom';
import getHumanizedDateTime from '../../functions/getHumanizedDateTime';
import { getFormatedDate } from '../../functions/functionsNew';
import DocumentAddFormItem from '../../components/form/DocumentAddFormItem';

const EventCalendarFields = ({ fields }) => {

  const location = useLocation();

  const selectedDate = location.state && location.state.selectedDate;
  let datePost;

  if (selectedDate) {
    const date = new Date(selectedDate);
    datePost = getFormatedDate(date);
  }

  return (
    <>
      <FormItem field={fields.name} classes="_full" />
      <FormItem field={fields.start_date} classes="" value={datePost} />
      <FormItem field={fields.start_time} classes="" />
      <FormItem field={fields.description} classes="_full" required='true' />
      <FormItem field={fields.meeting_type} classes="_full" />
      <FormItem field={fields.place} classes="_full" required='true' />
      <DocumentAddFormItem field={fields.files} classes="_full" />
      <AddedFormItem field={fields.members} classes="_full" button={'Добавить участника'} required='true'/>

    </>
  )
}

export default EventCalendarFields