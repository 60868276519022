export const API_CHAT_MODEL = {
    entity: 'chat',
    url: 'chats',
    methods: {
        chat:{
            url: 'start/'
        },
        update:{
            url: 'update/'
        },
        allChats:{
            url: 'chats/'
        },
        getChat:{
            url: 'get_chat/'
        }
    },
}