import React, { useEffect, useState } from "react"
import FormItem from "../../components/form/FormItem";
import SearchContragent from "../../components/form/SearchContragent";
import { createFieldsObject, getDomain, getItems } from "../../functions/functions";

const DocumentsFilter = (props) => {
   
    const fields = ['doc_title', 'register_date_in', 'register_date_out']
        , [items, setItems] = useState([])


    useEffect(() => {
        console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        const register_date_in = {
            label: "Дата регистрации с",
            name: "register_date_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }
        const register_date_out = {
            label: "Дата регистрации по",
            name: "register_date_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }
        new_fields.register_date_in = register_date_in
        new_fields.register_date_out = register_date_out
        setItems(new_fields)
    }

    return (
        <>
            {Object.keys(items).length > 0 ?
                <>
                    {fields.map((key) => {
                        const value = props.values.find(item => item[key] !== undefined)
                        const new_field = items[key]
                        if (items[key].name === 'contragent') {
                            return <SearchContragent key={key} field={new_field} required={false} value={value} canAdd={false} />
                        } else {
                            return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />
                        }
                    })}
                    {/* <FormItem field={for_date} required={false} value={for_date_val ? for_date_val['for_date'] : ''} /> */}
                </> : <>загрузка</>}
        </>
    )
}

export default DocumentsFilter