import { API_INCOMING_MODEL } from "./const";
import apiConfig from "../apiConfig";
import axiosClient from '../axiosClient';

export const uploadImage = async (body, filter) => {
    let methodUrl = [API_INCOMING_MODEL.url + '/' + API_INCOMING_MODEL.methods.upload.url].join('/');

    try {

        const resp = await axiosClient.post(apiConfig.baseUrlMediaGeo + methodUrl, body);

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
