import React from "react";
import SortingCell from "../../components/SortingCell";
import getFormattedDateTime from "../../functions/getFormattedDateTime"
import {Link} from "react-router-dom";
import {checkNotifications} from "../../functions/functions";
import '../../styles/SendSMS.scss'

function SendSMSTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    // console.log('props', props)

    const getContragentsStr = (items) => {
        let res = items.map((item) => item.fio).join(', ')
        return res
    }

    return (
        <>
            <table className={'table _sms_service'}>
                <thead>
                <tr>
                    <th className="">Дата создания</th>
                    <th className="">Дата отправки</th>
                    <SortingCell classes="" title={'Сотрудник'} name={'sender'} page_name={props.name}/>
                    <th className="">Текст сообщения</th>
                    <th className="">Контрагент</th>
                    <SortingCell classes="" title={'Состояние'} name={'is_sent'} page_name={props.name}/>
                </tr>
                </thead>
                <tbody>
                {props.items && props.items.map((item, idx) => {
                    const notify = checkNotifications(item.id, page_notifications)
                    return (
                        <tr key={idx} className={notify ? '_notify-row' : ''}>
                            <td className=""><Link to={`/${props.name}/detail/${item.id}`}
                                                   className="table__link">{getFormattedDateTime(item.created_at)}</Link>
                            </td>
                            <td className="">{getFormattedDateTime(item.send_at)}</td>
                            <td className="">{item.sender.last_name} {item.sender.first_name} {item.sender.patronymic}</td>
                            <td className="">{item.sms_text}</td>
                            <td className="">{getContragentsStr(item.contragent)}</td>
                            <td className="">{item.is_sent ? 'Отправлено' : 'Не отправлено'}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    );
}

export default SendSMSTable;