import { createSlice } from '@reduxjs/toolkit'

const searchData = {
    search: '',
}

export const searchSDataSlice = createSlice({
    name: 'user_data',
    initialState: {
        searchData
    },
    reducers: {
        setSearchData(state, action) {
            state.searchData.search = action.payload
        },
        deleteSearchData(state) {
            state.searchData.search = ''
        }
    }
})

export const { setSearchData, deleteSearchData } = searchSDataSlice.actions
export default searchSDataSlice.reducer