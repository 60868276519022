import React, { useState, useEffect } from "react"
import Select from 'react-select';
import Modal from "../../components/Modal";

import {getDomain, createFieldsObject, getItems} from "../../functions/functions";

import FormItem from "../../components/form/FormItem";

 

function ProjectFastCreate({addOption, openModal}) {
    const [fields, setFields] = useState({})
        , [error, setError] = useState('')
        , [title, setTitle] = useState('')
        , project_name = {
            label: 'Название проекта',
            name: 'title',
            type:'text'
        }
        
    useEffect(() => {
        getItems(`${getDomain()}/api/tasks/`, 'OPTIONS', setData)
    },[])
 
    const setData = (data) => {
        const newFields = createFieldsObject(data.actions.POST)
        setFields(newFields)

    }

    const getTitle = (e) => {
       setTitle(e.target.value)
    }
    
    const ProjectCreate = (e) => {
        e.preventDefault()
        const formJson = {
                author: localStorage.getItem('user_id'),
                title: title
            }

        console.log('formJson', formJson)

        fetch(`${getDomain()}/api/tasks/project/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify(formJson)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка. Неверно заполнены поля')
                }
                return response.json()
            })
            .then(data => {
                addOption(data)
                openModal(false)
            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                    setError('Доступ запрещен')
                } else {
                    setError(error.message)
                }
            })
    }

    return (
        <>
            {error ? <p className="error-message mb-8">{error}</p> : <></>}
            {Object.keys(fields).length > 0 ?
                <div className="form-data">
                    <div className="">

                    <input type="hidden" name='author' value={localStorage.getItem('user_id')}/>
                    <FormItem field={project_name} required={true} handler={getTitle} />

                    </div>
                    <div className="modal__buttons-panel">
                        <input type="submit" id="save_contragent_button" className="d-none" value="Send"/>
                        <label htmlFor="save_contragent_button" className="save_button_fixed" onClick={ProjectCreate}>Создать</label>
                    </div>
                </div>
                :
                <>Загрузка...</>
            }
        </>
    )
};

function SelectTaskGroup(props) {
    const [value, setValue] = useState([]),
        [addButton, setAddButton] = useState(false),
        [options, setOptions] = useState(props.field.children)

    useEffect(() => {
        if (props.value) {
            const options = props.field.children.filter(option => typeof props.value !== 'object' ?
                option.value === props.value.toString() :
                props.value.some(val => option.value === val))
            setValue(options)
        } else if (props.field.value) {
            const options = props.field.children.filter(option => typeof props.field.value !== 'object' ?
                option.value === props.field.value :
                props.field.value.some(val => option.value === val))
            setValue(options)
        }
    },[])

    const handleChange = selectedOption => {
        setAddButton(false)
        setValue(selectedOption)
    }

    const addOption = (data) => {
        const new_option = {}
        new_option.value = data.id
        new_option.label = data.title
        options.push(new_option)
        setOptions(options)
        setValue(new_option)
    }

    const emptySearch = () => {
        setAddButton(true)
        return 'Нет результатов'
    }
    return (
        <>
            <div className={"form__item " + props.classes}>
                <span className="error-message">{props.error ? props.error : ''}</span>
                <Select classNamePrefix="select-item"
                        onChange={handleChange}
                        noOptionsMessage={emptySearch}
                        onEmptied={() => console.log('onEmptied')}
                        value={value}
                        options={options}
                        isMulti={false}
                        name={props.field.name}
                        placeholder={props.field.placeholder}
                        isSearchable={true}
                        required={false}
                        isDisabled={props.read_only ? props.read_only : props.field.read_only}/>
                <label htmlFor={props.field.name}>{props.field.label}</label>
                {addButton && <Modal classes='_small' title="Создание проекта" button={{title: 'Новый проект', classes: 'add-absolute add-btn btn'}}><ProjectFastCreate addOption={addOption} openModal={setAddButton} /></Modal>}
            </div>
        </> 
    )
}


export default SelectTaskGroup