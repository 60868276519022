import React from 'react'
import { checkNotifications } from '../../functions/functions';
import { Link } from "react-router-dom";
import SortingCell from "../../components/SortingCell";

const ClientsTasksTable = (props) => {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())

    // console.log('====================================');
    // console.log('props', props.items);
    // console.log('====================================');

    return (
        <>
            {props.tableLoading ? props.tableLoading :
                <table className="table _client-tasks">
                    <thead>
                        <tr>
                            <th className="">№</th>
                            {/* <th className="">Категория задачи</th> */}
                            <SortingCell classes="" title={'Категория задачи'} name={'category'} page_name={props.name} />
                            {/* <th className="_center">Дата создания</th> */}
                            <SortingCell classes="_center" title={'Дата создания'} name={'create_at'} page_name={props.name} />
                            <th className="_center">Взято в работу</th>
                            {/* <th className="_center">Дата исполнения</th> */}
                            <SortingCell classes="_center" title={'Дата исполнения'} name={'success_date'} page_name={props.name} />
                            {/* <th className="">Контрагент</th> */}
                            <SortingCell classes="" title={'Контрагент'} name={'contragent'} page_name={props.name} />

                            {/* <th className="_center">Исполнитель</th>
                            <th className="_center">Ответственный</th> */}
                            <SortingCell classes="_center" title={'Исполнитель'} name={'executor'} page_name={props.name} />
                            <SortingCell classes="_center" title={'Ответственный'} name={'responsible'} page_name={props.name} />
                            {/* <th className="_center">Статус</th> */}
                            <SortingCell classes="_center" title={'Статус'} name={'status'} page_name={props.name} />
                            {/* <SortingCell classes="" title={'№'} name={'id'} page_name={props.name} /> */}
                            {/* <SortingCell classes="" title={'Категория задачи'} name={'category'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата создания'} name={'create_at'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Взято в работу'} name={'action_at'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата исполнения'} name={'success_date'} page_name={props.name} />
                        <SortingCell classes="" title={'Контрагент'} name={'contragent'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Исполнитель'} name={'department'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Ответственный'} name={'responsible'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Статус'} name={'status'} page_name={props.name} /> */}
                        </tr>
                    </thead>
                    <tbody>
                        {props.items.map((item, idx) => {
                            const notify = checkNotifications(item.id, page_notifications)
                            const status = () => {
                                if (!item.date_of_succeed && item.dead_line) {
                                    const str = item.dead_line.split('.')
                                    const dead_line = new Date(str[2], str[1] - 1, str[0])
                                    const date_now = new Date()
                                    const date = new Date();
                                    date_now.setHours(0)
                                    date_now.setMinutes(0)
                                    date_now.setSeconds(0)
                                    date_now.setMilliseconds(0)
                                    date.setDate(date.getDate() + 2)
                                    if (date_now > dead_line) {
                                        return 'просрочено'
                                    } else if (date > dead_line) {
                                        return 'внимание'
                                    }
                                }
                                return false
                            }

                            return (
                                <tr key={idx} className={notify ? '_notify-row' : ''}>

                                    <td><Link to={`detail/${item.id}`} className="table__link">{item.id}</Link></td>
                                    <td><Link to={`detail/${item.id}`} className="table__link">{item.category?.name}</Link></td>

                                    {/* <td className="">{item.category.name}</td> */}
                                    {item.create_at ? <td className="_center">{item.create_at}</td>
                                        : <td></td>
                                    }
                                    {item.action_at ? <td className="_center">{item.action_at}</td>
                                        : <td></td>
                                    }
                                    {item.success_date ? <td className="_center">{item.success_date}</td>
                                        : <td></td>
                                    }
                                    {item.contragent ?
                                        item.contragent.org_name ?
                                            <td className="">{item.contragent.org_name}</td>
                                            :
                                            <td className="">{item.contragent.fio}</td>
                                        : <td></td>
                                    }
                                    {item.executor ?
                                        <td className='_center'>{item.executor?.last_name ? item.executor.last_name : ''} {item.executor?.first_name ? item.executor?.first_name[0]?.toUpperCase() : ''}. {item.executor?.patronymic ? item.executor?.patronymic[0]?.toUpperCase() : ''}</td>
                                        : <td></td>
                                    }
                                    {item.responsible ?
                                        <td className='_center'>{item.responsible?.last_name ? item.responsible?.last_name : ''} {item.responsible?.first_name ? item.responsible?.first_name?.[0]?.toUpperCase() : ''}. {item.responsible?.patronymic ? item.responsible?.patronymic?.[0]?.toUpperCase() : ''}</td>
                                        : <td></td>
                                    }
                                    {item.status ?
                                        <td className="_center _service-records-status"
                                            style={item.status.color
                                                ? { backgroundColor: item.status.color, color: 'white' }
                                                : { color: '#919399' }}>
                                            {item.status.name}
                                        </td>
                                        :
                                        <td></td>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>

                </table>
            }
        </>
    )
}

export default ClientsTasksTable