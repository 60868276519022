import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getItems } from "../../functions/functions";

function UserFilter(props) {
    const fields = ['status', 'organisation', 'last_name', 'first_name', 'department', 'post_bik', 'post_fond', 'structure_bik', 'structure_fond', 'personal_phone'],
        [items, setItems] = useState([])

    useEffect(() => {
        // console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        setItems(createFieldsObject(data.actions.POST))
    }

    return (
        <>
            {Object.keys(items).length > 0 ? fields.map((key) => {
                const value = props.values.find(item => item[key] !== undefined)
                const new_field = items[key]
                return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />
            }) : <>загрузка</>}
        </>
    )
}

export default UserFilter