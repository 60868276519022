import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useOutletContext } from "react-router-dom"
import { userHasPermission } from '../functions/functions';
import { routes } from "../pages";
import Footer from "./Footer";
import logo_home from "/public/Logo-item.svg";
import logo from "/public/Logo-white-color.svg";
import setCookie from '../functions/setCookie';
import sections from '../navigation';
import { closeAccordionMenu, openAccordionMenu } from '../store/accordionMenu';

function Nav() {
    const userData = useSelector(state => state.user_data.userData)
        , navState = useSelector(state => state.nav_state.navState)
        , accordionState = useSelector(state => state.accordionMenu.accordionMenu)
        , dispatch = useDispatch()
        , openAccordion = useCallback((id) => dispatch(openAccordionMenu(id)), [dispatch])
        , closeAccordion = useCallback((id) => dispatch(closeAccordionMenu(id)), [dispatch])
        , [notifiedSections, setNotifiedSections] = useState([])
        , notificationClasses = useSelector(state => state.navNotification.notificationClasses)
        , notificationPrivateClasses = useSelector(state => state.navNotification.notificationPrivateClasses)

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1
    };

    const toGEOS = (e) => {
        e.preventDefault();
        setCookie("token", localStorage.getItem("token"), 1); // Измените срок действия (1 день в этом примере)
        setCookie("refresh", localStorage.getItem("refresh"), 1); // Измените срок действия (1 день в этом примере)
        window.location.replace(process.env.REACT_APP_GEO_URL);
    }

    const accordionNotification = (section) => {
        try {
            const isHasNotify = notificationClasses.some(model_name => section.model.includes(model_name));

            if (isHasNotify && !notifiedSections.includes(section.id)) {
                setNotifiedSections([...notifiedSections, section.id])
            }

            if (!isHasNotify && notifiedSections.includes(section.id)) {
                const updatedNotifiedSections = notifiedSections.filter(item => item !== section.id)
                setNotifiedSections(updatedNotifiedSections)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const renderNavItems = (pages) => {
        return pages.map((page, idx) => {

            const route = routes.find(route => route.name === page);
            if (route) {
                const isMarked = (route) => {
                    try {
                        if (route.is_my) {
                            return notificationPrivateClasses.includes(route.model_name)
                        }
                        return notificationClasses.includes(route.model_name)
                            || notificationPrivateClasses.includes(route.model_name)
                    } catch (e) {
                        return false
                    }
                }

                if (userHasPermission('view_' + route.model_name) || !route.use_permissions) {
                    return <NavItem key={idx}
                        to={route.name === 'lenta' ? '/' : '/' + route.name}
                        name={route.name}
                        is_marked={isMarked(route)}
                        title={route.title} />
                }
            }
        });
    }

    return (
        <nav className="nav bg" id="nav">
            <div className="nav__header">
                <a href='/' className="nav__logo">
                    <img src={navState.nav_active ? logo : logo_home} className="nav__logo-img" />
                    {navState.nav_active && <span className="nav__logo-text">Коллектив<br />1.0</span>}
                </a>
            </div>
            {
                <div className="nav__links">
                    {sections.map((section, index) => {
                        accordionNotification(section)

                        if (section.title) {
                            return <div key={index}
                                className={`accordion ${accordionState.includes(section.id) ? 'active' : ''}`}>
                                <div
                                    className={`accordion-header ${notifiedSections.includes(section.id) ? 'blink' : ''}`}
                                    onClick={() => {
                                        accordionState.includes(section.id) ? closeAccordion(section.id) : openAccordion(section.id)
                                    }}>
                                    <span style={{ backgroundColor: section.color }}
                                        className='accordion-indicator'></span>
                                    <p className=''>{section.title}</p>
                                </div>
                                <div className={`accordion-body`} id={section.id}>
                                    {renderNavItems(section.pages)}
                                </div>
                            </div>
                        } else {
                            return <div key={index} className='mb-12'>
                                {renderNavItems(section.pages)}
                                <a href='https://roundcube.timeweb.ru/' className={`nav__link _email`}
                                    alt={'Корпоративная почта'}>
                                    <span className="nav__title">Корпоративная почта</span>
                                </a>
                            </div>
                        }
                    })}
                    <hr />

                    {isAdmin() || userHasPermission('view_geolandplothistory') ?
                        <a href='#' onClick={toGEOS} className={`nav__link _GEOS`} alt={'ГЕО ИЖС'}>
                            <span className="nav__title">ГЕО ИЖС</span>
                        </a>

                        : false
                    }
                </div>
            }
            {/* <span onClick={() => setNavState('nav_active', !navState.nav_active)} className='nav__close-btn'>
                <img src={NavArrowIcon} />
            </span> */}
            <Footer />
        </nav>
    );
}

function NavItem(props) {
    return (
        <NavLink to={props.to} title={props.title} className={`nav__link _${props.name}`}>
            {props.is_marked ?
                <div className='_notify-item'>
                    {<span className='_notify-mark'></span>}
                </div> : <></>
            }

            <span className="nav__title">{props.title}</span>
        </NavLink>
    )
}

export default Nav
