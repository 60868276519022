import React from "react";
import { Link } from "react-router-dom";
import { getDomain, checkNotifications } from "../../functions/functions";
import LinkDownload from "../../components/LinkDownload";
import SortingCell from "../../components/SortingCell";

function IncomingTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())

    return (
        <>
            <table className="table _incoming">
                <thead>
                    <tr>
                        {/* ordering_fields = ['doc_name', 'register_date', 'incoming_number', 'out_org_number', 'organisation', 'contragent', 'responsible'] */}
                  
                        <SortingCell classes="" title={'Наименование документа'} name={'doc_name'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата регистрации'} name={'register_date'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Входящий номер'} name={'incoming_number'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Исходящий номер орг.'} name={'out_org_number'} page_name={props.name} />
                        <th className="_center">Скан документа</th>
                        <SortingCell classes="_center" title={'Фонд/БИК'} name={'organisation'} page_name={props.name} />
                        <SortingCell classes="" title={'От кого'} name={'contragent'} page_name={props.name} />
                        <SortingCell classes="" title={'Ответственный'} name={'responsible'} page_name={props.name} />
                        <th className="_right">Срок исполнения</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        let execution_date = ''
                        let overdue = false
                        let dateNow = new Date
                        dateNow.setDate(dateNow.getDate() - 1)
                        if (item.register_date) {
                            const register_date = new Date(item.register_date.split('.')[2], item.register_date.split('.')[1] - 1, item.register_date.split('.')[0])
                            register_date.setDate(register_date.getDate() + item.execution_period)
                            execution_date = `${register_date.getDate() < 10 ? "0" : ""}${register_date.getDate()}.${(register_date.getMonth() + 1) < 10 ? "0" : ""}${register_date.getMonth() + 1}.${register_date.getFullYear()}`
                            if (register_date.getTime() < dateNow.getTime())
                                overdue = true
                        }

                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td className=""><Link to={`/${props.name}/detail/${item.id}`} className="table__link">{item.doc_name}</Link></td>
                                <td className="_center">{item.register_date}</td>
                                <td className="_center">{typeof item.incoming_number !== 'object' ? item.incoming_number : ''}</td>
                                <td className="_center">{item.out_org_number}</td>
                                <td className="_center">{item.file ? <LinkDownload url={getDomain() + item.file} /> : <></>}</td>
                                <td className="_center">{item.organisation ? item.organisation.name : ''}</td>
                                <td>{item.contragent ? item.contragent.org_name ? item.contragent.org_name : item.contragent.fio : ''}</td>
                                <td>
                                {item.responsible
                                    ? item.responsible?.last_name
                                    ? `${item.responsible?.last_name} ${item.responsible?.first_name?.[0] || ''}. ${item.responsible?.patronymic?.[0] || ''}.`
                                    : ''
                                    : ''}
                                </td>

                                {item.output_doc || item.do_not_executed || (item.output && item.output.length > 0)
                                    ? <td className="_right _document-status pr-32" style={{ color: "rgb(13, 194, 104)", }}>Готов</td>
                                    : <td className={overdue ? "_red _right" : "_right"}> {execution_date ? `до ${execution_date}` : ''}{overdue}</td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    );
}

export default IncomingTable;