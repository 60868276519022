import React from "react";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import UpcomingDatesTable from "./UpcomingDatesTable";

export default function UpcomingDatesView(props) {
    return (
        <ViewContainer {...props} >
            <UpcomingDatesTable/>
        </ViewContainer>
    )
}