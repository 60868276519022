import React from 'react'
import getHumanizedDateTime from '../../functions/getHumanizedDateTime';
import { useSelector } from 'react-redux';

const EntranceTable = (props) => {
    const userData = useSelector((state) => state.user_data.userData)

    return (
        <>
            <table className="table _entrance">
                <thead>
                    <tr>
                        <th>ФИО</th>
                        <th className="_center">Турникет</th>
                        <th className="_center">Дата и время</th>
                        <th className="_center">Вход/Выход</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{item.user_id}</td>
                                <td className="_center">{item.is_enter ? item.zone_enter : item.zone_exit}</td>
                                <td className="_center">{getHumanizedDateTime(item.time_label)}</td>
                                <td className="_center">{item.is_enter ? 'Вход' : 'Выход'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}

export default EntranceTable