import React from 'react'
import FormItem from '../../components/form/FormItem';
import SearchContragent from '../../components/form/SearchContragent';
import SearchLandplot from '../../components/form/SearchLandplot';
import { onKadastrInput } from '../../functions/functions';

const DocumentsCreate = ({ fields }) => {

  return (
    <>
      <FormItem field={fields.doc_title} classes="_full" />

      <SearchContragent field={fields.contragent} classes="_full" />
      <SearchLandplot field={fields.landplot} mask={onKadastrInput} classes="_full" />
      <div className='form__item _full'>
        <label className='_full' style={{fontVariationSettings: "'wght' 400"}}>Поле "Контрагент" заполняется автоматически при заполнении поля "Участок"</label>
      </div>

    </>
  )
}

export default DocumentsCreate