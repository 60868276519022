import React, { useState, useEffect } from "react"
import FileItem from "./FileItem";
import { getDomain, getFileName } from "../../functions/functions";
import { useSelector } from "react-redux";

function AddFileInput(props) {
    const [value, setValue] = useState(props.field.value)
        , [loading, setLoading] = useState(false)
        , [error, setError] = useState('')
        , [fullFileName, setFullFileName] = useState(false)
        , userData = useSelector(state => state.user_data.userData)

    // console.log('userData', userData)

    useEffect(() => {
        const arr = []
        for (let val of props.field.value) {
            arr.push({ "file": decodeURI(val.file) })
        }
        setValue(arr)

    }, [])

    const isAdmin = () => {
        const groups = JSON.parse(userData.groups)
        return (groups.indexOf('Admin') !== -1)
    }


    const addField = (item) => {
        if (typeof item === 'object') {
            const new_arr = [...value]
            for (const url of item) {
                new_arr.push({ "file": decodeURI(url) })
            }
            setValue(new_arr)
        } else {
            const new_arr = [...value]
            new_arr.push({ "file": decodeURI(item) })
            setValue(new_arr)
        }

    }

    const removeField = (e, index) => {
        e.preventDefault()
        const arr = [...value]
        arr.splice(index, 1)
        setValue(arr)
    }
    const sendFile = function (e) {
        const input = e.target,
            files = [...input.files]
            , new_value = []
        setLoading(true)
        setError('')

        for (let i = 0; i < files.length; i++) {
            const form = new FormData()
            form.append('file', files[i])

            fetch(getDomain() + '/api/incoming/upload/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: form,
            })
                .then(response => {
                    if (!response.ok) {
                        throw Error('Ошибка загрузки файла')
                    }
                    return response.json()
                })
                .then(data => {
                    const url = decodeURIComponent(data)
                    new_value.push(url)
                    if (new_value.length === files.length) {
                        addField(new_value)
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                    if (error.message === 'URI malformed') {
                        setError('Имя файла содержит запрещенные символы')
                    } else if (error.message.toLowerCase() === 'failed to fetch') {
                        setError('Доступ запрещен')
                    } else {
                        setError(error.message)
                    }
                })
        }
    }
    const showFileName = (file) => {
        const name = getFileName(file)
        // console.log(name)

    }

    return (
        <>
            <label htmlFor={"add_" + props.field.name} className={`add-file__btn mt-8 ${loading ? 'doc_loader' : ''}`}>{loading ? 'Загрузка' : 'Добавить документ'}</label>
            <input type="hidden" name={props.field.name} onChange={() => { }} value={JSON.stringify(value)} />
            <input id={"add_" + props.field.name} onChange={e => sendFile(e)} className="d-none" type="file" multiple />
            {value.length > 0 ? value.map((val, idx) => {
                return (
                    <div className="file__item_wrap" key={idx}>
                        <a className="file-label" title={getFileName(val.file)} idx={idx} href={getDomain() + val.file} download={true}>{getFileName(val.file)}</a>
                        {isAdmin() &&
                            <a href="" onClick={e => removeField(e, idx)} className="delete-file"></a>
                        }


                    </div>
                )
            }) : ''}
            {error ? <span className="error-message ml-4 mb-0">{error}</span> : ''}
        </>
    )
}

export default AddFileInput