import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import getFormattedDate from "../../functions/getFormattedDate";

function LentaTable(props) {

    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _lenta">
                <thead>
                    <tr>
                        <th>Название объявления</th>
                        <th className="_center">Дата создания</th>
                        <th className="_center">Организация</th>
                        <th className="_right">Автор</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        //console.log(item)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                {item.title ?
                                    <td><Link to={`/lenta/detail/${item.id}`} className="table__link">{item.title}</Link></td> : <td></td>
                                }
                                {item.upload_at ?
                                    <td className="_center">{getFormattedDate(item.upload_at)}</td> : <td></td>
                                }
                                {item.organisation ?
                                    <td className="_center">{Array.isArray(item.organisation) ? item.organisation.map((org, idx) => (
                                        <span key={idx}>{org.name}{(idx + 1) < item.organisation.length ? ', ' : ''}</span>
                                    )) : ''}</td> : <td></td>
                                }
                                {item.author ?
                                    <td className="_right">{item.author.last_name} {item.author.first_name}</td> : <td></td>
                                }

                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}
export default LentaTable;