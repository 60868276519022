import {createSlice} from "@reduxjs/toolkit";

export const navNotifications = createSlice({
    name: 'nav_notify',
    initialState: {
        notificationClasses: [],
        notificationPrivateClasses:[]
    },
    reducers: {
        setNavNotification(state, action) {
            // console.log('there navNotifications', action.payload)
            return {
                notificationClasses: action.payload.classes,
                notificationPrivateClasses: action.payload.classes_private
            }
        },

        readAllNavNotification(state, action) {
            state.notificationClasses = []
            state.notificationPrivateClasses=[]

            return state
        }
    }
})

export const {setNavNotification, readAllNavNotification} = navNotifications.actions

export default navNotifications.reducer