import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import apiConfig from '../api/apiConfig';
import {useBeforeUnload} from './useBeforeUnload';
import CustomWebSocket from "../components/CustomWebSocket/WebSocket";

const WebSocketComponent = ({handleMessageEvent, socketUrl}) => {
    const userData = useSelector((state) => state.user_data.userData);

    const [socket, setSocket] = useState(null)

    useEffect(() => {
        console.log('create')
        const newSocket = new CustomWebSocket(`${apiConfig.webSoketChat}notification/?token=${userData.token}`, handleMessageEvent)
        newSocket.reconnect()
        setSocket(newSocket)


        return () => {
        console.log('die')
        }
    }, []);

    useEffect(() => {
        return () => {
            if (socket)
                socket.destroy();
        }
    }, [socket]);
    // const socket = new CustomWebSocket(`${apiConfig.webSoketChat}notification/?token=${userData.token}`, handleMessageEvent)
    // socket.reconnect()
    // const socket = useMemo(() => {
    //     return new CustomWebSocket(socketUrl ? socketUrl : `${apiConfig.webSoketChat}notification/?token=${userData.token}`);
    // }, [socketUrl, userData.token]);
    //
    // useEffect(() => {
    //
    //     const handleOpen = (event) => {
    //         console.warn('CustomWebSocket открыт');
    //     };
    //
    //     const handleClose = (event) => {
    //         console.warn('CustomWebSocket закрыт');
    //     };
    //
    //     const handleError = (event) => {
    //         console.error('CustomWebSocket ошибка', event);
    //
    //     };
    //
    //     const handleMessage = (event) => {
    //         try {
    //             handleMessageEvent(JSON.parse(event.data))
    //         } catch (error) {
    //             console.error('handleMessageEvent error ', error);
    //             console.log('handleMessageEvent ', event);
    //         }
    //     };
    //
    //     socket.addEventListener('open', handleOpen);
    //     socket.addEventListener('close', handleClose);
    //     socket.addEventListener('error', handleError);
    //     socket.addEventListener('message', handleMessage);
    //
    //     setInterval(() => {
    //         socket.send(JSON.stringify({type: 'ping'}))
    //     }, 1000 * 1 * 60);
    //
    //     return (() => {
    //         socket.removeEventListener('open', handleOpen);
    //         socket.removeEventListener('close', handleClose);
    //         socket.removeEventListener('error', handleError);
    //         socket.removeEventListener('message', handleMessage);
    //         socket.close()
    //     })
    //
    // }, []);
    //
    // useBeforeUnload(() => {
    //     socket.close()
    // })
    //

    return null
};

export default WebSocketComponent;
