import { API_AGREEMENT_MODEL } from "../const";
import apiConfig from "../../apiConfig";
import axiosClient from '../../axiosClient';

export const agreementPut = async (id, data) => {
    let methodUrl = [API_AGREEMENT_MODEL.url + '/' + API_AGREEMENT_MODEL.methods.agreementPut.url].join('/');

    try {
        const resp = await axiosClient.post(apiConfig.baseUrl + methodUrl + '/', data);

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
