import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications, getDomain } from "../../functions/functions";
import LinkDownload from "../../components/LinkDownload";
import SortingCell from "../../components/SortingCell";

function RegisterTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _register">
                <thead>
                    <tr>
                        {/* ordering_fields = ['doc_name', 'date_of_conclusion', 'date_of_execution',
                 'doc_num', 'price', 'doc_law', 'organisation', 'contragent', 'status'] */}
                        <SortingCell classes="" title={'Наименование договора'} name={'doc_name'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата заключения'} name={'date_of_conclusion'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата исполнения'} name={'date_of_execution'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Номер'} name={'doc_num'} page_name={props.name} />
                        <SortingCell classes="_right" title={'Цена'} name={'price'} page_name={props.name} />
                        <SortingCell classes="_center" title={'ФЗ'} name={'doc_law'} page_name={props.name} />
                        <th className="_center">Скан документа</th>
                        <SortingCell classes="_center" title={'Фонд/БИК'} name={'organisation'} page_name={props.name} />
                        <SortingCell classes="" title={'Контрагент'} name={'contragent'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Статус'} name={'status'} page_name={props.name} />
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td><Link to={`detail/${item.id}`} className="table__link">{item.doc_name}</Link></td>
                                <td className="_center">{item.date_of_conclusion}</td>
                                <td className="_center">{item.date_of_execution}</td>
                                <td className="_center">{item.doc_num}</td>
                                <td className="_right">{item.price}</td>
                                <td className="_center">{item.doc_law}</td>
                                <td className="_center">{item.file ?
                                    <LinkDownload url={getDomain() + item.file} /> :
                                    <></>}</td>
                                <td className="_center">{item.organisation ? item.organisation.name : ''}</td>
                                <td>{item.contragent ? item.contragent.org_name ? item.contragent.org_name : item.contragent.fio : ''}</td>
                                {item.status ?
                                    <td className="_center _document-status"
                                        style={item.status.color_text
                                            ? { backgroundColor: item.status.color_text, color: 'white' }
                                            : { color: '#919399' }}>
                                        {item.status.status}
                                    </td>
                                    :
                                    <td></td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}

export default RegisterTable;