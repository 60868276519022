import { API_LOGOUT_MODEL } from "./const";
import apiConfig from "../apiConfig";
import axiosClient from '../axiosClient';

export const revokeRefresh = async (body) => {
    let methodUrl = [API_LOGOUT_MODEL.url + '/'].join('/');

    try {

        const resp = await axiosClient.post(apiConfig.baseUrl + methodUrl, { refresh: body });

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
