export const API_AGREEMENT_MODEL = {
    entity: 'agreement',
    url: 'agreement',
    methods: {
        agreementGet: {
            url: 'agreement'
        },
        agreementPut: {
            url: 'add_info'
        },
        agreementUpdate: {
            url: 'update'
        },
        agreementUpload: {
            url: 'upload'
        },
        agreementDecline: {
            url: 'decline'
        },
        agreementGetReport: {
            url: 'get_report'
        },
        agreementTransfer: {
            url: 'transfer'
        },
        getContragent: {
            url: 'contragent'
        },
    },
}