import React, {useEffect, useState} from "react";
import apiConfig from "../../api/apiConfig";
import {getItems} from "../../functions/functions";

import '../../styles/BirthdayCards.scss'
import CardsLenta from "./CardsLenta";
import {redirect, useNavigate} from "react-router-dom";

function CardsLentaWrapper(props) {
    const REDIRECT_TO_UPCOMING_DATES_URL = '/upcoming_dates'
    const navigate = useNavigate()

    const [birthdayUsers, setBirthdayUsers] = useState([])
    const [corpDateUsers, setCorpDateUsers] = useState([])

    const fetchBirthdayUsers = () => {
        const date = new Date()
        let stringDate = date.toLocaleDateString()
        const url = apiConfig.baseUrl + 'user/?birth_date=' + stringDate
        getItems(url, "GET", (data) => (setBirthdayUsers(data.results)))
    }

    const checkCorpDate = (corp_date) => {
        const corpDateYear = Number(corp_date.match(/.*\.(\d{4})/)[1]);
        const curDate = new Date()
        const yearInWork = curDate.getFullYear() - corpDateYear

        return yearInWork > 0
    }

    const fetchCorpDateUsers = () => {
        const date = new Date()
        let stringDate = date.toLocaleDateString()
        const url = apiConfig.baseUrl + 'user/?corp_date=' + stringDate
        getItems(url, "GET", (data) => setCorpDateUsers(data.results.filter(item => checkCorpDate(item.corp_date))))

    }

    useEffect(() => {
        fetchBirthdayUsers()
        fetchCorpDateUsers()
    }, []);

    return birthdayUsers.length > 0 || corpDateUsers.length > 0 ?
        <div className={'wrapper-birthday-cards'}>
            <hr/>
            <CardsLenta birthdayUsers={birthdayUsers} corpDateUsers={corpDateUsers} onClick={(e) => {
                e.preventDefault()
                navigate(REDIRECT_TO_UPCOMING_DATES_URL)
            }}/>
        </div> :
        <></>

}

export default CardsLentaWrapper