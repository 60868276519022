import React, { useEffect, useState } from 'react'
import { routes } from '../../pages';
import { getDomain, getItems } from '../../functions/functions';


//=================================================================================================================================================

const IncomingNumbersSearch = (props) => {

    const [value, setValue] = useState(),
        [displayNames, setDisplayNames] = useState(),
        // [displayID, setDisplayID] = useState(),
        [showOptions, setShowOptions] = useState(false),
        [options, setOptions] = useState([]),
        [detail, setDetail] = useState(props.detail)


    const incoming_url = routes.filter(r => r.name === "incoming")

    const setError = (message) => {
        console.log('====================================');
        console.log('Error', message);
        console.log('====================================');
    }

    const emptySearch = () => {
        setAddButton(true)
        return 'Нет результатов'
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler(e)
        }
    }


    useEffect(() => {
        if (props.field.value) {
            const names_arr = []
            const id_arr = []
            props.field.value.map((item, idx) => {
                const url = `${getDomain()}${incoming_url[0]?.url}${item}`
                getItems(url, 'GET', (data) => {
                   
                    names_arr.push(data.incoming_number ? data.incoming_number : 'номер входящего')
                    setDisplayNames([...names_arr])

                    // id_arr.push(data.id)
                    // setDisplayID([...id_arr])
                }, false, setError)
                setValue(props.field.value)
            })
        }
    }, [])

    const searchHandler = (e) => {
        if (e.target.value.length >= 2) {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
        e.preventDefault()
        //   setDisplayNames(e.target.value)
        const url = `${getDomain()}${incoming_url[0]?.url}?search=${e.target.value}`
        getItems(url, 'GET', setData, false, setError)
    }

    const setData = (data) => {
       
        const list = []
        if (data.count > 0) {
            for (let incom of data.results) {
                const option = { value: incom.id, display_name: incom.incoming_number ? incom.incoming_number : 'Ошибка', organisation: incom.organisation.name ? incom.organisation.name : '' }
                list.push(option)
            }
        } else {
            emptySearch()
        }
        setOptions(list)

   
    }


    const selectIncomingNumber = (val, name) => {
        setShowOptions(false)

        const names_arr = displayNames ? displayNames : []
        names_arr.push(name)
        setDisplayNames([...names_arr])

        const values_arr = value ? value : []
        values_arr.push(val)
        setValue([...values_arr])
    }
    const deleteNumber = (index) => {
        const names_arr = displayNames ? displayNames : []
        names_arr.splice(+index, 1)
        setDisplayNames([...names_arr])

        const values_arr = value ? value : []
        values_arr.splice(+index, 1)
        setValue([...values_arr])

    }

    return (
        <>
            <div className={"form-added-item " + props.classes}>
                <label className='form-added-item__title' htmlFor={props.field.name}>{props.field.label}{props.field.required ? <span>*</span> : ''}</label>

                {displayNames?.map((item, idx) => (
                    <div className='incoming-selected-items__wrap' key={idx}>
                        <span className='incoming-selected-items'>{item}</span>
                        {!detail &&
                            <span className='delete-select' onClick={() => deleteNumber(idx)}></span>
                        }
                    </div>
                ))}
                {!detail &&
                    <div className="search-contragent-wrap">
                        <input
                            onChange={e => searchHandler(e)}
                            onKeyDown={onEnter}
                            //value={displayNames}
                            type="text"
                            className='contragent_search'
                            placeholder='Введите слово для поиска…' />
                        {value?.map((val, idx) => (
                            <input key={idx} type="hidden" value={val} name='incoming_numbers' />
                        ))}

                    </div>
                }

                <span className="error-message">{props.error ? props.error : ''}</span>
                <div className={`contragent__fake-select ${!showOptions ? 'd-none' : ''}`}>
                    {options.length > 0 ?
                        options.map(op => (
                            <span key={op.value} className={value?.includes(op.value) ? 'selected' : ''} onClick={() => {!value?.includes(op.value) && selectIncomingNumber(op.value, op.display_name)}}>{`${op.organisation}: ${op.display_name}`}</span>
                        ))
                        :
                        <span className="no-resalts">Нет результатов</span>
                    }
                </div>



            </div>
        </>
    )
}

export default IncomingNumbersSearch