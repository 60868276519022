import { createSlice } from '@reduxjs/toolkit'

const navState = {
    nav_active: JSON.parse(localStorage.getItem("nav_active")) || false,
}

export const navStateSlice = createSlice({
    name: 'nav_state',
    initialState: {
        navState
    },
    reducers: {
        setNavStateItem(state, action) {
            state.navState[action.payload[0]] = action.payload[1]
            localStorage.setItem(action.payload[0], JSON.stringify(action.payload[1]))
        }
    }
})

export const { setNavStateItem } = navStateSlice.actions
export default navStateSlice.reducer