import { createSlice } from '@reduxjs/toolkit'

const QRData = sessionStorage.getItem('qr') ? JSON.parse(sessionStorage.getItem('qr')) : {}

export const QRSlice = createSlice({
    name: 'qr_data',
    initialState: {
        QRData
    },
    reducers: {
        setQR(state, action) {
            state.QRData = action.payload
            sessionStorage.setItem('qr', JSON.stringify(action.payload))
        }
    }
})

export const { setQR } = QRSlice.actions
export default QRSlice.reducer