import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { getItems } from '../../functions/functions';
import getHumanizedDateTime from '../../functions/getHumanizedDateTime';

const AhoHistoryTable = (props) => {
    const [list, setList] = useState([])

    useEffect(() => {
        getItems(`${props.url}${props.id}/history/`, 'GET', setData)
    }, [])

    const setData = (data) => {
        setList(data.history)
    }
    // console.log('list', list);

    return (
        <>
            <div className='inventory_table-title'>История изменений</div>
            <table className="history-table">
                <thead>
                    <tr>
                        <th className="">Инвентарный номер</th>
                        <th className="_center">Дата изменения</th>
                        <th className="_right">Кем изменен</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, idx) => {
                        return (
                            <tr key={idx} className=''>
                                {item.inventory_number ?
                                    <td><Link to={`history/${item.history_id}`} className="table__link">{item.inventory_number}</Link></td> : <td></td>
                                }
                                {item.history_date ?
                                    <td className="_center">{getHumanizedDateTime(item.history_date)}</td> : <td></td>
                                }
                                {item.history_user ?
                                    <td className="_right">{item.history_user}</td> : <td></td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>

    )
}

export default AhoHistoryTable