import React from "react";
import { InfoItem } from "../../components/InfoItem";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";

function CounterpartyDetail(props) {
    console.log(props.fields);
    if (props.fields.status.value === "Физическое лицо") {
        return (
            <>
                <div className={"page__info-container"}>
                    <InfoItem item={props.fields.bik_card_number} classes="_full" />
                    <InfoItem item={props.fields.fio} classes="_full" />
                    <InfoItem item={props.fields.birth_date} />
                    <InfoItem item={props.fields.place_of_birth} />
                    <InfoItem item={props.fields.inn} />
                    <InfoItem item={props.fields.fakt_address} classes="_full" />
                    <InfoItem item={props.fields.phone} classes="_full" />
                    <InfoItem item={props.fields.email} classes="_full" />
                    <InfoItem item={props.fields.passport} classes="_full" />
                    <InfoItem item={props.fields.www_password} classes="_full" />
                    <InfoItem item={props.fields.work_place} classes="_full" />
                    <InfoItem item={props.fields.family} classes="_full" />
                    <InfoItem item={props.fields.spouse_fio} classes="_full" />
                    {/*<p className="_full form__sub-title">Бонусная программа</p>*/}
                    <InfoItem item={props.fields.client} classes="_full" />
                    <InfoItem item={props.fields.user} classes="_full" />
                </div>
                <HistoryComponent id={props.id} name={'main/contragent'} />

            </>

        )
    }
    else {
        return (
            <>
                <div className={"page__info-container"}>
                    <InfoItem item={props.fields.org_form} />
                    <InfoItem item={props.fields.inn} />
                    <InfoItem item={props.fields.ogrn} />
                    <InfoItem item={props.fields.kpp} />
                    <InfoItem item={props.fields.org_name} classes="_full" />
                    <InfoItem item={props.fields.fakt_address} classes="_full" />
                    <InfoItem item={props.fields.ur_address} classes="_full" />
                    <InfoItem item={props.fields.fio} classes="_full" />
                    <InfoItem item={props.fields.phone} classes="_full" />
                    <InfoItem item={props.fields.email} classes="_full" />
                    <InfoItem item={props.fields.dir_fio} classes="_full" />
                    <InfoItem item={props.fields.dir_phone} classes="_full" />
                    <InfoItem item={props.fields.payment_account} classes="_full" />
                    <InfoItem item={props.fields.bik} classes="_full" />
                    <InfoItem item={props.fields.bank} classes="_full" />
                    <InfoItem item={props.fields.cor_account} classes="_full" />
                    <p className="_full form__sub-title m-">Бонусная программа</p>
                    <InfoItem item={props.fields.partner} classes="_full" />
                    <InfoItem item={props.fields.saleaction} classes="_full" />
                    <InfoItem item={props.fields.user} classes="_full" />
                </div>
                <HistoryComponent id={props.id} name={'main/contragent'} />

            </>

        )

    }


}

export default CounterpartyDetail