import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'activeChat',
    initialState: null, // Начальное состояние - загрузка не активна
    reducers: {
        setActiveChat: (state, action) => action.payload,
    },
});

export const { setActiveChat } = chatSlice.actions;
export default chatSlice.reducer;
