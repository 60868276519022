import React, { useState } from 'react';
import userPlus from "/public/page_icons/users-plus.svg";

const Search = ({ onSearch, openModal, hiddeAddGroupButton = false }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    onSearch(searchTerm);
  };

  return (
    <div className='chat-list__search'>
      <input
        type="text"
        className="search__input"
        placeholder='Поиск по имени…'
        value={searchTerm}
        onChange={handleSearch}
      />

      {!hiddeAddGroupButton && openModal &&
        <button className='buttonAddGroup' onClick={()=>{openModal()}}>
          <img src={userPlus}></img>
        </button>
      }
    
    </div>
  );
}

export default Search;
