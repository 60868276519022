import React, { useState, useReducer } from "react"
import FormItem from "../../components/form/FormItem";
import AddedFormItem from "../../components/form/AddedFormItem"
import SearchContragent from "../../components/form/SearchContragent"
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import getFormattedDate from "../../functions/getFormattedDate";

function IncomingFields(props) {
    const responsible_value = props.is_my ? localStorage.getItem('user_id') : ''

    return (
        <>
            <FormItem field={props.fields.organisation} required={true} classes="_full" read_only={true}/>
            <FormItem field={props.fields.incoming_number} classes="_grid-start-1" read_only={true}/>
            <FormItem field={props.fields.register_date} classes="" read_only={true} value={props.fields.register_date.value ? props.fields.register_date.value : getFormattedDate(Date.now())}/>
            <FormItem field={props.fields.date_out} required={true} classes="_grid-start-1"/>
            <FormItem field={props.fields.out_org_number} classes=""/>
            <FormItem field={props.fields.doc_name} type={"textarea"} required={true} classes="_full"/>
            <SearchContragent field={props.fields.contragent} classes="_full" />
            <FormItem field={props.fields.who_unsubscribed} required={true} classes="_full"/>
            <FormItem field={props.fields.file} classes="_full"/>
            <DocumentAddFormItem field={props.fields.other_doc} classes="_full"/>
            <FormItem field={props.fields.document_type} classes="_full"/>
            <FormItem field={props.fields.source} required={true} classes="_full"/>
            <FormItem field={props.fields.description} classes="_full"/>
            <FormItem field={props.fields.service_comment} classes="_full"/>
            <FormItem field={props.fields.resolution} classes="_full"/>
            <FormItem field={props.fields.do_not_executed} classes="_full"/>
            <FormItem field={props.fields.execution_period} classes="_grid-start-1"/>
            {/*<FormItem field={props.fields.executor} required={true} classes="_full"/>*/}
            <FormItem field={props.fields.responsible} required={true} classes="_full" read_only={!!props.is_my} value={responsible_value ? responsible_value : false} />
            <AddedFormItem field={props.fields.soresponsibles} classes="_full" button={'Добавить соисполнителя'} />
            <AddedFormItem field={props.fields.observers} classes="_full" button={'Добавить наблюдателя'} />
            <FormItem field={props.fields.registrar} required={true} classes="d-none _full" read_only={true} value={localStorage.getItem('user_id')} />
        </>
    )
}

export default IncomingFields