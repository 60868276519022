import React from "react";
import FormItem from "../../components/form/FormItem";
import TextEditor from "../../components/form/TextEditor/TextEditor";
import getFormattedDate from "../../functions/getFormattedDate";


function AhoFields({fields}) {
    const formatted_date = getFormattedDate()


    return (
        <>
            <FormItem field={fields.organisation} classes="_full"/>
            {/*<FormItem field={fields.receiver} required={true}  classes="_full"/>*/}
            <FormItem field={fields.author} required={true} classes="_full" read_only={true} value={localStorage.getItem('user_id')} />
            <FormItem field={fields.date_of_creation}  classes="_full" read_only={true} value={fields.date_of_creation.value ? fields.date_of_creation.value : formatted_date}/>
            <FormItem field={fields.name} required={true}  classes="_full"/>

            <TextEditor field={fields.description} classes="_full"/>

            <FormItem field={fields.file} classes="_full"/>
            <input type="hidden" name='support_status' value={fields.support_status.value ? fields.support_status.value : 4}/>
        </>
    )
}
export default AhoFields;