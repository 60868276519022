import {createSlice} from "@reduxjs/toolkit";

export const overduePopupSlice = createSlice({
    name: 'overdue_popup',
    initialState: {
        isActive: true,
        timer: null,
        isRedirect: true
    },
    reducers: {
        setOverduePopup(state, action) {
            return {
                isActive: action.payload.isActive,
                timer: action.payload.timer,
                isRedirect: state.isRedirect,
            }
        },
        setIsActive(state, action) {
            return {
                isActive: action.payload.isActive,
                timer: state.timer,
                isRedirect: state.isRedirect,
            }
        },
        setTimer(state, action) {
            return {
                isActive: state.isActive,
                timer: action.payload.timer,
                isRedirect: state.isRedirect,
            }
        },
        setIsRedirect(state, action) {
            return {
                isActive: state.isActive,
                timer: state.timer,
                isRedirect: action.payload.isRedirect,
            }
        }
    }
})

export const {
    setIsActive,
    setIsRedirect
} = overduePopupSlice.actions

export default overduePopupSlice.reducer