import React, { useEffect, useState } from "react";

const DashboardCard = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    // console.log('================', props.options?.label, '================');
    // console.log(props);
    // console.log(props.img);

    setData(props.options);
  }, [props.options]);

  const isDouble = props;
  return (
    <div className="dashboard-card">
      {data && (
        <>
          <div className="d-flex align-items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="dashboard-icon"
            >
              <use href={`${props.img}#item`} />
            </svg>
            <p className="title">{data?.label}</p>
          </div>

          {data.total && (
            <div className="d-flex justify-space-between mt-8">
              <p className="total-title">{data.total?.label}</p>
              <p className="total-count">
                {data.total?.value && data.total.value}
              </p>
            </div>
          )}
          {data.total_doc && (
            <div className="d-flex justify-space-between mt-8">
              <p className="total-title">{data.total_doc?.label}</p>
              <p className="total-count">
                {data.total_doc?.value && data.total_doc.value}
              </p>
            </div>
          )}

          {data.microdistrict && (
            <div className="d-flex justify-space-between mt-8">
              <p className="total-title">{data.microdistrict?.total?.label}</p>
              <p className="total-count">
                {data.microdistrict?.total?.value &&
                  data.microdistrict.total.value}
              </p>
            </div>
          )}
          {data.landplot && (
            <div className="column">
              {Object.keys(data.landplot).map((key, idx) => {
                if (key.includes("total")) {
                  return (
                    <div
                      className={`${
                        data.landplot[key].is_bold ? "is_bold mt-8" : ""
                      }`}
                      key={idx}
                    >
                      <p
                        className={`column-title ${
                          data.landplot[key].is_red ? "is_red" : ""
                        }`}
                      >
                        {data.landplot[key].label}
                      </p>
                      <p
                        className={`column-result ${
                          data.landplot[key].is_red ? "is_red" : ""
                        }`}
                      >
                        {data.landplot[key].value}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
          )}
          {data.bik && data.fond ? (
            <div className="double-column">
              <div className="column">
                {Object.keys(data.bik).map((key, idx) => {
                  if (key.includes("total")) {
                    return (
                      <div
                        className={`${data.bik[key].is_bold ? "is_bold" : ""}`}
                        key={idx}
                      >
                        <p
                          className={`column-title ${
                            data.fond[key].is_red ? "is_red" : ""
                          }`}
                        >
                          {data.bik[key].label}
                        </p>
                        <p
                          className={`column-result ${
                            data.fond[key].is_red ? "is_red" : ""
                          }`}
                        >
                          {data.bik[key].value}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
              <span className="separate-line"></span>
              <div className="column">
                {Object.keys(data.fond).map((key, idx) => {
                  if (key.includes("total")) {
                    return (
                      <div
                        className={`${data.fond[key].is_bold ? "is_bold" : ""}`}
                        key={idx}
                      >
                        <p
                          className={`column-title ${
                            data.fond[key].is_red ? "is_red" : ""
                          }`}
                        >
                          {data.fond[key].label}
                        </p>
                        <p
                          className={`column-result ${
                            data.fond[key].is_red ? "is_red" : ""
                          }`}
                        >
                          {data.fond[key].value}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <div className="column">
              {Object.keys(data).map((key, idx) => {
                if (key.includes("total_")) {
                  return (
                    <div
                      className={`${data[key].is_bold ? "is_bold mt-8" : ""}`}
                      key={idx}
                    >
                      <p
                        className={`column-title ${
                          data[key].is_red ? "is_red" : ""
                        }`}
                      >
                        {data[key].label}
                      </p>
                      <p
                        className={`column-result ${
                          data[key].is_red ? "is_red" : ""
                        }`}
                      >
                        {data[key].value}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
          )}
          {data.average_days && (
            <div className="d-flex justify-space-between mt-8">
              <p className="total-title is_bold">{data.average_days?.label}</p>
              <p className="total-count is_bold">
                ~{data.average_days?.value && data.average_days.value}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardCard;
