import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";

function IncomingFilter(props) {
    const fields = ['doc_name', 'contragent', 'organisation', 'incoming_number',
        'register_date', 'out_org_number', 'document_type', 'source', 'who_unsubscribed',
        'responsible', 'description', 'out_doc', 'output'] // 'register_date_in', 'register_date_out'
        , [items, setItems] = useState([])
        
    useEffect(() => {
        console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        const out_doc = {
            children: [
                { value: "1", label: 'Исполнено' },
                { value: "2", label: 'Просрочено' },
                { value: "3", label: 'Текущие' },
            ],
            label: "По выполнению",
            name: "out_doc",
            placeholder: "",
            read_only: false,
            required: true,
            type: "select",
            value: ""
        }
        const output = {
            label: "Исходящий документ",
            name: "output",
            placeholder: "",
            read_only: false,
            required: false,
            type: "text",
            value: ""
        }
        const register_date_in = {
            label: "Дата регистрации с",
            name: "register_date_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: getValue('register_date_in')
        }
        const register_date_out = {
            label: "Дата регистрации по",
            name: "register_date_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: getValue('register_date_out')
        }
        new_fields.out_doc = out_doc
        new_fields.output = output
        new_fields.register_date_in = register_date_in
        new_fields.register_date_out = register_date_out
        setItems(new_fields)
    }

    const getValue = (key) => {
        const value = props.values.find(item => item[key] !== undefined)
        if (value !== undefined)
            return value[key]
        return ''
    }

    return (
        <>
            {Object.keys(items).length > 0 ? fields.map((key) => {
                const value = getValue(key)
                const new_field = items[key]
                //console.log(items['output_doc'])
                //console.log(items['document_type'])
                // if (new_field.name === 'source')
                //     new_field.type = 'checkbox'
                if (items[key].name === 'contragent') {
                    return <SearchContragent key={key} field={new_field} required={false} value={value} canAdd={false} />
                } else {
                    return <FormItem key={key} field={new_field} required={false} value={value} />

                }
                // return <FormItem key={key} field={new_field} required={false} value={value}  />
            }) : <>загрузка</>}
            {items.register_date_in ? <FormItem field={items.register_date_in} required={false} /> : ''}
            {items.register_date_out ? <FormItem field={items.register_date_out} required={false} /> : ''}
        </>
    )
}

export default IncomingFilter