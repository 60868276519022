import React, { useState } from 'react'
import FormItem from "../../components/form/FormItem";
import FileItem from '../../components/form/FileItem';
import { getDomain } from '../../functions/functions';
import Alert from '../../components/Allert';

const AhoInventoryCreate = ({ fields }) => {

    const [alert, setAlert] = useState({
        title: '',
        text: '',
        type: '', // error, alert, info, success
    })

    const generateQR = (id) => {
        fetch(`${getDomain()}/api/invent/${id}/gen_qr_code/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },

            credentials: "same-origin",
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка. Неверно заполнены поля')
                }
                return response.json()
            })
            .then(data => {
                console.log('data', data);
                setAlert({
                    title: 'QR-код перегенерирован',
                    type: 'success'
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });

            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                    setAlert({
                        title: 'Доступ запрещен',
                        type: 'error'
                    })
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                    setAlert({
                        title: error.message,
                        type: 'error'
                    })
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
    }


    console.log('fields', fields);


    return (
        <>
            <Alert setAlert={setAlert} alert={alert} />

            <FormItem field={fields.title} required={true} classes="_full" />
            <FormItem field={fields.inventory_number} classes="" />
            <FormItem field={fields.nomenclature_code} classes="" />
            <FormItem field={fields.serial_number} classes="_full" />
            <FormItem field={fields.organisation} classes="_full" />

            <FormItem field={fields.status} classes="_full" />
            <FormItem field={fields.responsible} classes="_full" />
            <FormItem field={fields.real_user} classes="_full" />

            <FormItem field={fields.description} classes="_full" />

            <FileItem field={fields.image} classes="_full" />

            {/* <a className='btn' onClick={() => generateQR(fields.id.value)}>Сгенерировать QR-код</a> */}

        </>
    )
}

export default AhoInventoryCreate