import React, {useEffect, useRef, useState} from 'react';
import reply from '/public/page_icons/reply.svg';
import copy from '/public/page_icons/Copy.svg';
import sendMessage from '/public/page_icons/sendMessage.svg';
import deleteMessage from '/public/page_icons/delete-message.svg';

const MessageContextMenu = ({top, left, onClose, onAction, isOwnerMessage}) => {

    const messageContextMenuRef = useRef(null)
    const contextMenuData = [
        {
            id: 1,
            text: 'Ответить',
            icon: reply,
            type: 'reply',
            isOwnerAction: false,
        },
        {
            id: 2,
            text: 'Копировать',
            icon: copy,
            type: 'copy',
            isOwnerAction: false,
        },
        {
            id: 3,
            text: 'Переслать',
            icon: sendMessage,
            type: 'sendMessage',
            isOwnerAction: false,
        },
        {
            id: 4,
            text: 'Удалить',
            icon: deleteMessage,
            type: 'deleteMessage',
            isOwnerAction: true,
        },
        // {
        //     id: 4,
        //     text: 'Создать задачу (в разработке)',
        //     icon: createTask,
        //     type: 'createTask',
        // },
    ];

    const [menuPosition, setMenuPosition] = useState({top: top, left: left});
    const [isCal, setIsCal] = useState(false)
    const [isShiftLeft, setIsShiftLeft] = useState(false)
    const [isShiftUp, setIsShiftUp] = useState(false)
    const calculatePosition = () => {
        const menuWidth = 400;
        const menuHeight = 400;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;


        if (windowWidth - left < menuWidth) {
            setIsShiftLeft(true)
        }

        if (windowHeight - top < menuHeight) {
            setIsShiftUp(true)
        }
        setIsCal(true)
    };

    useEffect(() => {
        calculatePosition();
    }, [top, left]);

    const handleContainerClick = (e) => {
        // Проверка, чтобы не срабатывал onClose при клике внутри FilePicker
        if (e.target.classList.contains('contextMenuContainer')) {
            handleClickOption();
        }
    };

    const handleClickOption = (type) => {
        onAction(type);
        onClose();
    };

    useEffect(() => {
        // Добавление обработчика события для закрытия меню при клике вне его
        const handleDocumentClick = (e) => {
            if (!e.target.closest('.contextMenuContainer')) {
                onClose();
            }
        };

        // Добавление обработчика события к документу
        document.addEventListener('click', handleDocumentClick);

        // Очистка обработчика события при размонтировании компонента
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [onClose]);
    console.log('isOwnerMessage', isOwnerMessage)
    return (
        <div
            style={{
                display: isCal ? 'block' : 'none',
                position: 'absolute',
                top: `${menuPosition.top}px`,
                left: `${menuPosition.left}px`,
                zIndex: 9999,
            }}
            className={`contextMenuContainer ${isShiftLeft ? 'shift-left' : ''} ${isShiftUp ? 'shift-up' : ''}`} // Добавлен класс стиля
            onClick={handleContainerClick}
            ref={messageContextMenuRef}
        >
            {contextMenuData.map((item) => {
                return (!item.isOwnerAction || isOwnerMessage) && (
                    <div onClick={() => handleClickOption(item.type)} className='optionContextMenu' key={item.id}>
                        <img src={item.icon} alt={item.text}/>
                        {item.text}
                    </div>
                )
            })
            }
        </div>
    );
};

export default MessageContextMenu;
