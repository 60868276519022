import React from 'react';
import FilePicker from '../FilePicker/FilePicker';

const ModalFilePicker = ({ onClose, dataFile, addFile }) => {
  const handleContainerClick = (e) => {
    // Проверка, чтобы не срабатывал onClose при клике внутри FilePicker
    if (e.target.classList.contains('modalGroupContainer')) {
      onClose();
    }
  };

  return (
    <div className='modalGroupContainer' onClick={handleContainerClick}>
      <div className='modalContainerChat'>
        <FilePicker setFiles={(e) => addFile(e)} onClose={() => onClose()} />
      </div>
    </div>
  );
};

export default ModalFilePicker;
