import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../../styles/Chat.scss';
import { oldUploadImage, uploadImage } from '../../../api/Incoming/uploadImage';

const FilePicker = ({ dataFile, setFiles, type, isUpload, oldUpload, onClose }) => {
    // const landPlot = new LandPlotApiRequest();
    // const incomingApi = new CommmentApiRequest();
    const onDrop = useCallback((acceptedFiles) => {
        // Обработка выбранных файлов

        if (acceptedFiles.length > 0) {
            const formData = new FormData();

            acceptedFiles.forEach((file) => {
                formData.append('file', file);
            });

            !isUpload ?
                !oldUpload?
                    uploadImage(formData).then((resp) => {
                        if (resp.success) {
                            if (dataFile) {
                                const mergedFiles = dataFile.concat(resp.data);
                                setFiles(mergedFiles);
                                onClose()
                            } else {
                                setFiles(resp.data);
                                onClose()
                            }
                        }
                    })
                    :
                    // oldUploadImage(formData).then((resp) => {
                    //     if (resp.success) {
                            
                    //         setFiles(resp.data);
                    //         onClose()
                    //     }
                    // })
                    console.log('')
                :
                setFiles(acceptedFiles);
        }

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: (() => {
            switch (type) {
                case 'image':
                    return { 'image/png': ['.jpg', '.png'] };
                case 'document':
                    return { 'text/html': ['.txt', '.docs', '.pdf', '.xlsx'] };
                case 'shape':
                    return { 'text/html': ['.shp', '.dbf', '.kml', '.zip'] };
                default:
                    // Можете установить здесь значение по умолчанию, если нужно
                    return '*/*';
            }
        })(),
    });


    return (
        <div className={`file-picker-modal ${isDragActive ? 'active' : ''}`}>
            <div {...getRootProps()} className={`file-picker-dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />

                <div className='textUploadContainer'>
                    {/* <img src={icons.fileDownload}></img> */}
                    <p className='textUpload'>
                        Перетащите файлы в эту область
                        <b>или нажмите, чтобы загрузить</b>
                        <span>
                            {
                                type === 'shape' ?
                                    "(Файлы одного из форматов: .kml)"
                                    :
                                    " (до 10 файлов, размером не более 5 МБ)"
                            }
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FilePicker