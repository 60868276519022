import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from '../store/filtersSlice'
import down from '/public/interface_img/arrow_down.svg'
import up from '/public/interface_img/arrow_up.svg'

const SortingCell = ({ title, name, page_name, classes = '' }) => {
    const sortData = useSelector(state => state.filters_data.sortData)
    const dispatch = useDispatch()
    const sorting = (name, value) => dispatch(sortBy([name, value]))

    return (
        <th className={`cursor-pointer filter-cell ${classes} ${sortData[page_name] === name || sortData[page_name] === '-' + name ? '_active' : ''}`} onClick={() => sorting(page_name, name)}>
            <span>{title} {sortData[page_name] ?
                    sortData[page_name] === name ?
                        <img src={down} /> :
                        sortData[page_name] === '-' + name ?
                            <img src={up} /> : '' : ''}
            </span>
        </th>
    )
}

export default SortingCell