import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"


import { FileItem, InfoItem } from "../../components/InfoItem";
import { createOrUpdate, onEmailInput } from "../../functions/functions";
import FormItem from "../../components/form/FormItem";
import TextareaItem from "../../components/form/TextareaItem";
import FormErrorsContext from "../../functions/formErrorsContext";
import { getDomain } from "../../functions/functions";
import AddedFormItem from "../../components/form/AddedFormItem";
import CheckComments from "./CheckComments";
import CommentComponent from "../../components/CommentCmponent/CommentComponent"
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";



function TaskDetail(props) {

    const  [agreementStatus, setAgreementStatus] = useState(props.fields.tasks_status.value)
        , [canSave, setCanSave] = useState(false)
        , [comment, setComment] = useState({})

        , form = useRef()
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , params = useParams()
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)

    useEffect(() => {
        const comment_input = {
            label: props.fields.check_comment.label,
            type: 'textarea',
            name: 'check_comment',
            value: ''
        }
        setComment(comment_input)
    }, [])

    const navigateToView = (id) => {
        const url = props.name === 'incoming' ? '/' : `/${props.name}/`
        //navigate(`/${props.name}/detail/${id}`, {
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект изменен',
                    status: 'success'
                }
            }
        })
    }

    const succeedSend = (id, status_id) => {
        fetch(`${getDomain()}/api/tasks/${id}/succeed/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${userData.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ "id": id, "status_id": status_id })

        })
            .then(response => {
                console.log('response', response)
                navigateToView()
            })
    }

    const setErrors = (data) => {
        data.then(res => {
            console.log('errors', res)
        })
    }
    function symbolsNumber(e) {
        if (e.target.value.length > 1) {
            setCanSave(true)
        }
        else {
            setCanSave(false)
        }
    }

    function sendAgreement(value) {
        setAgreementStatus(value)
    }

    const date = new Date();
    const formattedDate = date.toLocaleDateString(); // Формат даты: "04.07.2023"

    return (
        <>
            <div className={"page__info-container"}>
                <InfoItem item={props.fields.organisation} />
                <InfoItem item={props.fields.id} />
                <InfoItem item={props.fields.task_project} classes="_full" />
                <InfoItem item={props.fields.date_of_creation} />
                <InfoItem item={props.fields.dead_line} />
                <InfoItem item={props.fields.author} classes="_full" />
                <InfoItem item={props.fields.receiver} classes="_full" />
                <InfoItem item={props.fields.name} classes="_full" />
                <InfoItem item={props.fields.description} classes="_full" />
                {props.fields.file.value ?
                    <FileItem item={props.fields.file} classes="_full" />
                    :
                    <FileItem item={props.fields.other_doc} classes="_full" />
                }
                <InfoItem item={props.fields.comment_reason} classes="_full" />
                <CheckComments item={props.fields.check_comment} classes="_full" />
                {props.fields.date_of_succeed &&
                    <InfoItem item={props.fields.date_of_succeed} classes="_full" />
                }
            </div>

            <PersonalAccesses url={props.url} fields={props.fields} page_name={props.name}>
                <div className="d-none">
                    <FormItem field={props.fields.organisation} />
                    <FormItem field={props.fields.date_of_creation} />
                    <FormItem field={props.fields.dead_line} />
                    <FormItem field={props.fields.id} />
                    <FormItem field={props.fields.author} />
                    <FormItem field={props.fields.comment_reason} />
                    <FormItem field={props.fields.receiver} />
                    <FormItem field={props.fields.name} />
                    <FormItem field={props.fields.description} />
                    <FormItem field={props.fields.file} />
                    <DocumentAddFormItem field={props.fields.other_doc} classes="_full" />
                </div>


                {props.fields.tasks_status.value === 1 ?

                    <div className={"page__info-container"}>
                        <div className='service-records _status-agreed _full justify-content-center mt-24'>Выполнено</div>
                    </div>
                    : ""
                }

                {props.fields.tasks_status.value === 2 ?
                    <div className={"page__info-container"}>
                        <div className='service-records _status-disagreed _full justify-content-center mt-24'>Отклонено</div>
                    </div>
                    : ""
                }

                {props.fields.tasks_status.value === 6 ?
                    <div className={"page__info-container"}>
                        <div className='service-records _status-disagreed _full justify-content-center mt-24'>Задача отменена постановщиком</div>
                    </div>
                    : ""
                }

                {(props.fields.tasks_status.value === 5 && +props.fields.receiver.value === +userData.user_id) ?
                    <div className={"page__info-container"}>
                        <div className='service-records _on-review _full justify-content-center mt-24'>Задача на проверке</div>
                    </div>
                    : ""
                }




                {props.fields.tasks_status.value === 4 ?
                    <>
                        {+props.fields.receiver.value === +userData.user_id ?
                            <>
                                {agreementStatus === 4 ?
                                    <div className="page__info-container mt-16 records-buttons">
                                        <div className="agreed_button">
                                            <span htmlFor="agreed_button" onClick={() => sendAgreement(3)} className="service-records_button _agreed">Принять в работу</span>
                                        </div>
                                        <div className="disagreed_button">
                                            <span htmlFor="disagreed_button" onClick={() => sendAgreement(2)} className="service-records_button _disagreed">Отклонить</span>
                                        </div>
                                    </div>
                                    : ""
                                }
                                {agreementStatus === 2 ?
                                    <div className="page__info-container mt-16">
                                        <div className='_full d-flex justify-content-center'>
                                            <div className='service-records _status-disagreed'>Отклонено</div>
                                            <span onClick={() => sendAgreement(4)} className="service-records _cancel">Отменить</span>
                                        </div>
                                        <TextareaItem field={props.fields.comment_reason} required={true} fieldHandler={symbolsNumber} classes="_full" />
                                        <input type="hidden" name='date_of_succeed' value={formattedDate}></input>
                                    </div>
                                    : ""
                                }
                                {agreementStatus === 3 ?
                                    <div className="page__info-container mt-16">
                                        <div className='_full d-flex justify-content-center'>
                                            <div className='service-records _status-agreed'>Принята в работу</div>
                                            <span onClick={() => sendAgreement(4)} className="service-records _cancel">Отменить</span>
                                        </div>
                                    </div>
                                    : ""
                                }
                            </>
                            : ''
                        }
                    </>
                    : ''
                }
                {props.fields.tasks_status.value === 3 ?
                    <>
                        {+ props.fields.receiver.value === +userData.user_id ? 

                            <div className="page__info-container mt-16 records-buttons">
                                <div className="agreed_button _full">
                                    {agreementStatus === 5 ?
                                        <div className={"page__info-container"}>
                                            <div className='_full d-flex justify-content-center'>
                                                <div className='service-records _status-agreed _full justify-content-center'>Выполнено</div>
                                                <span onClick={() => sendAgreement(3)} className="service-records _cancel">Отменить</span>
                                            </div>
                                            <FormItem field={comment} classes="_full" required={true} />
                                        </div>
                                        :

                                        <span htmlFor="agreed_button" onClick={() => {
                                            sendAgreement(5);

                                        }} className="service-records_button _agreed m-0">Выполнить</span>


                                    }
                                </div>
                            </div>
                            : ''
                        }
                    </>
                    : ""
                }

                {props.fields.tasks_status.value === 5 ?
                    <>
                        {+props.fields.author.value === +userData.user_id ?
                            <>
                                {agreementStatus === 5 ?
                                    <div className="page__info-container mt-16 records-buttons">
                                        <div className="agreed_button">
                                            <span htmlFor="agreed_button" onClick={() => { sendAgreement(1); succeedSend(props.fields.id.value, 1); }} className="service-records_button _agreed ml-0">Принять</span>
                                        </div>
                                        <div className="disagreed_button">
                                            <span htmlFor="disagreed_button" onClick={() => sendAgreement(3)} className="service-records_button _disagreed ml-0">На доработку</span>
                                        </div>
                                    </div> : ''
                                }
                                {agreementStatus === 3 ?
                                    <div className="page__info-container mt-16">
                                        <div className='_full d-flex justify-content-center'>
                                            <div className='service-records _status-disagreed'>На доработку</div>
                                            <span onClick={() => sendAgreement(5)} className="service-records _cancel">Отменить</span>
                                        </div>
                                        <FormItem field={comment} classes="_full" required={true} />
                                    </div>
                                    : ""
                                }
                            </>
                            : ""
                        }
                    </>
                    : ''
                }

                {+props.fields.author.value === +userData.user_id && (props.fields.tasks_status.value != 1 && props.fields.tasks_status.value != 6) ?
                    <div className="page__info-container">
                        <div className="disagreed_button _full mt-16">
                            <span htmlFor="disagreed_button" onClick={() => { sendAgreement(6); succeedSend(props.fields.id.value, 6); }} className="service-records_button _cancel  ml-0">Отменить задачу</span>
                        </div>
                    </div>

                    : ''
                }

                {(+props.fields.author.value === +userData.user_id && (props.fields.tasks_status.value === 1 || props.fields.tasks_status.value === 6)) ?
                    <>
                        <div className="page__info-container">
                            <span htmlFor="disagreed_button" onClick={() => sendAgreement(3)} className="service-records_button _full _reset ml-0 mt-16">Переоткрыть</span>
                        </div>
                        {agreementStatus === 3 ?
                            <div className="page__info-container mt-16">
                                <div className='_full d-flex justify-content-center'>
                                    <div className='service-records _on-review'>Переоткрыть задачу</div>
                                    <span onClick={() => sendAgreement(5)} className="service-records _cancel">Отменить</span>
                                </div>
                                <FormItem field={comment} classes="_full" required={true} />
                            </div>
                            : ""
                        }

                    </> : ""
                }
                <input type="hidden" name="tasks_status" value={agreementStatus} />
            </PersonalAccesses>



            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} title={'Лента действий'} />
                </div>
            }
            <HistoryComponent id={props.id} name={'tasks'} />

        </>

    )
}
export default TaskDetail;