import React, { Component, useEffect } from 'react'
import { getDomain } from '../../functions/functions';
import { useSelector } from 'react-redux';



const PrintQR = () => {
    const qr = useSelector(state => state.qr_data.QRData)



    useEffect(() => {
        if (qr.qr_code) {
            window.print()
        }
    }, [])



    
    
    return (

        <div className='qr-sticker'>
            <svg xmlns="http://www.w3.org/2000/svg" width="260" height="60" viewBox="0 0 260 60" fill="none">
                <g clipPath="url(#clip0_925_968)">
                    <path d="M60 60H0V20L30 0L60 20V60Z" fill="black" />
                    <path d="M126.6 32.5C123.45 30 118.55 28.575 111.9 28.575H95V21.225H127.825V10H80V60H111.8C118.467 60 123.375 58.575 126.525 55.725C128.157 54.2044 129.436 52.3436 130.27 50.2743C131.103 48.2049 131.472 45.9776 131.35 43.75C131.473 41.6363 131.108 39.5226 130.284 37.5721C129.461 35.6216 128.2 33.8863 126.6 32.5ZM114.55 48.775C112.658 50.0608 110.377 50.6445 108.1 50.425H95V37.5H108.125C110.417 37.2688 112.714 37.892 114.575 39.25C115.2 39.8616 115.689 40.5991 116.008 41.4137C116.327 42.2283 116.469 43.1013 116.425 43.975C116.48 44.8633 116.341 45.7528 116.017 46.5818C115.693 47.4107 115.192 48.1592 114.55 48.775Z" fill="black" />
                    <path d="M153.376 39.025C152.951 39.6 152.651 40.025 152.451 40.35L151.951 41.175H151.801V10H136.801V60H153.151L175.001 30.5C175.426 29.9 175.726 29.425 175.926 29.1L176.451 28.1V29.05C176.451 29.35 176.451 29.875 176.451 30.65V60H191.451V10H175.001L153.376 39.025Z" fill="black" />
                    <path d="M240.275 60H260L233.475 33.55L258.15 10H239.2L220.225 28.375H214.85V10H199.875V60H214.85V40.425H220.475L240.275 60Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_925_968">
                        <rect width="260" height="60" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <img className="qr" src={getDomain() + qr.qr_code} />
            <div className="reg-num-wrap">
                <span className="reg-num-title">Номер</span>
                <span className="reg-num">{qr.inventory_number}</span>
            </div>
            {/* <a href="" className='btn' onClick={}>Печать</a> */}
        </div>
    )
}

export default PrintQR