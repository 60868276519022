import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from "react-redux"
import EmojiPicker, { Emoji } from 'emoji-picker-react';

import FileComponent from '../FileComponent'
import getFormattedDate from '../../functions/getFormattedDate'
import { getItems, getDomain, sendFile } from '../../functions/functions'

const CommentInputGroup = ({ object_id, type, parent = null, parent_id = null, close = () => { } }) => {
    const userData = useSelector(state => state.user_data.userData)
    const parent_name = parent ? parent.first_name + ' ' + parent.patronymic + ', ' : ''
    const [text, setText] = useState('')
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [height, setHeight] = useState(39)
    
    const [sowEmoji, setShowEmoji] = useState(false)
    const file_input = useRef()
    const text_input = useRef()

    useEffect(() => {
        setText(parent_name)
    }, [])

    const sendMessage = () => {
        setSending(true)
        const clean_text = text.replace(parent_name, '')

        const body = {
            files: files,
            type: type,
            object_id: object_id,
            text: text,
            parent: parent_id,
            author: +userData.user_id,
            answers: []
        }

        if (clean_text || files.length > 0) {
            getItems(getDomain() + '/api/comment/', "POST", (data) => {
                setText(''), setFiles([]); close(); setSending(false)
                //window.location.reload()
            }, JSON.stringify(body), (error) => {
                setSending(false)
                window.location.reload()
            })
        } else {
            console.log('Сообщение пустое')
        }
    }


    const addFile = (urls) => {
        const arr = []
        for (let file of urls) {
            arr.push({ file: file })
        }
        setFiles([...files, ...arr])
    }

    const emojiSelect = (item) => {
        setText(text => text + item.emoji)
        text_input.current.focus()
        setShowEmoji(false)
    }

    return (
        <div className='comment-field'>
            <div className='comment-field__input-wrap'>
            {/* rows={rows} */}
                <textarea className='comment-field__input' style={{ height: `${height}px` }} ref={text_input} onChange={e => {setText(e.target.value); setHeight(text_input.current.scrollHeight)}} value={`${text}`} placeholder='Написать комментарий…' ></textarea>
                {/* <div className='comment-field__input' onInput={e => setText(e.target.innerHTML)} contenteditable="true" value={{__html: text}}></div> */}
                <input type='file' onChange={(e) => sendFile(e, addFile, setLoading, setError)} ref={file_input} className='d-none' multiple={true} />
                <span onClick={() => setShowEmoji(!sowEmoji)} className='comment-field__emoji-btn'></span>
                <span onClick={() => { file_input.current.click() }} className={`comment-field__file-btn`}></span>
                <span onClick={sendMessage} className={`comment-field__send-btn${sending ? ' _disabled' : ''}`}></span>
            </div>
            {sowEmoji &&
                <div className='emoji-container'>
                    <EmojiPicker
                        onEmojiClick={emojiSelect}
                        emojiStyle={'native'}
                        categories={[
                            {
                                category: 'suggested',
                                name: 'Использованные'
                            },
                            {
                                category: 'smileys_people',
                                name: 'Смайлы'
                            },
                            {
                                category: 'animals_nature',
                                name: 'Животные & природа'
                            },
                            {
                                category: 'food_drink',
                                name: 'Еда & напитки'
                            },
                            {
                                category: 'travel_places',
                                name: 'Путешествия'
                            },
                            {
                                category: 'activities',
                                name: 'Активность'
                            },
                            {
                                category: 'objects',
                                name: 'Объекты'
                            },
                            {
                                category: 'symbols',
                                name: 'Символы'
                            }
                        ]}
                        skinTonesDisabled={true}
                        emojiVersion={"5.0"}
                        searchPlaceholder={'Поиск'}
                    />
                </div>
            }
            <div className='comment-field__files'>
                {loading && 'Загрузка...'}
                {error && error}
                {files.map((file, idx) => (
                    <FileComponent file={file.file} date={file.lastModifiedDate ? getFormattedDate(file.lastModifiedDate) : false} key={idx} onDelete={() => console.log('delete')} />
                ))}
            </div>
        </div>
    )
}

export default CommentInputGroup