import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'

import CommentCard from './CommentCard'
import CommentInputGroup from './CommentInputGroup'
import WebSocketComponent from '../../functions/openSocket'
import CommentCardDelete from "./CommentCardDelete";

const CommentComponent = ({comments, title = "Комментарии", type = '', object_id}) => {
    const [commentsState, setCommentsState] = useState(comments?.value ? [...comments?.value?.reverse()] : [])
        , userData = useSelector((state) => state.user_data.userData)

    //console.log('CommentComponent type', type);

    const handleComment = (message) => {
        if (message.type === 'send_comment' && message.model_class === type && message.object_id === object_id) {
            if (message.parent) {
                for (let comment of commentsState) {
                    if (+comment.id === +message.parent) {
                        comment.answers.push(message)
                    }
                }
                setCommentsState([...commentsState])
            } else {
                commentsState.unshift(message)
                setCommentsState([...commentsState])
            }
        } else if (message.type === 'delete_comment' && message.model_class === type && message.object_id === object_id) {
            if (message.parent_id) {
                for (let comment of commentsState) {
                    if (+comment.id === +message.parent_id) {
                        if (comment.answers.length > 0) {
                            let index = comment.answers.findIndex(answer => answer.id === message.id);
                            comment.answers[index].is_available = false
                        }
                    }
                }
                setCommentsState([...commentsState])
            } else {
                let index = commentsState.findIndex(comment => comment.id === message.id);
                commentsState[index].is_available = false
                setCommentsState([...commentsState])
            }
        }
        console.log('commentsState',commentsState)
    }



    return (
        <div className='comments'>
            <WebSocketComponent userData={userData} handleMessageEvent={(e) => {
                handleComment(e)
            }}/>
            <hr className="my-12"/>
            <h2 className='comments__title'>{title}<span className='comments__count'>{commentsState?.length}</span></h2>
            <CommentInputGroup object_id={object_id} type={type}/>
            <hr className="my-12"/>
            {commentsState?.length > 0 && commentsState.map((comment, idx) => {
                    console.log(comment)
                    if (comment?.is_available !== undefined && !comment?.is_available) {
                        // console.log('===>', comment)
                        return <CommentCardDelete key={idx} comment={comment} object_id={object_id} type={type}/>
                    }
                    return <CommentCard key={idx} comment={comment} object_id={object_id} type={type}/>
                }
            )}
        </div>
    )
}

export default CommentComponent