import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { InfoItem, FileItem } from "../../components/InfoItem";
import FormItem from "../../components/form/FormItem";
import Alert from "../../components/Allert";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import IncomingNumbersSearch from "../../components/form/IncomingNumbersSearch";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";

function OutgoingDetail(props) {
  console.log(props.fields);
  const [loading, setLoading] = useState(false),
    [formErrors, setFormErrors] = useState([]),
    params = useParams(),
    [alert, setAlert] = useState({
      title: "",
      text: "",
      type: "",
    }); // error, alert, info, success

  const setErrors = (data) => {
    data.then((res) => {
      console.log("errors", res);
    });
  };
  return (
    <>
      <Alert setAlert={setAlert} alert={alert} />
      <div className={"page__info-container"}>
        <InfoItem item={props.fields.organisation} />
        <InfoItem item={props.fields.output_number} />
        <InfoItem item={props.fields.register_date} classes="grid-start-1" />
        <InfoItem item={props.fields.date_out} classes="" />
        <InfoItem item={props.fields.doc_name} classes="_full" />
        <InfoItem item={props.fields.contragent} classes="_full" />
        <InfoItem item={props.fields.whom_fio} classes="_full" />
        <InfoItem item={props.fields.output_doc_type} classes="_full" />
        <InfoItem item={props.fields.source} classes="_full" />
        <InfoItem item={props.fields.motiv_number} />
        <InfoItem item={props.fields.close_doc_number} />
        <InfoItem item={props.fields.description} classes="_full" />
        <FileItem item={props.fields.file} classes="_full" />
        <FileItem item={props.fields.other_doc} classes="_full" />
        <InfoItem item={props.fields.responsible} classes="_full" />

        {/* <InfoItem item={props.fields.incoming_numbers} classes="_full" /> */}
        {props.fields.incoming_numbers.length > 0 &&
        <IncomingNumbersSearch field={props.fields.incoming_numbers} classes="_full" detail={true} />
        }

        
        <InfoItem item={props.fields.registrar} classes="_full" />
        <InfoItem item={props.fields.observers} classes="_full" />
      </div>

      <PersonalAccesses url={props.url} fields={props.fields} absolut_save_btn={false} page_name={props.name}>
        <div className="d-none">
          <FormItem field={props.fields.organisation} />
          <FormItem field={props.fields.output_number} />
          <FormItem field={props.fields.register_date} />
          <FormItem field={props.fields.date_out} />
          <FormItem field={props.fields.doc_name} />
          <FormItem field={props.fields.contragent} />
          <FormItem field={props.fields.output_doc_type} />
          <FormItem field={props.fields.responsible} />
          {/* <FormItem field={props.fields.incoming_numbers} /> */}
          <FormItem field={props.fields.registrar} />
          <FormItem field={props.fields.file} />
          <DocumentAddFormItem field={props.fields.other_doc} />
        </div>
      </PersonalAccesses>

      
      {props.fields.comments && (
        <div className="lenta">
          <CommentComponent
            comments={props.fields.comments}
            object_id={props.fields.id.value}
            type={props.type}
          />
        </div>
      )}
      <HistoryComponent id={props.id} name={'output'} />
    </>
  );
}

export default OutgoingDetail;
