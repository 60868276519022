import React, { useEffect, useState } from 'react'
import { createFieldsObject, getDomain, getItems } from '../../functions/functions';
import { useParams, useNavigate } from 'react-router-dom';
import { FileItem, InfoItem } from '../../components/InfoItem';
import HeaderInside from '../../components/HeaderInside';
import { useSelector } from "react-redux";

const HistoryDetail = () => {
    const [list, setList] = useState([])
        , [fields, setFields] = useState({})
        , [alert, setAlert] = useState({})
        , params = useParams()
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)
    useEffect(() => {
        // getItems(`${getDomain()}/api/invent/${params.id}/history/${params.id_history}`, 'GET', setData)
        getItems(`${getDomain()}/api/invent/${params.id}/`, 'OPTIONS', setData, false, (error) => {
        })
    }, [])


    const setData = (data) => {
        const newFields = createFieldsObject(data.actions.PUT)
        const setValues = (data) => {

            setAlert({ title: '', text: '', type: '', })
            for (const key in data) {
                if (newFields[key]) {
                    newFields[key].value = data[key] ?? ''
                }
            }
            setFields(newFields)
        }
        getItems(`${getDomain()}/api/invent/${params.id}/history/${params.id_history}`, 'GET', setValues)
    }


    const goToBack = (id) => {
        navigate(`/invent/detail/${id}/`)
    }

    const restoreHistory = (id, history_id) => {
        fetch(`${getDomain()}/api/invent/${id}/revert/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${userData.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ "history_id": history_id })

        })
            .then(response => {
                navigateToView()
            })
    }

    return (
        <div className="page">
            <HeaderInside title={`История`}
                edit={false}
                create={false}
                delete_btn={false}
                clickEvent={() => goToBack(params.id)}
            />
            <hr />
            <div className="page__content">
                <div className={"page__info-container"}>
                    {Object.keys(fields).length > 0 &&
                        <>
                            <InfoItem item={fields.title} classes="_full" />
                            <InfoItem item={fields.inventory_number} classes="" />
                            <InfoItem item={fields.nomenclature_code} classes="" />
                            <InfoItem item={fields.serial_number} classes="_full" />
                            <InfoItem item={fields.organisation} classes="_full" />
                            <InfoItem item={fields.status} classes="_full" />
                            <InfoItem item={fields.responsible} classes="_full" />
                            <InfoItem item={fields.real_user} classes="_full" />
                            <InfoItem item={fields.description} classes="_full" />
                            {fields.image.value &&
                                <img src={getDomain() + '/media/' + fields.image.value} className="w-100" />
                            }
                            <a className='btn grid-start-1' onClick={(e) => { restoreHistory(params.id, params.id_history) }}>Восстановить</a>

                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default HistoryDetail