export default (str, collback = (date) => console.log(date)) => {
    let d
    try {
        d = new Date(str)
    } catch {
        console.log('--- Неподдерживаемый формат даты ---', str)
        return ''
    }

    const utc = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds())
    
    const utcDate = new Date(utc);
    
    return collback(utcDate.toString())
}