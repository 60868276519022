import React from "react";
import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import AddedFormItem from "../../components/form/AddedFormItem";
import AddedFolder from "../../components/form/AddedFolder";

import getFormattedDate from "../../functions/getFormattedDate";



function SimpleArchiveFields({fields}) {
    const formatted_date = getFormattedDate()

    return (
        <>
            <FormItem field={fields.title} required={true} classes="_full"/>
            <DocumentAddFormItem field={fields.other_doc} folder={true} classes="_full"/>
            <AddedFolder field={fields.sub_dumping} classes="_full" button={'Добавить подпапку'} />

            <FormItem field={fields.author} required={true} classes="_full" read_only={true} value={!fields.author.value && localStorage.getItem('user_id')} />

            <FormItem field={fields.date_of_creation}  classes="_full" read_only={true} value={fields.date_of_creation.value ? fields.date_of_creation.value : formatted_date}/>
            <FormItem field={fields.date_of_update}  classes="_full" read_only={true} value={fields.date_of_update.value ? fields.date_of_update.value : formatted_date}/>
            <FormItem field={fields.description} classes="_full"/>


            <AddedFormItem field={fields.soresponsibles} classes="_full" button={'Добавить наблюдателя'} />

        </>

    )
}
export default SimpleArchiveFields;