import React, { useEffect, useState } from "react"
import FormItem from "../../components/form/FormItem";
import SearchContragent from "../../components/form/SearchContragent";
import { createFieldsObject, getDomain, getItems } from "../../functions/functions";

const TimelogFilter = (props) => {

    useEffect(() => {
        getItems(`${getDomain()}/api/skud/users/?limit=1000`, 'GET', setUsers)
    }, [])

    const [items, setItems] = useState({
        creation_date_in: {
            label: "Начало периода",
            name: "creation_date_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        },
        creation_date_out: {
            label: "Конец периода",
            name: "creation_date_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        },
    })


    const setUsers = (data) => {

        let is_my = []
        data.results.map((item, key) => {
            is_my.push({ value: item.id, label: `${item?.last_name} ${item.first_name} ${item.patronymic}` })
        })
        items.is_my = {
            children: is_my,
            label: "Работник",
            name: "is_my",
            placeholder: "",
            read_only: false,
            required: true,
            type: "select",
            value: ""
        }
        setItems({ ...items })
    }

    const getValue = (key) => {
        const value = props.values.find(item => item[key] !== undefined)
        if (value !== undefined)
            return value[key]
        return ''
    }

    return (
        <>
            {Object.keys(items).length > 0 ? Object.keys(items).map((key) => {
                const value = getValue(key)
                const new_field = items[key]
                return <FormItem key={key} field={new_field} required={false} value={value} />
            }) : <>загрузка</>}
            {items.register_date_in ? <FormItem field={items.register_date_in} required={false} value={() => getValue('register_date_in')} /> : ''}
            {items.register_date_out ? <FormItem field={items.register_date_out} required={false} value={() => getValue('register_date_out')} /> : ''}
        </>
    )
}

export default TimelogFilter 