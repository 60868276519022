import React, { useEffect, useState } from 'react'
import ViewPage from '../../components/ViewPage'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import EventCalendarTable from './EventCalendarTable'
import { getItems } from '../../functions/functions'
import { useSelector } from 'react-redux'

const CalendarView = (props) => {
 


  return (
    <ViewContainer {...props}>
      <EventCalendarTable {...props} />
    </ViewContainer>

  )
}

export default CalendarView