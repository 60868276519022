import React, { useState, useEffect } from "react"
import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import {InfoItem} from "../../components/InfoItem";
import AddedFormItem from "../../components/form/AddedFormItem";
import SearchContragent from "../../components/form/SearchContragent";

function RegisterFields({fields}) {
    const [isSettings, setIsSettings] = useState(false)
    return (
        <>
            <div className="tabs _full">
                <span className={isSettings ? "tab" : "tab _active"} onClick={() => {setIsSettings(false)}}>Материалы договора</span>
                <span className={isSettings ? "tab _active" : "tab"} onClick={() => {setIsSettings(true)}}>Настройки договора</span>
            </div>
            <div className={isSettings === true ? 'form-container _full' : 'd-none'}>
                <FormItem field={fields.organisation} classes="_full"/>
                <FormItem field={fields.status} classes="_full"/>
                <FormItem field={fields.doc_num} classes="_grid-start-1"/>
                <FormItem field={fields.doc_law}/>
                <FormItem field={fields.date_of_approval} classes="_grid-start-1"/>
                <FormItem field={fields.date_of_conclusion} />
                <FormItem field={fields.date_of_execution} classes="_grid-start-1"/>
                <FormItem field={fields.footing} classes="_full"/>
                <FormItem field={fields.price} classes="_full"/>
                <FormItem field={fields.doc_name} classes="_full"/>
                <SearchContragent field={fields.contragent} classes="_full"/>
                {/*<FormItem field={fields.file} classes="_full"/>*/}
                <FormItem field={fields.initiator} classes="_full"/>
                <AddedFormItem field={fields.observers} classes="_full" button={'Добавить наблюдателя'} />
            </div>
            <div className={isSettings !== true ? 'form-container _full' : 'd-none'}>
                <div className='form-container _full _combined-block'>
                    <FormItem field={fields.file} classes="_full"/>
                    <div>
                        <p className="info-title">Сумма</p>
                        <p className="info-value">{fields.price.value}</p>
                    </div>
                    <div>
                        <p className="info-title">Уплачено</p>
                        <p className="info-value">{fields.payment_sum.value}</p>
                    </div>
                </div>
                <div className='form-container _full _combined-block'>
                    <DocumentAddFormItem field={fields.doc_payment} classes="_full"/>
                    <FormItem field={fields.payment_sum} />
                </div>
                <DocumentAddFormItem field={fields.act_files} classes="_full"/>
                <DocumentAddFormItem field={fields.form_files} classes="_full"/>
                <DocumentAddFormItem field={fields.other_files} classes="_full"/>

            </div>

        </>
    )
}

export default RegisterFields