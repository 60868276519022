import React from "react";
import "./styles/App.scss";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
  useOutletContext,
} from "react-router-dom";

import {
  userHasGroup,
  userHasPermission,
} from "./functions/functions";
import { routes } from "./pages";

// Pages
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import PassRecovery from "./pages/PassRecoveryPage";
import RefreshPage from "./pages/RefreshPage";

import Page from "./components/Page";
import DetailPage from "./components/DetailPage";
import ViewPage from "./components/ViewPage";
import HistoryDetail from "./pages/history/HistoryDetail";
import PrintQR from "./pages/inventory/printQR";
import DashboardFullScreen from "./pages/dashboard/DashboardFullScreen";

function hasJWT() {
  let flag = false;
  //check user has JWT token
  localStorage.getItem("token") ? (flag = true) : (flag = false);
  return flag;
}

const PrivateRoute = () => {
  const isLoggedIn = hasJWT(), // replace with your authentication logic
    location = useLocation();
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
function MyOutlet() {
  const outlet = useOutletContext();
  return <Outlet context={outlet} />;
}
const UserGroup = ({ group = "Users", replace_to = "/" }) => {
  const success = userHasGroup(group); // replace with your authentication logic
  return success ? <MyOutlet /> : <Navigate to={replace_to} replace />;
};
const UserPermission = ({ permission = "", replace_to = "/" }) => {
  const success = userHasPermission(permission); // replace with your authentication logic
  return success ? <MyOutlet /> : <Navigate to={replace_to} replace />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<PrivateRoute />}>
        <Route element={<Page />}>
          {routes.map((route) => (
            <Route key={route.name}>
              <Route
                element={
                  route.use_permissions ? (
                    <UserPermission permission={"view_" + route.model_name} replace_to={route.replace_to} />
                  ) : (
                    <MyOutlet />
                  )
                }
              >
                {route.name === "lenta" ? (
                  <Route index element={route.pages.view} />
                ) : (
                  <Route path={route.name} element={route.pages.view} />
                )}
                {/* action={async () => {
                    setTimeout(() => {return true}, 3000)
                    
                  }} */}
                <Route path={route.name + "/detail/:id"} element={route.pages.detail} />
              </Route>
              <Route
                element={
                  route.use_permissions ? (
                    <UserPermission permission={"change_" + route.model_name} replace_to={"/" + route.name} />
                  ) : (
                    <MyOutlet />
                  )
                }
              >
                <Route path={route.name + "/edit/:id"} element={route.pages.edit} />
              </Route>
              <Route
                element={
                  route.use_permissions ? (
                    <UserPermission permission={"add_" + route.model_name} replace_to={"/" + route.name} />
                  ) : (
                    <MyOutlet />
                  )
                }
              >
                <Route path={route.name + "/create"} element={route.pages.create} />
              </Route>
              <Route path={route.name + "/detail/:id/history/:id_history"} element={<HistoryDetail />} />
            </Route>
          ))}
          <Route path="/lenta" element={<Navigate to="/" replace />} />
        </Route>
        <Route path={"/print-qr"} element={<PrintQR />} />

        <Route path={"/dashboard-full"} element={<DashboardFullScreen />} />

        <Route path="/logout" element={<LogoutPage />} />
      </Route>

      {hasJWT() ? (
        <Route path="/login" element={<Navigate to="/" replace />} />
      ) : (
        <Route path="/login" element={<LoginPage />} />
      )}

      <Route path="/refresh" element={<RefreshPage />} />

      <Route path="/pass-recovery" element={<PassRecovery />} />
    </>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
