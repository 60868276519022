import React, {useEffect, useRef, useState} from "react";
import BirthdayCard from "./BirthdayCard";

import '../../styles/BirthdayCards.scss'
import CorpDateCard from "./CorpDateCard";

function CardsLenta(props) {
    const scrollRef = useRef()
    const birthdayUsers = props.birthdayUsers
    const corpDateUsers = props.corpDateUsers

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const el = scrollRef.current;

        if (el) {
            const handleMouseDown = (e) => {
                setIsDragging(true);
                setStartX(e.pageX - el.offsetLeft);
                setScrollLeft(el.scrollLeft);
            };

            const handleMouseMove = (e) => {
                if (!isDragging)
                    return;
                e.preventDefault();
                const x = e.pageX - el.offsetLeft;
                const walk = (x - startX) * 2;
                el.scrollLeft = scrollLeft - walk;
            };

            const handleMouseUp = () => {
                setIsDragging(false);
            };

            const handleTouchStart = (e) => {
                setIsDragging(true);
                setStartX(e.touches[0].pageX - el.offsetLeft);
                setScrollLeft(el.scrollLeft);
            };

            const handleTouchMove = (e) => {
                if (!isDragging)
                    return;
                e.preventDefault();
                const x = e.touches[0].pageX - el.offsetLeft;
                const walk = (x - startX) * 2;
                el.scrollLeft = scrollLeft - walk;
            };

            const handleTouchEnd = () => {
                setIsDragging(false);
            };

            const wheelListener = (e) => {
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY * 5,
                    behavior: "smooth"
                });
            }

            const handleMouseLeave = () => {
                setIsDragging(false);
            };

            // el.addEventListener("mousedown", handleMouseDown);
            // el.addEventListener("mousemove", handleMouseMove);
            // el.addEventListener("mouseup", handleMouseUp);
            // el.addEventListener("mouseleave", handleMouseLeave);
            el.addEventListener("touchstart", handleTouchStart);
            el.addEventListener("touchmove", handleTouchMove);
            el.addEventListener("touchend", handleTouchEnd);
            el.addEventListener("wheel", wheelListener);

            return () => {
                // el.removeEventListener("mousedown", handleMouseDown);
                // el.removeEventListener("mousemove", handleMouseMove);
                // el.removeEventListener("mouseup", handleMouseUp);
                // el.removeEventListener("mouseleave", handleMouseLeave);
                el.removeEventListener("touchstart", handleTouchStart);
                el.removeEventListener("touchmove", handleTouchMove);
                el.removeEventListener("touchend", handleTouchEnd);
                el.removeEventListener("wheel", wheelListener);
            };
        }

    }, [isDragging, scrollLeft, startX]);

    function getYearsMessage(years) {
        let lastDigit = years % 10;
        let lastTwoDigits = years % 100;

        let word = "лет";
        if (lastDigit === 1 && lastTwoDigits !== 11) {
            word = "год";
        } else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 12 || lastTwoDigits > 14)) {
            word = "года";
        }

        return `${years} ${word} работы в компании`
    }

    const getHintTextForCorpCard = (corp_date) => {
        const corpDateYear = Number(corp_date.match(/.*\.(\d{4})/)[1]);
        const curDate = new Date()
        const yearInWork = curDate.getFullYear() - corpDateYear

        return getYearsMessage(yearInWork)
    }

    return (
        <div ref={scrollRef} className={"birthday-cards"}>
            {birthdayUsers.map((item, idx) => {
                return (
                    <BirthdayCard
                        onClick={props.onClick}
                        last_name={item.last_name}
                        first_name={item.first_name}
                        patronymic={item.patronymic}
                        birth_date={item.birth_date}
                        file={item.file}
                        id={item.id}
                        key={`birthday-date-card-${idx}`}
                    />
                )
            })}
            {corpDateUsers.map((item, idx) => {
                return (
                    <CorpDateCard
                        onClick={props.onClick}
                        last_name={item.last_name}
                        first_name={item.first_name}
                        patronymic={item.patronymic}
                        corp_date={item.corp_date}
                        id={item.id}
                        hintText={getHintTextForCorpCard(item.corp_date)}
                        file={item.file}
                        key={`corp-date-card-${idx}`}
                    />
                )
            })}
        </div>
    )
}


export default CardsLenta