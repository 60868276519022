import React, { useState } from "react"
import FormItem from "../../components/form/FormItem";
import { onEmailInput, onPhoneInput } from "../../functions/functions"
import PasswordItem from "../../components/form/PasswordItem";
import ResponsibilityTransfer from "./ResponsibilityTransfer";
import Alert from "../../components/Allert";

function UserEdit({ fields }) {
    const [alert, setAlert] = useState({
        title: '',
        text: '',
        type: '', // error, alert, info, success
    })

    return (
        <>
            <Alert setAlert={setAlert} alert={alert} />


            <FormItem field={fields.status} classes="_full" required={true} />
            <FormItem field={fields.groups} required={true} classes="_full" />
            <FormItem field={fields.last_name} classes="_grid-start-1" />
            <FormItem field={fields.first_name} classes="" />
            <FormItem field={fields.patronymic} classes="_grid-start-1" />
            <FormItem field={fields.birth_date} classes="" />
            <FormItem field={fields.corp_date} classes="" />
            <FormItem field={fields.email} required={true} mask={onEmailInput} classes="_full" />
            <FormItem field={fields.internal_phone} classes="_grid-start-1" />
            <FormItem field={fields.personal_phone} mask={onPhoneInput} classes="_full" />
            <FormItem field={fields.organisation} required={true} classes="_full" />
            <FormItem field={fields.department} required={true} classes="_full" />
            <FormItem field={fields.skud_fond} classes="_full" />
            <FormItem field={fields.structure_bik} classes="_full" />
            <FormItem field={fields.post_bik} classes="_full" />
            <FormItem field={fields.structure_fond} classes="_full" />
            <FormItem field={fields.post_fond} classes="_full" />
            <FormItem field={fields.file} classes="_full" />
            <FormItem field={fields.username} required={true} classes="_full" />
            <PasswordItem field={fields.password} classes="_full" />

            <ResponsibilityTransfer id={fields.id.value} setAlert={setAlert} />

        </>
    )
}

export default UserEdit