import axios from 'axios';
import queryString from 'query-string';
import apiConfig from './apiConfig';

const axiosClient = axios.create({
    baseURL: apiConfig.baseUrl,
    headers: {
        'Content-Type': 'application/json',
        // No token initially in the headers
    },
    paramsSerializer: params => queryString.stringify({ ...params, api_key: apiConfig.apiKey })
});

axiosClient.interceptors.request.use(async (config) => {
    // Get the token from local storage here
    const token = localStorage.getItem('token');

    // If the token is available, set it in the request headers
    if (token && token !== 'null') {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Check if the request data is FormData
    if (config.data instanceof FormData) {
        // Change the content type to 'multipart/form-data'
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
});

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    throw error;
});

export default axiosClient;
