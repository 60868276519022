import React, { useEffect, useRef, useState } from 'react'
import { createFieldsObject, getDomain, getItems } from '../../functions/functions'
import FormErrorsContext from '../../functions/formErrorsContext'
import TasksFields from '../../pages/tasks/TasksFields'
import Lightbox from '../Lightbox'
import HeaderInside from '../HeaderInside'
import { useNavigate } from 'react-router-dom'




function CreateTaskForm({ itemNum, itemId, text, openModal, title, url }) {
    const [fields, setFields] = useState({})
        , [error, setError] = useState('')
        , [formErrors, setFormErrors] = useState({})
        , navigate = useNavigate()
    const link = useRef()
    const [redirectPath, setRedirectPath] = useState('')



    useEffect(() => {
        getItems(`${getDomain()}/api/tasks/`, 'OPTIONS', setData)

    }, [])

    useEffect(() => {

        if (redirectPath !== '') {
            link.current.click()
        }
    }, [redirectPath])

    const setData = (data) => {

        const newFields = createFieldsObject(data.actions.POST)
        setFields(newFields)

    }

    const TaskCreate = (e) => {
        e.preventDefault()
        const button = e.currentTarget
            , inputs = button.closest('.form-data').querySelectorAll('.form-container input')
            , selects = button.closest('.form-data').querySelectorAll('.form-container select')
            , formJson = {}
            , nested_fields = ['saleaction', 'other_doc', 'act_files', 'other_files', 'doc_payment', 'form_files', 'level_agreements', 'archive_doc', 'pasport_doc']


        for (let input of selects) {
            if (input.name !== "") {
                const arr = []
                for (const o of input.selectedOptions) {
                    arr.push(o.value)
                }
                formJson[input.name] = arr
            }
        }

        for (const input of inputs) {
            if (nested_fields.includes(input.name)) {
                formJson[input.name] = JSON.parse(input.value)
            } else if (input.multiple) {
                if (formJson[input.name]) {
                    const arr = formJson[input.name]
                    arr.push(input.value)
                    formJson[input.name] = arr
                } else {
                    const arr = []
                    arr.push(input.value)
                    formJson[input.name] = arr
                }
            } else if (input.type === 'checkbox') {
                if (input.checked) {
                    formJson[input.name] = input.value
                }
            } else {
                formJson[input.name] = input.value
            }
        }

        fetch(`${getDomain()}/api/tasks/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },

            credentials: "same-origin",
            body: JSON.stringify(formJson)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка. Неверно заполнены поля')
                }
                return response.json()
            })
            .then(data => {
                openInNewTab(data.id)
                setTimeout(() => {
                    openModal(false)
                }, 200)
            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                    setError('Доступ запрещен')
                } else {
                    setError(error.message)
                }
            })
    }


    const navigateToView = () => {
        const url = `/my-tasks/`
        navigate(url)
    }


    function openInNewTab(id) {
        try {
            const win = window.open(`/my-tasks/detail/${id}/`, '_blank');
            win.focus();
        } catch (err) {
            navigateToView()
            console.log('====================================');
            console.log(err);
            console.log('====================================');
        }
    }

    return (
        <>

            <HeaderInside title={title} clickEvent={e => {
                e.preventDefault();
                openModal(false)

            }} />
            <hr />
            <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                {error ? <p className="error-message mb-8">{error}</p> : <></>}
                {Object.keys(fields).length > 0 ?
                    <div className="form-data">
                        <div className="form-container form-2-columns">
                            <TasksFields fields={fields} basedOn={`Задача на основе ${text}`} itemNumber={itemNum} itemUrl={`/${url}/detail/${itemId}`} />
                        </div>
                        <div className="modal__buttons-panel">
                            <input type="submit" id="save_contragent_button" className="d-none" value="Send" />
                            <label htmlFor="save_contragent_button" className="save_button_fixed" onClick={TaskCreate}>Создать</label>
                        </div>
                    </div>
                    :
                    <>Загрузка...</>
                }
            </FormErrorsContext.Provider>
        </>
    )
};

const FastTaskCreate = (props) => {
    const [openModal, setOpenModal] = useState(false)
    const [styleModal, setStyleModal] = useState({ display: 'none' })

    const setModal = (e) => {
        setStyleModal({ display: 'grid' })
        setTimeout(() => {
            setOpenModal(true)
        }, 100)
    }  

    const closeModal = (e) => {
        setOpenModal(false)
        setTimeout(() => {
            setStyleModal({ display: 'none' })
        }, 300)
    }

    return (
        <>
            {/* <Modal title={`Создание задачи на основе ${props.text}`} button={{ title: `Создать задачу на основе ${props.text}`, classes: 'add-btn btn mt-16' }} ><CreateTaskForm itemNum={props.index} itemId={props.id} text={props.text} /></Modal> */}
            <a onClick={setModal} className='add-btn btn mt-16'>{`Создать задачу на осн. ${props.text}`}</a>
            {openModal &&
                <Lightbox style={styleModal} active={openModal} classes={'modal__content'} onClick={closeModal} noClose="true" ><CreateTaskForm itemNum={props.index} itemId={props.id} text={props.text} title={`Создание задачи на основе ${props.text}`} url={props.url} openModal={setOpenModal} /></Lightbox>
            }

        </>
    )
}

export default FastTaskCreate 
