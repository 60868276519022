import { API_USER_MODEL } from "./const";
import apiConfig from "../apiConfig";
import axiosClient from '../axiosClient';

export const getAllUsers = async (params, search) => {
    let methodUrl = [API_USER_MODEL.url + '/'].join('/');

    try {
        const url = search ? `${apiConfig.baseUrl}${methodUrl}${search}` : `${apiConfig.baseUrl}${methodUrl}`;
        const resp = await axiosClient.get(params? params : url)

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
