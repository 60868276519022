import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getItems } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";

function RegisterFilter(props) {
    const fields = ['doc_name', 'status', 'organisation', 'contragent', 'price', 'date_of_approval', 'date_of_conclusion', 'date_of_execution', 'initiator'],
        [items, setItems] = useState([])

    useEffect(() => {
        console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const new_fields = createFieldsObject(data.actions.POST)
        setItems(new_fields)
    }

    return (
        <>
            {Object.keys(items).length > 0 ? fields.map((key) => {
                const value = props.values.find(item => item[key] !== undefined)
                const new_field = items[key]
                // return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''}  />
                if (items[key].name === 'contragent') {
                    return <SearchContragent key={key} field={new_field} required={false} value={value ? value[key] : ''} canAdd={false} />
                } else {
                    return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />

                }
            }) : <>загрузка</>}

        </>
    )
}

export default RegisterFilter