import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getDomain, getItems } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";

function EntranceFilter(props) {

  //const [division, setDivision] = useState([])

  useEffect(() => {
    getItems(`${getDomain()}/api/skud/departments/?limit=1000`, 'GET', setData)
    getItems(`${getDomain()}/api/skud/users/?limit=1000`, 'GET', setUsers)
  }, [])

  const [items, setItems] = useState({
    is_enter: {
      children: [
        { value: "1", label: 'Вход' },
        { value: "2", label: 'Выход' },
      ],
      label: "Вход/Выход",
      name: "is_enter",
      placeholder: "",
      read_only: false,
      required: true,
      type: "select",
      value: ""
    },
    dateBegin: {
      label: "Начало периода",
      name: "dateBegin",
      placeholder: "",
      read_only: false,
      required: true,
      type: "date",
      value: ""
    },
    dateEnd: {
      label: "Конец периода",
      name: "dateEnd",
      placeholder: "",
      read_only: false,
      required: true,
      type: "date",
      value: ""
    },
  })

  const setData = (data) => {
   // console.log(data);
    let division = []
    data.results.map((item, key) => {
      division.push({ value: `${item?.organization?.name} ${item.name}`, label: `${item?.organization?.name}: ${item.name}`})
    })
    items.division = {
      children: division,
      label: "Отдел",
      name: "division",
      placeholder: "",
      read_only: false,
      required: true,
      type: "select",
      value: ""
    }
    setItems({ ...items })
  }

  const setUsers = (data) => {

    let users = []
    data.results.map((item, key) => {
      users.push({ value: item.id, label: `${item?.last_name} ${item.first_name} ${item.patronymic}`})
    })
    items.users = {
      children: users,
      label: "Работник",
      name: "user_id",
      placeholder: "",
      read_only: false,
      required: true,
      type: "select",
      value: ""
    }
    setItems({ ...items })
  }

  const getValue = (key) => {
    const value = props.values.find(item => item[key] !== undefined)
    if (value !== undefined)
      return value[key]
    return ''
  }

  return (
    <>
      {Object.keys(items).length > 0 ? Object.keys(items).map((key) => {
        const value = getValue(key)
        const new_field = items[key]


        if (items[key].name === 'contragent') {
          return <SearchContragent key={key} field={new_field} required={false} value={value} canAdd={false} />
        } else {
          return <FormItem key={key} field={new_field} required={false} value={value} />

        }
      }) : <>загрузка</>}
      {items.register_date_in ? <FormItem field={items.register_date_in} required={false} value={() => getValue('register_date_in')} /> : ''}
      {items.register_date_out ? <FormItem field={items.register_date_out} required={false} value={() => getValue('register_date_out')} /> : ''}
    </>
  )
}

export default EntranceFilter

