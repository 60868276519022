export default (str = Date.now()) => {
    let parsed_date
    try {
        parsed_date = new Date(str)
    } catch {
        console.log('--- Неподдерживаемый формат даты ---', str)
        return ''
    }
    const date_now = new Date()

    const d = parsed_date.getDate();
    const mon = (parsed_date.getMonth() + 1).toString().padStart(2, "0"); // Добавляем ведущий ноль, если месяц меньше 10
    const y = parsed_date.getFullYear();
    let h
    let m

    try {
        h = parsed_date.getHours().toString().padStart(2, "0")
        m = parsed_date.getMinutes().toString().padStart(2, "0")
    } catch {
        console.log('--- В дате нет данных о времени ---', str)
        return `${d}.${mon}.${y}`;
    }

    // Формируем строку в формате "день месяц год"

    if (date_now.getDate() === parsed_date.getDate()
        && date_now.getMonth() === parsed_date.getMonth()
        && date_now.getFullYear() === parsed_date.getFullYear()) { // совпадает день, месяц, год

        return `сегодня в ${h}:${m}`;

    } else if (date_now.getDate() === (parsed_date.getDate() + 1)
        && date_now.getMonth() === parsed_date.getMonth()
        && date_now.getFullYear() === parsed_date.getFullYear()) { // совпадает день - 1, месяц, год

        return `вчера в ${h}:${m}`;

    } else {
        return `${d}.${mon}.${y} ${h}:${m}`;
    }
}