import React from 'react';

const ChatConfirmDeleteModal = ({isShow = false, onCancel = null, onSubmit = null}) => {
    const handleOnCancel = (e) => {
        e.preventDefault()
        if (onCancel)
            onCancel()
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        if (onSubmit)
            onSubmit()
    }

    return (isShow ?
            <div className={'chat-confirm-delete-modal-window-overlay'}>
                <div className={'chat-confirm-delete-modal-window'}>
                    <span className={'label'}>Удалить это сообщение?</span>
                    <div className={'selection'}>
                        <button className={'selection-button-cancel'} onClick={handleOnCancel}>Отмена</button>
                        <button className={'selection-button-submit'} onClick={handleOnSubmit}>Удалить</button>
                    </div>
                </div>
            </div>
            :
            null
    )
}

export default ChatConfirmDeleteModal