import React, {useEffect} from 'react'
import {getDomain, getFileName} from '../../../functions/functions';
import {useDispatch, useSelector} from 'react-redux';
import {setActiveChat} from '../../../store/chatReducer';
import logo_home from "/public/Logo-item.svg";
import apiConfig from '../../../api/apiConfig';
import {getFormatedDate} from '../../../functions/functionsNew';
import {readNotification, readNotificationByInstanceId} from '../../../store/tableNotification';
import {getItems} from '../../../functions/functions';
import {useOutletContext} from 'react-router-dom';

const ChatCard = ({
                      dataChat,
                      chatList,
                      matchingNotifications,
                      lastMatchingNotification,
                      additionActionOnClick = null
                  }) => {
    const dispatch = useDispatch()
        , userData = useSelector((state) => state.user_data.userData)
        , isMyChats = chatList && dataChat?.initiator?.id === Number(userData?.user_id)
        , activeChat = useSelector((state) => state.activeChat)
        , fetchNavNotifications = useOutletContext()
        , readNotificationSignal = (notification_id) => dispatch(readNotification(notification_id))
        , readNotificationByInstanceIdSignal = (instance_id) => dispatch(readNotificationByInstanceId(instance_id))


    const readNotificationChat = () => {
        if (matchingNotifications) {
            console.log(matchingNotifications)
            const ids = []
            const instance_ids = []
            fetchNavNotifications()
            for (const item of matchingNotifications) {
                const curId = item.id
                if (curId) {
                    ids.push(curId)
                    readNotificationSignal(curId)
                }


                const curInstanceId = item.instance_id;
                if (curInstanceId) {
                    instance_ids.push(curInstanceId)
                    readNotificationByInstanceIdSignal(curInstanceId)
                }
            }

            if (instance_ids.length === 0) {
                return
            } else {
                getItems(`${getDomain()}/api/notifications/read`, "POST", data => {
                }, JSON.stringify({
                    owner_id: userData.user_id,
                    notification_ids: ids,
                    instance_ids: ids.length === 0 ? instance_ids : [],
                    model_name: "ChatModel"
                }))
            }
        }
    };

    useEffect(() => {
        if (lastMatchingNotification?.instance_id === activeChat) {
            readNotificationChat()
        }
    }, [matchingNotifications, activeChat])


    function extractTextInsidePTag(htmlString) {
        const regex = /<p>(.*?)<\/p>/; // Регулярное выражение для извлечения текста внутри тега <p>
        const match = htmlString?.match(regex);
        return match ? match[1] : ''; // Возвращаем текст из тега <p>
    }


    const handleOnClick = (e) => {
        e.preventDefault()

        if (additionActionOnClick !== null)
            additionActionOnClick()

        dispatch(setActiveChat(chatList ? isMyChats ? dataChat.receivers_rel != 0 ? dataChat.id : dataChat.receiver : dataChat.receivers_rel != 0 ? dataChat.id : dataChat.initiator : dataChat))
        readNotificationChat()
    }

    return (
        <div key={dataChat.id} className={`chat-card ${activeChat === dataChat?.id && 'active'}`}
             onClick={handleOnClick}>
            {dataChat?.chat_fill ?
                <div className='circleChat' style={{backgroundColor: dataChat?.chat_fill}}>
                    <p className='circlaNameChat'>{dataChat?.title?.charAt(0)}</p>
                </div>
                :

                <img src={
                    chatList ?
                        isMyChats ?
                            `${!dataChat?.receiver?.file?.startsWith('http') ? (apiConfig.baseUrlMedia + dataChat?.receiver?.file) : 'https://collback.bik31.ru/media/Screenshot_5_Slr7bQJ.png'}`
                            :
                            `${!dataChat?.initiator?.file?.startsWith('http') ? (apiConfig.baseUrlMedia + dataChat?.initiator?.file) : 'https://collback.bik31.ru/media/Screenshot_5_Slr7bQJ.png'}`
                        :
                        `${dataChat?.file ? dataChat.file : 'https://collback.bik31.ru/media/Screenshot_5_Slr7bQJ.png'}`
                } className='chatCardImg'/>

            }

            <div className='chatCardContainer'>
                <div className='chatCardHeader'>
                    <span className='chat-card__name'>
                        {
                            dataChat.title ?
                                dataChat.title
                                :
                                chatList ?
                                    isMyChats ?
                                        `${dataChat?.receiver?.last_name + ' ' + dataChat?.receiver?.first_name + ' ' + dataChat?.receiver?.patronymic}`
                                        :
                                        `${dataChat?.initiator?.last_name + ' ' + dataChat?.initiator?.first_name + ' ' + dataChat?.initiator?.patronymic}`
                                    :
                                    `${dataChat?.last_name + ' ' + dataChat?.first_name + ' ' + dataChat?.patronymic}`
                        }
                    </span>
                    <span
                        className='chat-card__date'>{lastMatchingNotification ? getFormatedDate(lastMatchingNotification.upload_time) : chatList ? dataChat?.last_message?.timestamp ? getFormatedDate(dataChat.last_message.timestamp) : '' : ''}</span>
                </div>
                <div className='footerChatCard'>
                    <p className='chat-card__message'>
                        {lastMatchingNotification ? lastMatchingNotification?.json_data?.message :
                            (dataChat?.last_message?.text !== '' ? dataChat?.last_message?.text :
                                    (dataChat?.last_message?.attachment ? 'Файл' : '')
                            )
                        }
                    </p>
                    {matchingNotifications?.length > 0 && (
                        <span
                            className={`chat-card__notifications ${matchingNotifications?.some(item => item?.json_data && Object.entries(item?.json_data)?.length !== 0) && 'alert'}`}>
                            {matchingNotifications?.length}
                        </span>
                    )}

                </div>
            </div>
        </div>
    )
}

export default ChatCard
