import React, { useState, useRef, useContext } from "react";

import { FileItem, InfoItem } from "../../components/InfoItem";
import AddedFolder from "../../components/form/AddedFolder";

import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";


function SimpleArchiveDetail(props) {
    return (
        <>
            <div className={"page__info-container"}>
                <InfoItem item={props.fields.title} classes="_full" />
                <div className="_full">
                    <PersonalAccesses url={props.url} fields={props.fields} page_name={props.name}>
                        <div className="d-none">
                            <FormItem field={props.fields.title} />
                            <FormItem field={props.fields.author} />
                            <FormItem field={props.fields.date_of_creation} />
                            <FormItem field={props.fields.date_of_update} />
                            <FormItem field={props.fields.description} />
                        </div>
                        <DocumentAddFormItem field={props.fields.other_doc} folder={true} classes="_full flex-column-reverse" />
                        <AddedFolder field={props.fields.sub_dumping} classes="_full flex-column-reverse" button={'Добавить подпапку'} />
                    </PersonalAccesses>
                </div>

                <InfoItem item={props.fields.author} classes="_full" />
                <InfoItem item={props.fields.date_of_creation} classes="_full" />
                <InfoItem item={props.fields.date_of_update} classes="_full" />
                <InfoItem item={props.fields.description} classes="_full" />


            </div>
            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} />
                </div>
            }
            <HistoryComponent id={props.id} name={'dumping'} />

        </>

    )
}
export default SimpleArchiveDetail;