import { createSlice } from '@reduxjs/toolkit'

const accordionMenu = localStorage.getItem('accordion') ? JSON.parse(localStorage.getItem('accordion')) : []


export const accordionMenuSlice = createSlice({
    name: 'accordion_data',
    initialState: {
        accordionMenu
    },
    reducers: {
        openAccordionMenu(state, action) {
            state.accordionMenu.push(action.payload)
            localStorage.setItem('accordion', JSON.stringify(state.accordionMenu))
            console.log('====================================');
            console.log( state.accordionMenu);
            console.log('====================================');
        
        },
        closeAccordionMenu(state, action) {
            state.accordionMenu = state.accordionMenu.filter(item => item != action.payload) 
            console.log('====================================');
            console.log( state.accordionMenu);
            console.log('====================================');
            localStorage.setItem('accordion', JSON.stringify(state.accordionMenu))
        }
    }
})

export const { openAccordionMenu, closeAccordionMenu} = accordionMenuSlice.actions
export default accordionMenuSlice.reducer