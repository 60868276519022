import React from "react";
import { useSelector } from "react-redux";

import Header from "./Header";
import Modal from "./Modal";
import Table from "./Table";
import Filter from "./Filter"
import { userHasPermission } from "../functions/functions";
import OverduePopup from "./OverduePopup";
import  ViewContainer  from "./ViewContainer/ViewContainer"

function ViewPage(props) {
    return (
        <ViewContainer {...props} >
            <Table {...props} />
        </ViewContainer>
    );
}


export default ViewPage