import React, { useState } from "react";

import LogoUrl from "/public/Logo.svg"
import FormItem from "../components/form/FormItem";
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom"
import { getDomain, setUserData } from "../functions/functions"
import { useDispatch } from "react-redux"
import { setUserDataItem } from "../store/userDataSlice"
import setCookie from "../functions/setCookie";

// Context
import FormErrorsContext from "../functions/formErrorsContext";

function LoginPage() {
    const [fields, setFields] = useState([
        {
            id: 'id_username',
            name: 'username',
            type: 'text',
            label: 'Логин',
            placeholder: '',
            value: '',
            required: true,
            disabled: false
        },
        {
            id: 'id_password',
            name: 'password',
            type: 'password',
            label: 'Пароль',
            placeholder: '',
            value: '',
            required: true,
            disabled: false
        }
    ])
        , [error, setError] = useState('')
        , [formErrors, setFormErrors] = useState({})
        , navigate = useNavigate()
        , location = useLocation()
        , dispatch = useDispatch()
        , addUserData = (name, value) => dispatch(setUserDataItem(name, value))

    const submitLogin = (event) => {
        event.preventDefault()
        const form = event.currentTarget,
            url = getDomain() + '/api/login/',
            formData = new FormData(form),
            object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });
        fetch(url, {
            method: form.method,
            headers: {
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': 'https://coll.bik31.ru'
            },
            mode: 'cors',
            credentials: "omit",
            body: JSON.stringify(object)
        })
            .then(response => {
                console.log('login response', response)
                if (response.status === 401)
                    throw new Error("Неправильный логин или пароль")
                if (response.status === 403)
                    throw new Error("Доступ запрещен")
                else if (response.status >= 500)
                    throw new Error(`Возникла ошибка, попробуйте позже (${response.status})`)
                return response.json()
            })
            .then(data => {
                localStorage.setItem("token", data.access);
                localStorage.setItem("refresh", data.refresh);

                setCookie("token", data.access, 1);
                setCookie("refresh", data.refresh, 1);

                addUserData(['token', data.access])
                addUserData(['refresh', data.refresh])

                const id = jwt_decode(data.access).user_id;
                getUserData(id);

            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch')
                    setError('Доступ запрещен')
                else
                    setError(error.message)
            });
    }

    const getUserData = (id) => {
        fetch(getDomain() + '/api/user/' + id + '/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log('user data response', response)
                console.dir(response)
                if (!response.ok) {
                    if (response.status === 401)
                        throw new Error("Неправильный логин или пароль")
                    if (response.status === 403)
                        throw new Error("Доступ запрещен")
                    else
                        throw new Error(`Возникла ошибка, попробуйте позже (${response.status})`)
                }
                return response.json()
            })
            .then(data => {
                console.log('login data', data)

                setUserData(data, addUserData)

                // Установка токена как куки
                setCookie("token", localStorage.getItem("token"), 1); // Измените срок действия (1 день в этом примере)
                setCookie("refresh", localStorage.getItem("refresh"), 1); // Измените срок действия (1 день в этом примере)

                const urlParams = new URLSearchParams(window.location.search);
                const next = urlParams.get('next');

                if (next) {
                    window.location.replace(`${next}`);
                } else {
                    if (location.state?.from?.pathname.includes('logout')) {
                        navigate('/')
                    } else if (location.state ? location.state.from && true : false) {
                        navigate(location.state.from)
                    } else {
                        navigate('/')
                    }
                }
            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch')
                    setError('Доступ запрещен')
                else
                    setError(error.message)
            })
    }

    const nameValidate = (e, field, setValue, setError) => {
        const val = e.target.value
        setValue(val)
        if (field.required) {
            if (val.length < 3) {
                setError('Имя должно быть не меньше 3 символов')
            } else {
                setError('')
            }
        }
    }

    return (
        <div className="container">
            <div className="login bg">
                <div>
                    <img src={LogoUrl} alt="" className="login__logo" />
                    <p className="login__title">Коллектив 1.0</p>
                </div>
                <div className="login__card-wrap">
                    <div className="login__card">
                        <h1 className="card__title">Вход для сотрудников</h1>
                        <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                            <form action="" onSubmit={submitLogin} method="post">
                                {error ? <p className="error-message mb-8">{error}</p> : ''}
                                {fields.map((field, idx) => {
                                    if (field.name === 'username') {
                                        return <FormItem field={field} handler={nameValidate} key={idx} />
                                    }
                                    return <FormItem field={field} key={idx} />
                                })}
                                {/*<a href="/pass-recovery" className="form__link">Забыли пароль?</a>*/}
                                <input type="submit" id="submit" className="d-none" value="Войти" />
                                <label htmlFor="submit" className="gr-button _block">Войти</label>
                            </form>
                        </FormErrorsContext.Provider>
                    </div>
                </div>
                <div>
                    <p className="login__contact">Белгородская ипотечная корпорация 2023</p>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;