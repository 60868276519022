import React, { useEffect, useRef, useState } from 'react';
import { getAllChats } from '../../../api/chat/getAllChat';
import Search from '../Search';
import { getAllUsers } from '../../../api/getAllUser/getAllUser';
import userPlus from "/public/page_icons/users-plus.svg";
import userMinus from "/public/page_icons/user-minus.svg";
import Check from "/public/page_icons/checkv1.svg";
import { getChats, updateChats } from '../../../api/chat/chat';
import { useDispatch, useSelector } from 'react-redux';
import chatSelectedUser, { resetSelectedUser, toggleUser } from '../../../store/chatSelectedUser';


const ModalGroupChat = ({onClose, dataReceiver, isShowButtonMinusPlus = true}) => {
  const debounceSearch = useRef(null);
  const [userList, setUserList] = useState([]);
  const [nameGroup, setNameGroup] = useState('');
  const [isNextUsers, setIsNextUsers] = useState('');
  const [loading, setLoading] = useState(false);
  const chatListRef = useRef();
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUserChat);

  const handleSearch = (searchTerm) => {
    if (debounceSearch.current) {
      clearTimeout(debounceSearch.current);
    }

    debounceSearch.current = setTimeout(() => {
      getAllUsers(undefined,  `?search=${searchTerm}&status=Активен`).then((resp) => {
        setUserList(resp.data.results);
      });
    }, 500);
  };

  const loadMoreChats = () => {
    if (isNextUsers !== null && !loading) {
      setLoading(true);
      getAllUsers(isNextUsers, `?status=Активен`).then((resp) => {
        if (resp.success) {
          setUserList((prevUsers) => [...prevUsers, ...resp.data.results]);
          setIsNextUsers(resp.data.next);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if(dataReceiver === null || dataReceiver === undefined){
      loadMoreChats();
    }
  }, [dataReceiver]);

  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = chatListRef.current;
  
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      loadMoreChats();
    }
  };

  useEffect(() => {
    if (dataReceiver?.receivers_rel?.length !== 0) {
      dataReceiver?.receivers_rel?.map((item) => dispatch(toggleUser({id: item.user.id})));
      setNameGroup(dataReceiver?.title)
    }
  }, [dataReceiver]);
  
  const handleAddOrRemoveUser = (user) => {
    dispatch(toggleUser(user));
  };

  const createChat = () =>{
    const bodyChat = {
        receivers_rel: selectedUser,
        title: nameGroup, 
        chat_id: dataReceiver? dataReceiver.id : null
    }
    if(nameGroup != ''){
      if(dataReceiver){
        updateChats(bodyChat).then((resp)=>{
          if(resp.success){
              onClose()
              dispatch(resetSelectedUser());
          }
      })
      } else {
        getChats(bodyChat).then((resp)=>{
            if(resp.success){
                onClose()
                dispatch(resetSelectedUser());
            }
        })
      }
       
    }
  }

  useEffect(() => {
    setUserList([])
    const fetchDataForSelectedUsers = async () => {
      const promises = selectedUser.map(async (user) => {
      
        const response = await getAllUsers(undefined, `${user?.id}/`);
        return response.data;
      });
  
      const userDataArray = await Promise.all(promises);
  
      // Перед добавлением данных в состояние, убедитесь, что все запросы выполнены успешно
      const allRequestsSuccessful = userDataArray.every((data) => data && data.success);
     

        // Обновление состояния userList только если все запросы успешны
      // if(allRequestsSuccessful){
        setUserList(userDataArray);
      // }
      
    };
  
    if (selectedUser.length !== 0) {
      fetchDataForSelectedUsers();
    }
  }, [selectedUser]);

  const handleCancel = () => {
    dispatch(resetSelectedUser()); // Dispatch the action to reset selectedUser
    onClose();
  };
  
  return (
    <div className='modalGroupContainer'>
      <div className='modalContainerChat'>
        <h1>Групповой чат</h1>
        <div className='inputContainer'>
            <label className='labelInputGroup'>Введите название</label>
            <input placeholder='Название чата' className='inputGroup' value={dataReceiver?.title && nameGroup} onChange={(e)=>{setNameGroup(e.target.value)}}></input>
        </div>
        <Search onSearch={handleSearch} />
        <div className='containerUserGroup' onScroll={dataReceiver? null :handleScroll} ref={chatListRef}>
          {loading?
            <></>
            :
            userList?.map((item) => (
              <div className='containerUser' key={item.id}>
                <div className='containerAvatar'>
                <img className='avatarUser' src={item.file} />
                <img className={`avatarUserCheck ${selectedUser?.some((selectedItem) => selectedItem.id === item.id) ? 'active' : ''}`} src={Check} />
                </div>
                <div className='containerTextUser'>
                  <h3 className={`${selectedUser?.some((selectedItem) => selectedItem.id === item.id) ? 'active' : ''}`}>{`${item.last_name} ${item.first_name} ${item.patronymic}`}</h3>
                  <p>{item.post_bik ? item.post_bik : item.post_fond}</p>
                </div>
                {!(selectedUser?.some((selectedItem) => selectedItem.id === item.id)) && <button
                    className={`buttonAddPeople ${selectedUser?.some((selectedItem) => selectedItem.id === item.id) ? 'active' : ''}
                  `}
                    onClick={() => handleAddOrRemoveUser(item)}
                >
                  <img src={selectedUser?.some((selectedItem) => selectedItem.id === item.id) ? userMinus : userPlus}
                       alt='Add User'/>
                </button>}
              </div>
            ))
          }
        </div>
        <div className='modalButtonContainer'>
          <button className='buttonModal__white' onClick={()=>{handleCancel()}}>Отменить</button>
          <button className='buttonModal' onClick={()=>{createChat()}}>Применить</button>
        </div>
      </div>
    </div>
  );
};

export default ModalGroupChat;
