import {createSlice} from "@reduxjs/toolkit";

export const tableNotifications = createSlice({
    name: 'table_notify',
    initialState: {
        model_name: "",
        tableNotifications: []
    },
    reducers: {
        //загрузка уведомлений
        setNotification(state, action) {
            // console.log('asd')
            return {
                model_name: action.payload.model_name,
                tableNotifications: [...action.payload.tableNotifications]
            }
        },

        //чтение уведомления
        readNotification(state, action) {
            // console.log("action.payload", action.payload)

            state.tableNotifications = state.tableNotifications.filter((notification) => {
                return notification.id !== action.payload
            })

            // console.log(tableNotifications)

            return state
        },

        readNotificationByInstanceId(state, action) {
            state.tableNotifications = state.tableNotifications.filter((notification) => {
                return notification.instance_id !== action.payload
            })

            return state
        },

        readAllNotification(state, action) {
            // console.log("action.payload", action.payload)

            state.tableNotifications = []

            // console.log(tableNotifications)

            return state
        },

        addTableNotification(state, action) {
            if (action.payload.model_name === state.model_name) {
                return {
                    model_name: state.model_name,
                    tableNotifications: [action.payload.notification, ...state.tableNotifications]
                }
            }
        }
    }
})

export const {
    setNotification,
    readNotification,
    readAllNotification,
    addTableNotification,
    readNotificationByInstanceId
} = tableNotifications.actions

export default tableNotifications.reducer