import React, { useEffect, useState } from "react"

import FormItem from "../../components/form/FormItem";
import { createFieldsObject, getItems } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";

function OutgoingFilter(props) {
    const fields = ['doc_name', 'contragent', 'organisation', 'output_number', 'register_date', 'output_doc_type', 'source', 'responsible', 'motiv_number', 'description'],
        [items, setItems] = useState([])

    useEffect(() => {
        console.log('props.options', props.options)
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        setItems(createFieldsObject(data.actions.POST))
    }

    return (
        <>
            {Object.keys(items).length > 0 ? fields.map((key) => {
                const value = props.values.find(item => item[key] !== undefined)
                const new_field = items[key]
                // if (new_field.name === 'source')
                //     new_field.type = 'checkbox'
                // return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''}  />
                if (items[key].name === 'contragent') {
                    return <SearchContragent key={key} field={new_field} required={false} value={value ? value[key] : ''} canAdd={false} />
                } else {
                    return <FormItem key={key} field={new_field} required={false} value={value ? value[key] : ''} />

                }
            }) : <>загрузка</>}
        </>
    )
}

export default OutgoingFilter