import React from 'react'
import { FileItem, InfoItem } from '../../components/InfoItem'
import PersonalAccesses from '../../components/form/PersonalAccesses/PersonalAccesses'
import FormItem from '../../components/form/FormItem'
import DocumentAddFormItem from '../../components/form/DocumentAddFormItem'

const EventCalendarDetail = (props) => {

  return (
    <>
      <div className={"page__info-container"}>
        <InfoItem item={props.fields.name} classes="_full" />
        <InfoItem item={props.fields.start_date} classes="_full" />
        <InfoItem item={props.fields.start_time} classes="_full" />
        <InfoItem item={props.fields.description} classes="_full" />
        <InfoItem item={props.fields.meeting_type} classes="_full" />
        <InfoItem item={props.fields.place} classes="_full" />
        <InfoItem item={props.fields.creator} classes="_full" />
        <FileItem item={props.fields.files} classes="_full" />
      </div>

      <PersonalAccesses url={props.url} fields={props.fields} absolut_save_btn={false} page_name={props.name}>
        <div className="d-none">
          <FormItem field={props.fields.name} />
          <FormItem field={props.fields.start_date} />
          <FormItem field={props.fields.start_time} />
          <FormItem field={props.fields.description} />
          <FormItem field={props.fields.meeting_type} />
          <FormItem field={props.fields.place} />
          <DocumentAddFormItem field={props.fields.files} classes="_full" />
        </div>
      </PersonalAccesses>
    </>

  )
}

export default EventCalendarDetail