import React from "react"

function Lightbox({active, children, style, classes, onClick, noClose=false }) {
    console.log("noClose", noClose);
    return (
        <div className={active ? "modal active" : "modal"} style={style} onClick={noClose ? ()=>{} : onClick}>
            <div className={`modal__content  ${classes ? classes : '_lightbox'}`} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Lightbox