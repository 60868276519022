import React, { useState, useEffect } from "react"
import FormItem from "./FormItem";

function DocumentSourceItem(props) {
    const [value, setValue] = useState(props.value ? props.value : props.field.value ? props.field.value : props.required ? props.field.choices[0].value : '')
        , [error, setError] = useState(props.field.error ? props.field.error : '')
    return (<>
            <div className={"form__item " + props.classes}>
                <span className="error-message">{error && props.error}</span>
                <div>
                    {props.field.choices.map((choice, idx) => {
                        return (
                            <div className="form__radio" key={idx}>
                                <input id={`${props.field.name}-${idx}`} type="radio" name={props.field.name} onChange={e => setValue(e.target.value)} value={choice.value} checked={value.toString() === choice.value.toString() ? 'checked' : false}/>
                                <label htmlFor={`${props.field.name}-${idx}`}>{choice.display_name}</label>
                            </div>
                        )
                    })}
                </div>
                <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
            </div>
            {value === 'Почтовое отправление'
                ? <FormItem field={props.second_field} classes="_full"/>
                : <></>
            }
        </>
    )
}

export default DocumentSourceItem