const apiConfig = {
    baseUrl: process.env.REACT_APP_BASE_URL + '/api/',              // 'https://collback.bik31.ru' + '/api/',
    baseUrlGeo: process.env.REACT_APP_BASE_URL + '/api/',           // 'https://collback.bik31.ru/api/',
    baseUrlChat: process.env.REACT_APP_BASE_URL + '/api/',          // 'https://collback.bik31.ru/api/',
    baseUrlMedia: process.env.REACT_APP_BASE_URL,                   // 'https://collback.bik31.ru/',
    baseUrlMediaGeo: process.env.REACT_APP_BASE_URL + '/api/geo/',  // 'https://collback.bik31.ru/api/geo/',
    webSoketChat: process.env.REACT_APP_WS_URL + '/ws/',            // 'wss://collback.bik31.ru/ws/', 
    apiKey: ''
}

export default apiConfig;