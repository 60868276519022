import { API_AGREEMENT_MODEL } from "../const";
import apiConfig from "../../apiConfig";
import axiosClient from '../../axiosClient';

export const agreementGet = async (id) => {
    let methodUrl = [API_AGREEMENT_MODEL.url, API_AGREEMENT_MODEL.methods.userLogin].join('/');
  
    if (id) {
        methodUrl += `${id}`;
    }

    try {
        const resp = await axiosClient.get(apiConfig.baseUrl + methodUrl);
        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};