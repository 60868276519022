import React, { useState } from "react"
import { Link, useOutletContext } from "react-router-dom";
import Search from "./Search";
import NotificationCenter from "./NotificationCenter";
import { useDispatch, useSelector } from "react-redux";
import { setOnlyNew } from "../store/filtersSlice";
import Switch from "./form/Switch"



function Header({ title = 'Пустая страница', name, count = false, Filter = () => <></>, create = false, search, searchHandler, searchClear, only_new }) {
    const dispatch = useDispatch()
    const onlyNew = useSelector(state => state.filters_data.onlyNew)
    const setFilterOnlyNew = (name, value) => dispatch(setOnlyNew([name, value]))
    const tablePageData = useSelector(state => state.table_page.tablePageData)

    return (
        <div className="header">
            <div className="title">
                <span className={`title__icon _${name}`}></span>
                <h1 className="title__text">{title}</h1>
                {tablePageData.count !== null ? <span className="title__count">{tablePageData.count}</span> : ''}
            </div>
            {search ?
                <Search searchHandler={searchHandler} searchClear={searchClear} /> :
                <div></div>
            }
            {only_new ?
                <Switch label={'Только новые'} value={onlyNew[name]} onChange={() => setFilterOnlyNew(name, !onlyNew[name])} /> :
                <></>
            }
            <div className="control">
                {Filter ? <Filter /> : <></>}
                {create && create.title ? <Link to={create.url ? create.url : 'create'} className="btn">{create.title}</Link> : ''}
                <NotificationCenter />
            </div>
        </div>
    )
}

export default Header