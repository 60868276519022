import React, { useState, useEffect } from "react";
import userDelete from "/public/page_icons/usersDelete.svg";
import addUserImg from "/public/page_icons/addUser.svg";
import levelDeleteImg from "/public/page_icons/levelDelete.svg";
import addLevelImg from "/public/page_icons/addLevel.svg";
import arrowDown from "/public/page_icons/arrowDown.svg";
import { agreementGet } from "../../api/agreement/agreementGet/agreementGet";

const ApprovalSheet = (props) => {
    const [data, setData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [usersData, setUsersData] = useState([{ level: 1, rel_users: [] }]);
    const [isAddUserFocused, setIsAddUserFocused] = useState(null);
    const [clearValue, setClearValue] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUserList, setShowUserList] = useState(true);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        extractUsersData(props.field.choices);
    }, []);




    const extractUsersData = (jsonData) => {
        const allUsers = jsonData?.map((item) => ({
            id: item.value,
            name: item.display_name,
        }));

        setData(allUsers);
        setSearchResults([]);
    };

    const searchUsers = (event) => {
        const value = event.target.value.toLowerCase();

        setClearValue(event.target.value);

        if (!data) {
            return;
        }

        if (value.length === 0) {
            setShowUserList(false);
            setSearchResults([...data]);
            return;
        }

        const foundUsers = data.filter((user) => {
            const isUserAlreadySelected = usersData.some((userData) => {
                return userData.rel_users.some((u) => u.id === user.id);
            });
            return (
                user.name.toLowerCase().includes(value) &&
                !isUserAlreadySelected &&
                user.name !== props.initiator // Exclude the user with the same id as props.initiator
            );
        });

        setShowUserList(value.length > 0);
        setSearchResults([...foundUsers]);
    };

    // Функция добавления уровня
    const addLevel = (lengthLevel) => {
        if (usersData.length >= 5) {
            return;
        }

        const highestLevel = usersData.reduce((maxLevel, data) => {
            return data.level > maxLevel ? data.level : maxLevel;
        }, 0);

        let newUsersData;

        // Если передан аргумент lengthLevel, добавляем несколько уровней
        if (lengthLevel) {
            newUsersData = [...usersData];
            const newLevel = highestLevel + 1;

            for (let i = 0; i < lengthLevel; i++) {
                const nextLevel = newLevel + i;
                newUsersData.push({ level: nextLevel, rel_users: [] });
            }
        } else {
            const newLevel = highestLevel + 1;
            newUsersData = [...usersData, { level: newLevel, rel_users: [] }];
        }

        setUsersData(newUsersData);
    };



    // Функция добавления пользователя на определенный уровень
    const addUserToLevel = (level, user) => {
        setShowUserList(false);
        const newUserData = { id: user.id, name: user.name };
        setUsersData((prevData) => {
            return prevData.map((data) => {
                if (data.level === level) {
                    // Сохраняем предыдущее состояние и добавляем нового пользователя в rel_users
                    return { ...data, rel_users: [...data.rel_users, newUserData] };
                }
                return data;
            });
        });

        setIsAddUserFocused(null);
        setClearValue("");
        setSelectedUser(null);
    };

    useEffect(() => {
        if (props.value !== '') {
            addLevel(props.value.length);
            props.value.forEach((item) => {
                if (item.rel_users) {
                    item.rel_users.forEach((user) => {
                        const userName = user?.user?.first_name || "";
                        const userLastName = user?.user?.last_name || "";
                        const userPatronymic = user?.user?.patronymic || "";
                        const userObject = `${userLastName} ${userName} ${userPatronymic}`;
                        const userArray = { id: user?.user?.id, name: userObject };
                        addUserToLevel(item.level, userArray);
                    });
                }
            });
        }
    }, []);


    const deleteLevel = (level) => {
        const updatedUsersData = usersData.filter((data) => data.level < level);
        setUsersData(updatedUsersData);
    };

    const deleteUsers = (level, user) => {
        const updatedUsersData = usersData.map((data) => {
            if (data.level === level) {
                const filteredUsers = data.rel_users.filter((u) => u.id !== user.id);
                return { ...data, rel_users: filteredUsers };
            }
            return data;
        });

        setUsersData(updatedUsersData);
    };

    return (
        <>
            <input
                type="hidden"
                name={props.field.name}
                onChange={() => { }}
                value={JSON.stringify(usersData)}
            />
            <div className={`form__item ${props.classes} approval`}>
                <div className="approval__header" onClick={() => setIsOpen(!isOpen)}>
                    <h1>Лист согласования</h1>
                    <img
                        src={arrowDown}
                        className={`arrow ${isOpen && "active"}`}
                        alt="Стрелка"
                    />
                </div>
                <div className={`approval__container ${isOpen && "active"}`}>
                    <div className="approvalList">
                        {usersData.map(({ level, rel_users }) => (
                            <div key={level} className="approvalLevel">
                                <div className="levelContainer">
                                    <h3>Уровень {level}</h3>
                                    {level !== 1 && (
                                        <img
                                            src={levelDeleteImg}
                                            onClick={() => deleteLevel(level)}
                                            alt="Удалить уровень"
                                        />
                                    )}
                                </div>
                                <div className="userList">
                                    {rel_users.map((user, index) => (
                                        <b key={index}>
                                            {user.name}

                                            <img
                                                src={userDelete}
                                                onClick={() => deleteUsers(level, user)}
                                                alt="Удалить пользователя"
                                            />

                                        </b>
                                    ))}
                                </div>
                                {isAddUserFocused === level ? (
                                    <div>
                                        <input
                                            onChange={searchUsers}
                                            className="approval__input"
                                            placeholder="Лист согласования"
                                            autoFocus={isAddUserFocused === level}
                                            value={clearValue}
                                        />
                                        {showUserList && searchResults.length > 0 && (
                                            <div className="searchResults">
                                                {searchResults.map((user) => (
                                                    <b
                                                        key={user.id}
                                                        onClick={() => addUserToLevel(level, user)}
                                                    >
                                                        {user.name}
                                                    </b>
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                ) : (
                                    <p
                                        className="addUserLevel"
                                        onClick={() => setIsAddUserFocused(level)}
                                    >
                                        <img src={addUserImg} alt="Добавить сотрудника" />
                                        Добавить сотрудника
                                        {selectedUser && (
                                            <span className="selectedUser">{selectedUser}</span>
                                        )}
                                    </p>
                                )}
                            </div>
                        ))}
                        <div className="addLevelContainer" onClick={() => { addLevel() }}>
                            {usersData.length < 5 && (
                                <>
                                    <img src={addLevelImg} alt="Добавить уровень" />
                                    <p>Добавить уровень</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApprovalSheet;
