import apiConfig from "../../api/apiConfig";

const RECONNECT_TIMER = 5000 // ms
const PING_TIMER = 30000 // ms

class CustomWebSocket {
    constructor(url, receiveMessageHandler) {
        this.url = url
        this.receiveMessageHandler = receiveMessageHandler

        this.socket = null
        this.reconnectTimeout = null
        this.pingPongTimeout = null
        this.isPongCame = {state: false}

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.addActionListenerOnSocket = this.addActionListenerOnSocket.bind(this);
        this.removeActionListenerOnSocket = this.removeActionListenerOnSocket.bind(this);
    }

    getSocketState() {
        return this.socket.readyState
    }

    // Функции слушатели сокета
    handleOpen(event) {
        console.warn('WebSocket открыт');
    }

    handleClose(event) {
        console.warn('WebSocket закрыт');
    }

    handleError(event) {
        console.error('WebSocket ошибка', event);
    }

    handleMessage(event) {
        try {
            const receivedMessage = JSON.parse(event.data);
            if (receivedMessage?.type === 'pong') {
                this.isPongCame.state = true
            } else {
                this.receiveMessageHandler(receivedMessage, this.socket)
            }
        } catch (error) {
            console.error('handleMessageEvent error ', error);
            console.error('handleMessageEvent ', event);
        }
    }

    // Добавляет функции слушатели для сокета s
    addActionListenerOnSocket(s) {
        s.addEventListener('open', this.handleOpen);
        s.addEventListener('close', this.handleClose);
        s.addEventListener('error', this.handleError);
        s.addEventListener('message', this.handleMessage);
    }

    // Удаляет функции слушатели для сокета s
    removeActionListenerOnSocket(s) {
        s.removeEventListener('open', this.handleOpen);
        s.removeEventListener('close', this.handleClose);
        s.removeEventListener('error', this.handleError);
        s.removeEventListener('message', this.handleMessage);
    }

    startPingPongTimeout() {
        this.pingPongTimeout = setTimeout(async () => {
            this.sendMessage(JSON.stringify({type: "ping"}))
            this.startReconnectTimeout()
        }, PING_TIMER)
    }

    dropPingPongTimeout() {
        if (this.pingPongTimeout) {
            clearTimeout(this.pingPongTimeout)
            this.pingPongTimeout = null
        }
    }

    startReconnectTimeout() {
        this.reconnectTimeout = setTimeout(async () => {
            if (!this.isPongCame.state) {
                this.dropPingPongTimeout()
                await this.reconnect()
            } else {
                this.startPingPongTimeout()
            }
            this.isPongCame.state = false
        }, RECONNECT_TIMER)
    }

    dropReconnectTimeout() {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout)
            this.reconnectTimeout = null
        }
    }

    async connect() {
        if (!(this.url)) {
            return false
        }
        this.socket = await new WebSocket(`${this.url}`)
        this.addActionListenerOnSocket(this.socket)
        this.startPingPongTimeout()
        return true
    }

    async close() {
        console.warn('close: this.socket', this.socket)
        if (this.socket) {
            this.dropReconnectTimeout()
            this.dropPingPongTimeout()
            this.removeActionListenerOnSocket(this.socket)
            await this.socket.close()
            console.warn('!!!!!!!!!!!!!!!socket closed!!!!!!!!!!!!!!!!!!!!!!', this.socket)
            this.socket = null
            return true
        }

        return false
    }

    async reconnect() {
        await this.close()
        await this.connect()
    }

    sendMessage(message) {
        if (this.socket)
            this.socket.send(message)
    }

    async destroy() {
        this.dropPingPongTimeout()
        this.dropReconnectTimeout()
        await this.close()
    }
}

export default CustomWebSocket