import React, {useEffect, useState} from 'react'
import ChatList from './ChatList/ChatList'
import ChatActiveComponent from './ChatActiveComponent/ChatActiveComponent'
import {useSelector} from 'react-redux';

const ChatPage = () => {
  const activeChat = useSelector((state) => state.activeChat);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024)

  useEffect(() => {
    const handelResize = () => {
      setIsMobile(window.innerWidth <= 1024)
    }

    window.addEventListener('resize', handelResize)

    return () => {
      window.removeEventListener('resize', handelResize)
    }
  }, []);

  return (
    <div className='chat-page'>
      {isMobile ? !activeChat && <ChatList/> : <ChatList/> }
      {isMobile ? activeChat && <ChatActiveComponent/> : <ChatActiveComponent/> }
    </div>
  )
}

export default ChatPage
