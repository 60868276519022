import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";

function SimpleArchiveTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _simple_archive">
                <thead>
                    <tr>
                        <th className="">Наименование</th>
                        <th>Автор</th>
                        <th className="_center">Дата создания</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                {item.title ?
                                    <td><Link to={`detail/${item.id}`} className="table__link">{item.title}</Link></td> : <td></td>
                                }
                                {item.author ?
                                    <td>{item.author.last_name ? item.author.last_name : ""} {item.author?.first_name ? item.author.first_name[0].toUpperCase() : ""}.{item.author?.patronymic ? item.author.patronymic[0].toUpperCase() : ""}.</td>
                                    : <td></td>
                                }
                                {item.date_of_creation ?
                                    <td className="_center">{item.date_of_creation}</td> : <td></td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}
export default SimpleArchiveTable;