import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications, getDomain } from "../../functions/functions";
import LinkDownload from "../../components/LinkDownload";
import SortingCell from "../../components/SortingCell";

function AgreementTable(props) {
  const { items, name, notifications, tableLoading } = props;
  const page_notifications = notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
  return (
    <>
      <table className={`table ${name === 'my-agreement' ? '_my_agreement' : '_agreement'}`}>
        <thead>
          <tr>
            <SortingCell classes="" title={'Наименование договора'} name={'doc_name'} page_name={name} />
            <SortingCell classes="_center" title={'Дата согласования'} name={'date_of_approval'} page_name={name} />
            <SortingCell classes="_center" title={'Номер договора'} name={'doc_num'} page_name={name} />
            <th className="_center">Скан документа</th>
            <SortingCell classes="_center" title={'Фонд/БИК'} name={'organisation'} page_name={name} />
            <SortingCell classes="" title={'Инициатор'} name={'initiator'} page_name={name} />
            <SortingCell classes="" title={'Контрагент'} name={'contragent'} page_name={name} />
            {name === 'my-agreement' && <SortingCell classes="" title={'Мой статус'} name={'user_status_agreement'} page_name={name} />}
            <SortingCell classes="_center" title={'Статус'} name={'status'} page_name={name} />
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => {
            const notify = checkNotifications(item.id, page_notifications);

            return (
              <tr key={idx} className={notify ? '_notify-row' : ''}>
                <td><Link to={`detail/${item.id}`} className="table__link">{item.doc_name}</Link></td>
                <td className="_center">{item.date_of_approval}</td>
                <td className="_center">{item.doc_num}</td>
                <td className="_center">
                  {item.file ? <LinkDownload url={getDomain() + item.file} /> : <></>}
                </td>
                <td className="_center">{item.organisation ? item.organisation.name : ''}</td>
                <td className="_center">
                  {item.initiator ? `${item.initiator.last_name || ''} ${item.initiator?.first_name ? item.initiator.first_name[0] : ''}.${item.initiator?.patronymic ? item.initiator.patronymic[0] : ''}.` : ''}
                </td>
                <td>{item.contragent ? item.contragent.org_name || item.contragent.fio : ''}</td>
                {name === 'my-agreement' && 
                (
                    item.user_status_agreement ?
                    <td className="_center _document-status" style={{ backgroundColor: item.user_status_agreement.color || '#919399', color: 'white' }}>
                        {item.user_status_agreement.status}
                    </td>
                    :
                    <td></td>
                )
                }
                {item.status ?
                  <td className="_center _document-status" style={{ backgroundColor: item.status.color_text || '#919399', color: 'white' }}>
                    {item.status.status}
                  </td>
                  :
                  <td></td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      {tableLoading && items.length === 0 && tableLoading}
    </>
  );
}

export default AgreementTable;
