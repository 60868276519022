import React, { useState, useEffect } from 'react'
import { logOut } from '../functions/functions'

import LogoutIcon from '/public/interface_img/log-out.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setNavStateItem } from '../store/navSlice';
import NavArrowIcon from '/public/interface_img/arrow-circle.svg'

function Footer() {
    const [items, setItems] = useState({})
    , navState = useSelector(state => state.nav_state.navState)
    , dispatch = useDispatch()
    , setNavState = (key, value) => dispatch(setNavStateItem([key, value]))

    useEffect(() => {
        const obj = {}
        const name = localStorage.getItem('user_name');
        const organisation = localStorage.getItem('organisation');
        const post = localStorage.getItem('post');
        if (name)
            obj.name = name
        if (organisation)
            obj.organisation = organisation
        if (post)
            obj.post = post
        setItems(obj);
    }, []);

    return (
        <footer className="footer">
            <hr/>
            <span onClick={() => setNavState('nav_active', !navState.nav_active)} className='nav__close-btn'>
                <img src={NavArrowIcon} />
                <span>Свернуть</span>
            </span>

            <span className="footer__name">{items.name}</span>
            <span className="footer__name-min">{`${items?.name?.slice(0, 1)}${items?.name?.split('.')[0].slice(-1)}`}</span>
            <span className="footer__position">{items.post}</span>
            <span className="footer__work-place">{items.organisation}</span>
            <a onClick={e => logOut(e)} className="footer__logout">
                <img src={LogoutIcon} />
                <span className="nav__title">Выход</span>
            </a>
        </footer>
    )
}

export default Footer