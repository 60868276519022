import React, { useState, useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"

import Alert from "./Allert";
import Pagination from "./Pagination";
import { getDomain, getItems } from "../functions/functions";
import WebSocketComponent from "../functions/openSocket";
import ContentLoader from "./ContentLoader";
import { setCountNum, setUrls } from "../store/tablePageSlice";
import { setNotification } from "../store/tableNotification";

const Table = (props) => {

    const [items, setItems] = useState([])
        , userData = useSelector(state => state.user_data.userData)
        , [pagination, setPagination] = useState({
            next: '',
            previous: '',
        })
        , [tableLoading, setTableLoading] = useState(() => <ContentLoader />)
        , [loader, setLoader] = useState(false)
        , searchData = useSelector(state => state.search_data.searchData)
        , filtersData = useSelector(state => state.filters_data.filtersData)
        , onlyNew = useSelector(state => state.filters_data.onlyNew)
        , sortData = useSelector(state => state.filters_data.sortData)
        , tablePageData = useSelector(state => state.table_page.tablePageData)
        , dispatch = useDispatch()
        , setInitialUrl = (model_name, url) => dispatch(setUrls([model_name, url]))
        , setCount = (num) => dispatch(setCountNum(num))
        , pathname = useLocation().pathname
        , location = useLocation()
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        }),
        setTableNotifications = (model_name, notifications) => dispatch(setNotification({
            model_name: model_name,
            tableNotifications: notifications
        }))
        , [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const notifications = useSelector(state => state.tableNotification.tableNotifications)

    const dispatcher = useDispatch()
    //const readNavNotificationSignal = (model_name) => dispatcher(readNavNotification(model_name))

    const getTableNotifications = () => {
        const notificationsURL = new URL(`api/notifications/?model_name=${props.model_name}&owner_id=${userData.user_id}`, getDomain());
        fetch(notificationsURL.href, {
            method: "GET",
        }).then(response => response.json())
            .then((resultNotifications) => {
                setTableNotifications(props.model_name, resultNotifications.notifications)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        // console.info('Table -------- start')
        setTableLoading(() => <ContentLoader />)
        setItems([])

        const runDataTimeout = setTimeout(() => {
            if (props.url) {
                getFilteredItems()
            } else {
                setTableLoading(false)
                setAlert({
                    title: 'Ошибка. Нет данных.',
                    text: 'Вы не зарегистрированы в системе контроля доступа. Обратитесь в it-отдел.',
                    type: 'error'
                })
            }
        }, 1000)

        return () => {
            clearTimeout(runDataTimeout);
            setTableLoading(() => <ContentLoader />)
            setItems([])
            setCount(null)
        }
    }, [pathname, sortData, filtersData, searchData, ignored, onlyNew]) // pathname window.location.href

    useEffect(() => {
        if (location.state ? location.state.message && true : false) {
            const m = location.state.message
            setAlert({ title: m.title ? m.title : '', text: m.text ? m.text : '', type: m.type ? m.type : 'success' })
        }
        getTableNotifications()
    }, [])

    const createUrlParams = (arr) => {
        let url_params = props.is_my ? `&is_my=${userData["user_id"]}` : ''
        for (let param of arr) {
            for (let key of Object.keys(param)) {
                url_params += `&${key}=${param[key]}`
            }
        }
        return url_params
    }

    // for new data replace items
    const setData = (data) => {
        const arr = data.results
        updateItems(data, arr)
        // console.log('data', data)
        if (data.results.length > 0) {
            setTableLoading(false)
        } else {
            setTableLoading('По этому запросу ничего не найдено')
        }
    }

    const updateItems = (data, arr) => {
        setItems(arr)
        pagination.next = data.next
        pagination.previous = data.previous
        setInitialUrl(props.name, pagination.next) // save page
        setPagination(pagination)
        setCount(data.count)
        if (!data.results) {
            return setTableLoading(data[Object.keys(data)[0]])
        }
        if (data.results.length === 0)
            setTableLoading(false)
    }

    const getFilteredItems = () => {

        const params = createUrlParams(filtersData[props.name] || [])
        const formParams = new URLSearchParams(params)

        if (sortData[props.name])
            formParams.set('ordering', sortData[props.name])
        else
            formParams.delete('ordering')
        if (searchData.search)
            formParams.set('search', searchData.search)
        else
            formParams.delete('search')

        if (tablePageData.init_limit[props.name]) {
            formParams.set('limit', tablePageData.init_limit[props.name])
            formParams.set('offset', 0)
        }
        if (onlyNew[props.name])
            formParams.set('on_notify', 'true')
        else
            formParams.delete('on_notify')

        const url = `${props.url}&${formParams.toString()}`
        
        setItems([])
        setTableLoading(() => <ContentLoader />)
        setAlert({ title: '', text: '', type: '', })
        getItems(url, "GET", setData, false, (error) => {
            setTableLoading(false)
            setAlert({
                title: 'Ошибка загрузки',
                text: error, //'При загрузке произошла ошибка, попробуйте обновить страницу',
                type: 'error', // error, alert, info, success
            })
        })
    }

    const paginate = (e, url) => {
        e.preventDefault()
        setLoader(true)
        getItems(url.toString(), 'GET', DataLoading)
    }

    // end of button loader
    const DataLoading = (data) => {
        addData(data)
        setLoader(false)
    }

    // for pagination adding items
    const addData = (data) => {
        const add_items = items.concat(data.results)
        updateItems(data, add_items)
    }

    // const fastAddItem = (notofication) => {
    //     let url = props.url + "id=" + notofication.instance_id;
    //     getItems(url, "GET", (data) => {
    //         if (data.results.length > 0) {
    //             const new_item = data.results[0];
    //             const items = props.items
    //             items.pop();
    //             items.unshift(new_item);
    //             props.setItems([...items]);
    //         }
    //     })
    // };

    // const fastUpdateItem = (notofication) => {
    //     let url = props.url + "id=" + notofication.instance_id;
    //     getItems(url, "GET", (data) => {
    //         if (data.results.length > 0) {
    //             const new_item = data.results[0];
    //             const items = props.items
    //             const index = items.findIndex(item => item.id === new_item.id)
    //             if (index !== -1) {
    //                 items[index] = new_item
    //                 props.setItems(items);
    //             }
    //         }
    //     })
    // };

    const getNewMessage = (data) => {
        if (props.model_name.toLowerCase() === data.model_name?.toLowerCase() || props.model_name.toLowerCase() === data.model_class?.toLowerCase()) {
            forceUpdate();
            getTableNotifications()
        }
    }

    return (
        <div className="page__content">
            <Alert setAlert={setAlert} alert={alert} />
            <WebSocketComponent handleMessageEvent={(e) => {
                getNewMessage(e);
            }} />

            <div className="table-wrap">
                <props.component
                    tableLoading={tableLoading}
                    name={props.name}
                    notifications={notifications}
                    filter={props.filter}
                    items={items}
                    model_name={props.model_name}
                />
            </div>
            <Pagination
                pagination={pagination}
                paginate={paginate}
                loader={loader}
                name={props.name}
            />
        </div>
    )
}

export default Table