import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getChats} from '../../../api/chat/chat';
import imgSubmit from "/public/page_icons/send.svg";
import attachment from "/public/page_icons/attachment.svg";
import {getDateTime, getDayMounthTime, getFileName} from '../../../functions/functions';
import {getChatRoom} from '../../../api/chat/getChatRoom';
import apiConfig from '../../../api/apiConfig';
import file from "/public/page_icons/file.svg";
import x_close from "/public/page_icons/x-close.svg";
import file_04 from "/public/page_icons/file-04.svg";
import reply_blue from "/public/page_icons/reply-blue.svg";
import emoji from "/public/page_icons/emoji.svg";
import checkMessage from "/public/page_icons/CheckMessage.svg";
import Checked from "/public/page_icons/Checked.svg";
import IconPhone from "/public/page_icons/IconPhone.svg";
import ModalGroupChat from '../ModalGroupChat/ModalGroupChat';
import EmojiPicker from 'emoji-picker-react';
import ModalFilePicker from '../ModalFilePicker/ModalFilePicker';
import {setActiveChat} from '../../../store/chatReducer';
import backArrow from "/public/page_icons/arrow-right.svg";
import MessageContextMenu from '../MessageContextMenu/MessageContextMenu';
import {Mention} from 'primereact/mention';
import {useBeforeUnload} from '../../../functions/useBeforeUnload';
import Lightbox from "../../../components/Lightbox";
import ChatList from "../ChatList/ChatList";
import {setForwardedMessage} from '../../../store/chatForwardedMessage';
import CustomWebSocket from "../../../components/CustomWebSocket/WebSocket";
import ChatConfirmDeleteModal from "../ConfirmDelete/ChatConfirmDeleteModal";

const ChatActiveComponent = () => {
    const [message, setMessage] = useState('');
    const [openModalGroup, setOpenModalGroup] = useState(false);
    const [openFilePicker, setOpenFilePicker] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [dataChat, setDataChat] = useState();
    const [showEmoji, setShowEmoji] = useState(false);
    const [isPage, setIsPage] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [activeMessage, setActiveMessage] = useState();
    const [replyMessage, setReplyMessage] = useState(null);
    const [lastMessage, setLastMessage] = useState();
    const [activeAllerts, setActiveAllerts] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [customers, setCustomers] = useState([]);
    const mentionedMessageRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024)
    const [isActiveModal, setIsActiveModal] = useState(false)
    const [modalStyle, setModalStyle] = useState()
    const [socket, setSocket] = useState(null);

    const activeChat = useSelector((state) => state.activeChat);
    const userData = useSelector((state) => state.user_data.userData);
    const forwardedMessage = useSelector(state => state.chatForwardedMessage.forwardedMessage)
    const _setForwardedMessage = (message) => dispatch(setForwardedMessage(message))
    const dispatch = useDispatch()

    useEffect(() => {
        const handelResize = () => {
            setIsMobile(window.innerWidth <= 1024)
        }

        window.addEventListener('resize', handelResize)

        return () => {
            window.removeEventListener('resize', handelResize)
        }
    }, []);


    useEffect(() => {
        setCustomers([])
        if (dataChat && Array.isArray(dataChat.receivers_rel)) {
            const newSuggestions = dataChat.receivers_rel.map((item) => ({
                id: item.id,
                nickname: item.user.username,
                img: item.user.file,
                display: `${item.user?.last_name}_${item.user?.first_name}_${item.user?.patronymic}`,
            }));
            setCustomers((prev) => [...prev, ...newSuggestions]);
            dataChatIdRef.current = dataChat?.id
        }
    }, [dataChat]);

    useEffect(() => {
        return () => {
            if (socket)
                socket.destroy()
        }
    }, [socket]);

    const receivedMessageHandler = (data, socket) => {
        const receivedMessage = data

        if (receivedMessage?.type === 'message_deleted') {
            setChatMessages(prevState => {
                let newChatMessage = [...prevState]
                const changedMessageIndex = newChatMessage.findIndex(item => item.id === receivedMessage.message_id)
                newChatMessage[changedMessageIndex].is_available = false
                return newChatMessage
            })
        }

        if (receivedMessage.sender.id !== Number(userData.user_id)) {

            if (receivedMessage?.been_read === false) {
                const messageData = {
                    message_id: receivedMessage.id,
                    message_type: 'chat_event'
                };
                socket.send(JSON.stringify(messageData));
                setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);

            } else if (receivedMessage?.message_type === 'chat_event') {

                setChatMessages((prevMessages) => {
                    return prevMessages.map((item) => {
                        if (receivedMessage?.message_id === item?.id) {
                            return {...item, been_read: receivedMessage.read};
                        } else {
                            return item;
                        }
                    });
                });
            } else {
                setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);
            }

            if (chatContentRef.current) {
                const {scrollTop, scrollHeight, clientHeight} = chatContentRef.current;
                const delta = 250
                if (scrollTop + clientHeight + delta >= scrollHeight) {
                    setTimeout(() => {
                        scrollBottom()
                    }, 100);
                }
            }

        } else {

            setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);
            if (receivedMessage?.been_read) {
                setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);

            } else if (receivedMessage?.message_type === 'chat_event') {
                setChatMessages((prevMessages) => {
                    return prevMessages.map((item) => {
                        if (receivedMessage?.message_id === item?.id) {
                            return {...item, been_read: receivedMessage.read};
                        } else {
                            return item;
                        }
                    });
                });
            }

            setTimeout(() => {
                scrollBottom()
            }, 100);
        }
    }

    useEffect(() => {
        if (activeChat && userData) {
            // closeSocket();
            setDataChat([])
            setActiveAllerts([])
            setActiveMessage(null)
            setMessage('')

            getChats(activeChat.id ? {receiver: activeChat.id} : {chat_id: activeChat}, userData.id).then(async (resp) => {
                if (resp.success) {
                    setDataChat(resp.data)
                    setChatMessages([...resp?.data?.messages_rel])

                    // создание сокета
                    if (socket)
                        socket.destroy()
                    const newSocket = await new CustomWebSocket(`${apiConfig.webSoketChat}chat/${resp.data.id}/?token=${userData.token}`, receivedMessageHandler);
                    await newSocket.reconnect()
                    await setSocket(newSocket)

                    // С задержкой, чтобы успели отрисоваться элементы
                    setTimeout(() => {
                        scrollBottom()
                    }, 100);
                }
            });
        }

        return () => {
            if (socket)
                socket.destroy()
        }
    }, [activeChat, userData]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            sendMessage();
        }
    };
    const sendMessage = () => {
        if (socket && socket.getSocketState() === WebSocket.OPEN) {
            if (message.trim() !== '' || selectedFiles.length !== 0 || replyMessage?.text || replyMessage?.attachment?.length) {
                const messageData = {
                    message: message,
                    parent_message: replyMessage ? replyMessage.id : null,
                    attachment: selectedFiles.map(file => (
                        {
                            url: `${file.url.slice(7)}`,
                            original_name: file.original_name
                        }
                    )),  // Надо срезать '/media/' и отправить только имя файла
                    message_type: activeAllerts ? "chat_notify" : (activeMessage ? 'chat_answer' : null),
                    chat_id: (activeAllerts || activeMessage) ? dataChat.id : null,
                    user_id: activeAllerts ? activeAllerts.map(item => item) : (activeMessage ? [activeMessage.sender.id] : null),
                    message_id: activeMessage && activeMessage.id
                };

                socket.sendMessage(JSON.stringify(messageData));

                setMessage('');
                setSelectedFiles([]);
                setActiveMessage(null)
                setActiveAllerts([])
                setReplyMessage(null)
            }
        }
    };

    const addFile = (urls) => {
        setSelectedFiles([...selectedFiles, ...urls]);
    }

    const deleteFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const chatContentRef = useRef(null);


    const handleScroll = async (e) => {
        const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;

        setContextMenuPosition({top: 0, left: 0})
        if (lockScroll) {
            setLockScroll(true)
            return
        }

        if (scrollTop === 0) {
            const newMessages = await getNextMessages(isPage)
            if (newMessages.length > 0) {
                setIsPage(isPage + 1)
                setChatMessages([...chatMessages, ...newMessages])

                setTimeout(() => {
                    scrollToMessageById({id: newMessages[0].id, isHighlight: false})
                }, 100);
            }
        }
    };

    const emojiSelect = (item) => {
        setMessage(text => text + item.emoji)
    }

    const emojiPickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && event.target.id !== 'emojiButtonContainer') {
                setShowEmoji(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiPickerRef]);

    const handleDragOver = (e) => {
        e.preventDefault();
        setOpenFilePicker(true);
    };

    const handleDrop = (e) => {
        setOpenFilePicker(false);
    };

    const [contextMenuPosition, setContextMenuPosition] = useState({top: 0, left: 0});

    const handleMessageContextMenu = (event, message) => {
        event.preventDefault();
        const {clientX, clientY} = event;
        setContextMenuPosition({top: clientY, left: clientX});
        setActiveMessage(message)
    };

    const closeContextMenu = () => {
        setContextMenuPosition({top: 0, left: 0});
    };

    const handleActionContext = (context) => {
        console.log(context)
        if (context === 'copy') {
            const copyText = activeMessage.text
            navigator.clipboard.writeText(copyText);
        } else if (context === 'reply') {
            setReplyMessage(activeMessage)
        } else if (context === 'sendMessage') {
            openModal()
        } else if (context === 'deleteMessage') {
            setIsShowConfirmDeleteModal(true)

            // const deleteMessageFetch = {
            //     type: "delete_message",
            //     message_id: activeMessage.id,
            // }
            // socket.sendMessage(JSON.stringify(deleteMessageFetch))
        }
    }

    const deleteReply = () => {
        setReplyMessage(null)
    }

    useEffect(() => {
        const firstOnlineMessage = chatMessages.find(item => item && 'online' in item);
        setLastMessage(firstOnlineMessage);
    }, [chatMessages]);

    const handleMessage = (event) => {
        setMessage(event);
    }

    const handleActiveAllert = (item) => {
        setActiveAllerts((prev) => [...prev, item]);
    }

    function safelyDecodeURI(uri) {
        try {

            const fileExtensionRegex = /\.[0-9a-z]+$/i;
            if (fileExtensionRegex.test(uri)) {
                return decodeURIComponent(uri);
            } else {
                return uri;
            }
            // return decodeURIComponent(uri);
        } catch (error) {
            return uri;
        }
    }

    const onSearch = (event) => {
        const regex = /\s@[\s\S]*$|\s$/
        if (message.length !== 0 && !regex.test(message))
            return

        setTimeout(() => {
            const query = event.query;
            let suggestions;

            if (!query.trim().length) {
                suggestions = [...customers];
            } else {
                suggestions = customers.filter((customer) => {
                    return customer.display.toLowerCase().startsWith(query.toLowerCase());
                });
            }

            setSuggestions(suggestions);
        }, 250);
    }

    const itemTemplate = useMemo(() => (suggestion) => {
        return (

            <div className='allertItem' onClick={() => {
                handleActiveAllert(suggestion.id)
            }}>
                <img alt={suggestion.img} src={suggestion.img} width="32"/>
                <p>
                    {suggestion.display}
                </p>
            </div>

        );
    }, [suggestions]);

    useBeforeUnload(() => {
        if (socket)
            socket.destroy()
        // closeSocket()
    })

    const convertText = (item) => {
        let resultText = item?.text

        const splitText = (text) => {
            const regex = /(?:^|\s)@([a-zA-Z0-9а-яА-я]+[ _][a-zA-Z0-9а-яА-я]+[ _][a-zA-Z0-9а-яА-я]+)|https?:\/\/[^\s/$.?#].[^\s]*|www\.[^\s/$.?#].[^\s]*/gi
            let result = [];
            let matches = text.matchAll(regex);
            let indexes = [0]

            for (const match of matches) {
                indexes.push(/\s/.test(text[match.index]) ? match.index + 1 : match.index)
                indexes.push(match.index + match[0].length)
            }
            indexes.push(text.length)
            for (let i = 1; i < indexes.length; i++) {
                result.push(text.substring(indexes[i - 1], indexes[i]))
            }

            return result.map(str => str.trim()).filter(str => str.length > 0);
        }

        const checkLink = (text) => {
            const regex = /https?:\/\/[^\s/$.?#].[^\s]*|www\.[^\s/$.?#].[^\s]*/i

            return regex.test(text)
        }

        const checkAddressSymbol = (text) => {
            const regex = /@([a-zA-Z0-9а-яА-я]+[ _][a-zA-Z0-9а-яА-я]+[ _][a-zA-Z0-9а-яА-я]+)|https?:\/\/[^\s/$.?#].[^\s]*|www\.[^\s/$.?#].[^\s]*/i

            return regex.test(text)
        }

        if (resultText) {
            let splitString = splitText(resultText)
            return (
                <>
                    {splitString.map((item, idx) => (
                        checkLink(item) ?
                            <a key={idx} href={item} className='ref-message-text' target='_blank'>{item}</a> :
                            checkAddressSymbol(item) ? <span key={idx} className="alert">{item}</span> :
                                <>{item}</>
                    ))}
                </>
            )
        } else {
            return <></>
        }
    }


    const closeModal = (e = null) => {
        e?.preventDefault()
        setIsActiveModal(false)
        setTimeout(() => setModalStyle({display: 'none'}), 300)
    }
    const openModal = (e = null) => {
        e?.preventDefault()
        setModalStyle({display: 'grid'})
        setTimeout(() => setIsActiveModal(true), 100)
    }

    const attachmentBlock = (message, isTextOrParentMessage = true) => {
        if (!message?.attachment)
            return <></>
        return message.attachment.map((item, index) => {
            return (
                <div className={'containerMessage'}>
                    <p className={`fileName`}>
                        <img src={file} className={'attachment-block-file-icon'}/>
                        <a href={item.file.startsWith('http') ? safelyDecodeURI(item.file) : `${apiConfig.baseUrlMedia}${safelyDecodeURI(item.file)}`}
                           target='_blank'
                           download>
                            {item?.original_name ? item?.original_name : getFileName(safelyDecodeURI(item.file))}
                        </a>
                    </p>

                    {!isTextOrParentMessage && index === 0 &&
                        <div className='messageDateTime'>
                            <p>{getDayMounthTime(message.timestamp)}</p>

                            <div className='reedMessage'>
                                {message?.been_read ?
                                    <img src={Checked}></img>
                                    :
                                    <img src={checkMessage}></img>
                                }
                            </div>
                        </div>
                    }
                </div>
            )
        })
    }

    const getMassageSender = (message) => {
        let isMyMessage = false
        let senderData = message?.sender
        if (senderData?.id === Number(userData?.user_id)) {
            isMyMessage = true
        }

        return [isMyMessage, senderData]
    }

    const parentMessageBlock = ({item = null, _patentMessage = null}) => {
        if (_patentMessage === null && item.parent_message === null)
            return <></>

        const parentMessage = _patentMessage ? _patentMessage : item.parent_message

        // if (!isValidMessage(parentMessage))
        //     return <></>

        const [isMyMessage, senderData] = getMassageSender(parentMessage)
        const isForwardMessage = parentMessage?.chat !== dataChat.id
        const styleName = isMyMessage ? 'myMessage' : 'friendMessage'
        const isAvailable = parentMessage.is_available
        console.log('parent message', isAvailable, parentMessage)
        return (
            <div className='parentContainer' onClick={() => {
                if (!isForwardMessage) {
                    scrollToParentMessage(parentMessage.id)
                }
            }}>
                <p className='titleParent'>
                    {isForwardMessage ?
                        `Пересланное сообщение`
                        :
                        `В ответ на сообщение ${senderData?.last_name} ${senderData?.first_name[0]}.${senderData?.patronymic[0]}.`
                    }
                </p>
                {isAvailable ?
                    parentMessage.text &&
                    <div className={`${styleName}Container`}>
                        <p className={`${styleName}Text`}>{convertText(parentMessage)}</p>
                    </div>
                    :
                    <div className={`${styleName}Container`}>
                        <p className={`${styleName}Text`}>
                            <p className={`deleted-message `}>Сообщение удалено пользователем <span
                                className={'deleted-message-user-name'}>{`${senderData?.last_name} ${senderData?.first_name} ${senderData?.patronymic}`}</span>
                            </p>
                        </p>
                    </div>
                }

                {attachmentBlock(parentMessage)}
            </div>)
    }

    const messageBlock = (message) => {
        const [isMyMessage, senderData] = getMassageSender(message)
        const isOneByOneChat = dataChat?.receivers_rel?.length === 0
        const styleName = isMyMessage ? 'myMessage' : 'friendMessage'
        const isTextOrParentMessage = message?.text?.length !== 0 || message?.parent_message

        const isAvailable = message?.is_available

        return (isAvailable ?
                <div
                    id={message.id}
                    className={styleName}
                    onContextMenu={(e) => handleMessageContextMenu(e, message)}
                    key={message.id}
                    ref={message.id === mentionedMessageRef.current?.id ? mentionedMessageRef : null}>
                    {!isOneByOneChat && <div className='messageHeader'>
                        <p className='messageUserName'>{isMyMessage ? 'Вы'
                            : `${senderData?.last_name} ${senderData?.first_name} ${senderData?.patronymic}`}</p>
                    </div>}

                    <div className='flexGroupChatMessage'>
                        {!isMyMessage && !isOneByOneChat && <img src={senderData?.file} className='chatCardImg_mini'/>}
                        <div className={'main'}>

                            {isTextOrParentMessage &&
                                <div className='containerMessage'>
                                    {parentMessageBlock({item: message})}
                                    <p className={`${styleName}Text`}>{convertText(message)}</p>

                                    <div className='messageDateTime'>
                                        <p>{getDayMounthTime(message.timestamp)}</p>

                                        <div className='reedMessage'>
                                            {message?.been_read ?
                                                <img src={Checked}></img>
                                                :
                                                <img src={checkMessage}></img>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {attachmentBlock(message, isTextOrParentMessage)}
                        </div>
                    </div>
                </div>
                :
                <div id={message.id} className={styleName} key={message.id}>
                    <div className='flexGroupChatMessage'>
                        <div className={'main'}>
                            <div className='containerMessage'>
                                <p className={`deleted-message `}>Сообщение удалено пользователем <span
                                    className={'deleted-message-user-name'}>{`${senderData?.last_name} ${senderData?.first_name} ${senderData?.patronymic}`}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    const relayMessageInputTextBlock = ({item = null, _patentMessage = null}) => {
        if (_patentMessage === null && item.parent_message === null)
            return <></>

        const parentMessage = _patentMessage ? _patentMessage : item.parent_message

        // if (!isValidMessage(parentMessage))
        //     return <></>

        const [isMyMessage, senderData] = getMassageSender(parentMessage)
        const isForwardMessage = parentMessage?.chat !== dataChat.id

        return (
            <div className='relayMain'>
                <p className='titleRelay'>
                    {isForwardMessage ?
                        `Пересланное сообщение`
                        :
                        `В ответ на сообщение ${senderData?.last_name} ${senderData?.first_name[0]}.${senderData?.patronymic[0]}.`
                    }
                </p>
                {
                    parentMessage.text && <p className={`relayMessageText`}>{convertText(parentMessage)}</p>
                }
                {
                    parentMessage.attachment?.length > 0 && attachmentBlock(parentMessage)
                }
            </div>)
    }

    const relayMessageInputBlock = () => {
        if (forwardedMessage) {
            setReplyMessage(forwardedMessage)
            _setForwardedMessage(null)
        }

        return replyMessage &&
            <div className='containerRelay'>
                <img src={reply_blue} className={'relayIcon'}/>
                {relayMessageInputTextBlock({_patentMessage: replyMessage})}
                <img src={x_close} className='deleteButtonReply' onClick={() => {
                    deleteReply()
                }}/>
            </div>
    }

    const dataChatIdRef = useRef(null)

    const getNextMessages = async (curPage) => {
        let result = []

        await getChatRoom(dataChatIdRef.current, `?page=${curPage + 1}&page_size=${25}`)
            .then((resp) => {
                if (resp.success) {
                    result = resp.data.messages_rel
                }
            })
            .catch((error) => {
                console.error('Ошибка при получении сообщений:', error);
                return [];
            });

        return result
    }

    const findMessageById = (id) => {
        return chatMessages.find((item) => item.id === id)
    }

    const tryGetMessageById = async (id) => {
        let res = findMessageById(id)

        if (res === undefined) {
            let curPage = isPage
            let additionMessage = []
            let nextMessage = await getNextMessages(curPage)

            while (nextMessage?.length > 0) {
                additionMessage = [...additionMessage, ...nextMessage]
                let foundedItem = nextMessage.find((item) => item.id === id)

                if (foundedItem !== undefined) {
                    res = foundedItem
                    break
                }

                curPage += 1
                nextMessage = await getNextMessages(curPage)
            }

            if (res !== undefined) {
                setChatMessages([...chatMessages, ...additionMessage])
            }
        }
        return res
    }

    const scrollBottom = () => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTo({
                top: chatContentRef.current.scrollHeight,
                behavior: "smooth"
            });
        } else {
            console.log('chatContentRef.current is null');
        }
    }

    const scrollToMessageById = ({
                                     id = null,
                                     isHighlight = true,
                                     scrollOptions = {behavior: 'smooth', block: 'end'}
                                 } = {}) => {
        if (chatContentRef?.current) {
            const allMessages = Array.from(chatContentRef.current.querySelectorAll('.myMessage, .friendMessage'));
            if (allMessages?.length > 0) {
                let message
                if (id === null) {
                    message = allMessages[allMessages.length - 1]
                } else {
                    message = allMessages.find((item) => Number(item.id) === id)
                }
                if (message) {
                    if (isHighlight) {
                        message.classList.add('highlight');
                        setTimeout(() => {
                            message.classList.remove('highlight');
                        }, 3000)
                    }
                    message.scrollIntoView(scrollOptions);
                }
            }
        }
    }

    const [lockScroll, setLockScroll] = useState(false)
    const scrollToParentMessage = async (parentMessageId) => {
        const res = await tryGetMessageById(parentMessageId)
        if (res !== undefined) {
            // С задержкой, чтобы успели отрисоваться элементы
            setTimeout(() => {
                setLockScroll(true)
                scrollToMessageById({scrollOptions: {behavior: 'auto'}});
                scrollToMessageById({id: parentMessageId});
            }, 100);
        }
    }
    // console.log(chatMessages, userData)
    const isOwnerMessage = (message) => {
        return Number(activeMessage.sender.id) === Number(userData.user_id)
    }

    const [isShowConfirmDeleteModal, setIsShowConfirmDeleteModal] = useState(false)

    const closeConfirmDeleteModal = () => {
        setIsShowConfirmDeleteModal(false)
    }

    const confirmDeleteMessage = () => {
        const deleteMessageFetch = {
            type: "delete_message",
            message_id: activeMessage.id,
        }
        socket.sendMessage(JSON.stringify(deleteMessageFetch))
        setIsShowConfirmDeleteModal(false)
    }

    return (
        <Fragment>
            <Lightbox active={isActiveModal} style={modalStyle} onClick={closeModal}>
                <ChatList additionActionOnClick={() => {
                    // console.log('got it')
                    _setForwardedMessage(activeMessage);
                    closeModal();
                }} hiddeAddGroupButton={true}/>
            </Lightbox>


            {openModalGroup && <ModalGroupChat onClose={() => {
                setOpenModalGroup(false)
            }} dataReceiver={dataChat} isShowButtonMinusPlus={false}/>}
            {activeChat && openFilePicker &&
                <ModalFilePicker onClose={() => {
                    handleDrop()
                }} addFile={(e) => addFile(e)}/>
            }
            <div className='chat'
                // onScroll={[handleScroll,handleScrollMove]}
                 onDragOver={handleDragOver}
                 onDrop={handleDrop}>
                <div className='chat__header'>
                    <div className='activeChatHeader'>
                        {(isMobile) && <img src={backArrow} className='chatBackArrow' onClick={() => {
                            dispatch(setActiveChat(null))
                        }}/>}
                        {activeChat &&
                            (dataChat?.chat_fill ?
                                <div className='circleChat' style={{backgroundColor: dataChat?.chat_fill}}>
                                    <p className='circlaNameChat'>{dataChat?.title?.charAt(0)}</p>
                                </div>
                                :
                                <img
                                    src={activeChat?.file?.startsWith('http') ? activeChat?.file : (apiConfig.baseUrlMedia + activeChat?.file)}
                                    className='chatCardImg'/>)
                        }

                        {activeChat && (
                            <div className='infoUserChat'>
                                <span className='chat-card__name'>
                                    {dataChat?.title ||
                                        <Fragment>
                                            {`${activeChat?.last_name || ''} ${activeChat?.first_name || ''} ${activeChat?.patronymic || ''} `}
                                            <span className='phoneChat'>
                                                <img src={IconPhone}></img>
                                                {`${activeChat?.internal_phone || ''}`}
                                            </span>
                                        </Fragment>
                                    }

                                </span>
                                <span className='chat-card__post'>
                                    {
                                        <Fragment>
                                            {lastMessage && dataChat.receiver && (
                                                <div
                                                    className={`indicatorOnline ${lastMessage?.is_online.includes(Number(userData.user_id)) && lastMessage?.is_online.includes(dataChat?.receiver?.id) ? 'online' : 'closed'}`}>

                                                </div>
                                            )}
                                            {lastMessage && dataChat.receiver && (
                                                lastMessage?.is_online.includes(Number(userData.user_id)) && lastMessage?.is_online.includes(dataChat?.receiver?.id) ?
                                                    <p className='inidicatorText'>В сети</p> :
                                                    <p className='inidicatorText'>Не в сети</p>
                                            )}
                                        </Fragment>
                                    }
                                    <span>{activeChat?.post_bik || activeChat?.post_fond || ''}</span>
                                </span>
                            </div>
                        )}
                    </div>

                    {activeChat && dataChat?.receivers_rel != 0 &&
                        <button className='chatGroupButton' onClick={() => {
                            setOpenModalGroup(true)
                        }}>Групповой чат</button>}
                </div>


                {activeChat && (
                    <Fragment>
                        <div className='chat__content' ref={chatContentRef} onScroll={handleScroll}>
                            {chatMessages.slice().reverse().map((item, index) => (
                                <Fragment key={index}>
                                    {contextMenuPosition.top !== 0 && (
                                        <MessageContextMenu
                                            top={contextMenuPosition.top}
                                            left={contextMenuPosition.left}
                                            onClose={closeContextMenu}
                                            onAction={(e) => {
                                                handleActionContext(e)
                                            }}
                                            isOwnerMessage={isOwnerMessage(item)}
                                        />
                                    )}
                                    {item && (
                                        !('online' in item || item.message_type === 'chat_event') && messageBlock(item, index)
                                    )}
                                </Fragment>
                            ))}
                        </div>
                        <ChatConfirmDeleteModal isShow={isShowConfirmDeleteModal}
                                                onCancel={closeConfirmDeleteModal}
                                                onSubmit={confirmDeleteMessage}/>
                        <div className='chatActiveFooterContainer'>
                            {relayMessageInputBlock()}
                            <div className='chatInputGroup'>
                                <button onClick={() => setOpenFilePicker(true)} className='buttonAttachmentSend'>
                                    <img src={attachment}/>
                                </button>
                                <Mention
                                    value={message}
                                    onChange={(e) => handleMessage(e.target.value)}
                                    suggestions={suggestions}
                                    field="display"
                                    placeholder="Сообщение"
                                    rows={5}
                                    cols={40}
                                    onSearch={onSearch}
                                    itemTemplate={itemTemplate}
                                    onKeyDown={handleKeyDown}
                                    autoResize={false}
                                    panelClassName={'allertContainer'}
                                />

                                <img id={'emojiButtonContainer'} src={emoji} className='emojiButton'
                                     onClick={() => {
                                         setShowEmoji(!showEmoji)
                                     }}></img>
                                {showEmoji &&
                                    <div className='emoji-container' ref={emojiPickerRef}>
                                        <EmojiPicker
                                            onEmojiClick={emojiSelect}
                                            emojiStyle={'native'}
                                            categories={[
                                                {
                                                    category: 'suggested',
                                                    name: 'Использованные'
                                                },
                                                {
                                                    category: 'smileys_people',
                                                    name: 'Смайлы'
                                                },
                                                {
                                                    category: 'animals_nature',
                                                    name: 'Животные & природа'
                                                },
                                                {
                                                    category: 'food_drink',
                                                    name: 'Еда & напитки'
                                                },
                                                {
                                                    category: 'travel_places',
                                                    name: 'Путешествия'
                                                },
                                                {
                                                    category: 'activities',
                                                    name: 'Активность'
                                                },
                                                {
                                                    category: 'objects',
                                                    name: 'Объекты'
                                                },
                                                {
                                                    category: 'symbols',
                                                    name: 'Символы'
                                                }
                                            ]}
                                            skinTonesDisabled={true}
                                            emojiVersion={"5.0"}
                                            searchPlaceholder={'Поиск'}
                                        />
                                    </div>
                                }
                                {/*</div>*/}
                                <button onClick={sendMessage} className='buttonSend'>
                                    <img src={imgSubmit}></img>
                                </button>
                            </div>

                            {selectedFiles?.length > 0 &&
                                <div
                                    className={`chatInputAttachmentBlock`}>
                                    {selectedFiles.map((item, index) => (
                                        <div key={index} className='fileContainer'>
                                            <img src={file_04}/>
                                            <p className='fileName'>{item.original_name}</p>

                                            <img src={x_close} className='trashButton' onClick={() => {
                                                deleteFile(index)
                                            }}></img>
                                        </div>
                                    ))}
                                </div>}
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};
export default ChatActiveComponent;


// Старый код, лучше пусть будет
// Заменен на relayMessage
// const [actionContext, setActionContext] = useState();
// const [filteredDataChat, setFilteredDataChat] = useState(dataChat);
// const [isScroll, setIsScroll] = useState(true);
// const fileInputRef = useRef(null);
// const notifications = useSelector(state => state.tableNotification.tableNotifications);
// const isMobile = window.innerWidth
// <= 767;
// const [openAlerts, setOpenAlerts] = useState(false);
// const [socket, setSocket] = useState(null);


// ===========================================================================================
// логика сокета
//     const closeSocket = () => {
//         if (socket) {
//             socket.close();
//             // setIsPage(0)
//             setChatMessages([])
//         }
//     };

// const setActionOnSocket = (newSocket) => {
//     newSocket.onmessage = (event) => {
//         const receivedMessage = JSON.parse(event.data);
//
//         if (receivedMessage.type === 'pong') {
//             // console.log('3333333333333333333333333333333333')
//             pingTimeOutRef.current = false;
//             return
//         }
//
//         if (receivedMessage.sender.id !== Number(userData.user_id)) {
//
//             if (receivedMessage.been_read === false) {
//                 const messageData = {
//                     message_id: receivedMessage.id,
//                     message_type: 'chat_event'
//                 };
//                 newSocket.send(JSON.stringify(messageData));
//                 setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);
//
//             } else if (receivedMessage.message_type === 'chat_event') {
//
//                 setChatMessages((prevMessages) => {
//                     return prevMessages.map((item) => {
//                         if (receivedMessage?.message_id === item?.id) {
//                             return {...item, been_read: receivedMessage.read};
//                         } else {
//                             return item;
//                         }
//                     });
//                 });
//             } else {
//                 setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);
//             }
//
//         } else {
//
//             setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);
//             if (receivedMessage.been_read) {
//                 setChatMessages((prevMessages) => [receivedMessage, ...prevMessages]);
//
//             } else if (receivedMessage.message_type === 'chat_event') {
//                 setChatMessages((prevMessages) => {
//                     return prevMessages.map((item) => {
//                         if (receivedMessage?.message_id === item?.id) {
//                             return {...item, been_read: receivedMessage.read};
//                         } else {
//                             return item;
//                         }
//                     });
//                 });
//             }
//
//             setTimeout(() => {
//                 scrollBottom()
//             }, 100);
//         }
//     };
//     newSocket.onclose = () => {
//         console.log('-------------------close socket')
//         // if (oncloseTimeoutReconnect.current) {
//         //     clearTimeout(oncloseTimeoutReconnect.current)
//         //     console.log('clear onclose timeout')
//         // }
//         // // Обработка закрытия сокета
//         // oncloseTimeoutReconnect.current = setTimeout(() => {
//         //     if (!(socketRef.current && socketRef.current.readyState === CustomWebSocket.OPEN)) {
//         //         console.log('onclose timeout work')
//         //         reconnectSocket()
//         //     }
//         // }, 5000)
//     };
//     newSocket.onerror = (error) => {
//         // Обработка ошибки сокета
//         console.error('CustomWebSocket Error:', error);
//     };
// }