import React, { useState, useEffect } from "react"
import Select from 'react-select';
import Modal from "../Modal";

import { createFieldsObject, getItems, getDomain } from "../../functions/functions";
import CounterpartyFields from "../../pages/counterparty/CounterpartyFields";
import { routes } from "../../pages";


function CounterpartyFastSearch({ addOption, openModal }) {
    const [fields, setFields] = useState({})
        , [error, setError] = useState('')

    useEffect(() => {
        getItems(`${getDomain()}/api/main/contragent/`, 'OPTIONS', setData)
    }, [])

    const setData = (data) => {
        const newFields = createFieldsObject(data.actions.POST)
        setFields(newFields)
    }

    const counterpartyCreate = (e) => {
        e.preventDefault()
        const button = e.currentTarget
            , inputs = button.closest('.form-data').querySelectorAll('.form-container input')
            , formJson = {}
            , nested_fields = ['saleaction', 'other_doc', 'act_files', 'other_files', 'doc_payment', 'form_files', 'level_agreements', 'archive_doc', 'pasport_doc']

        for (const input of inputs) {
            if (nested_fields.includes(input.name)) {
                formJson[input.name] = JSON.parse(input.value)
            } else if (input.multiple) {
                if (formJson[input.name]) {
                    const arr = formJson[input.name]
                    arr.push(input.value)
                    formJson[input.name] = arr
                } else {
                    const arr = []
                    arr.push(input.value)
                    formJson[input.name] = arr
                }
            } else if (input.type === 'checkbox') {
                if (input.checked) {
                    formJson[input.name] = input.value
                }
            } else {
                formJson[input.name] = input.value
            }
        }

        console.log('formJson', formJson)
        fetch(`${getDomain()}/api/main/contragent/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },

            credentials: "same-origin",
            body: JSON.stringify(formJson)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка. Неверно заполнены поля')
                }
                return response.json()
            })
            .then(data => {
                addOption(data)
                openModal(false)
            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                    setError('Доступ запрещен')
                } else {
                    setError(error.message)
                }
            })
    }

    return (
        <>
            {error ? <p className="error-message mb-8">{error}</p> : <></>}
            {Object.keys(fields).length > 0 ?
                <div className="form-data">
                    <div className="form-container form-2-columns">
                        <CounterpartyFields fields={fields} />
                    </div>
                    <div className="modal__buttons-panel">
                        <input type="submit" id="save_contragent_button" className="d-none" value="Send" />
                        <label htmlFor="save_contragent_button" className="save_button_fixed" onClick={counterpartyCreate}>Создать</label>
                    </div>
                </div>
                :
                <>Загрузка...</>
            }
        </>
    )
};


//=================================================================================================================================================


function SearchContragent(props) {
    const [value, setValue] = useState(''),
        [displayName, setDisplayName] = useState(''),
        [addButton, setAddButton] = useState(false),
        [options, setOptions] = useState([]),
        [showOptions, setShowOptions] = useState(false),
        [canAdd, setCanAdd] = useState(props.canAdd ? props.canAdd : true)


    

    const contragent_url = routes.filter(r => r.name === "counterparty")


    useEffect(() => {

        if (props.value) {
            const url = `${getDomain()}${contragent_url[0]?.url}${props.value}`
            getItems(url, 'GET', (data) => {
                setDisplayName(data.fio ? data.fio : data.org_name)
            }, false, setError)
            setValue(props.value)
        } else if (props.field.value) {
            const url = `${getDomain()}${contragent_url[0]?.url}${props.field.value}`
            getItems(url, 'GET', (data) => {
                setDisplayName(data.fio ? data.fio : data.org_name)
            }, false, setError)
            setValue(props.field.value)
        }
    }, [])


    const handleChange = selectedOption => {
        setAddButton(false)
        setValue(selectedOption)
    }

    const addOption = (data) => {
        const new_option = {}
        new_option.value = data.id
        new_option.label = data.fio ? data.fio : data.org_name
        options.push(new_option)
        setOptions(options)
        setValue(new_option.value)
        setDisplayName(new_option.label)
        setShowOptions(false)
    }

    const emptySearch = () => {
        setAddButton(true)
        return 'Нет результатов'
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler(e)
        }
    }

    const setData = (data) => {
        const arr = []
        if (data.count > 0) {
            for (let contragent of data.results) {
                const option = { value: contragent.id, display_name: contragent.org_name ? contragent.org_name : contragent.fio }
                arr.push(option)
                setAddButton(false)
            }
        } else {
            emptySearch()

        }

        setOptions(arr)

    }

    const setError = (message) => {
        console.log('====================================');
        console.log('Error', message);
        console.log('====================================');
    }

    const searchHandler = (e) => {
        if (e.target.value.length >= 2) {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
        e.preventDefault()
        setDisplayName(e.target.value)
        const url = `${getDomain()}${contragent_url[0]?.url}?search=${e.target.value}`
        //setStatus(() => <ContentLoader />)

        getItems(url, 'GET', setData, false, setError)
    }

    const selectContragent = (value, name) => {
        setShowOptions(false)
        setValue(value)
        setDisplayName(name)
    }

    return (
        <>
            <div className={"form__item " + props.classes}>
                <span className="error-message">{props.error ? props.error : ''}</span>
                <div className={`contragent__fake-select ${!showOptions ? 'd-none' : ''}`}>
                    {options.length > 0 ?
                        options.map(op => (
                            <span className={displayName === op.display_name ? 'selected' : ''} onClick={() => selectContragent(op.value, op.display_name)}>{op.display_name}</span>
                        )) :
                        <span className="no-resalts">Нет результатов</span>
                    }
                </div>

                <input type="hidden" value={value} name={props.field.name} />
                <div className="search-contragent-wrap">
                    <input
                        onChange={e => searchHandler(e)}
                        onKeyDown={onEnter}
                        value={displayName}
                        type="text"
                        className='contragent_search'
                        placeholder='Введите слово для поиска…' />

                    {(addButton && canAdd) && <Modal title="Создание контрагента" button={{ title: 'Новый контрагент', classes: 'add-absolute add-btn btn' }}><CounterpartyFastSearch addOption={addOption} openModal={setAddButton} /></Modal>}

                </div>
                <label htmlFor={props.field.name}>{props.field.label}{props.field.required ? <span>*</span> : ''}</label>

            </div>
        </>
    )
}


export default SearchContragent