import { API_AGREEMENT_MODEL } from "../const";
import apiConfig from "../../apiConfig";
import axiosClient from '../../axiosClient';

export const agreementDecline = async (file) => {
    let methodUrl = [API_AGREEMENT_MODEL.url + '/' + API_AGREEMENT_MODEL.methods.agreementDecline.url + '/'].join('/');

    console.log('METHOD', file);

    try {

        const resp = await axiosClient.post(apiConfig.baseUrl + methodUrl, { agreement_id: file });

        if (resp) {
            return { success: true, data: resp };
        } else {
            return { success: false, data: {}, message: resp ?? 'unknownError' };
        }
    } catch (error) {
        return { success: false, data: {}, message: error.message || 'unknownError' };
    }
};
