import React, { useState, useEffect, useRef, useReducer } from "react"
import Select from 'react-select'

import userPlus from '../../../public/interface_img/user-plus-01.svg'

function AddedFormItem({ field, button, can_delete = true, can_add = true }) {
    const [value, setValue] = useState([])
        , [addBtn, setAddBtn] = useState(false)


    useEffect(() => {
        const nested_value = []
        if (field.name === "members") {
            for (const item of field.value) {
                for (const k in item) {
                    if (k === 'id')
                        nested_value.push(item[k])
                }
            }
        } else {
            for (const item of field.value) {
                delete item.id
                for (const k in item) {
                    if (k !== 'changed_by')
                        nested_value.push(item[k])
                }
            }
        }

        setValue(nested_value)
    }, [])

    useEffect(() => {
        setAddBtn(field.choices ? field.choices.filter(choice => ![...value].includes(choice.value)).length > 0 ?? true : false)
    }, [value.length])

    const addField = (e) => {
        e.preventDefault()
        const free_choices = field.choices ? field.choices.filter(choice => ![...value].includes(choice.value)) : {}
        console.log('free choices', free_choices)
        if (free_choices.length > 0) {
            const arr = [...value]
            arr.push(free_choices[0].value)
            setValue(arr)
        }
        if (free_choices.length === 1) {
            setAddBtn(false)
        }
    }

    return (
        <div className="form-added-item _full">
            <span className="form-added-item__title">{field.label}</span>
            <div className="_full d-none">
                <select name={field.name} value={value} onChange={() => {
                }} multiple={true}>
                    {value ? value.map((item, idx) => {
                        return <option key={idx} value={item}>{item}</option>
                    }) : <></>}
                </select>

            </div>
            {can_add ?
                <>
                    {value ? value.map((val, idx) => {
                        return <CustomSelect key={idx} field={field} val={val} value={value} can_delete={can_delete}
                            setValue={setValue} />
                    }) : ''}
                </>
                :
                <>
                    {value ? value.map((val, idx) => {
                        const fields = field.choices.filter(choice => val === (choice.value))
                        return (
                            <div key={idx} className={`info__item`}>
                                <p className="info__text p-0">{fields[0].label}</p>
                            </div>
                        )
                    }) : ''}
                </>
            }

            {addBtn && can_add ? <a href="" onClick={addField} className={"added-field-btn _grid-start-1"}>
                <img src={userPlus} />
                {button}
            </a> : <></>}
        </div>
    )
}

function CustomSelect({ can_delete, value, val, field, setValue, required }) {
    const [options, setOptions] = useState([]) // all options
        , inputRef = useRef()
    //alue, setValue // all nested values array [1, 2, 3, ...]

    useEffect(() => {
        if (value) {
            setOptions(field.choices ? field.choices.filter(choice => ![...value].includes(choice.value) || choice.value == val) : {}) // ||choice.value == val
        } else {
            setOptions(field.choices)
        }
    }, [value])

    const handleChange = selectedOption => {
        const arr_values = value ? [...value] : [],
            index = arr_values.indexOf(val)

        if (index > -1) {
            arr_values[index] = selectedOption.value
            setValue(arr_values)
        } else {
            console.log('selectedOption not in value:', selectedOption)
        }

    }
    const deleteValue = () => {
        const arr_values = value ? [...value] : [],
            index = arr_values.indexOf(val)
        if (index > -1) {
            arr_values.splice(index, 1)
            setValue(arr_values)
        }
    }
    return (
        <div className={"form__item _full"}>
            <div className="custom-select-wrap">
                <Select classNamePrefix="select-item"
                    ref={inputRef}
                    onChange={handleChange}
                    noOptionsMessage={() => 'Нет результатов'}
                    value={val ? field.choices ? field.choices.find(option => option.value == val) : {} : {}}
                    options={options}
                    isSearchable={true}
                />
                {can_delete && <span className={"delete-select"} onClick={deleteValue}></span>}
            </div>
        </div>
    )
}

export default AddedFormItem