import React, {useEffect, useState} from 'react'
import {getDomain, getItems} from '../../functions/functions'
import getFormattedDateTime from '../../functions/getFormattedDateTime'
import getFormattedDateTimeSec from "../../functions/getFormattedDateTimeSec";

const HistoryComponent = ({name, id}) => {
    const [items, setItems] = useState([])
        , [status, setStatus] = useState('Загружаем историю...')

    const setData = (data) => {
        setStatus('')
        setItems(data.history.reverse())
    }

    useEffect(() => {
        getItems(`${getDomain()}/api/${name}/${id}/history`, 'GET', setData, false, (error) => setStatus('Ошибка загрузки'))
        return () => {
            setItems([])
        }
    }, [])

    const isInverseHistoryRecords = (a, b) => {
        return a.changed_fields === b.changed_fields && a.author === b.author && (
            (a.history_type === '+' && b.history_type === '-' && a.new === b.old) ||
            (b.history_type === '+' && a.history_type === '-' && b.new === a.old))
    }

    const filterItems = () => {
        const bool_arr = Array(items.length).fill(1)
        for (let i = 0; i < items.length; i++) {
            if (bool_arr[i]) {
                const cur_item = items[i]
                let j = i + 1
                while (j < items.length &&
                getFormattedDateTimeSec(cur_item.history_date) === getFormattedDateTimeSec(items[j].history_date)) {
                    if (isInverseHistoryRecords(cur_item, items[j])) {
                        bool_arr[i] = 0
                        bool_arr[j] = 0
                        break
                    }
                    j++
                }
            }
        }

        let new_items = []

        for (let i = 0; i < bool_arr.length; i++) {
            if (bool_arr[i])
                new_items.push(items[i])
        }

        return new_items
    }


    return (
        <div className='mt-32'>
            <h2 className='inventory_table-title'>История изменений</h2>
            <p>{status}</p>
            {items.length > 0 ?
                <div className='history-table_wrap'>
                    <table className="history-table">
                        <thead>
                        <tr>
                            <th className="pl-24">Дата изменения</th>
                            <th className="_center">Кто изменил</th>
                            <th className="_center">Измененное поле</th>
                            <th className="_center">Старое значение</th>
                            <th className="_center">Новое значение</th>
                            <th className="_right pr-24">Тип изменения</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filterItems().map((item, idx) => {
                            return <tr className="" key={idx}>
                                <td className="">{getFormattedDateTime(item.history_date)}</td>
                                <td className="_center">{item.author}</td>
                                <td className="_center">{item.changed_fields}</td>
                                <td className="_center">{item.old ? item.old : ''}</td>
                                <td className="_center">{item.new}</td>
                                <td className="_right">{item.history_type === "+" ? "Добавлен" :
                                    item.history_type === '-' ? "Удален" : "Обновлен"}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
                :
                <p>Нет изменений</p>
            }
        </div>
    )
}

export default HistoryComponent