import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";
import SortingCell from "../../components/SortingCell";

function ArchiveTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _archive">
                <thead>
                    <tr>
                        {/* ordering_fields = ['category', 'doc_number', 'date_of_conclusion', 
                        'title', 'organisation', 'contragent'] */}
                        <SortingCell classes="" title={'Категория'} name={'category'} page_name={props.name} />
                        <SortingCell classes="" title={'Номер договора'} name={'doc_number'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Дата заключения'} name={'date_of_conclusion'} page_name={props.name} />
                        <SortingCell classes="" title={'Кадастровый номер'} name={'title'} page_name={props.name} />
                        <SortingCell classes="_center" title={'Организация'} name={'organisation'} page_name={props.name} />
                        <SortingCell classes="" title={'Контрагент'} name={'contragent'} page_name={props.name} />
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)

                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                {item.category?.title ?
                                    <td><Link to={`detail/${item.id}`} className="table__link">{item.category.title}</Link></td> : <td></td>
                                }
                                {item.doc_number ?
                                    <td className="">{item.doc_number}</td> : <td></td>
                                }
                                {item.date_of_conclusion ?
                                    <td className="_center">{item.date_of_conclusion}</td> : <td></td>
                                }
                                {item?.title ?
                                    <td className="">{item.title}</td> : <td></td>
                                }
                                {item.organisation ?
                                    <td className="_center">{item.organisation.name}</td>
                                    : <td></td>
                                }
                                {item.contragent ?
                                    item.contragent.org_name ?
                                        <td className="">{item.contragent.org_name}</td>
                                        :
                                        <td className="">{item.contragent.fio}</td>
                                    : <td></td>
                                }

                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}
export default ArchiveTable;