import React, {Fragment, useEffect, useRef, useState} from 'react';
import Search from '../Search';
import ChatCard from '../ChatCard/ChatCard';
import {getAllUsers} from '../../../api/getAllUser/getAllUser';
import {getAllChats} from '../../../api/chat/getAllChat';
import ModalGroupChat from '../ModalGroupChat/ModalGroupChat';
import {useDispatch, useSelector} from 'react-redux';
import ContentLoader from '../../../components/ContentLoader';
import {getDomain} from '../../../functions/functions';
import {setNotification} from '../../../store/tableNotification';


const ChatList = (props) => {

    const userData = useSelector(state => state.user_data.userData)

    const [chatList, setChatList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isNextUsers, setIsNextUsers] = useState('');
    const [loading, setLoading] = useState(false);
    const [openModalGroup, setOpenModalGroup] = useState(false);
    const debounceSearch = useRef(null);
    const chatListRef = useRef();
    const filteredUserList = userList.filter(user => !chatList.some(chat => chat?.receiver?.id === user?.id));
    const activeChat = useSelector((state) => state.activeChat);

    const dispatch = useDispatch()

    const setTableNotifications = (model_name, notifications) => dispatch(setNotification({
        model_name: model_name,
        tableNotifications: notifications
    }))

    const chatNotification = useSelector(state => state.tableNotification.tableNotifications)

    useEffect(() => {
        const notificationsURL = new URL(`api/notifications/?model_name=ChatModel&owner_id=${userData.user_id}`, getDomain());
        fetch(notificationsURL.href, {
            method: "GET",
        }).then(response => response.json())
            .then((resultNotifications) => {
                setTableNotifications("ChatModel", resultNotifications.notifications)
            })
            .catch(e => console.log(e))
    }, [])


    // useEffect(() => {
    //   if (notifications) {
    //     notifications.map((item) => {
    //       if (item.model_name === "ChatModel") {
    //         setChatNotification(notifications);
    //       }
    //     });
    //   }
    // }, [notifications]);


    const handleScroll = () => {
        if (
            chatListRef.current.scrollHeight - chatListRef.current.scrollTop ===
            chatListRef.current.clientHeight
        ) {
            loadMoreChats();
        }
    };

    const handleSearch = (searchTerm) => {
        if (debounceSearch.current) {
            clearTimeout(debounceSearch.current);
        }

        debounceSearch.current = setTimeout(() => {

            getAllChats(searchTerm).then((resp) => {
                setChatList(resp.data)
            })
            getAllUsers(undefined, `?search=${searchTerm}&status=Активен`).then((resp) => {
                const filteredUserList = resp.data.results.filter(user => !chatList.some(chat => chat?.receiver?.id === user?.id));
                setUserList(filteredUserList);
            })
        }, 300);
    };

    const loadMoreChats = () => {
        if (isNextUsers !== null && !loading) {
            setLoading(true);

            getAllUsers(isNextUsers, `${'?status=Активен'}`).then((resp) => {
                if (resp.success) {
                    const filteredUserList = resp.data.results.filter(user => (
                        !chatList.some(chat => (
                            chat.receiver ? chat.receiver.id === user.id :
                                chat.receivers_rel.some(item => item.id === user.id)
                        ))
                    ));

                    setUserList((prevUserList) => [...prevUserList, ...filteredUserList]);
                    setIsNextUsers(resp.data.next);
                    setLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        setLoading(true)
        getAllChats().then((resp) => {
            if (resp.success) {
                setChatList(resp.data)
                loadMoreChats();
                setLoading(false)
            }
        })

    }, []);

    // Элементы с совпадением
    const matchingItems = chatList.filter(item => {
        const matchingNotifications = chatNotification.filter(chat => chat.instance_id === item.id);
        const lastMatchingNotification = matchingNotifications?.length > 0 ? matchingNotifications[matchingNotifications?.length - 1] : null;
        return item.id === lastMatchingNotification?.instance_id;
    });

    // Элементы без совпадения
    const nonMatchingItems = chatList.filter(item => {
        const matchingNotifications = chatNotification.filter(chat => chat.instance_id === item.id);
        const lastMatchingNotification = matchingNotifications?.length > 0 ? matchingNotifications[matchingNotifications?.length - 1] : null;
        return item.id !== lastMatchingNotification?.instance_id;
    });

    // Объединяем элементы в один массив
    const updatedChatList = matchingItems.concat(nonMatchingItems);

    return (
        <Fragment>

            {openModalGroup && <ModalGroupChat onClose={() => {
                setOpenModalGroup(false)
            }}/>}
            <div className='chat-list'>
                <Search onSearch={handleSearch} openModal={() => setOpenModalGroup(true)}
                        hiddeAddGroupButton={props?.hiddeAddGroupButton}/>

                <div className='chat-list__wrap' onScroll={handleScroll} ref={chatListRef}>
                    {loading ?
                        <ContentLoader/>
                        :
                        chatList.length > 0 &&
                        <Fragment>
                            <p className='titleChatList'>Чаты</p>
                            {updatedChatList.map((item, index) => (
                                <ChatCard
                                    key={index}
                                    dataChat={item}
                                    chatList={true}
                                    matchingNotifications={chatNotification.filter(chat => chat.instance_id === item.id)}
                                    lastMatchingNotification={chatNotification.find(chat => chat.instance_id === item.id)}
                                    additionActionOnClick={props?.additionActionOnClick}
                                />
                            ))}

                        </Fragment>

                    }
                    {filteredUserList.length > 0 &&
                        <Fragment>
                            <p className='titleChatList'>Пользователи</p>
                            {filteredUserList.map((item, index) => (
                                <ChatCard key={index} dataChat={item}/>
                            ))}
                        </Fragment>
                    }
                    {loading && <ContentLoader/>}
                </div>

            </div>
        </Fragment>
    );
};

export default ChatList;
