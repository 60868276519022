import React, { useState, useEffect } from "react"

function SaleAddFormItem(props) {
    const createField = (label, name, val = '') =>
    {
        const field = {}
        field.id = `id_${name}`
        field.name = name
        field.label = label
        field.type = 'text'
        field.value = val
        return field
    }

        , parseFields = (data) =>
    {
        const arr = []
        for (let i = 0; data.length > i; i++) {
            const sale = []
            for (const name of ['sale_action_name', 'condition_of_receipt']) {
                const field = createField(name === 'sale_action_name' ? 'Скидка или акция' : 'Условия получения', name, props.field.value[i][name])
                sale.push(field)
            }
            arr.push(sale)
        }
        return arr
    }
        , [sales, setSales] = useState(parseFields(props.field.value))
        , [value, setValue] = useState([])

    useEffect(() => {
        const arr = []
        for (const sale of sales) {
            const obj = {}
            for (const f of sale) {
                obj[f.name] = f.value
            }
            arr.push(obj)
        }
        setValue(arr)
    }, [sales])

    const addField = (e) => {
        e.preventDefault()
        const sale = []
        for (const name of ['sale_action_name', 'condition_of_receipt']) {
            const field = createField(name === 'sale_action_name' ? 'Скидка или акция' : 'Условия получения', name)
            sale.push(field)
        }
        const new_arr = [...sales]
        new_arr.push(sale)
        setSales(new_arr)
    }

    const removeField = (e, index) => {
        const arr = [...sales]
        arr.splice(index, 1)
        setSales(arr)
    }

    const changeField = (e, index) => {
        const arr = [...sales]
            , el = e.target
        arr[index].find( item => item.name === e.target.name).value = el.value
        setSales(arr)
    }

    return (
        <>
            <input type="hidden" name={props.field.name} onChange={() => {}} value={JSON.stringify(value)}/>
            {sales.length > 0 ? sales.map((sale, idx) => {
                return (
                    <div key={idx} className={`form__card ${props.classes && props.classes}`}>
                        <span className="card__title">Акция <i className="card__delete" onClick={e => removeField(e, idx)}></i></span>
                        {sale.map((f, i) => {
                            return (
                                <div key={i} className={`form__item`}>
                                    <input type={f.type} id={f.id} value={f.value}
                                           onChange={e => changeField(e, idx)}
                                           name={f.name}/>
                                    <label htmlFor={f.name}>{f.label}</label>
                                </div>
                            )
                        })}
                    </div>
                )
            }) : ''}
            <a href="" onClick={addField} className={"added-field-btn _grid-start-1"}>Добавить акцию</a>
        </>
    )
}

export default SaleAddFormItem