import React, { useState, useEffect, useReducer, useRef, forceUpdate } from "react";
import { useNavigate } from "react-router-dom"

import HeaderInside from "../../components/HeaderInside";
import { getItems, createFieldsObject, createOrUpdate, getDomain } from "../../functions/functions";
import ContentLoader from "../../components/ContentLoader";
import Alert from "../../components/Allert";
import FormItem from "../../components/form/FormItem";
import SearchContragent from "../../components/form/SearchContragent";
import AddedFormItem from "../../components/form/AddedFormItem";
import DocumentSourceItem from "../../components/form/DocumentSourceItem";
import getFormattedDate from "../../functions/getFormattedDate";

// Context
import FormErrorsContext from "../../functions/formErrorsContext";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import TextItem from "../../components/form/TextItem";


function IncomingCreate(props) {
    const formatted_date = getFormattedDate()
        , responsible_value = props.is_my ? localStorage.getItem('user_id') : ''
        , [regNumber, setRegNumber] = useState(null)
        , [newId, setNewId] = useState(false)
        , [fields, setFields] = useState({})
        , [load, setLoad] = useState(false)
        , [loading, setLoading] = useState(false)
        , [status, setStatus] = useState(() => <ContentLoader />)
        , [formErrors, setFormErrors] = useState({})
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , navigate = useNavigate()


    useEffect(() => {
        getItems(props.url, 'OPTIONS', setData, false, (error) => {
            setLoading(false)
            setStatus('')
            setAlert({
                title: 'Ошибка загрузки',
                text: error, //'При загрузке произошла ошибка, попробуйте обновить страницу',
                type: 'error', // error, alert, info, success
            })
        })
    }, [])


    const navigateToView = (id) => {
        const url =`/${props.name}/`
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект создан',
                    status: 'success'
                }
            }
        })
    }

    const setData = (data) => {
        //console.log(data)
        const newFields = createFieldsObject(data.actions.POST)
        //console.log('newFields', newFields)
        setFields(newFields)
    }
    const setErrors = (data) => {
        /*console.log(fields)*/

        const newFields = { ...fields }
        data.then(res => {
            console.log('errors', res)
            Object.keys(res).forEach(key => {
                newFields[key].error = res[key][0]
            })
            setFields(newFields)
        })
    }

    const createNumber = (e) => {
        e.preventDefault()
        setLoad(true)
        console.log('counterpartyCreate')
        const button = e.currentTarget
            , inputs = button.closest('.form-data').querySelectorAll('input')
            , formJson = {}

        for (const input of inputs) {
            if (input.type === 'checkbox') {
                if (input.checked) {
                    formJson[input.name] = input.value
                }
            } else {
                formJson[input.name] = input.value
            }
        }
        formJson['incoming_number'] = '1'

        console.log('formJson', formJson)

        fetch(`${getDomain()}/api/incoming/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify(formJson)
        })
            .then(response => {
                if (!response.ok) {
                    setLoad(false)
                    throw new Error('Ошибка. Неверно заполнены поля')
                } else {
                    setLoad(false)
                }
                return response.json()
            })
            .then(data => {
                console.log(data)
                setRegNumber(data.incoming_number)
                setNewId(data.id)
                setAlert({
                    title: 'Документ создан',
                    text: 'Загрузите документ под номером ' + data.incoming_number + ' в поле «Скан документа» и сохраните его',
                    type: 'success'
                })

                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .catch(error => {
                if (error.message.toLowerCase() === 'failed to fetch') {
                    setAlert({ title: 'Доступ запрещен', type: 'error' })
                } else {
                    setAlert({ title: error.message, type: 'error' })
                }
            })


    }
    return (
        <div className="page">
            <HeaderInside title={props.title} icon="__to-back" />
            <hr />
            <div className={`page__content ${loading ? '_disabled' : ''}`}>
                <Alert setAlert={setAlert} alert={alert} />

                {Object.keys(fields).length > 0 ?
                    <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                        <form action={props.url + newId + '/'} method="POST" onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, setErrors, navigateToView, fields)}>

                            <div className="form-container form-data _gray" style={{ width: "fit-content" }}>
                                <h3 className="form__step _full"><span>1</span>Получить входящий номер</h3>
                                <FormItem field={fields.organisation} required={true} classes="_full" />
                                <FormItem field={fields.date_out} required={true} classes="_grid-start-1" />
                                <FormItem field={fields.out_org_number} classes="" />
                                <FormItem field={fields.doc_name} required={true} classes="_full" />
                                <SearchContragent field={fields.contragent} classes="_full" required={true} />
                                <FormItem field={fields.responsible} required={true} classes="_full" read_only={!!props.is_my} value={responsible_value ? responsible_value : false} />
                                <FormItem field={fields.who_unsubscribed} required={true} classes="_full" />
                                <TextItem field={fields.incoming_number} classes="_grid-start-1" read_only={true} value={regNumber} fieldHandler={setRegNumber} />
                                <FormItem field={fields.register_date} classes="" read_only={true} value={fields.register_date.value ? fields.register_date.value : formatted_date} />
                                <FormItem field={fields.registrar} required={true} classes="d-none _full" read_only={true} value={localStorage.getItem('user_id')} />
                                <a href="" className={regNumber || load ? "btn _full _disabled" : "btn _full"} onClick={createNumber}>Получить номер</a>
                            </div>

                            <div className={regNumber ? "mt-48" : "mt-48 form-disabled"}>
                                <div className="form-container">
                                    <h3 className="form__step _full"><span>2</span>Завершить заполнение</h3>
                                    <FormItem field={fields.file} classes="_full" />
                                    <DocumentAddFormItem field={fields.other_doc} classes="_full" />
                                    <FormItem field={fields.document_type} classes="_full" />
                                    <FormItem field={fields.source} classes="_full" />
                                    <FormItem field={fields.description} classes="_full" />
                                    <FormItem field={fields.service_comment} classes="_full" />
                                    <FormItem field={fields.resolution} classes="_full" />
                                    <FormItem field={fields.do_not_executed} classes="_full" />
                                    <FormItem field={fields.execution_period} classes="_grid-start-1" />
                                    <AddedFormItem field={fields.soresponsibles} classes="_full" button={'Добавить соисполнителя'} />
                                    <AddedFormItem field={fields.observers} classes="_full" button={'Добавить наблюдателя'} />
                                </div>
                            </div>



                            <div className="form__buttons-panel">
                                {/*<a  onClick={e => navigateToView(1)}>ложное сохранение</a>*/}
                                <input type="submit" id="save_button" className="d-none" value="Send" />
                                <label htmlFor="save_button" className={(Object.keys(formErrors).length > 0 || loading) ? 'save_button_fixed _disable' : "save_button_fixed"}>{loading ? 'Сохранение...' : 'Сохранить'}</label>
                            </div>
                        </form>
                    </FormErrorsContext.Provider>
                    : status
                }
            </div>
        </div>
    )
}

export default IncomingCreate
