import React from "react"

import ChatPage from "./pages/chat/ChatPage";
import DefaultViewPage from "./components/ViewPage";
import DetailPage from "./components/DetailPage";

// Tables
import IncomingTable from "./pages/incoming/IncomingTable";
import OutgoingTable from "./pages/outgoing/OutgoingTable";
import CounterpartyTable from "./pages/counterparty/CounterpartyTable";
import SupportTable from "./pages/support/SupportTable";
import AhoTable from "./pages/aho/AhoTable";
import ExploitationTable from "./pages/exploitation/ExploitationTable";
import EngineeringNetworkTable from "./pages/engineering_network/EngineeringNetworkTable";
import UsersTable from "./pages/users/UsersTable";
import RegisterTable from "./pages/register/RegisterTable";
import AgreementTable from "./pages/agreement/AgreementTable";
import ServiceRecordsTable from "./pages/service_records/ServiceRecordsTable";
import TasksTable from "./pages/tasks/TasksTable";
import ArchiveTable from "./pages/digital_archive/ArchiveTable";
import LentaTable from "./pages/lenta/LentaTable";
import SimpleArchiveTable from "./pages/simple_archive/SimpleArchiveTable";
import InventoryTable from "./pages/inventory/InventoryTable";
import EntranceTable from "./pages/entrance/EntranceTable";

// Detail
import IncomingDetail from "./pages/incoming/IncomingDetail";
import OutgoingDetail from "./pages/outgoing/OutgoingDetail";
import CounterpartyDetail from "./pages/counterparty/CounterpartyDetail";
import SupportDetail from "./pages/support/SupportDetail";
import AhoDetail from "./pages/aho/AhoDetail";
import EngineeringNetworkDetail from "./pages/engineering_network/EngineeringNetworkDetail";
import ExploitationDetail from "./pages/exploitation/ExploitationDetail";
import UserDetail from "./pages/users/UserDetail";
import RegisterDetail from "./pages/register/RegisterDetail";
import AgreementDetail from "./pages/agreement/AgreementDetail";
import ServiceRecordsDetail from "./pages/service_records/ServiceRecordsDetail";
import TasksDetail from "./pages/tasks/TasksDetail";
import ArchiveDetail from "./pages/digital_archive/ArchiveDetail";
import LentaDetail from "./pages/lenta/LentaDetail";
import SimpleArchiveDetail from "./pages/simple_archive/SimpleArchiveDetail";
import InventoryDetail from "./pages/inventory/InventoryDetail";

// Fields
import IncomingFields from "./pages/incoming/IncomingFields";
import OutgoingFields from "./pages/outgoing/OutgoingFields";
import UserFields from "./pages/users/UserFields";
import CounterpartyFields from "./pages/counterparty/CounterpartyFields";
import SupportFields from "./pages/support/SupportFields";
import AhoFields from "./pages/aho/AhoFields";
import EngineeringNetworkFields from "./pages/engineering_network/EngineeringNetworkFields";
import ExploitationFields from "./pages/exploitation/ExploitationFields";
import RegisterFields from "./pages/register/RegisterFields";
import AgreementFields from "./pages/agreement/AgreementFields";
import ServiceRecordsFields from "./pages/service_records/ServiceRecordsFields";
import TasksFields from "./pages/tasks/TasksFields";
import ArchiveFields from "./pages/digital_archive/ArchiveFields";
import LentaFields from "./pages/lenta/LentaFields";
import InventoryFields from "./pages/inventory/InventoryFields";

import SimpleArchiveFields from "./pages/simple_archive/SimpleArchiveFields";
import RegisterEdit from "./pages/register/RegisterEdit";

// Filters
import IncomingFilter from "./pages/incoming/IncomingFilter";
import {getDomain} from "./functions/functions";
import OutgoingFilter from "./pages/outgoing/OutgoingFilter";
import ServiceRecordsFilter from "./pages/service_records/ServiceRecordsFilter";
import CounterpartyFilter from "./pages/counterparty/CounterpartyFilter";
import UserFilter from "./pages/users/UserFilter";
import RegisterFilter from "./pages/register/RegisterFilter";
import SupportFilter from "./pages/support/SupportFilter";
import AhoFilter from "./pages/aho/AhoFilter";
import EngineeringNetworkFilter from "./pages/engineering_network/EngineeringNetworkFilter";
import ExploitationFilter from "./pages/exploitation/ExploitationFilter";
import TasksFilter from "./pages/tasks/TasksFilter";
import ArchiveFilter from "./pages/digital_archive/ArchiveFilter";
import SimpleArchiveFilter from "./pages/simple_archive/SimpleArchiveFilter";
import InventoryFilter from "./pages/inventory/InventoryFilter";
import EntranceFilter from "./pages/entrance/EntranceFilter";


import CreatePage from "./components/CreatePage";
import EditPage from "./components/EditPage";
import IncomingCreate from "./pages/incoming/IncomingCreate";
import OutgoingCreate from "./pages/outgoing/OutgoingCreate";
import AgreementFilter from "./pages/agreement/AgreementFilter";
import Gigachat from "./pages/gigachat/Gigachat";
import ClientsTasksTable from "./pages/clients_tasks/ClientsTasksTable";
import ClientsTasksDetail from "./pages/clients_tasks/ClientsTasksDetail";
import AhoInventoryFilter from "./pages/aho_inventory/AhoInventoryFilter";
import AhoInventoryTable from "./pages/aho_inventory/AhoInventoryTable";
import AhoInventoryDetail from "./pages/aho_inventory/AhoInventoryDetail";
import AhoInventoryFields from "./pages/aho_inventory/AhoInventoryFields";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardFullScreen from "./pages/dashboard/DashboardFullScreen";
import AhoInventoryCreate from "./pages/aho_inventory/AhoInventoryCreate";
import TelephonyTable from "./pages/telephony/TelephonyTable";
import TelephonyFilter from "./pages/telephony/TelephonyFilter";
import UserView from "./pages/users/UserView";
import ClientsTaskView from "./pages/clients_tasks/ClientTaskView"
import AgreementView from "./pages/agreement/AgreementView";
import AhoView from "./pages/aho/AhoView";
import AhoInventoryView from "./pages/aho_inventory/AhoInventoryView";
import CounterpartyView from "./pages/counterparty/CounterpartyView";
import ExploitationView from "./pages/exploitation/ExploitationView";
import TasksView from "./pages/tasks/TasksView";
import ServiceRecordsView from "./pages/service_records/ServiceRecordsView";
import EngineeringNetworkView from "./pages/engineering_network/EngineeringNetworkView";
import RegisterView from "./pages/register/RegisterView";
import SimpleArchiveView from "./pages/simple_archive/SimpleArchiveView";
import SupportView from "./pages/support/SupportView";
import InventoryView from "./pages/inventory/InventoryView";
import ArchiveView from "./pages/digital_archive/ArchiveView";
import OutgoingView from "./pages/outgoing/OutgoingView";
import IncomingView from "./pages/incoming/IncomingView";
import EntranceView from "./pages/entrance/EntranceView";
import TelephonyView from "./pages/telephony/TelephonyView";
import DocumentsView from "./pages/documents/DocumentsView";
import ClientsTasksFilter from "./pages/clients_tasks/ClientsTasksFilter";
import DocumentsTable from "./pages/documents/DocumentsTable";
import DocumentsCreate from "./pages/documents/DocumentsCreate";
import DocumentsFilter from "./pages/documents/DocumentsFilter";
import EventCalendarTable from "./pages/EventCalendar/EventCalendarTable";

import CalendarView from "./pages/EventCalendar/CalendarView";
import EventCalendarFields from "./pages/EventCalendar/EventCalendarFields";
import EventCalendarDetail from "./pages/EventCalendar/EventCalendarDetail";
import LentaView from "./pages/lenta/LentaView";
import TimelogView from "./pages/timelog/TimelogView";
import TimelogTable from "./pages/timelog/TimelogTable";
import TimelogFields from "./pages/timelog/TimelogFields";
import TimelogFilter from "./pages/timelog/TimelogFilter";
import UserEdit from "./pages/users/UserEdit";

import SendSMSCreate from './pages/sendSms/sendSMSCreate';
import SendSMSTable from "./pages/sendSms/SendSMSTable";
import SendSmsView from "./pages/sendSms/SendSmsView";
import SendSMSFields from "./pages/sendSms/SendSMSFields";
import SendSMSDetail from "./pages/sendSms/SendSMSDetail";
import SendSMSFilter from "./pages/sendSms/sendSMSFilter";
import KpkArchiveView from "./pages/kpk_archive/KpkArchiveView";
import KpkArchiveFilter from "./pages/kpk_archive/KpkArchiveFilter";
import KpkArchiveTable from "./pages/kpk_archive/KpkArchiveTable";
import KpkArchiveDetail from "./pages/kpk_archive/KpkArchiveDetail";
import KpkArchiveFields from "./pages/kpk_archive/KpkArchiveFields";
import UpcomingDatesView from "./pages/upcoming_dates/UpcomingDatesView";
import UpcomingDatesTable from "./pages/upcoming_dates/UpcomingDatesTable";


const domain = getDomain()

function Page(name, url, use_permissions, ViewPage, title, filter, search, model_name, create_btn, view_title, view_сомponent, detail_title, detail_сомponent, edit_title, edit_сомponent, create_title, create_сомponent, CreateComponent = CreatePage, only_new = false, can_delete = true) {
    this.name = name
    this.use_permissions = use_permissions
    this.title = title
    this.model_name = model_name
    this.replace_to = '/' + name
    this.is_my = name.startsWith('my-')
    this.url = url
    this.pages = {
        view: <ViewPage {...{
            url: domain + url + '?format=json',
            name: name,
            title: view_title,
            component: view_сомponent,
            use_permissions: use_permissions,
            model_name: model_name,
            create_title: create_btn,
            filter: filter,
            search: search,
            is_my: name.startsWith('my-'),
            only_new: only_new
        }} />,
        detail: <DetailPage {...{
            url: domain + url,
            name: name,
            title: detail_title,
            component: detail_сомponent,
            use_permissions: use_permissions,
            model_name: model_name,
            can_delete: can_delete
        }} />,
        edit: <EditPage {...{url: domain + url, name: name, title: edit_title, component: edit_сомponent}} />,
        create: <CreateComponent {...{
            url: domain + url,
            name: name,
            title: create_title,
            component: create_сомponent
        }} />
    }
}

const routes = [
    new Page('lenta', '/api/lenta/', false, LentaView, 'Лента', false, true, 'AnnouncementModel', 'Создать объявление', 'Лента', LentaTable, 'Вернуться в ленту', LentaDetail, 'Редактировать объявление', LentaFields, 'Создание нового объявления', LentaFields),

    {
        name: 'chat',
        use_permissions: false,
        title: 'Чаты',
        model_name: 'ChatModel',
        replace_to: '/chat',
        pages: {
            view: <ChatPage {...{url: domain + '/api/chats/?', name: "chat", title: "Чаты", filter: false}} />
        }
    },

    {
        name: 'gigachat',
        use_permissions: false,
        title: 'ИИ-помощник',
        model_name: 'gigachat',
        pages: {
            view: <Gigachat {...{
                url: domain + '/api/ai/answers/?',
                name: "gigachat",
                title: 'ИИ-помощник',
                filter: false
            }} />
        }
    },

    new Page('incoming', '/api/incoming/', true, IncomingView, 'Входящие', IncomingFilter, true, 'IncomingModel', 'Зарегистрировать документ', 'Входящие', IncomingTable, 'Входящий документ', IncomingDetail, 'Редактировать документ', IncomingFields, 'Регистрация входящего документа', IncomingFields, IncomingCreate, true),
    new Page('my-incoming', '/api/incoming/', false, IncomingView, 'Мои входящие', IncomingFilter, true, 'IncomingModel', 'Зарегистрировать документ', 'Мои входящие', IncomingTable, 'Мой входящий документ', IncomingDetail, 'Редактировать документ', IncomingFields, 'Регистрация входящего документа', IncomingFields, IncomingCreate, true),

    new Page('outgoing', '/api/output/', true, OutgoingView, 'Исходящие', OutgoingFilter, true, 'OutputModel', 'Зарегистрировать документ', 'Исходящие', OutgoingTable, 'Исходящий документ', OutgoingDetail, 'Редактировать документ', OutgoingFields, 'Регистрация исходящего документа', OutgoingFields, OutgoingCreate, true),
    new Page('my-outgoing', '/api/output/', false, OutgoingView, 'Мои исходящие', OutgoingFilter, true, 'OutputModel', 'Зарегистрировать документ', 'Мои исходящие', OutgoingTable, 'Мой исходящий документ', OutgoingDetail, 'Редактировать документ', OutgoingFields, 'Регистрация исходящего документа', OutgoingFields, OutgoingCreate, true),

    new Page('agreement', '/api/agreement/', true, AgreementView, 'Согласования', AgreementFilter, true, 'AgreementModel', 'Согласовать договор', 'Согласования', AgreementTable, 'Согласование договора', AgreementDetail, 'Согласование нового договора', AgreementFields, 'Регистрация исходящего документа', AgreementFields),
    new Page('my-agreement', '/api/agreement/', false, AgreementView, 'Мои согласования', AgreementFilter, false, 'AgreementModel', 'Согласовать договор', 'Мои согласования', AgreementTable, 'Согласование договора', AgreementDetail, 'Согласование нового договора', AgreementFields, 'Регистрация исходящего документа', AgreementFields),

    new Page('service-records', '/api/service_records/', true, ServiceRecordsView, 'Служебки', ServiceRecordsFilter, true, 'ServiceRecordModel', 'Создать служебную записку', 'Служебки', ServiceRecordsTable, 'Служебная записка', ServiceRecordsDetail, 'Редактировать служебную записку', ServiceRecordsFields, 'Создать служебную записку', ServiceRecordsFields),
    new Page('my-service-records', '/api/service_records/', false, ServiceRecordsView, 'Мои служебки', ServiceRecordsFilter, true, 'ServiceRecordModel', 'Создать служебную записку', 'Мои служебки', ServiceRecordsTable, 'Служебная записка', ServiceRecordsDetail, 'Редактировать служебную записку', ServiceRecordsFields, 'Создать служебную записку', ServiceRecordsFields),

    new Page('tasks', '/api/tasks/', true, TasksView, 'Задачи', TasksFilter, true, 'TasksModel', 'Создать задачу', 'Задачи', TasksTable, 'Просмотр задачи', TasksDetail, 'Редактировать задачу', TasksFields, 'Создание задачи', TasksFields, CreatePage, true),
    new Page('my-tasks', '/api/tasks/', false, TasksView, 'Мои задачи', TasksFilter, true, 'TasksModel', 'Создать задачу', 'Мои задачи', TasksTable, 'Просмотр задачи', TasksDetail, 'Редактировать задачу', TasksFields, 'Создание задачи', TasksFields, CreatePage, true),

    new Page('ahosupport', '/api/ahosupport/', false, AhoView, 'Заявки АХО', AhoFilter, false, 'AhoSupportModel', 'Создать заявку', 'Заявки АХО', AhoTable, 'Просмотр заявки', AhoDetail, 'Редактировать заявку', AhoFields, 'Создание заявки', AhoFields),

    new Page('exsupport', '/api/exsupport/', false, ExploitationView, 'Заявки отдела эксплуатации', ExploitationFilter, true, 'ExploitationSupportModel', 'Создать заявку', 'Заявки отдела эксплуатации', ExploitationTable, 'Просмотр заявки', ExploitationDetail, 'Редактировать заявку', ExploitationFields, 'Создание заявки', ExploitationFields),

    new Page('capsupport', '/api/capsupport/', false, EngineeringNetworkView, 'Заявки кап. строительства', EngineeringNetworkFilter, false, 'CapSupportModel', 'Создать заявку', 'Заявки кап. строительства', EngineeringNetworkTable, 'Просмотр заявки', EngineeringNetworkDetail, 'Редактировать заявку', EngineeringNetworkFields, 'Создание заявки', EngineeringNetworkFields),
 
    new Page('register', '/api/reestr/', true, RegisterView, 'Реестр договоров', RegisterFilter, true, 'DocReestrModel', 'Зарегистрировать', 'Реестр договоров', RegisterTable, 'Договор оказания услуг', RegisterDetail, 'Договор оказания услуг', RegisterEdit, 'Создание нового договора', RegisterEdit),
    new Page('my-register', '/api/reestr/', false, RegisterView, 'Мои договоры', RegisterFilter, true, 'DocReestrModel', 'Зарегистрировать', 'Мои договоры', RegisterTable, 'Договор оказания услуг', RegisterDetail, 'Договор оказания услуг', RegisterEdit, 'Создание нового договора', RegisterEdit),

    new Page('support', '/api/support/', false, SupportView, 'Техподдержка', SupportFilter, true, 'SupportModel', 'Создать заявку', 'Техподдержка', SupportTable, 'Просмотр заявки', SupportDetail, 'Редактировать заявку', SupportFields, 'Создание заявки', SupportFields),

    new Page('users', '/api/user/', true, UserView, 'Пользователи', UserFilter, true, 'User', 'Зарегистрировать пользователя', 'Пользователи', UsersTable, 'Просмотр пользователя', UserDetail, 'Редактировать пользователя', UserEdit, 'Регистрация пользователя', UserFields, CreatePage),

    new Page('counterparty', '/api/main/contragent/', true, CounterpartyView, 'Контрагенты', CounterpartyFilter, true, 'Contragent', 'Зарегистрировать контрагента', 'Контрагенты', CounterpartyTable, 'Просмотр контрагента', CounterpartyDetail, 'Редактировать контрагента', CounterpartyFields, 'Регистрация контрагента', CounterpartyFields),

    new Page('digital-archive', '/api/digital-archive/', true, ArchiveView, 'Архив займов', ArchiveFilter, false, 'ArchiveCategory', 'Создать запись', 'Архив займов', ArchiveTable, 'Просмотр архивных данных', ArchiveDetail, 'Редактировать запись', ArchiveFields, 'Создание записи', ArchiveFields),

    new Page('digital-archive-kpk', '/api/digital-archive-kpk/', true, KpkArchiveView, 'Архив КПК', KpkArchiveFilter, false, 'digital_archive_kpk', 'Создать запись', 'Архив КПК', KpkArchiveTable, 'Просмотр архивных данных', KpkArchiveDetail, 'Редактировать запись', KpkArchiveFields, 'Создание записи', KpkArchiveFields),

    new Page('archive', '/api/dumping/', true, SimpleArchiveView, 'Архив', SimpleArchiveFilter, true, 'ProjectDumpingModel', 'Создать папку', 'Архив', SimpleArchiveTable, 'Просмотр папок', SimpleArchiveDetail, 'Редактировать папку', SimpleArchiveFields, 'Создание папки', SimpleArchiveFields),
    new Page('my-archive', '/api/dumping/', false, SimpleArchiveView, 'Мой архив', SimpleArchiveFilter, true, 'ProjectDumpingModel', 'Создать папку', 'Мой архив', SimpleArchiveTable, 'Просмотр папок', SimpleArchiveDetail, 'Редактировать папку', SimpleArchiveFields, 'Создание папки', SimpleArchiveFields),

    new Page('invent', '/api/invent/', true, InventoryView, 'Инвентаризация', InventoryFilter, true, 'MaterialValueModel', 'Добавить номер', 'Инвентаризация', InventoryTable, 'Просмотр инвентарного номера', InventoryDetail, 'Редактировать номер', InventoryFields, 'Создание нового инвентарного номера', InventoryFields),
    new Page('my-invent', '/api/invent/', false, InventoryView, 'Мое оборудование', InventoryFilter, true, 'MaterialValueModel', false, 'Мое оборудование', InventoryTable, 'Просмотр инвентарного номера', InventoryDetail, 'Редактировать номер', InventoryFields, 'Создание нового инвентарного номера', InventoryFields),

    new Page('aho-invent', '/api/aho-invent/', true, AhoInventoryView, 'Инвент. АХО', AhoInventoryFilter, true, 'AhoMaterialValueModel', 'Добавить номер', 'Инвентаризация АХО', AhoInventoryTable, 'Просмотр инвентарного номера', AhoInventoryDetail, 'Редактировать номер', AhoInventoryFields, 'Создание нового инвентарного номера', AhoInventoryCreate),
    new Page('my-aho-invent', '/api/aho-invent/', false, AhoInventoryView, 'Моя инвент. АХО', AhoInventoryFilter, true, 'AhoMaterialValueModel', false, 'Моя инвентаризация АХО', AhoInventoryTable, 'Просмотр инвентарного номера', AhoInventoryDetail, 'Редактировать номер', AhoInventoryFields, 'Создание нового инвентарного номера', AhoInventoryFields),

    new Page('calendar', '/api/calendar/', true, CalendarView, 'Календарь', false, false, 'MeetingModel', 'Создать мероприятие', 'Календарь', EventCalendarTable, 'Просмотр мероприятия', EventCalendarDetail, 'Редактирование мероприятие', EventCalendarFields, 'Создать мероприятие', EventCalendarFields),
    new Page('my-calendar', '/api/calendar/', false, CalendarView, 'Мой календарь', false, false, 'MeetingModel', 'Создать мероприятие', 'Календарь', EventCalendarTable, 'Просмотр мероприятия', EventCalendarDetail, 'Редактирование мероприятие', EventCalendarFields, 'Создать мероприятие', EventCalendarFields),

    new Page('timelog', '/api/timelog/', true, TimelogView, 'Учет рабочего времени', TimelogFilter, false, 'TimeLogModel', 'Создать запись рабочего времени', 'Учет рабочего времени', TimelogTable, false, false, false, false, 'Создать запись рабочего времени', TimelogFields),
    new Page('my-timelog', '/api/timelog/', false, TimelogView, 'Мой учет рабочего времени', TimelogFilter, false, 'TimeLogModel', 'Создать запись рабочего времени', 'Мой учет рабочего времени', TimelogTable, false, false, false, false, 'Создать запись рабочего времени', TimelogFields),

    new Page('sms_service', '/api/sms_service/', false, SendSmsView, 'Создание СМС', SendSMSFilter, false, 'SMSModel', 'Создать СМС', 'Отправка СМС', SendSMSTable, false, SendSMSDetail, 'Редактировать СМС', SendSMSFields, 'Создать СМС', SendSMSFields, SendSMSCreate, false, false),

    new Page('upcoming_dates', '/api/upcoming_dates/', false, UpcomingDatesView, 'Ближайшие даты', false, false, null, null, 'Ближайшие даты', UpcomingDatesTable, false, null, null, null, null, null, null, false, false),


    {
        name: 'dashboard',
        use_permissions: false,
        title: 'Дашборд',
        model_name: 'dashboard',
        pages: {
            view: <Dashboard {...{
                url: domain + '/api/dashboard/?',
                name: "dashboard",
                title: 'Дашборд',
                filter: false
            }} />
        }
    },

    {
        name: 'client-task',
        use_permissions: true,
        title: 'Задачи клиентов',
        model_name: 'ClientTaskModel',
        pages: {
            view: <ClientsTaskView {...{
                url: domain + '/api/client_task/?',
                name: "client-task",
                title: "Задачи клиентов",
                component: ClientsTasksTable,
                use_permissions: true,
                model_name: "ClientTaskModel",
                create_title: false,
                filter: ClientsTasksFilter,
                search: true,
                is_my: false
            }} />,
            detail: <DetailPage {...{
                url: domain + '/api/client_task/',
                name: "client-task",
                title: 'Просмотр задачи',
                component: ClientsTasksDetail,
                use_permissions: true,
                model_name: "ClientTaskModel"
            }} />,
            edit: false
        }
    },

    {
        name: 'skud',
        use_permissions: true,
        title: 'Проходы',
        model_name: 'Skud',
        pages: {
            view: <EntranceView {...{
                url: domain + '/api/skud/event-list/?',
                name: "skud",
                title: "Проходы",
                component: EntranceTable,
                use_permissions: false,
                model_name: "Skud",
                create_title: false,
                filter: EntranceFilter,
                search: true,
                is_my: false
            }} />,
        }
    },
    {
        name: 'my-skud',
        use_permissions: false,
        title: 'Мои Проходы',
        model_name: 'Skud',
        pages: {
            view: <EntranceView {...{
                url: (localStorage.getItem('user_id') ? domain + '/api/skud/event-list/?user_id=' + JSON.parse(localStorage.getItem('user_id')) : false),
                name: "my-skud",
                title: "Мои проходы",
                component: EntranceTable,
                use_permissions: false,
                model_name: "Skud",
                create_title: false,
                filter: false,
                search: false,
                is_my: false
            }} />,
        }
    },


    {
        name: 'telephony',
        use_permissions: true,
        title: 'Записи звонков',
        model_name: 'CallModel',
        pages: {
            view: <TelephonyView {...{
                url: domain + '/api/ip_telephony/call-list/?',
                name: "telephony",
                title: "Записи звонков",
                component: TelephonyTable,
                use_permissions: true,
                model_name: "CallModel",
                create_title: false,
                filter: TelephonyFilter,
                search: true,
                is_my: false
            }} />,
        }
    },
    {
        name: 'my-telephony',
        use_permissions: false,
        title: 'Мои звонки',
        model_name: 'CallModel',
        pages: {
            view: <TelephonyView {...{
                url: domain + '/api/ip_telephony/call-list/?',
                name: "my-telephony",
                title: "Мои звонки",
                component: TelephonyTable,
                use_permissions: false,
                model_name: "CallModel",
                create_title: false,
                filter: TelephonyFilter,
                search: false,
                is_my: true
            }} />,
        }
    },
    {
        name: 'documents',
        use_permissions: false,
        title: 'Документы',
        model_name: 'GeneratedDocument',
        pages: {
            view: <DocumentsView {...{
                url: domain + '/api/doc-generator/doc/?',
                name: "documents",
                title: "Документы",
                component: DocumentsTable,
                use_permissions: false,
                model_name: "GeneratedDocument",
                create_title: 'Создать',
                filter: DocumentsFilter,
                search: false,
                is_my: false
            }} />,
            create: <CreatePage {...{
                url: domain + '/api/doc-generator/doc/?',
                name: "documents",
                title: "Создать документ",
                component: DocumentsCreate
            }} />,
        }
    },

]

export {routes}
