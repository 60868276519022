import React from "react";
import FormItem from "../../components/form/FormItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import { onKadastrInput } from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";



function ArchiveFields({ fields }) {
    return (
        <>
            <FormItem field={fields.organisation} required={true} classes="_full" />
            <FormItem field={fields.date_of_conclusion} classes="" />
            <FormItem field={fields.doc_number} classes="" />
            <FormItem field={fields.title} mask={onKadastrInput} required classes="_full" />
            <FormItem field={fields.municipality} classes="_full" />
            {/*<FormItem field={fields.microdistrict} classes="_full"/>*/}
            {/* <FormItem field={fields.category} classes="_full" /> */}
            <FormItem field={fields.description} classes="_full" />

            <SearchContragent field={fields.contragent} classes="_full" />
            {/* <SelectContragent field={fields.contragent} classes="_full" /> */}

            <DocumentAddFormItem field={fields.pasport_doc} classes="_full" />
            <DocumentAddFormItem field={fields.archive_doc} classes="_full" />
            <DocumentAddFormItem field={fields.other_doc} classes="_full" />

        </>

    )
}
export default ArchiveFields;