import React, { useState, useRef, useContext } from "react";
import { FileItem, InfoItem } from "../../components/InfoItem";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import { createOrUpdate, getDomain } from "../../functions/functions";
import FormErrorsContext from "../../functions/formErrorsContext";
import { useParams, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import FormItem from "../../components/form/FormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent"
import Alert from "../../components/Allert";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";



function ArchiveDetail(props) {
    const [loading, setLoading] = useState(false)
        , [canSave, setCanSave] = useState(false)
        , [succeed, setSucceed] = useState(false)
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , form = useRef()
        , params = useParams()
        , [formErrors, setFormErrors] = useState([])
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)


    const setErrors = (data) => {
        data.then(res => {
            console.log('errors', res)
        })
    }


    return (
        <>
            <Alert alert={alert} setAlert={setAlert} />
            <div className={"page__info-container"}>
                <InfoItem item={props.fields.organisation} classes="_full" />
                <InfoItem item={props.fields.date_of_conclusion} classes="_grid-start-1" />
                <InfoItem item={props.fields.doc_number} classes="" />
                <InfoItem item={props.fields.title} classes="_full" />
                <InfoItem item={props.fields.municipality} classes="_full" />
                {/* <InfoItem item={props.fields.microdistrict} classes="_full"/>*/}
                <InfoItem item={props.fields.category} classes="_full" />
                <InfoItem item={props.fields.description} classes="_full" />
                <InfoItem item={props.fields.contragent} classes="_full" />

                {/*<FileItem item={props.fields.pasport_doc} classes="_full"/>*/}
                {/*<FileItem item={props.fields.archive_doc} classes="_full"/>*/}
                {/*<FileItem item={props.fields.other_doc} classes="_full"/>*/}

                {/*<DocumentAddFormItem field={props.fields.pasport_doc} classes="_full flex-column-reverse"/>*/}
                {/*<DocumentAddFormItem field={props.fields.archive_doc} classes="_full flex-column-reverse"/>*/}
                {/*<DocumentAddFormItem field={props.fields.other_doc} classes="_full flex-column-reverse"/>*/}
            </div>
            <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                <form action={`${props.url}${params.id}/`} ref={form} onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, setErrors, () => { }, props.fields)}>

                    <div className="d-none">
                        <FormItem field={props.fields.organisation} classes="_full" />
                        <FormItem field={props.fields.municipality} classes="_grid-start-1" />
                        <FormItem field={props.fields.category} classes="" />
                        <FormItem field={props.fields.contragent} classes="" />
                        <FormItem field={props.fields.date_of_conclusion} classes="_full" />


                    </div>

                    <div className={'page__info-container mt-24'}>
                        <DocumentAddFormItem field={props.fields.pasport_doc} classes="_full flex-column-reverse" />
                        <DocumentAddFormItem field={props.fields.archive_doc} classes="_full flex-column-reverse" />
                        <DocumentAddFormItem field={props.fields.other_doc} classes="_full flex-column-reverse" />
                    </div>


                    <div className="form__buttons-panel">
                        <input type="submit" id="save_button" className="d-none" value="Send" />
                        <label htmlFor="save_button" style={{ width: "fit-content", marginLeft: "auto" }}
                            className='save_button_fixed mt-0 ml-0'>{loading ? 'Сохранение...' : 'Сохранить'}</label>
                    </div>
                </form>
            </FormErrorsContext.Provider>
            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} />
                </div>
            }
            <HistoryComponent id={props.id} name={props.name} />

        </>

    )
}
export default ArchiveDetail;