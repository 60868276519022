import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"

import HeaderInside from "./HeaderInside"
import { getItems, createFieldsObject, createOrUpdate } from "../functions/functions"
import ContentLoader from "./ContentLoader"
import Alert from "./Allert";

// Context
import FormErrorsContext from "../functions/formErrorsContext";

function EditPage(props) {
    const [fields, setFields] = useState({})
        , [loading, setLoading] = useState(false)
        , [status, setStatus] = useState(() => <ContentLoader />)
        , [formErrors, setFormErrors] = useState({})
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '',
        }) // error, alert, info, success
        , params = useParams()
        , navigate = useNavigate()


    // Сделано, чтобы не давать сохранять при отсутствие достаточного баланса
    const [canSave, setCanSave] = useState(true)

    useEffect(() => {
        setAlert({ title: '', text: '', type: '', })
        getItems(`${props.url}${params.id}/`, 'OPTIONS', setData, false, (error) => {
            setLoading(false)
            setStatus('')
            setAlert({
                title: 'Ошибка загрузки',
                text: error, //'При загрузке произошла ошибка, попробуйте обновить страницу',
                type: 'error', // error, alert, info, success
            })
        })
    }, [])

    const setErrors = (data) => {
        const newFields = { ...fields }
        data.then(res => {
            Object.keys(res).forEach(key => {
                newFields[key].error = res[key][0] === 'Ожидался date, но был получен datetime.' ? 'Заполните правильно дату' : res[key][0]
            })
            setFields(newFields)
        })
    }

    const navigateToView = (id) => {
        const url = props.name === 'lenta' ? `/${props.name}/detail/${id}` : `/${props.name}/`
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект изменен',
                    status: 'success'
                }
            }
        })
    }

    const setData = (data) => {
        setAlert({ title: '', text: '', type: '', })
        const newFields = createFieldsObject(data.actions.PUT)
        if (props.read_only) {
            for (const key of props.read_only) {
                if (newFields[key]) {
                    newFields[key].read_only = true
                }
            }
        }
        console.log("newFields", newFields);
        const setValues = (data) => {

            Object.keys(data).map((key, idx) => {
                              try {
                    if (newFields[key]) {
                        newFields[key].value = data[key]
                    } else {
                                               newFields[key].value = '1'
                    }

                    if (newFields[key].type === 'nested') {
                        newFields[key].value = data[key]
                    } else if (newFields[key]?.type === 'nested_files') {
                        newFields[key].value = data[key]
                    }
                } catch (e) {
                    console.log("error", e);
                }

            })

            setFields(newFields)


        }
        getItems(`${props.url}${params.id}/`, 'GET', setValues)
    }



    return (
        <div className="page">
            <HeaderInside title={props.title} />
            <hr />
            <div className={`page__content ${loading ? '_disabled' : ''}`}>
                <Alert setAlert={setAlert} alert={alert} />
                {Object.keys(fields).length > 0 ?
                    <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                        <form action={`${props.url}${params.id}/`} onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, setErrors, navigateToView, fields)}>
                            <div className="form-container">
                                <props.component fields={fields} is_my={props.is_my && true} setCanSave={(data) => {setCanSave(data)}}/>
                            </div>
                            {canSave && <div className="form__buttons-panel">
                                {/*<a  onClick={e => navigateToView(1)}>ложное сохранение</a>*/}
                                <input type="submit" id="save_button" className="d-none" value="Send" />
                                <label htmlFor="save_button" className={Object.keys(formErrors).length > 0 ? 'save_button_fixed _disable' : "save_button_fixed"}>{loading ? 'Сохранение...' : 'Сохранить'}</label>
                            </div>}
                        </form>
                    </FormErrorsContext.Provider>
                    : status
                }
            </div>
        </div>
    )
}

export default EditPage