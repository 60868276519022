import React, {useState, useEffect, useReducer} from "react";
import {useNavigate} from "react-router-dom"

import HeaderInside from "../../components/HeaderInside";
import {getItems, createFieldsObject, createOrUpdate} from "../../functions/functions";
import ContentLoader from "../../components/ContentLoader";
import Alert from "../../components/Allert";

// Context
import FormErrorsContext from "../../functions/formErrorsContext";

function SendSMSCreate(props) {
    const [fields, setFields] = useState({})
        , [loading, setLoading] = useState(false)
        , [status, setStatus] = useState(() => <ContentLoader/>)
        , [formErrors, setFormErrors] = useState({})
        , [alert, setAlert] = useState({
        title: '',
        text: '',
        type: '', // error, alert, info, success
    })
        , navigate = useNavigate()

    // Сделано, чтобы не давать сохранять при отсутствие достаточного баланса
    const [canSave, setCanSave] = useState(true)

    useEffect(() => {
        setAlert({title: '', text: '', type: '',})
        getItems(props.url, 'OPTIONS', setData, false, (error) => {
            setLoading(false)
            setStatus('')
            setAlert({
                title: 'Ошибка загрузки',
                text: error, //'При загрузке произошла ошибка, попробуйте обновить страницу',
                type: 'error', // error, alert, info, success
            })
        })
    }, [])

    const navigateToView = (id) => {
        const url = props.name === 'lenta' ? `/${props.name}/detail/${id}` : `/${props.name}/`
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект создан',
                    status: 'success'
                }
            }
        })
    }

    const setData = (data) => {
        const newFields = createFieldsObject(data.actions.POST)
        setFields(newFields)
    }

    const setErrors = (data) => {
        const newFields = {...fields}
        data.then(res => {
            Object.keys(res).forEach(key => {
                newFields[key].error = res[key][0] === 'Ожидался date, но был получен datetime.' ? 'Заполните правильно дату' : res[key][0]
            })
            setFields(newFields)
        })
    }

    useEffect(() => {
        console.log('canSave', canSave)
    }, [canSave])

    return (
        <div className="page">
            <HeaderInside title={props.title} icon="__to-back"/>
            <hr/>
            <div className={`page__content ${loading ? '_disabled' : ''}`}>
                <Alert setAlert={setAlert} alert={alert}/>

                {Object.keys(fields).length > 0 ?
                    <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
                        <form action={props.url} method="POST"
                              onSubmit={e => createOrUpdate(e, "POST", setLoading, setAlert, setErrors, navigateToView, fields)}>
                            <div className="form-container">
                                <props.component fields={fields} is_my={props.is_my ? true : false}
                                                 setCanSave={(data) => {setCanSave(data)}}/>
                            </div>
                            {canSave && <div className="form__buttons-panel">
                                <input type="submit" id="save_button" className="d-none" value="Send"/>
                                <label htmlFor="save_button"
                                       className={`save_button_fixed ${loading ? '_disable' : ''}`}>{loading ? 'Сохранение...' : 'Сохранить'}</label>
                            </div>}
                        </form>
                    </FormErrorsContext.Provider>
                    : status
                }
            </div>
        </div>
    )
}

export default SendSMSCreate
