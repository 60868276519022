import React, { useEffect } from "react";

import getFormattedDate from "../../functions/getFormattedDate";
import getFormattedDateTime from "../../functions/getFormattedDateTime"
import FileComponent from "../../components/FileComponent";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";

function LentaDetail({fields, url, type}) {
  //console.log('type', type)
  console.log('fields', fields);
  return (
    <div className="lenta">
      <h1 className="lenta__title">{fields.title.value}</h1>
      <div className="lenta__info">
        <span className="lenta__date">{getFormattedDate(fields.upload_at.value)}</span>
        {fields.organisation && <span className="lenta__organisation">{fields.organisation.value.map((org, idx) => (
          <span key={org.id}>{org.name}{(idx + 1) < fields.organisation.value.length ? ', ' : ''}</span>
        ))}</span>}
        <span className="lenta__author">{fields.author?.value?.last_name} {fields.author?.value?.first_name} {fields.author?.value?.patronymic}</span>
      </div>
      <div className="lenta__content" dangerouslySetInnerHTML={{__html: fields.content.value}}></div>
      <div>
        {fields.files?.value ? fields.files?.value.map((item) => (
          <FileComponent key={item.id} file={item.file} date={getFormattedDateTime(item.upload_at)} />
        )) : ''}
      </div>
      {fields.comments ? <CommentComponent comments={fields.comments} object_id={fields.id.value} type={type} /> : ''}
    </div>

  );
}
export default LentaDetail;
