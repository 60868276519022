import React, {useState, useEffect} from "react";

function Alert(props) {
    return(
        <>
            {props.alert.title &&
                <div className='alert-wrap'>
                    <div className={`page__${props.alert.type}`}>
                        <p className='alert-title'>{props.alert.title}</p>
                        {props.alert.text &&
                            <p className='alert-text'>{props.alert.text}</p>
                        }
                    </div>
                    <span onClick={e => props.setAlert('')}></span>
                </div>
            }
        </>
    )
}
export default Alert