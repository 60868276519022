import React, { useState, useEffect, useRef } from "react";
// import AgreementPeople from "../../components/partials/AgreementPeople";
import AgreementPeople from "../../components/AgreementPeople";
import { agreementGet, agreementGetbyId } from "../../api/agreement/agreementGet/agreementGet";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom"
import Check from "/public/page_icons/Check.svg";
import Disabled from "/public/page_icons/Disabled.svg";
import docFileCheck from "/public/page_icons/documents-file-checkmark.svg";
import docFileDelete from "/public/page_icons/documents-file-delete-cross.svg";
import sendMessage from "/public/page_icons/send-message.svg";
import clockRewind from "/public/page_icons/clock-rewind.svg";
import DeleteDisable from "/public/page_icons/DeleteDisable.svg";
import DoneCheck from "/public/page_icons/DoneCheck.svg";
import arrowBack from "/public/page_icons/arrowBack.svg";
import File from "/public/interface_img/file.svg";
import Trash from "/public/interface_img/delete.svg";
import chatMessage from "/public/page_icons/chat-message.svg";
import Agreed from "/public/page_icons/Agreed.svg";
import { getDomain, getFileName } from "../../functions/functions";
import FormErrorsContext from "../../functions/formErrorsContext";
// import { agreementPut } from "../../api/agreement/agreementPut/agreementPut";
import { agreementPut } from "../../api/agreement/agreementPut/agreementPut";
import { agreementUpdate } from "../../api/agreement/agreementUpdate/agreementUpdate";
import { agreementGetReport } from "../../api/agreement/agreementGetReport/agreementGetReport";
import { agreementUpload } from "../../api/agreement/agreementUpload/agreementUpload";
import { agreementDecline } from "../../api/agreement/agreementDecline/agreemenDecline";
import { agreementTransfer } from "../../api/agreement/agreementTransfer/agreementTransfer";
// import { createOrUpdate } from "../../functions/functions";
import { createOrUpdate } from "../../functions/functions";
import CommentComponent from "../../components/CommentCmponent/CommentComponent"
import AddedFormItem from "../../components/form/AddedFormItem";
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import FormItem from "../../components/form/FormItem";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";
import { contragetnGet } from "../../api/contragetnGet/agreementGet";
import getFormattedDateTime from "../../functions/getFormattedDateTime";
import getFormattedDateTimeSec from "../../functions/getFormattedDateTimeSec";
// import { getDomain } from "../../functions/functions";

function AgreementDetail(props) {

    const [isOpen, setIsOpen] = useState(false)
    const [isRefus, setIsRefus] = useState(null)
    const [isChat, setIsChat] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false);
    const [isData, setIsData] = useState()
    const [isError, setIsError] = useState(false);
    const [fileInput, setFileInput] = useState('');
    const [contragent, setContragent] = useState('');

    const [selectedFileName, setSelectedFileName] = useState('');

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await agreementGet(props.fields.id.value);
                setIsData(resp.data);
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [isUpdate]);

    useEffect(() => {
        if (isData?.contragent) {
            contragetnGet(isData?.contragent).then((resp) => {
                if (resp.success) {
                    setContragent(resp.data);
                }
            });
        }
    }, [isData]);


    const getFileType = (filename) => {
        const extension = filename.split('.').pop();
        return extension.toLowerCase(); // or use any other logic to format the file type
    };

    const handleInputChange = (event) => {
        setDataUpdate((prevData) => ({ ...prevData, comment: event.target.value }));
    };



    const handleFileInputChange = (event) => {
        setFileInput(event.target.value);
    };


    const [loading, setLoading] = useState(false)
        , [agreementStatus, setAgreementStatus] = useState(props.fields.status.value.id || 3)
        , [canSave, setCanSave] = useState(false)
        , form = useRef()
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , params = useParams()
        , [formErrors, setFormErrors] = useState([])
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)
        , comment_field = {
            id: "comment_reason",
            name: "comment_reason",
            label: "Причина отклонения",
        }


    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        uploadFile(file)
    };

    const openFileDialog = () => {
        fileInputRef.current.click();
    };


    const [indexForm, setIndexForm] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataComplete, setIsDataComplete] = useState(isData);

    const updateAgreementContent = (data) => {
        if (selectedFile != undefined) {
            const updatedIndexForm = indexForm + 1;
            setIsError(false)
            setIsLoading(true)
            agreementUpdate(props.fields.id.value, {
                agreement_type: isData.agreement_type,
                contragent: isData.contragent,
                date_of_approval: isData.date_of_approval,
                doc_name: isData?.doc_name,
                doc_num: isData?.doc_num,
                organisation: isData?.organisation?.id,
                status: isData?.status?.id,
                file: data.name,
                last_form_index: updatedIndexForm,
                comment: fileInput,
                organisation: isData.organisation
            }).then((resp) => {
                if (resp.success) {
                    setIndexForm(updatedIndexForm); // Обновление значения indexForm
                    setIsUpdate(true)
                }
            });
        } else {
            console.log('====================================');
            console.log('(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧');
            console.log('🔥🔥🔥ПОЛУЧЕНО ДОСТИЖЕНИЕ🔥🔥🔥');
            console.log('❌❌❌Моя эволюция на уровне амёбки, кидаю файл в компухтер, а он не прикрепляется❌❌❌');
            console.log('====================================');
            setIsError(true)
        }
    };

    useEffect(() => {
        if (isData) {
            setIsDataComplete({
                agreement_type: isData.agreement_type,
                contragent: isData.contragent,
                date_of_approval: isData.date_of_approval,
                doc_name: isData.doc_name,
                doc_num: isData.doc_num,
                organisation: isData?.organisation?.id,
                status: isData?.status?.id
            })
        }
    }, [isData])



    const uploadFile = (file) => {
        agreementUpload(file).then((resp) => {
            console.log(resp)
        })
    }
    const agrDecline = () => {
        const updatedIndexForm = indexForm + 1;
        agreementDecline(isData.id).then((resp) => {
            if (resp.success) {
                setIndexForm(updatedIndexForm); // Обновление значения indexForm
            }
        })
    }
    const [downloadedFileUrl, setDownloadedFileUrl] = useState('');


    const getReport = () => {
        agreementGetReport(isData.id).then((resp) => {
            setDownloadedFileUrl(resp.data)
        });
    };

    const [dataUpdate, setDataUpdate] = useState(null);
    const [isChoise, setIsChoice] = useState(null);

    const putAgreementContent = (refus) => {
        setIsLoading(true);
        setShowCounter(true);
        setIsChat(false);
        setIsRefus(refus)
    };

    //🔥 Обновление при отправке комментария или согласен/не согласен
    useEffect(() => {
        if (isData) {
            const levelId = isData?.level_agreements?.find(item =>
                item.rel_users.some(user => user?.user?.id == userData?.user_id)
            )?.id;

            setDataUpdate({
                id: isData.id,
                level_id: levelId || null,
                comment: dataUpdate?.comment,
                status_comment: isRefus === null ? 3 : isRefus,
                status: isRefus,
                user_id: Number(userData.user_id),
                form_index: isData?.last_form_index,
            });
        }
    }, [isData, isRefus, props, indexForm]);
    //❌ Конец

    //🔥 Создание массива с комментариями 
    const allUsersComment = [];
    const filteredComments = [];

    isData?.agreement_history?.forEach((history) => {
        history.users.forEach((user) => {
            const userName = user?.user?.first_name || "";
            const userLastName = user?.user?.last_name || "";
            const userPatronymic = user?.user?.patronymic || "";
            const userObject = `${userLastName} ${userName} ${userPatronymic}`;

            user.comments.forEach((comment) => {
                allUsersComment.push({
                    historyId: history.id,
                    user_name: userObject,
                    comment: comment.comment,
                    upload_at: comment.upload_at,
                    status_comment: comment.status_comment
                });
            });
        });
    });

    // Сортируем массив комментариев по дате в порядке возрастания
    allUsersComment.sort((a, b) => new Date(a.upload_at) - new Date(b.upload_at));
    isData?.agreement_history?.forEach((history) => {
        const commentsForHistory = allUsersComment.filter(comment => comment.historyId === history.id);
        filteredComments.push(commentsForHistory);
    });
    //❌ Конец создания массива с комментариями 


    const [timeLeft, setTimeLeft] = useState(3);
    const [showCounter, setShowCounter] = useState(false);



    // Запуск таймера при изменении showCounter
    useEffect(() => {
        if (showCounter) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
            }, 1000);

            // Очистка интервала, когда showCounter становится false
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [showCounter]);

    // Обработка таймера
    useEffect(() => {


        // Выполняется после завершения таймера
        // agreementPut(props.fields.id.value, dataUpdate).then((resp) => {
        //     if (resp.success) {
        //         setIsUpdate(!isUpdate);
        //         setIsLoading(false);
        //         setShowCounter(false)
        //         setTimeLeft(10)
        //     } else {
        //         setIsUpdate(!isUpdate);
        //         setIsLoading(false);
        //         setShowCounter(false)
        //         setTimeLeft(10)
        //     }
        // });
        if (timeLeft === 0) {
            // Выполняется после завершения таймера
            agreementPut(props.fields.id.value, dataUpdate).then((resp) => {
                if (resp.success) {
                    setIsUpdate(!isUpdate);
                    setIsLoading(false);
                    setShowCounter(false)
                    setTimeLeft(3)
                } else {
                    setIsUpdate(!isUpdate);
                    setIsLoading(false);
                    setShowCounter(false)
                    setTimeLeft(3)
                }
            });
        }

    }, [timeLeft, props.fields.id.value]);

    const Counter = ({ seconds }) => {
        return (
            <div className="containerCounter _full" >
                <div className={`counterInfo ${isChoise === 1 ? 'green' : 'red'}`}>
                    {isChoise === 1 ?
                        <>
                            <img src={DoneCheck} alt="Done Check icon" /> Согласовано
                        </>
                        : isChoise === 2 ?
                            <>
                                <img src={DeleteDisable} alt="Delete Disable icon" /> Не согласовано
                            </>
                            :
                            <>
                                Отправка комментария
                            </>
                    }
                </div>
                <div className="counterButton" onClick={() => { setShowCounter(false), setTimeLeft(3) }}>Отменить <img src={clockRewind} alt="Clock Rewind icon" /><p>{seconds}</p></div>
            </div>
        );
    };

    const hasRenderedBlock = useRef(false);

    return (
        <>
            <FormErrorsContext.Provider value={{ formErrors, setFormErrors }}>
                <form action={`${props.url}${params.id}/`} ref={form} onSubmit={e => createOrUpdate(e, "PUT", setLoading, setAlert, () => { }, navigateToView, isDataComplete)}>
                    <div className='page__info-container'>
                        {isOpen &&
                            isData?.file && (
                                <FileView
                                    file={selectedFileName}
                                    type={getFileType(selectedFileName)}
                                    onCLick={(value) => { setIsOpen(false) }}
                                />
                            )
                        }

                        <div className="_full listDocs">
                            <div>

                                <h1 className="infoAgreementTitle">{isData?.doc_name}</h1>
                                <p className="infoAgreement"><span className="agreementDateTime">Цена</span> {isData?.price}</p>
                                <p className="infoAgreement"><span className="agreementDateTime">Основание</span> {isData?.footing}</p>
                                <p className="infoAgreement"><span className="agreementDateTime">Номер</span> {isData?.doc_num}</p>
                                <p className="infoAgreement"><span className="agreementDateTime">Организация</span> {props.fields.organisation.children.map((item) => {
                                    if (item.value === isData?.organisation) {
                                        return item.display_name
                                    }
                                })}
                                </p>
                                <p className="infoAgreement">
                                    <span className="agreementDateTime">Контрагент</span> {
                                        contragent?.org_name ? contragent?.org_name : contragent?.fio
                                    }
                                </p>
                            </div>

                        </div>
                        {/* Первый уровень согласования */}
                        <AgreementPeople
                            nameInit={props.fields.initiator}
                            dateInit={isData?.agreement_history?.[0]?.upload_at}
                        />
                        {isData?.agreement_history?.map((history) => {
                            var minId = Infinity;
                            var maxId = Infinity;
                            // Поиск минимального значения
                            for (var i = 0; i < isData.agreement_history.length; i++) {
                                var id = isData.agreement_history[i].id;
                                if (id < minId) {
                                    minId = id;
                                } else {
                                    maxId = id
                                }
                            }

                            if (history.users.length === 0) {
                                return (
                                    <div className="_full">

                                        {minId === history.id ? (
                                            <></>
                                        ) : (
                                            <AgreementPeople
                                                nameInit={props.fields.initiator}
                                                dateInit={history.upload_at}
                                            />
                                        )}
                                        <div className="listDocs _full" key={history.id}>

                                            <h1 className="listDocsTitle">Документы на согласование</h1>
                                            <div className="docsList">
                                                {history?.file && (
                                                    <div className="fileNameContainer">
                                                        <img src={File} alt="file" />
                                                        <a
                                                            href={getDomain() + history.file}
                                                            download
                                                            className="docsLabel"
                                                            onClick={() => {
                                                                setSelectedFileName(history.file);
                                                            }}
                                                        >
                                                            {getFileName(history.file)}
                                                        </a>
                                                        {userData.user_id == history?.initiator && <img src={Trash} alt="trash" />}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {
                                            props.fields.initiator.children.map((item) => {
                                                if (item.value === isData.initiator && history.comment != '') {
                                                    return (
                                                        <div className="containerUserLoad border">
                                                            <p className="nameUserLoad">{item.display_name}</p>
                                                            <p className="textUserLoad">{history.comment}</p>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                        {isData.level_agreements.map((item) => {
                                            return item.rel_users.map((user) => {
                                                const userName = user?.user?.first_name;
                                                const userLastName = user?.user?.last_name;
                                                const userPatronymic = user?.user?.patronymic;
                                                const userObject = `${userLastName} ${userName} ${userPatronymic}`;
                                                return (
                                                    <div className="containerUserLoad" key={user?.user?.id}>
                                                        <p className="nameUserLoad">{userObject}</p>
                                                        <p className="textUserLoad">Ожидание согласования</p>
                                                    </div>
                                                );
                                            });
                                        })}

                                    </div>
                                );
                            } else {
                                return (
                                    <div className="_full container__history" style={{ opacity: (maxId > history.id && maxId != Infinity) && 0.5 }}>
                                        {minId === history.id ? (
                                            <></>
                                        ) :
                                            <AgreementPeople
                                                redo={true}
                                                nameInit={props.fields.initiator}
                                                dateInit={history.upload_at}
                                            />
                                        }


                                        <div className="listDocs _full" key={history.id} >
                                            <h1 className="listDocsTitle">Документы на согласование</h1>
                                            <div className="docsList">
                                                {history?.file && (
                                                    <div className="fileNameContainer">
                                                        <img src={File} alt="file" />
                                                        <a
                                                            className="docsLabel"
                                                            href={getDomain() + history.file}
                                                            download
                                                            onClick={() => {
                                                                setSelectedFileName(history.file);
                                                            }}
                                                        >
                                                            {getFileName(history.file)}
                                                        </a>
                                                        {userData.user_id == history?.initiator && <img src={Trash} alt="trash" />}
                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                        {
                                            props.fields.initiator.children.map((item) => {
                                                if (item.value === isData.initiator && history.comment != '') {
                                                    return (
                                                        <div className="containerUserLoad border">
                                                            <p className="nameUserLoad">{item.display_name}</p>
                                                            <p className="textUserLoad">{history.comment}</p>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                        {

                                            filteredComments.map((item) => {
                                                return item.map((comment, commentIndex) => {
                                                    if (comment.status_comment === 3) {
                                                        const date = new Date(comment.upload_at);
                                                        const formattedDateTime = date.toLocaleString(); // Форматирование даты и времени
                                                        if (history.id === comment.historyId) {
                                                            return (
                                                                <div className="containerUserLoad" key={commentIndex}>
                                                                    <p className="agreementDateTime">{formattedDateTime}</p>
                                                                    <p className="nameUserLoad">{comment.user_name}</p>
                                                                    <p className="textUserLoad">{comment.comment}</p>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                });
                                            })
                                        }

                                        {isData.level_agreements.flatMap((item) => item.rel_users).map((user) => {
                                            const hisUser = history.users.find((hisUser) => hisUser?.user?.id === user?.user?.id);

                                            const userName = user?.user?.first_name;
                                            const userLastName = user?.user?.last_name;
                                            const userPatronymic = user?.user?.patronymic;
                                            const userObject = `${userLastName} ${userName} ${userPatronymic}`;

                                            if (!hisUser || !hisUser.status) {
                                                return (
                                                    <div className="containerUserLoad" key={user?.user?.id}>
                                                        <p className="nameUserLoad">{userObject}</p>
                                                        <p className="textUserLoad">Ожидание согласования</p>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <AgreementPeople
                                                        agreementUser={userObject}
                                                        dateInit={hisUser.upload_at}
                                                        status={hisUser.status}
                                                        comments={hisUser.comments}
                                                        key={hisUser.id}
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                );
                            }
                        })}

                        {
                            userData.user_id != isData?.initiator && (
                                isData?.level_agreements?.map((item) => {
                                    if (item.visible) {
                                        return item.rel_users.map((user) => {
                                            return (
                                                (user?.user?.id == userData?.user_id && user?.last_status == null) && (

                                                    <>
                                                        {showCounter ?
                                                            <Counter seconds={timeLeft} />
                                                            :
                                                            <div className="buttonContainer _full">
                                                                {!isChat ?
                                                                    <>
                                                                        <div className="buttonSucces green" onClick={() => { setIsChat(true), setIsChoice(1) }}>

                                                                            <div className="containerLoad">
                                                                                <img src={Check}></img>
                                                                                <p>Согласовано</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="buttonSucces red" onClick={() => { setIsChat(true), setIsChoice(2) }}>

                                                                            <div className="containerLoad">
                                                                                <img src={Disabled}></img>
                                                                                <p>Не согласовано</p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="buttonSucces blue" onClick={() => { setIsChat(true), setIsChoice(0) }}><img src={chatMessage}></img></div> */}
                                                                    </>
                                                                    :
                                                                    isChoise === 0 ?
                                                                        <div className="commentContainer">
                                                                            {/* <div className="headerCommentContainer">
                                                                                <h1 className="titleMessageContainer">Написать комментарий</h1>
                                                                                <p className="backMessageButton" onClick={() => { setIsChat(false) }}><img src={arrowBack}></img>Отмена</p>
                                                                            </div>
                                                                            <textarea className="inputMessageDocs _full" placeholder="Комментарий" onChange={(e) => { handleInputChange(e) }} rows={1}></textarea>
                                                                            <div className="buttonMassage blue" onClick={() => { putAgreementContent(null) }}>Отправить<img src={sendMessage}></img></div> */}
                                                                        </div>
                                                                        : isChoise === 1 ?
                                                                            <div className="commentContainer _full">
                                                                                <div className="headerCommentContainer">
                                                                                    <h1 className="titleMessageContainer">Cогласовано</h1>
                                                                                    <p className="backMessageButton" onClick={() => { setIsChat(false) }}><img src={arrowBack}></img>Отмена</p>
                                                                                </div>
                                                                                <textarea className="inputMessageDocs _full" placeholder="Комментарий" onChange={(e) => { handleInputChange(e) }} rows={1}></textarea>
                                                                                <div className="buttonMassage green" onClick={() => { putAgreementContent(1) }}>Отправить<img src={sendMessage}></img></div>
                                                                            </div>
                                                                            :
                                                                            <div className="commentContainer _full">
                                                                                <div className="headerCommentContainer">
                                                                                    <h1 className="titleMessageContainer">Не согласовано</h1>
                                                                                    <p className="backMessageButton" onClick={() => { setIsChat(false) }}><img src={arrowBack}></img>Отмена</p>
                                                                                </div>
                                                                                <textarea className="inputMessageDocs _full" placeholder="Комментарий" onChange={(e) => { handleInputChange(e) }} rows={1}></textarea>
                                                                                <div className="buttonMassage red" onClick={() => { putAgreementContent(2) }}>Отправить<img src={sendMessage}></img></div>
                                                                            </div>
                                                                }
                                                            </div>
                                                        }
                                                    </>

                                                )
                                            );
                                        });
                                    }
                                })
                            )
                        }
                        
                        {userData.user_id == isData?.initiator && (
                            isData?.status?.status === "Доработка" &&
                            (

                                <>
                                    <div className="listDocs _full">
                                        <h1 className="listDocsTitle">Документы на согласованиe</h1>
                                        <div className="docsList">
                                            {selectedFile?.name && (
                                                <div className="fileNameContainer">
                                                    <img src={File}></img>
                                                    <a className="docsLabel"
                                                        href={getDomain() + history.file}
                                                        download
                                                    >
                                                        {getFileName(selectedFile.name)}
                                                    </a>
                                                    <img src={Trash}></img>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                            <p className="addFileButton" onClick={openFileDialog}>
                                                Добавить файл
                                            </p>
                                        </div>
                                    </div>

                                    {isError && <p className={'errorAgreement'}>Файл не выбран</p>}
                                    {isChat ?

                                        <div className="commentContainer _full">
                                            <div className="headerCommentContainer">
                                                <h1 className="titleMessageContainer">Написать комментарий</h1>
                                                <p className="backMessageButton" onClick={() => { setIsChat(false) }}><img src={arrowBack}></img>Отмена</p>
                                            </div>
                                            <textarea className="inputMessageDocs _full" placeholder="Комментарий" onChange={(e) => { handleFileInputChange(e) }} rows={1}></textarea>
                                            <div className="buttonMassage blue" onClick={() => { updateAgreementContent(selectedFile) }}>Отправить<img src={sendMessage}></img></div>
                                        </div>
                                        :
                                        <div className="buttonContainer _full">
                                            <div className="buttonSucces agreed" onClick={() => { updateAgreementContent(selectedFile) }}>
                                                {!isLoading ?
                                                    <div className="containerLoad">
                                                        <img src={docFileCheck}></img>
                                                        <p>На согласование</p>
                                                    </div>
                                                    :
                                                    <div>
                                                        <p>loading...</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="buttonSucces gray" onClick={() => { agrDecline() }}>
                                                {!isLoading ?
                                                    <div className="containerLoad">
                                                        <img src={docFileDelete}></img>
                                                        <p>Отменить договор</p>
                                                    </div>
                                                    :
                                                    <div>
                                                        <p>loading...</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="buttonSucces blue" onClick={() => { setIsChat(!isChat) }}>
                                                <img src={chatMessage}></img>
                                            </div>
                                        </div>
                                    }

                                    {hasRenderedBlock.current = true}
                                </>


                            )
                        )}

                        <>
                            {/* <h1 className="levelAgreement _full">Статус документа: {props.fields.status.children.map((item) => {
                                if (item.value === isData?.status) {
                                    return item.display_name
                                }
                            })}
                            </h1> */}
                            {
                                <div className="lenta _full">
                                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} />
                                </div>
                            }
                            <h1 className="levelAgreement _full">Статус документа: {isData?.status?.status}
                            </h1>
                            {isData?.status?.status === 'Согласован' && (
                                <>
                                    <div className="buttonContainer _full">
                                        <a
                                            className="buttonSucces saveListAgreement"
                                            href={downloadedFileUrl}
                                            download
                                            target="_blank"
                                            onClick={getReport}
                                        >
                                            <img src={Agreed} alt="Agreed Icon" />
                                            <p>Скачать лист согласования</p>
                                        </a>
                                    </div>
                                </>
                            )}


                        </>



                    </div>

                </form>
            </FormErrorsContext.Provider >

            <PersonalAccesses url={props.url} fields={props.fields} absolut_save_btn={false} page_name={props.name}>
                <div className="d-none">
                    <FormItem field={props.fields.file} />
                    <FormItem field={props.fields.doc_name} />
                    <FormItem field={props.fields.contragent} />
                    <FormItem field={props.fields.date_of_approval} />
                    <FormItem field={props.fields.doc_name} />
                    <FormItem field={props.fields.doc_num} />
                    <FormItem field={props.fields.organisation} />
                    <FormItem field={props.fields.agreement_type} />
                </div>
            </PersonalAccesses>
            <HistoryComponent id={props.id} name={'agreement'} />
        </>
    )
}
export default AgreementDetail