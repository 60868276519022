import React from "react";

import '../../styles/BirthdayCards.scss'
import CardsLenta from "./CardsLenta";
import {replace, useNavigate} from "react-router-dom";

export default function CustomCardsLenta({birthdayUsers = [], corpDateUsers = []}) {
    const navigate = useNavigate()

    const onClick = (e, props) => {
        e.preventDefault();
        navigate(`/users/detail/${props.id}?callbackUrl=/upcoming_dates`);
    }

    return birthdayUsers.length > 0 || corpDateUsers.length > 0 ?
        <div className={'wrapper-birthday-cards'}>
            <CardsLenta birthdayUsers={birthdayUsers} corpDateUsers={corpDateUsers} onClick={onClick}/>
        </div> :
        <></>
}

