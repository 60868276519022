import React from "react";
import { Link } from "react-router-dom";
import { checkNotifications } from "../../functions/functions";

function CounterpartyTable(props) {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())
    return (
        <>
            <table className="table _counterparty">
                <thead>
                    <tr>
                        <th>Наименование/ФИО</th>
                        <th>Тип</th>
                        <th>Статус</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, idx) => {
                        const notify = checkNotifications(item.id, page_notifications)
                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td><Link to={`detail/${item.id}`} className="table__link">{item.fio ? item.fio : item.org_name ? item.org_name : ''}</Link></td>
                                <td>{item.status && item.status.id ? item.status.id : item.status}</td>
                                <td>{item.partner ? 'Партнер' : item.client ? 'Действующий клиент' : ''}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {props.tableLoading && props.items.length === 0 ? props.tableLoading : ''}
        </>
    )
}

export default CounterpartyTable;