import React, { useState, useEffect } from 'react'

import WebSocketComponent from '../../functions/openSocket'
import GigachatComponent from './GigachatComponent'
import HeaderInside from '../../components/HeaderInside'

const Gigachat = (props) => {
    return (
        <div className='ai-page page'>
            {/* <WebSocketComponent handleMessageEvent={(e) => { console.log('wwww', e); }} /> */}
            <HeaderInside title={props.title} />
            <GigachatComponent url={props.url} />
        </div>
    )
}

export default Gigachat