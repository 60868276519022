import React, {useEffect, useState} from "react"
import AddFoldInput from "./AddFoldInput";
import {useSelector} from "react-redux";

function AddedFolder(props) {
    const [value, setValue] = useState(props.field.value),
        [edit, setEdit] = useState(false),
        [lastID, setlastID] = useState(),
        [num, setNum] = useState()
        ,userData = useSelector(state => state.user_data.userData)

    useEffect(() => {
        for (let folder of value) {
            const arr = []
            setlastID(folder.id)
            for(let file of folder.sub_other_doc){
                arr.push({"file": decodeURI(file.file)})
            }
            folder.sub_other_doc = arr
        }
        setValue([...value])

    }, [value.length])


    const addFold = (e, index) => {
        e.preventDefault()
        let new_idx = +index + 1
        const new_arr = [...value]
        const new_value= {
            'description': '',
            'sub_other_doc': [],
            'title':'Новая папка'
        }
        new_arr.push(new_value)
        setValue(new_arr)


    }
      const isAdmin = () =>{
        const groups = JSON.parse(userData.groups)
        return (groups.indexOf('Admin') !== -1)
    }
    const changeTitle = (index) => {
        setEdit(true)
        setNum(index)
    }
    const setNewData = (idx, name, data) => {
        value[idx][name] = data
        setValue([...value])
    }

    const setFiles = (idx, data) => {
        value[idx].sub_other_doc = data
        setValue([...value])
    }

    const deleteValue = (idx) => {

        const arr_values = value ? [...value] : []
            , toDelete = confirm('Вы уверены, что хотите удалить папку?')
        if (toDelete) {
            // console.log('on delete data', arr_values, idx)
            if (idx > -1) {
                arr_values.splice(idx, 1)
                setValue(arr_values)
                setEdit(false)
            }
        }
    }
    return (
        <>
            {value ? value.map((val, idx) => {
                return (
                    <div className={`file__item _full ${props.classes && props.classes}`} key={idx}>

                        <input type="hidden" name={props.field.name} onChange={() => {}} value={JSON.stringify(value)}/>
                        <AddFoldInput files={val.sub_other_doc} id={val.id} folderIdx={idx} setFiles={setFiles} name={'sub_dumping'} index={idx} />


                        <div className=''>
                            {edit && idx===num ?
                                <div className='d-flex flex-column'>

                                    <div className={`folder_item d-flex align-items-center`}>
                                        <input type="text"
                                               id={val.idx}
                                               value={val.title}
                                               onChange={e => {setNewData(idx, 'title', e.target.value);}}
                                               className={'sub-folder-title'}
                                        />
                                        <a onClick={e => {setEdit(false)} } className='folder-done mr-4'></a>

                                        {isAdmin() &&
                                            <span className={"delete-folder"} onClick={e => deleteValue(idx)}></span>
                                        }
                                        {/*<a onClick={e => {setEdit(false)} } className='folder-save'>готово</a>*/}
                                    </div>
                                    <div className={`folder_item`}>
                                        <input type="text"
                                               id={val.idx}
                                               value={val.description}
                                               onChange={e => {setNewData(idx, 'description', e.target.value);}}
                                               className={'sub-folder-description'}
                                               placeholder={'Введите описание...'}
                                        />
                                    </div>
                                </div>
                                :
                                <div className='folder-title'>
                                    <span className="folder-description ml-4">{val.description}</span>
                                    <div className='folder-title-block'>
                                        <span className="bold-title ml-4">{val.title}</span>
                                        <a onClick={e => changeTitle(idx)} className='folder-edit'></a>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                )

            }) : ''}
            <a href="" onClick={e => addFold(e, lastID)} className={"added-field-btn _grid-start-1"}>{props.button}</a>
        </>
    )
}



export default AddedFolder