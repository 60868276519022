import React, {useState} from "react"

function CheckboxItem(props) {
    const [value, setValue] = useState(props.field.value)
    return (
         <div className={"form__item " + props.classes}>
                <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span>
                <div className="checkbox-wrap form__checkbox">
                    <input type="checkbox"
                           id={props.field.name}
                           name={props.field.name}
                           placeholder={props.field.placeholder}
                           onChange={e => setValue(e.target.checked)}
                           checked={value}
                           required={props.required}
                           readOnly={props.readOnly}/>
                    <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
                </div>
            </div>
    )
}

export default CheckboxItem
