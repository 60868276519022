const getDateObject = (string = '') => {
    const r_ru = /^([0-2][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/
    if (r_ru.test(string)) {
        const date_arr = string.split('.')
        const new_date = `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`
        return new Date(new_date)
    } else {
        return string ? new Date(string) : new Date();
    }
}

export default getDateObject