import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import { InfoItem, FileItem } from "../../components/InfoItem";
import FormItem from "../../components/form/FormItem";
import TextareaItem from "../../components/form/TextareaItem";
import Alert from "../../components/Allert";
import DocumentAddFormItem from "../../components/form/DocumentAddFormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent";
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import FastTaskCreate from "../../components/form/FastTaskCreate";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";



function ServiceRecordsDetail(props) {
    console.log('props.fields', props.fields)
    const [loading, setLoading] = useState(false)
        , [agreementStatus, setAgreementStatus] = useState(props.fields.status.value.id || 3)
        , [canSave, setCanSave] = useState(false)
        , [alert, setAlert] = useState({
            title: '',
            text: '',
            type: '', // error, alert, info, success
        })
        , userData = useSelector(state => state.user_data.userData)

    function sendAgreement(value) {
        setAgreementStatus(value)
    }

    function symbolsNumber(e) {
        if (e.target.value.length > 1) {
            setCanSave(true)
        }
        else {
            setCanSave(false)
        }
    }

    return (

        <>
            <Alert setAlert={setAlert} alert={alert} />
            <div className={"page__info-container"}>
                <InfoItem item={props.fields.organisation} classes="_full" />
                <InfoItem item={props.fields.record_number} classes="_grid-start-1" />
                <InfoItem item={props.fields.date_of_creation} classes="" />
                <InfoItem item={props.fields.name} classes="_full" />
                <InfoItem item={props.fields.description} classes="_full" />
                <InfoItem item={props.fields.receiver} classes="_full" />
                <InfoItem item={props.fields.author} classes="_full" />
                <FileItem item={props.fields.file} classes="_full" />
                <FileItem item={props.fields.other_doc} classes="_full" />
                {props.fields.comment_reason ?
                    <InfoItem item={props.fields.comment_reason} classes="_full" />
                    : ""
                }
                {props.fields.comment_success ?
                    <InfoItem item={props.fields.comment_success} classes="_full" />
                    : ""
                }
                {props.fields.responsible ?
                    <InfoItem item={props.fields.responsible} classes="_full" />
                    : ""
                }
            </div>

            <PersonalAccesses url={props.url} fields={props.fields} page_name={props.name}>
                <div className="d-none">
                    <FormItem field={props.fields.organisation} />
                    <FormItem field={props.fields.record_number} />
                    <FormItem field={props.fields.date_of_creation} />
                    <FormItem field={props.fields.name} />
                    <FormItem field={props.fields.description} />
                    <FormItem field={props.fields.receiver} />
                    <FormItem field={props.fields.author} />
                    <FormItem field={props.fields.file} />
                    <DocumentAddFormItem field={props.fields.other_doc} />
                </div>
                {props.fields.status.value.id === 3 ?
                    <>
                        {+props.fields.receiver.value === +userData.user_id ?
                            <>
                                {agreementStatus === 3 ?
                                    <div className="page__info-container mt-16 records-buttons">
                                        <div className="agreed_button">
                                            <span htmlFor="agreed_button" onClick={() => sendAgreement(1)} className="service-records_button _agreed">Согласовано</span>
                                        </div>
                                        <div className="disagreed_button">
                                            <span htmlFor="disagreed_button" onClick={() => sendAgreement(2)} className="service-records_button _disagreed">Не согласовано</span>
                                        </div>
                                    </div>
                                    : ""
                                }
                                {agreementStatus === 2 ?
                                    <div className="page__info-container mt-16">
                                        <div className='_full d-flex justify-content-center'>
                                            <div className='service-records _status-disagreed'>Не согласовано</div>
                                            <span onClick={() => sendAgreement(3)} className="service-records _cancel">Отменить</span>
                                        </div>
                                        <TextareaItem field={props.fields.comment_reason} required={true} fieldHandler={symbolsNumber} classes="_full" />
                                    </div>
                                    : ""
                                }
                                {agreementStatus === 1 ?
                                    <div className="page__info-container mt-16">
                                        <div className='_full d-flex justify-content-center'>
                                            <div className='service-records _status-agreed'>Согласовано</div>
                                            <span onClick={() => sendAgreement(3)} className="service-records _cancel">Отменить</span>
                                        </div>
                                        <TextareaItem field={props.fields.comment_success} required={true} fieldHandler={symbolsNumber} classes="_full" />
                                        <FormItem field={props.fields.responsible} classes="_full" />
                                    </div>
                                    : ""
                                }
                            </>
                            : ''
                        }
                    </>
                    : ''
                }
                <div className="form__buttons-panel">
                    <input type="submit" id="save_button" className="d-none" value="Send" />
                    <label htmlFor="save_button" style={{ width: "fit-content", marginLeft: "auto" }} className='save_button_fixed mt-0 ml-0'>{loading ? 'Сохранение...' : 'Сохранить'}</label>
                </div>
                <input type="hidden" name="status" value={agreementStatus} />

            </PersonalAccesses>


            {props.fields.status.value.id === 1 ?
                <div className={"page__info-container"}>
                    <div className='service-records _status-agreed _full justify-content-center mt-24'>Согласовано</div>
                </div>
                : ""
            }
            {props.fields.status.value.id === 2 ?
                <div className={"page__info-container"}>
                    <div className='service-records _status-disagreed _full justify-content-center mt-24'>Не согласовано</div>
                </div>
                : ""
            }
            {props.fields.status.value.id === 1 ?
                // <a className='pdf-download' href={`https://collback.bik31.ru/service-pdf/${props.fields.id.value}`}>Скачать PDF</a>
                <a className='pdf-download' href={`https://collback.bik31.ru/service-pdf/${props.fields.id.value}`}>Скачать PDF</a>
                : ""}

            <FastTaskCreate index={props.fields.record_number.value} id={props.fields.id.value} text='служебки' url={'my-service-records'} />

            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value} type={props.type} />
                </div>
            }
              <HistoryComponent id={props.id} name={'service_records'} />
        </>
    )
}

export default ServiceRecordsDetail
