import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FormItem from "../../components/form/FormItem";
import SendSMSAddedFormItem from "./forms/sendSMSAddedFormItem";
import getFormattedDateTime from "../../functions/getFormattedDateTime"
import {getDomain, getItems} from "../../functions/functions";


const SendSMSFields = ({fields, setCanSave = null}) => {
    // console.log('fields', fields)

    const MESSAGE_COST = 5.5
    const MAX_OWNER_MESSAGE_LENGTH = 70
    const MAX_MULTI_MESSAGE_LENGTH = 67
    const SAVED_COST = 5000

    const CONTRAGENT_COUNTER = 0
    const MESSAGE_COUNTER = 1
    const PREDICT_COST = 2
    const BALANCE = 3

    const [expenses, setExpenses] = useState([
        {label: 'Контрагенты', value: 0},
        {label: 'Сообщения', value: 0},
        {label: 'Стоимость отправки (₽)', value: 0},
        {label: 'Баланс СМС организации (₽)', value: 0}
    ])

    const [isCalCost, setIsCalCost] = useState(true)

    useEffect(() => {
        const url = `${getDomain()}/api/sms_service/balance/`
        getItems(url, 'GET', (data) => {
            console.log('data', data)
            let resBalance = Number(data.balance) - Number(data.reserved_sum) - SAVED_COST

            if (resBalance <= 0) {
                resBalance = 0
            }

            setValueByIndexSave(setExpenses, Number(resBalance.toFixed(2)), BALANCE)
        })

        if (fields.cost.value) {
            const cost = fields.cost.value
            let newResBalance = expenses[BALANCE].value + Number(cost)

            if (newResBalance <= 0) {
                newResBalance = 0
            }
            setValueByIndexSave(setExpenses, Number(newResBalance.toFixed(2)), BALANCE)
            setValueByIndexSave(setExpenses, Number(cost), PREDICT_COST)
        }

        setCountOfContragents(Array.isArray(fields.contragent?.value) ? fields.contragent?.value : [])
        setCountOfMessage(fields.sms_text.value)
    }, []);

    const setValueByIndexSave = (setValue, value, index) => {
        setValue(prevState => {
            let newState = prevState
            newState[index].value = value
            return [...newState]
        })
    }

    const calPredictCost = () => {
        let count = Number(expenses[MESSAGE_COUNTER].value) * Number(expenses[CONTRAGENT_COUNTER].value) * MESSAGE_COST
        setValueByIndexSave(setExpenses, count, Number(PREDICT_COST.toFixed(2)))
    }

    const checkCost = () => {
        if (setCanSave) {
            if (Number(expenses[BALANCE].value) === 0) {
                setCanSave(false)
                return
            }

            const isGoodCost = Number(expenses[PREDICT_COST].value) <= Number(expenses[BALANCE].value)
            setCanSave(isGoodCost && Number(expenses[PREDICT_COST].value) <= SAVED_COST)
        }
    }

    const setCountOfMessage = (data) => {
        let count = Math.ceil(data.length <= MAX_OWNER_MESSAGE_LENGTH ? data.length / MAX_OWNER_MESSAGE_LENGTH : data.length / MAX_MULTI_MESSAGE_LENGTH)
        setValueByIndexSave(setExpenses, count, MESSAGE_COUNTER)
        setIsCalCost(true)
    }

    const setCountOfContragents = (data) => {
        // Убираем всех повторяющихся контрагентов
        setValueByIndexSave(setExpenses, [...new Set(data)].length, CONTRAGENT_COUNTER)
        setIsCalCost(true)
    }

    useEffect(() => {
        if (isCalCost) {
            calPredictCost()
            setIsCalCost(false)
        }
        checkCost()
    }, [expenses]);

    const getSmsTextField = () => {
        let newField = fields.sms_text
        newField.type = 'textarea'
        return newField
    }

    return (
        <>
            <FormItem field={fields.send_at} classes="_full"
                      value={fields.send_at?.value ? getFormattedDateTime(fields.send_at.value) : null}/>

            <div className={'send-sms-create-text-input'}>
                <FormItem field={getSmsTextField()} classes="_full" value={fields.sms_text.value}
                          setFormData={setCountOfMessage}/>
                <div className={'label'}>
                    1 сообщение / 70 символов
                </div>
            </div>
            <SendSMSAddedFormItem field={fields.contragent}
                                  classes="_full"
                                  button={'Добавить контрагента'}
                                  setFormData={setCountOfContragents}
                                  value={Array.isArray(fields.contragent?.value) ? fields.contragent?.value.map((item, idx) => {
                                      let newItem = item
                                      newItem.index = idx
                                      return newItem
                                  }) : null}/>

            <input name={fields.cost.name} value={Number(expenses[PREDICT_COST].value)} type="hidden"/>

            <div className={'send-sms-addition-path'}>
                <div className={'send-sms-create-expenses'}>
                    <header>Предварительные расходы на рассылку</header>
                    <table className={'expenses-table'}>
                        <tbody>
                        {expenses.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td className={'label'}>{item.label}</td>
                                    <td className={'item'}>{item.value}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <div className={'send-sms-create-info-banner'}>
                    Уважаемые сотрудники, баланс организации рассчитан на 1 месяц. Если баланс станет
                    нулевым, клиенты не
                    смогут воспользоваться личным кабинетом БИК и продажи участков прекратятся. Высчитав
                    стоимость отправки,
                    просим пополнить баланс СМС через служебную записку руководству.
                </div>
            </div>
        </>
    )
}

export default SendSMSFields