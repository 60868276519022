import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { getFileName } from '../../functions/functions'

const CommentFile = ({ file, onClick }) => {
    const [showPhoto, setShowPhoto] = useState(false)
    const name_arr = file.split('.')

    if (name_arr[name_arr.length - 1] === 'jpg' || name_arr[name_arr.length - 1] === 'jpeg' || name_arr[name_arr.length - 1] === 'png') {
        return (
            <>
                <div className='comment-file-image-container'>
                    <img src={file} onClick={() => setShowPhoto(true)} className={'comment-file-image'} />
                    <a href={file} download={getFileName(file)} className='comment-file-image-download' target='_blank'></a>
                </div>
                {showPhoto &&
                    <div className='comment-image-view-container' onClick={() => setShowPhoto(false)}>
                        <img src={file} className={''} />
                    </div>
                }
            </>
        )
    }
    else {
        return (
            <a href={file} className='comment-file' download={getFileName(file)} target='_blank'><span className='comment-file__name'>{getFileName(file)}</span></a>
        )
    }
}

export default CommentFile